import { useState, useEffect } from "react";

// react-router components
import { useLocation, Link, useHistory } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";
import { FiSun } from "react-icons/fi";
import { FaMoon } from "react-icons/fa";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";

// Vision UI Dashboard PRO React example components
import Breadcrumbs from "examples/Breadcrumbs";
import DefaultItem from "examples/Items/DefaultItem";
import jwt_decode from "jwt-decode";
import setAuthToken from "utils/setAuthToken";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarDesktopMenu,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";

// Vision UI Dashboard PRO React context
import {
  useVisionUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
  setThemeMode,
} from "context";

// Images
import { CgProfile, CgLogOut } from "react-icons/cg";

function DashboardNavbar({ absolute, light, isMini }) {
  let history = useHistory();
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useVisionUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator, themeMode } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);
  const [userName, setUserName] = useState("");
  const [logout, setLogout] = useState(false);

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    const decoded = jwt_decode(localStorage.jwtTokenBitDocs);
    setUserName(decoded.email);
    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleTheme = () => setThemeMode(dispatch, !themeMode);
  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = (type) => {
    setOpenMenu(false);
    if (type === "profile") {
      // window.location = "/bit-docs/profile";
      history.push("/btnt-docs/profile");
    }
  };
  // const handleLogOut = () => setLogout(false);

  function handleLogOut() {
    localStorage.removeItem("jwtTokenBitDocs");
    setAuthToken(false);
    window.location = "";
  }

  // Render the notifications menu

  const icon = themeMode === true ? <FiSun size="40%" /> : <FaMoon size="40%" />;
  const mode = themeMode === true ? "Light" : "Dark";

  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={() => handleCloseMenu("")}
      sx={{ mt: 2 }}
    >
      <DefaultItem
        icon={<CgProfile size="20px" color="#F2F0FE" />}
        title="Profile"
        p={2}
        onClick={() => handleCloseMenu("profile")}
      />

      <DefaultItem
        icon={<CgLogOut size="20px" color="#F2F0FE" />}
        title="Logout"
        onClick={() => handleLogOut()}
        p={2}
      />

      <DefaultItem icon={icon} title={mode} onClick={handleTheme} p={2} />
    </Menu>
  );

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <VuiBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} />
          <Icon fontSize="medium" sx={navbarDesktopMenu} onClick={handleMiniSidenav}>
            {miniSidenav ? "menu_open" : "menu"}
          </Icon>
        </VuiBox>
        {isMini ? null : (
          <VuiBox sx={(theme) => navbarRow(theme, { isMini })}>
            {/* <VuiBox pr={1} maxWidth={{ sm: "175px", md: "100%" }}>
            <VuiInput
              placeholder="Type here..."
              icon={{ component: "search", direction: "left" }}
              sx={({ breakpoints }) => ({
                [breakpoints.down("sm")]: {
                  maxWidth: "80px",
                },
                [breakpoints.only("sm")]: {
                  maxWidth: "80px",
                },
                backgroundColor: "info.main !important",
              })}
            />
          </VuiBox> */}

            <VuiBox color={light ? "white" : "inherit"}>
              {/* <Link to="/authentication/sign-in/basic"> */}
              <IconButton onClick={handleOpenMenu} sx={navbarIconButton} size="small">
                <Icon
                  sx={({ palette: { dark, text } }) => ({
                    color: text.main,
                  })}
                >
                  account_circle
                </Icon>
                <VuiTypography variant="button" fontWeight="medium" color="text">
                  {userName}
                </VuiTypography>
              </IconButton>
              {renderMenu()}
              {/* </Link> */}
              <IconButton
                size="small"
                color="text"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon color="text">{miniSidenav ? "menu_open" : "menu"}</Icon>
              </IconButton>
              {/* <IconButton
              size="small"
              color="white"
              sx={navbarIconButton}
              onClick={handleConfiguratorOpen}
            >
              <Icon>settings</Icon>
            </IconButton> */}
              {/* <IconButton
              size="small"
              color="white"
              sx={navbarIconButton}
              aria-controls="notification-menu"
              aria-haspopup="true"
              variant="contained"
              onClick={handleOpenMenu}
            >
              <Icon>notifications</Icon>
            </IconButton> */}
              {/* {renderMenu()} */}
            </VuiBox>
          </VuiBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
