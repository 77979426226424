import { Card, Typography } from "@mui/material";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React from "react";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { useState } from "react";
import VuiButton from "components/VuiButton";
import stripeCards from "assets/images/stripeCards.png";
import newShipLogo from "./newShipLogo.png";
import { FaCreditCard } from "react-icons/fa";
import { FaPlus } from "react-icons/fa";

import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import VuiInput from "components/VuiInput";
import { BsInfo } from "react-icons/bs";
import masterCardLogo from "./mastercard.jpeg";
import visaLogo from "./visa.png";
import dinersClubLogo from "./dinersclub.png";
import americanExpressLogo from "./americanexpress.png";
import gPayLogo from "./gpay.png";
import applePayLogo from "./applepay.jpg";

export default function PaymentOptions() {
  const [radioValue, setRadioValue] = useState("savedCard");
  const [addCardBox, setAddCardBox] = useState(false);
  const [savedCards, setSavedCards] = useState(true);

  const handleRadioChange = (e) => {
    setRadioValue(e.target.value);
  };

  const handleAddCard = () => {
    console.log("test");
    setAddCardBox(true);
  };

  const addCardBoxClose = () => {
    setAddCardBox(false);
  };

  const test =
    "https://checkout.stripe.com/c/pay/cs_test_b1k6E3xiuZcpGoZCheIfYDcivNacjbOmTjPpKS4mNOJfNDlCKMuzI617jY#fidkdWxOYHwnPyd1blpxYHZxWjA0SWlEZz1ER2RgXEJyTTZraEZcNDFxYVM3TUMyfzZTNX09QnA2f0NJS3U3XENObHYxYk5CQW4yamRrcmhcZkF9a05UXF1Id3ZWcDxpRk9CNTVJRnE9UmNONTVtV0xBbXdcZCcpJ3VpbGtuQH11anZgYUxhJz8ncWB2cVozakA8RlRhPF1jamYySFRkQEAneCUl";

  const handlePayStripe = () => {
    window.location.href = test;
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <Card>
        <VuiBox sx={{ marginBottom: "2rem" }}>
          <VuiTypography fontWeight="bold">Select a payment method</VuiTypography>
        </VuiBox>

        <VuiBox sx={{ display: "flex", justifyContent: "space-between" }}>
          <VuiBox
            sx={{
              border: "1px solid red",
              width: "50%",
              textAlign: "center",
            }}
          >
            <VuiBox sx={{ marginBottom: "0.5rem" }}>
              <VuiTypography fontWeight="bold">Payment with BTNT</VuiTypography>
            </VuiBox>
            <VuiBox>
              <img src={newShipLogo} width="10%" alt="btntLogo" />
            </VuiBox>
            <VuiBox sx={{ marginTop: "3rem" }}>
              <VuiButton color="info" variant="gradient">
                Pay with Btnt
              </VuiButton>
            </VuiBox>
          </VuiBox>

          <VuiBox sx={{ border: "1px solid black", width: "50%", textAlign: "center" }}>
            <VuiBox sx={{ marginBottom: "0.5rem" }}>
              <VuiTypography fontWeight="bold">Payment with Stripe</VuiTypography>
            </VuiBox>
            <VuiBox
              sx={{
                display: "flex",
                justifyContent: "center",
                gap: "0.5rem",
                alignItems: "center",
              }}
            >
              <img src={masterCardLogo} width="10%" height="40px" alt="accepted cards images" />
              <img src={visaLogo} width="10%" alt="accepted cards images" />
              <img src={dinersClubLogo} width="10%" alt="accepted cards images" />
              <img src={americanExpressLogo} width="10%" alt="accepted cards images" />
              <img src={gPayLogo} width="10%" alt="accepted cards images" />
              <img src={applePayLogo} width="9%" height="40px" alt="accepted cards images" />
            </VuiBox>
            <VuiBox>
              <VuiButton color="info" variant="gradient" onClick={() => handlePayStripe()}>
                Pay with Stripe
              </VuiButton>
            </VuiBox>
          </VuiBox>
        </VuiBox>

        {/* <VuiBox sx={{ width: "20%" }}>
          <Card sx={{ padding: "1rem" }}>
            <VuiButton fullWidth color="info" variant="gradient">
              Continue
            </VuiButton>
            <VuiTypography fontWeight="regular" fontSize="0.9rem" sx={{ textAlign: "center" }}>
              You can review this details before it's final.
            </VuiTypography>
          </Card>
        </VuiBox> */}
      </Card>

      {/* <VuiBox sx={{ marginBottom: "1rem" }}>
        <VuiTypography fontWeight="bold" fontSize="1.5rem">
          Select a payment method
        </VuiTypography>
      </VuiBox>
      <VuiBox sx={{ display: "flex", justifyContent: "space-between" }}>
        <Card sx={{ padding: "1rem", width: "75%" }}>
          <VuiBox sx={{ marginBottom: "2rem" }}>
            <Typography>Your saved credit and debit cards</Typography>
            <VuiBox sx={{ display: "flex" }}>
              <BsInfo />
              <Typography fontWeight="light" fontSize="0.8rem">
                Please ensure your card is enabled for online transactions.
              </Typography>
            </VuiBox>
          </VuiBox>
          <VuiBox>
            <FormControl sx={{ width: "100%" }}>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={radioValue}
                onChange={(e) => handleRadioChange(e)}
              >
                {savedCards ? (
                  <FormControlLabel
                    sx={{
                      margin: 0,
                      display: "flex",
                      alignItems: "flex-start",
                      marginBottom: "2rem",

                      // background: "#99c9ff",
                      "&:Mui-focused": { background: "#99c9ff" },
                    }}
                    value="savedCard"
                    control={<Radio sx={{ marginTop: "5px" }} />}
                    label={
                      <VuiBox sx={{}}>
                        <VuiBox
                          sx={{
                            display: "flex",
                            gap: "1rem",
                          }}
                        >
                          <VuiBox sx={{ display: "flex", gap: "0.4rem", marginBottom: "1rem" }}>
                            <VuiTypography fontWeight="bold" fontSize="0.9rem">
                              State Bank of India Debit Card
                            </VuiTypography>
                            <VuiTypography fontSize="0.9rem">ending in 8972</VuiTypography>
                          </VuiBox>

                          <VuiBox>
                            <VuiTypography fontSize="0.9rem">Kim Jong</VuiTypography>
                          </VuiBox>

                          <VuiBox>
                            <VuiTypography fontSize="0.9rem">09/2025</VuiTypography>
                          </VuiBox>
                        </VuiBox>

                        <VuiBox sx={{ marginBottom: "1rem" }}>Content</VuiBox>

                        <VuiBox>
                          <VuiTypography fontSize="0.9rem">
                            Save card as per new RBI guidelines.
                          </VuiTypography>
                        </VuiBox>
                      </VuiBox>
                    }
                  />
                ) : (
                  <VuiBox sx={{ marginBottom: "2rem" }}>
                    <VuiTypography>Currently u have no saved cards</VuiTypography>
                  </VuiBox>
                )}

                <VuiBox sx={{ marginBottom: "1rem" }}>
                  <VuiTypography>Another payment method</VuiTypography>
                </VuiBox>
                <FormControlLabel
                  sx={{
                    margin: 0,
                    display: "flex",
                    alignItems: "flex-start",
                    marginBottom: "2rem",
                  }}
                  value="addCard"
                  control={<Radio sx={{ marginTop: "7px" }} />}
                  label={
                    <VuiBox>
                      <VuiBox sx={{ marginBottom: "1rem" }}>
                        <VuiTypography fontWeight="bold" fontSize="0.9rem">
                          Add Debit/Credit/ATM Card
                        </VuiTypography>
                        <VuiTypography fontWeight="light" fontSize="0.9rem">
                          You can save your cards as per new RBI guidelines.
                        </VuiTypography>
                      </VuiBox>
                      <VuiBox sx={{ marginBottom: "1rem" }}>
                        <img src={stripeCards} alt="accepted cards images" />
                      </VuiBox>
                      {radioValue === "addCard" && (
                        <VuiBox
                          sx={{
                            display: "flex",
                            gap: "5px",
                            alignItems: "center",
                            color: "#0075ff",
                          }}
                        >
                          <FaPlus size="1rem" />
                          <FaCreditCard size="1rem" />
                          <VuiTypography
                            onClick={() => handleAddCard()}
                            color="inherit"
                            fontWeight="regular"
                            fontSize="0.9rem"
                            sx={{
                              "&:hover": { textDecoration: "underline" },
                            }}
                          >
                            Add a credit or debit card
                          </VuiTypography>
                        
                        </VuiBox>
                      )}
                    </VuiBox>
                  }
                />
                <FormControlLabel
                  sx={{ margin: 0, display: "flex", alignItems: "flex-start" }}
                  value="otherUpiApps"
                  control={<Radio sx={{ marginTop: "7px" }} />}
                  label={
                    <VuiBox>
                      <VuiBox>
                        <VuiTypography>Other UPI Apps</VuiTypography>
                      </VuiBox>
                      {radioValue === "otherUpiApps" && (
                        <VuiBox>
                          <VuiBox>
                            <VuiTypography fontSize="0.9rem">
                              Please enter your UPI ID
                            </VuiTypography>
                          </VuiBox>
                          <VuiBox sx={{ display: "flex", gap: "0.5rem" }}>
                            <VuiInput size="small" placeholder="Ex: MobileNumber@upi" />
                            <VuiButton color="info" variant="gradient">
                              Verify
                            </VuiButton>
                          </VuiBox>
                        </VuiBox>
                      )}
                    </VuiBox>
                  }
                />
              </RadioGroup>
            </FormControl>
          </VuiBox>
        </Card>
        <VuiBox sx={{ width: "20%" }}>
          <Card sx={{ padding: "1rem" }}>
            <VuiButton fullWidth color="info" variant="gradient">
              Continue
            </VuiButton>
            <VuiTypography fontWeight="regular" fontSize="0.9rem" sx={{ textAlign: "center" }}>
              You can review this details before it's final.
            </VuiTypography>
          </Card>
        </VuiBox>
      </VuiBox> */}

      {/* <Dialog
        //   sx={{ "& .MuiDialog-paper": { background: "rgb(242, 240, 254)" } }}
        fullWidth={true}
        maxWidth="md"
        open={addCardBox}
        onClose={() => addCardBoxClose()}
      >
        <VuiBox>
          <DialogTitle>
            <VuiTypography>Add a credit or debit card</VuiTypography>
          </DialogTitle>
          <DialogContent dividers sx={{ display: "flex", justifyContent: "space-around" }}>
            <VuiBox sx={{}}>
              <VuiBox sx={{ display: "flex", gap: "1rem", marginBottom: "0.5rem" }}>
                <VuiBox>
                  <VuiTypography fontSize="1rem">Card number</VuiTypography>
                </VuiBox>
                <VuiBox>
                  <VuiInput placeholder="number" size="small" />
                </VuiBox>
              </VuiBox>
              <VuiBox sx={{ display: "flex", gap: "1rem", marginBottom: "0.5rem" }}>
                <VuiBox>
                  <VuiTypography fontSize="1rem">Name on card</VuiTypography>
                </VuiBox>
                <VuiBox>
                  <VuiInput placeholder="number" size="small" />
                </VuiBox>
              </VuiBox>
              <VuiBox sx={{ display: "flex", gap: "1rem", marginBottom: "0.5rem" }}>
                <VuiBox>
                  <VuiTypography fontSize="1rem">Expiry date</VuiTypography>
                </VuiBox>
                <VuiBox sx={{ width: "40%" }}>
                  <VuiBox sx={{ display: "flex" }}>
                    <VuiInput
                      size="small"
                      placeholder="Month"
                      sx={{ borderRadius: "15px 0px 0px 15px" }}
                    />
                    <VuiInput
                      size="small"
                      placeholder="Year"
                      sx={{ borderRadius: "0px 15px 15px 0px" }}
                    />
                  </VuiBox>
                </VuiBox>
              </VuiBox>
            </VuiBox>

            <VuiBox>
              <img src={stripeCards} alt="accepted cards images" />
            </VuiBox>
          </DialogContent>
          <DialogActions>
            <VuiButton color="info" variant="gradient">
              Cancel
            </VuiButton>
            <VuiButton color="info" variant="gradient">
              Add your card
            </VuiButton>
          </DialogActions>
        </VuiBox>
      </Dialog> */}
    </DashboardLayout>
  );
}
