const axios = require("axios");
import swal from "sweetalert";

import { baseUrl } from "./../helpers/baseUrl";

export async function createContainer(containerData) {
  try {
    const response = await axios.post(
      `${baseUrl}/serviceProvider/container/addContainer`,
      containerData
    );
    return response.data;
  } catch (err) {
    if (err.message === "Network Error") {
      swal("Server is not responding", "Try again!", "error");
    }
    if (err.response) {
      const errors = [];
      if (err.response && err.response.data.msg.includes("already")) {
        swal(
          `Container for KEY = ${container.containerKey} is already exist`,
          "Try again!",
          "error"
        );
      }
      if (err.response && err.response.data.msg.includes("Key")) {
        errors.push(["Key", err.response.data.msg]);
      }

      if (err.response && err.response.data.msg.includes("record")) {
        swal("Something went wrong", "Try again!", "error");
      }
      if (err.response && err.response.data.msg.includes("fail")) {
        swal("Container is not added due to internel issue", "Please Try again!", "error");
      }
    }
  }
}

export async function getShipQuoatation() {
  var tokenStr = localStorage.getItem("jwtTokenBitDocs");
  axios.defaults.headers.common["authorization"] = tokenStr;
  const response = await axios.get(
    `${baseUrl}/serviceProvider/ship/getBookedShipsWithApprovedPaymentBySPID`
  );
  if (response) {
    console.log(">>>>>>>>>>>>>>>> getBookedShipsWithApprovedPaymentBySPID", response.data);
    return response.data;
  } else {
    swal("", response.message, "error");
  }
}

export async function getShipPendingQuoatation() {
  var tokenStr = localStorage.getItem("jwtTokenBitDocs");
  axios.defaults.headers.common["authorization"] = tokenStr;
  const response = await axios.get(
    `${baseUrl}/serviceProvider/ship/getBookedShipsWithPendingPaymentBySPID`
  );
  if (response) {
    return response.data;
  } else {
    swal("", response.message, "error");
  }
}
