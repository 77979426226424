

import { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import AppBar from "@mui/material/AppBar";

import VuiSwitch from "components/VuiSwitch";

// Vision UI Dashboard PRO React icons
import { IoDocument } from "react-icons/io5";
import { IoBuild } from "react-icons/io5";
import { IoMdCube } from "react-icons/io";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiAvatar from "components/VuiAvatar";

import breakpoints from "assets/theme/base/breakpoints";


// Images
import burceMars from "assets/images/avatar-bitnautic.png";

function Header() {
  
  const [visible, setVisible] = useState(true);

  const handleSetVisible = () => setVisible(!visible);

  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.lg
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);


  return (
    <Card id="profile">
      <VuiBox>
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <VuiAvatar
              src={burceMars}
              alt="profile-image"
              variant="rounded"
              size="xl"
              shadow="sm"
            />
          </Grid>
          <Grid item>
            <VuiBox height="100%" display="flex" flexDirection="column" mt={0.5} lineHeight={1}>
              <VuiTypography variant="lg" color="white" fontWeight="bold">
                Mark Johnson
              </VuiTypography>
              <VuiTypography variant="button" color="text" fontWeight="regular">
                mark@bitnautic.com
              </VuiTypography>
            </VuiBox>
          </Grid>
          <Grid item xs={12} md={6} lg={6.5} xl={6} xxl={4} sx={{ ml: "auto" }}>
            <AppBar position="static">
              <Tabs
                orientation={tabsOrientation}
                value={tabValue}
                onChange={handleSetTabValue}
                sx={{ background: "transparent", display: "flex", justifyContent: "flex-end" }}
              >
                <Tab
                  label="SEA"
                  icon={<IoMdCube size="16px" color="white" fontWeight="bold" />}
                />
                <Tab
                  label="LAND"
                  icon={<IoDocument size="16px" color="white" fontWeight="bold" />}
                />
                <Tab
                  label="AIR"
                  icon={<IoBuild size="16px" color="white" fontWeight="bold" />}
                />
              </Tabs>
            </AppBar>
          </Grid>
          
        </Grid>
      </VuiBox>
    </Card>
  );
}

export default Header;
