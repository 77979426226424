const axios = require("axios");
import { baseUrl } from "./../helpers/baseUrl";

export async function getUserFiles() {
  var tokenStr = localStorage.getItem("jwtTokenBitDocs");
  axios.defaults.headers.common["authorization"] = tokenStr;
  try {
    const response = await axios.get(`${baseUrl}/shipper/bitdocs/email/user/blockchain-files`);
    // console.log("userFiles", response);
    return response.data;
  } catch (err) {
    console.log(err.response);
    console.log(err.response.data);
    return err.response.data;
  }
}

export async function sendMail(composeData) {
  var tokenStr = localStorage.getItem("jwtTokenBitDocs");
  axios.defaults.headers.common["authorization"] = tokenStr;
  try {
    const response = await axios.post(`${baseUrl}/shipper/bitdocs/email/send`, composeData);
    console.log("sendMail", response);
    return response.data;
  } catch (err) {
    console.log(err.response);
    console.log(err.response.data);
    return err.response.data;
  }
}

export async function emailInboxReceived() {
  var tokenStr = localStorage.getItem("jwtTokenBitDocs");
  axios.defaults.headers.common["authorization"] = tokenStr;
  try {
    const response = await axios.get(`${baseUrl}/shipper/bitdocs/email/inbox/received`);
    // console.log("emailInboxRec", response);
    return response.data;
  } catch (err) {
    console.log(err.response);
    console.log(err.response.data);
    return err.response.data;
  }
}

export async function emailInboxReceivedById(id) {
  var tokenStr = localStorage.getItem("jwtTokenBitDocs");
  axios.defaults.headers.common["authorization"] = tokenStr;
  try {
    const response = await axios.get(`${baseUrl}/shipper/bitdocs/email/getEmailDetails/${id}`);
    console.log("emailInboxRecById", response);
    return response.data;
  } catch (err) {
    console.log(err.response);
    console.log(err.response.data);
    return err.response.data;
  }
}

export async function emailInboxSent() {
  var tokenStr = localStorage.getItem("jwtTokenBitDocs");
  axios.defaults.headers.common["authorization"] = tokenStr;
  try {
    const response = await axios.get(`${baseUrl}/shipper/bitdocs/email/inbox/sent`);
    // console.log("emailInboxSent", response);
    return response.data;
  } catch (err) {
    console.log(err.response);
    console.log(err.response.data);
    return err.response.data;
  }
}

export async function emailInboxSentById(id) {
  var tokenStr = localStorage.getItem("jwtTokenBitDocs");
  axios.defaults.headers.common["authorization"] = tokenStr;
  try {
    const response = await axios.get(`${baseUrl}/shipper/bitdocs/email/getEmailDetails/${id}`);
    console.log("emailInboxSentById", response);
    return response.data;
  } catch (err) {
    console.log(err.response);
    console.log(err.response.data);
    return err.response.data;
  }
}

export async function getUserList() {
  var tokenStr = localStorage.getItem("jwtTokenBitDocs");
  axios.defaults.headers.common["authorization"] = tokenStr;
  try {
    const response = await axios.get(`${baseUrl}/shipper/bitdocs/user-list`);
    console.log("getUserList", response);
    return response.data;
  } catch (err) {
    console.log(err.response);
    console.log(err.response.data);
    return err.response.data;
  }
}

export async function isRead(id) {
  console.log("isRead", id);
  var tokenStr = localStorage.getItem("jwtTokenBitDocs");
  axios.defaults.headers.common["authorization"] = tokenStr;
  try {
    const response = await axios.get(`${baseUrl}/shipper/bitdocs/email/read/${id}`);
    console.log("isRead", response);
    return response.data;
  } catch (err) {
    console.log(err.response);
    console.log(err.response.data);
    return err.response.data;
  }
}

export async function getIpfsHashData(hash) {
  console.log("hashedData", hash);
  // var tokenStr = localStorage.getItem("jwtTokenBitDocs");
  // axios.defaults.headers.common["authorization"] = tokenStr;
  try {
    const response = await axios.get(`${baseUrl}/serviceProvider/common/hash?hash=${hash}`);
    console.log("hashRes", response);
    return response.data;
  } catch (err) {
    console.log(err.response);
    console.log(err.response.data);
    return err.response.data;
  }
}
