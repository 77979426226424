import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";


import { styled } from '@mui/material/styles';
// @mui material components
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import AddIcon from "@mui/icons-material/Add";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
// @mui material components
// import VuiTypography from "components/VuiTypography";
// Vision UI Dashboard PRO React components
// import VuiBox from "components/VuiBox";;
import VuiSelect from "components/VuiSelect";
import VuiDatePicker from "components/VuiDatePicker";
// import VuiButton from "components/VuiButton";
import VuiInput from "components/VuiInput";
import VuiSwitch from "components/VuiSwitch";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";

// Vision UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import dataTableData from "layouts/applications/data-tables/data/dataTableData";

import { getProformaInvoice } from "services/documentService";
import { PDFViewer } from "@react-pdf/renderer";
import QRCode from "qrcode";
import Picture from "./ship.png";
import { getCompanyById } from "services/documentService";
import { isMobile, isBrowser } from "react-device-detect";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { updateProformaOnChain } from "services/documentService";
import Swal from "sweetalert2";
import { getActivePlan } from "services/pricingService";

import { checkCreateDocs } from "services/pricingService";
import ButtonGroup from "@mui/material/ButtonGroup";
import { deleteProformaInvoiceById } from "services/documentService";
import { MdDelete } from "react-icons/md";
import { MdModeEdit } from "react-icons/md";
import { FaEdit } from "react-icons/fa";

import ShipImage from "./ship.png";
import AttributeDescriptionTable from "./AttributeDescriptionTable";




function PreviewSection({productData, onHidePreview}) {
	//TODO: 1 Make this pretty.
	return (
		<div>
			<header><img src={productData.companyLogo} width="50px"></img></header>
			
			
		</div>
	);
}

/*
<h1>Preview Section</h1>
			
			<h2>Product details</h2>
			<p>{productData.product.name}</p>
			<p>Product image here</p>
			<img src={productData.product.image} width="200px"></img>
			<p>{productData.product.description}</p>
			<ul>
				{
					productData.product.attributes.map(
						(property, index) => <li key={index}>{property.attribute}: {property.description}</li>
					)
				}
			</ul>
			<h2>Supply Chain Details</h2>
			<p>{productData.supplier.name}</p>
			<p>{productData.supplier.description}</p>
			<p>Attributes:</p>
			<ul>
				{
					productData.supplier.attributes.map(
						(property, index) => <li key={index}>{property.attribute}: {property.description}</li>
					)
				}
			</ul>
			
			<div style={{display: "flex", justifyContent: "center"}}>
				<VuiButton variant="gradient" component="div" size="medium" color="info" sx={{ width: "200px", minWidth: "75px" }} onClick={onHidePreview}>
					Close
				</VuiButton>
			</div>
*/

export default PreviewSection;