const axios = require('axios');
import swal from 'sweetalert';

import {
    baseUrl
  } from '../helpers/baseUrl';

  
export async function trasportData(data) {
    const response = await axios.post(`${baseUrl}/user/auth/register`, data);
    if(response){
    return response.data;
    } else {
        swal("",response.message, "error");
    }
}