import React from "react";
import { useState, useRef, useEffect } from "react";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
// import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";
// Vision UI Dashboard PRO React components
// import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiSelect from "components/VuiSelect";
import VuiDatePicker from "components/VuiDatePicker";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
// import VuiButton from "components/VuiButton";
import VuiInput from "components/VuiInput";
import VuiSwitch from "components/VuiSwitch";
// Settings page components
import FormField from "layouts/pages/account/components/FormField";
// Data

import { createShip } from "services/shipService";
import { getPorts } from "services/shipService";
import "./proformaInvoice.css";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { MdDelete } from "react-icons/md";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import Stack from "@mui/material/Stack";
import AddIcon from "@mui/icons-material/Add";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
import { PDFDownloadLink } from "@react-pdf/renderer";
import ReactPDF from "@react-pdf/renderer";
import { PDFViewer } from "@react-pdf/renderer";
import jsPDF from "jspdf";
import PDFDocument from "./invPdfdoc";
import SignaturePad from "react-signature-canvas";
import Validations from "shared/validations";
import ValidationService from "shared/validationService";

import { addProformaInvoice, addCompany, getCompany } from "services/documentService";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Swal from "sweetalert2";
import Ship from "./ship.png";
import { getProformaInvoiceById } from "services/documentService";
import { editProformaInvoiceById } from "services/documentService";
import { useHistory } from "react-router-dom";

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];

const pdfStyles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#E4E4E4",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
});

const Input = styled("input")({
  display: "none",
});

let currentDate = new Date();
currentDate = `${currentDate.getFullYear()}-${
  currentDate.getMonth() + 1 <= 9 ? "0" + (currentDate.getMonth() + 1) : currentDate.getMonth() + 1
}-${currentDate.getDate() <= 9 ? "0" + currentDate.getDate() : currentDate.getDate()}`;
// console.log(">>>>>>>>>>>>>>> current date", currentDate);

function ProfomaInvoice() {
  let history = useHistory();
  const [errorMsg, setErrorMsg] = useState({
    companyName: { errorMsg: "" },
    place: { errorMsg: "" },
    address: { errorMsg: "" },
    phone: { errorMsg: "" },
    bankDetails: { errorMsg: "" },
    from: { errorMsg: "" },
    to: { errorMsg: "" },
    invoiceNumber: { errorMsg: "" },
    datee: { errorMsg: "" },
    buyerReference: { errorMsg: "" },
    methodOfDispatch: { errorMsg: "" },
    typeOfShipment: { errorMsg: "" },
    portOfLoading: { errorMsg: "" },
    portOfDischarge: { errorMsg: "" },
    totalAmount: { errorMsg: "" },
    placeOfIssue: { errorMsg: "" },
    date: { errorMsg: "" },
    signatoryCompany: { errorMsg: "" },
    nameOfAuthorizedSignatory: { errorMsg: "" },
    additionalInformation: { errorMsg: "" },
    bankDetails: { errorMsg: "" },
    signature: { errorMsg: "" },
  });

  // let stateRandomNumber = Math.random();
  const [proformaId, setProformaId] = useState("");
  const [open, setOpen] = useState(false);
  const [logo, setLogo] = useState(Ship);
  const [imgSrc, setImgsrc] = useState(Ship);
  const [commonCompany, setCommonCompany] = useState({
    companyName: "",
    place: "",
    address: "",
    phone: "",
    bankDetails: "",
  });
  const [from, setFrom] = useState({
    companyName: "",
    place: "",
    address: "",
    phone: "",
    bankDetails: "",
  });
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [datee, setDatee] = useState(currentDate);
  const [buyerReference, setBuyerReference] = useState("");
  const [to, setTo] = useState({
    companyName: "",
    place: "",
    address: "",
    phone: "",
    bankDetails: "",
  });
  const [showPreview, setShowPreview] = useState(false);
  const [methodOfDispatch, setMethodOfDispatch] = useState("");
  const [typeOfShipment, setTypeOfShipment] = useState("");
  const [portOfLoading, setPortOfLoading] = useState("");
  const [portOfDischarge, setPortOfDischarge] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const [additionalInformation, setAdditionalInformation] = useState("");
  const [bankDetails, setBankDetails] = useState("");
  const [placeOfIssue, setPlaceOfIssue] = useState("");
  const [date, setDate] = useState(currentDate);
  const [signatoryCompany, setSignatoryCompany] = useState("");
  const [nameOfAuthorizedSignatory, setNameOfAuthorizedSignatory] = useState("");
  const [shipperDataTableValue, setShipperDataTableValue] = useState([
    {
      // customId: stateRandomNumber,
      productCode: "",
      descriptionOfGoods: "",
      unitQuantity: "",
      unitType: "",
      price: "",
      amount: 0,
    },
  ]);

  const [signature, setSignature] = useState("");
  const [dialogInformation, setDialogInformation] = useState({
    title: "",
    type: "from",
  });

  const [imageURL, setImageURL] = useState(null);
  const sigCanvas = useRef({});
  const [searchedPorts, setSearchedPorts] = useState([]);
  const [company, setCompany] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [update, setUpdate] = useState(false);

  useEffect(() => {
    handleGetCompany();
  }, [shipperDataTableValue]);

  const customValidations = (name, value) => {
    let errorLength = 0;
    const args = [
      {
        name,
        value: value,
        validations: Validations.proformaInvoiceValidations[name],
      },
    ];

    ValidationService.validate(args, (validationErr, validationErrMsgs) => {
      if (validationErrMsgs.length > 0) {
        errorLength = errorLength + 1;
        errorMsg[name].errorMsg = validationErrMsgs[0].validations[0].message;
        setErrorMsg({ ...errorMsg });
      } else {
        errorMsg[name].errorMsg = "";
        setErrorMsg({ ...errorMsg });
      }
    });
    return errorLength;
  };

  const handleChangeInput = (name, value) => {
    if (name === "from") {
      setFrom(value);
    } else if (name === "to") {
      setTo(value);
    } else if (name === "invoiceNumber") {
      setInvoiceNumber(value);
    } else if (name === "datee") {
      setDatee(value);
    } else if (name === "buyerReference") {
      setBuyerReference(value);
    } else if (name === "methodOfDispatch") {
      setMethodOfDispatch(value);
    } else if (name === "typeOfShipment") {
      setTypeOfShipment(value);
    } else if (name === "portOfLoading") {
      setPortOfLoading(value);
    } else if (name === "portOfDischarge") {
      setPortOfDischarge(value);
    } else if (name === "additionalInformation") {
      setAdditionalInformation(value);
    } else if (name === "placeOfIssue") {
      setPlaceOfIssue(value);
    } else if (name === "date") {
      setDate(value);
    } else if (name === "signatoryCompany") {
      setSignatoryCompany(value);
    } else if (name === "nameOfAuthorizedSignatory") {
      setNameOfAuthorizedSignatory(value);
    } else if (name === "bankDetails") {
      setBankDetails(value);
    }
  };

  // console.log("Signture", signature);

  useEffect(() => {
    if (window.location.search !== "") {
      let queryString = window.location.search;
      let urlParams = new URLSearchParams(queryString);
      let id = urlParams.get("id");
      setProformaId(id);
      // console.log(id);

      getProformaInvoiceById(id).then((resp) => {
        console.log("resp", resp);
        resp[0].fromCompanyDataId[0].label = resp[0].fromCompanyDataId[0].company_name;
        resp[0].toCompanyDataId[0].label = resp[0].toCompanyDataId[0].company_name;
        // resp[0].goodsAndPackages[unitType].label = resp[0].toCompanyDataId[0].company_name;
        resp[0].goodsAndPackages.forEach((element) => {
          element.unitType = { value: element.unitType, label: element.unitType };
        });
        setFrom(resp[0].fromCompanyDataId[0]);
        setTo(resp[0].toCompanyDataId[0]);

        setInvoiceNumber(resp[0].invoiceNumber);
        setBuyerReference(resp[0].buyersReference);
        setMethodOfDispatch({ value: resp[0].methodOfDispatch, label: resp[0].methodOfDispatch });
        setTypeOfShipment({ value: resp[0].typeOfShipment, label: resp[0].typeOfShipment });
        setPortOfLoading({ value: resp[0].portOfLoading, label: resp[0].portOfLoading });
        setPortOfDischarge({ value: resp[0].portOfDischarge, label: resp[0].portOfDischarge });
        setShipperDataTableValue(resp[0].goodsAndPackages);
        setTotalAmount(resp[0].totalAmount);
        setPlaceOfIssue({ value: resp[0].placeOfIssue, label: resp[0].placeOfIssue });
        setAdditionalInformation(resp[0].additionalInfo);
        setBankDetails(resp[0].bankDetails);
        setSignatoryCompany(resp[0].signatoryCompany);
        setNameOfAuthorizedSignatory(resp[0].nameOfAuthorizedSignatory);
        setLogo(resp[0].logourl);
        setImgsrc(resp[0].logourl);

        getBase64FromUrl(resp[0].signatureUrl).then((base) => {
          // console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", base);

          setSignature(base);
        });

        setUpdate(true);
      });
    } else {
      console.log("emptyurl");
    }
  }, []);

  const getBase64FromUrl = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
  };

  const hanldeAddDataTableRow = () => {
    let totalLength = shipperDataTableValue.length;
    let randomNumber = Math.random();
    // console.log(">>>>>>>>>>>>>> totalLength", totalLength);
    shipperDataTableValue.push({
      // customId: randomNumber,
      productCode: "",
      descriptionOfGoods: "",
      unitQuantity: "",
      unitType: "",
      price: "",
      amount: 0,
    });

    setShipperDataTableValue([...shipperDataTableValue]);
  };

  const totalAmountAdder = (tableData) => {
    let sum = 0;
    tableData.forEach((obj) => {
      // console.log("test>>>>", obj.amount);
      sum += obj.amount;
      setTotalAmount(sum);
    });
  };

  const handleDeleteCommodity = (id) => {
    // console.log(">>>>>>>>>>>>>>> shipper datatable value", shipperDataTableValue);
    // console.log(">>>>>>>>>>>>>>> delete index", id);

    let newShipperData = [...shipperDataTableValue];
    newShipperData.splice(id, 1);
    // console.log(">>>>>>>>>>>>. new shipper data", newShipperData);
    setShipperDataTableValue([...newShipperData]);

    totalAmountAdder(newShipperData);
  };

  const handleOnChangeCommodity = (name, value, id) => {
    console.log(">>>>>>>>>>>>>>>>>>.handleOnChangeCommodity", name, value, id - 1);
    if (name === "productCode") {
      shipperDataTableValue[id].productCode = value;
    } else if (name === "descriptionOfGoods") {
      shipperDataTableValue[id].descriptionOfGoods = value;
    } else if (name === "unitQuantity") {
      shipperDataTableValue[id].unitQuantity = value;
    } else if (name === "unitType") {
      shipperDataTableValue[id].unitType = value;
    } else if (name === "price") {
      shipperDataTableValue[id].price = value;
    }

    shipperDataTableValue[id].amount =
      shipperDataTableValue[id].unitQuantity * shipperDataTableValue[id].price;

    totalAmountAdder(shipperDataTableValue);
    console.log(">>>>>>>>>>>>>> new commodity", shipperDataTableValue);
    setShipperDataTableValue([...shipperDataTableValue]);
  };

  const handleGetCompany = () => {
    getCompany().then((response) => {
      let newResponse = [];
      if (response.length > 0) {
        for (let data of response) {
          newResponse.push({
            ...data,
            label: data.company_name,
          });
        }
      }
      setCompany(newResponse);
      // console.log(">>>>>>>>>>>> get Company new response", newResponse);
    });
  };

  const findPort = (e) => {
    if (e != undefined || e != "") {
      if (e.length < 2) return;
      getPorts(e).then((response) => {
        const resp = response.map((data) => {
          data.label = `${data.portName} (${data.unloCode}) (${data.countryName})`;
          data.value = data.portName;
          return data;
        });
        // console.log(resp, "resp");
        setSearchedPorts(resp);
      });
    }
  };

  const handleUploadLogo = (e) => {
    // console.log(">>>>>>>>>>>>>> upload file", e.target.files[0]);
    setLogo(e.target.files[0]);
    var file = e.target.files[0];
    var reader = new FileReader();
    var url = reader.readAsDataURL(file);

    reader.onloadend = function (e) {
      setImgsrc(reader.result);
    };
  };

  const handleDeleteLogo = () => {
    setImgsrc("");
  };

  const handleAddShipper = (type) => {
    if (type === "from") {
      setDialogInformation({
        title: "Add From",
        type: "from",
      });
    } else if (type === "to") {
      setDialogInformation({
        title: "Add to",
        type: "to",
      });
    }

    setOpen(true);
  };

  const handleClose = () => {
    setDialogInformation({
      title: "",
      type: "from",
    });
    setOpen(false);
  };

  const handleSaveDialogData = () => {
    let errorLengthCompanyName = customValidations("companyName", commonCompany.companyName);
    let errorLengthPlace = customValidations("place", commonCompany.place);
    let errorLengthAddress = customValidations("address", commonCompany.address);
    let errorLengthPhone = customValidations("phone", commonCompany.phone);
    let errorLengthbankDetails = customValidations("bankDetails", commonCompany.bankDetails);

    if (
      errorLengthCompanyName !== 1 &&
      errorLengthPlace !== 1 &&
      errorLengthAddress !== 1 &&
      errorLengthPhone !== 1 &&
      errorLengthbankDetails !== 1
    ) {
      console.log("all ok ");
    } else {
      console.log("not ok");

      return;
    }

    // let errorLength = 0;
    let type = dialogInformation.type;

    // console.log(
    //   "conditions--------",
    //   commonCompany.companyName !== "",
    //   commonCompany.place !== "",
    //   commonCompany.address !== "",
    //   "" + commonCompany.phone !== "",
    //   "" + commonCompany.bankDetails !== "",
    //   commonCompany
    // );

    // if (
    //   commonCompany.companyName !== "" &&
    //   commonCompany.place !== "" &&
    //   commonCompany.address !== "" &&
    //   "" + commonCompany.phone !== "" &&
    //   "" + commonCompany.bankDetails !== ""
    // ) {
    //   errorLength = 1;
    // }

    addCompany({ ...commonCompany })
      .then((response) => {
        if (type == "from") {
          setFrom({ ...commonCompany });
        } else if (type == "to") {
          setTo({ ...commonCompany });
        }
        handleAddCompany();
      })
      .catch((error) => {
        console.log("add company error", error);
      });
  };

  const handleAddCompany = () => {
    handleGetCompany();
    handleClose();
    setCommonCompany({
      companyName: "",
      place: "",
      address: "",
      phone: "",
      bankDetails: "",
    });
    Swal.fire("", "Company added successfully", "success");
  };

  const handleDialogInformation = (name, value) => {
    console.log(">>>>>>>>>>>>>> handleDialogInformation", name, value);
    setCommonCompany({ ...commonCompany, [name]: value });
  };

  const clearCanvas = () => {
    sigCanvas.current.clear();
    setSignature("");
  };

  const handleSaveSignature = () => {
    // console.log("sgin>>>>>>>>", sigCanvas.current);
    setSignature(sigCanvas.current.toDataURL());
  };

  const showSignature = () => {
    sigCanvas.current?.fromDataURL(signature);
  };

  useEffect(() => {
    showSignature();
  }, [showPreview, signature]);

  const handleShowSinature = () => {
    sigCanvas.current.fromDataURL(signature);
  };

  const handleSavePDF = () => {
    ReactPDF.render(
      <PDFDocument
        value={{
          logo: imgSrc,
          fromCompany: from,
          invoiceNumber: invoiceNumber,
          datee: datee,
          toCompany: to,
          buyerReference: buyerReference,
          methodOfDispatch: methodOfDispatch,
          typeOfShipment: typeOfShipment,
          portOfLoading: portOfLoading.value,
          portOfDischarge: portOfDischarge.value,
          totalAmount: totalAmount,
          additionalInformation: additionalInformation,
          placeOfIssue: placeOfIssue,
          date: date,
          signatoryCompany: signatoryCompany,
          nameOfAuthorizedSignatory: nameOfAuthorizedSignatory,
          signature: signature,
          bankDetails: bankDetails,
          shipperDataTableValue: shipperDataTableValue,
        }}
      />,
      `${__dirname}/example.pdf`
    );
  };

  const handleShowPreview = () => {
    if (showPreview) {
      setShowPreview(false);
    } else {
      setShowPreview(true);
    }
  };

  const handleClosePreview = () => {
    setShowPreview(false);
  };

  const handleCheckedDataTableValue = () => {
    let error = false;
    for (let data of shipperDataTableValue) {
      if (
        !error &&
        (data.productCode === "" ||
          data.descriptionOfGoods === "" ||
          data.unitQuantity === "" ||
          data.unitType === "" ||
          data.price === "" ||
          data.amount === "")
      ) {
        console.log(">>>>>>>>>>>>>>>>if error");
        error = true;
      }
    }
    console.log(">>>>>>>>>>>>> error", error);
    return error;
  };

  const handleSubmitProformaInvoice = async () => {
    let tableError = handleCheckedDataTableValue();

    let errorLengthFrom = customValidations("from", from.id);
    let errorLengthTo = customValidations("to", to.id);
    let errorLengthInvoiceNumber = customValidations("invoiceNumber", invoiceNumber);
    let errorLengthDatee = customValidations("datee", datee);
    let errorLengthBuyerReference = customValidations("buyerReference", buyerReference);
    let errorLengthMethodOfDispatch = customValidations("methodOfDispatch", methodOfDispatch);
    let errorLengthTypeOfShipment = customValidations("typeOfShipment", typeOfShipment);
    let errorLengthPortOfLoading = customValidations("portOfLoading", portOfLoading);
    let errorLengthPortOfDischarge = customValidations("portOfDischarge", portOfDischarge);
    let errorLengthTotalAmount = customValidations("totalAmount", totalAmount);
    let errorLengthAdditionalInformation = customValidations(
      "additionalInformation",
      additionalInformation
    );
    let errorLengthPlaceOfIssue = customValidations("placeOfIssue", placeOfIssue);
    let errorLengthDate = customValidations("date", date);
    let errorLengthSignatoryCompany = customValidations("signatoryCompany", signatoryCompany);
    let errorLengthNameOfAuthorizedSignatory = customValidations(
      "nameOfAuthorizedSignatory",
      nameOfAuthorizedSignatory
    );
    let errorLengthBankDetails = customValidations("bankDetails", bankDetails);
    if (
      // imgSrc !== "" &&
      // from.companyName !== "" &&
      // invoiceNumber !== "" &&
      // datee !== "" &&
      // buyerReference !== "" &&
      // to.companyName !== "" &&
      // methodOfDispatch !== "" &&
      // typeOfShipment !== "" &&
      // portOfLoading !== "" &&
      // portOfDischarge !== "" &&
      // totalAmount !== "" &&
      // additionalInformation !== "" &&
      // placeOfIssue !== "" &&
      // date !== "" &&
      // signatoryCompany !== "" &&
      // nameOfAuthorizedSignatory !== "" &&
      // signature !== "" &&
      // bankDetails !== "" &&
      // shipperDataTableValue !== "" &&
      // tableError === false

      errorLengthFrom !== 1 &&
      errorLengthTo !== 1 &&
      errorLengthInvoiceNumber !== 1 &&
      errorLengthDatee !== 1 &&
      errorLengthBuyerReference !== 1 &&
      errorLengthMethodOfDispatch !== 1 &&
      errorLengthTypeOfShipment !== 1 &&
      errorLengthPortOfLoading !== 1 &&
      errorLengthPortOfDischarge !== 1 &&
      errorLengthTotalAmount !== 1 &&
      errorLengthAdditionalInformation !== 1 &&
      errorLengthPlaceOfIssue !== 1 &&
      errorLengthDate !== 1 &&
      errorLengthSignatoryCompany !== 1 &&
      errorLengthNameOfAuthorizedSignatory !== 1 &&
      errorLengthBankDetails !== 1 &&
      signature !== "" &&
      tableError === false
    ) {
      console.log("all ok");
      let goodsAndPackages = [];
      for (let item of shipperDataTableValue) {
        goodsAndPackages.push({
          productCode: item.productCode,
          descriptionOfGoods: item.descriptionOfGoods,
          unitQuantity: item.unitQuantity,
          unitType: item.unitType.value,
          price: item.price,
          amount: item.amount,
        });
      }

      let formData = new FormData();

      // console.log( this.state.invoice,"invoic");
      let finalData = {
        id: proformaId,
        shipperCompanyDataId: from.id,
        consigneeCompanyDataId: to.id,
        invoiceNumber: invoiceNumber,
        dateOfInvoice: datee,
        buyersReference: buyerReference,
        methodOfDispatch: methodOfDispatch.value,
        typeOfShipment: typeOfShipment.value,
        portOfLoading: portOfLoading.value,
        portOfDischarge: portOfDischarge.value,
        goodsAndPackages: goodsAndPackages,
        totalAmount: totalAmount,
        placeOfIssue: placeOfIssue.value,
        dateOfIssue: date,
        signatoryCompany: signatoryCompany,
        nameOfAuthorizedSignatory: nameOfAuthorizedSignatory,
        additionalInfo: additionalInformation,
        bankDetails: bankDetails,
      };
      // console.log(">>>>>>>>>>>>>finale data", finalData);

      if (logo instanceof File !== true) {
        const logoToFile = await fetch(logo).then((it) => it.blob());
        const logoFile = new File([logoToFile], "logo.png", {
          type: "image/png",
          lastModified: new Date(),
        });

        formData.append("logo", logoFile);
      } else {
        formData.append("logo", logo);
      }

      const signatureUrltoFile = await fetch(signature).then((it) => it.blob());
      const signatureFile = new File([signatureUrltoFile], "signature.png", {
        // type: "image/png",
        lastModified: new Date(),
      });
      // console.log("signatureFile", signatureFile);
      formData.append("data", JSON.stringify(finalData));

      formData.append("signature", signatureFile);

      if (update === true) {
        editProformaInvoiceById(formData).then((response) => {
          console.log("response proforma inv update", response);
          Swal.fire("", "Proforma Invoice Updated", "success").then(() => {
            history.push("/btnt-docs/list-proforma-invoice");
          });
        });
      } else {
        addProformaInvoice(formData).then((response) => {
          console.log("response proforma inv", response);
          Swal.fire("", "Proforma Invoice Added", "success").then(() => {
            history.push("/btnt-docs/list-proforma-invoice");
          });
        });
      }
    } else {
      if (tableError === true) {
        Swal.fire("", "Please fill all the product data fields", "warning");
        console.log("not ok", tableError);
      } else if (signature === "") {
        Swal.fire("", "Please enter your signature", "warning");
      }
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card id="basic-info" sx={{ overflow: "visible" }}>
        {!showPreview ? (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6}>
                <VuiBox mb="40px">
                  <VuiTypography variant="lg" color="text" fontWeight="bold">
                    Proforma Invoice
                  </VuiTypography>
                </VuiBox>
              </Grid>
              <Grid item xs={12} lg={6}>
                <div className={"inv-download-preview-save"}>
                  <VuiButton
                    variant="gradient"
                    component="span"
                    color="info"
                    sx={{ minWidth: "100px" }}
                    onClick={handleShowPreview}
                  >
                    Preview
                  </VuiButton>

                  <PDFDownloadLink
                    document={
                      <PDFDocument
                        value={{
                          logo: imgSrc,
                          fromCompany: from,
                          invoiceNumber: invoiceNumber,
                          datee: datee,
                          toCompany: to,
                          buyerReference: buyerReference,
                          methodOfDispatch: methodOfDispatch.value,
                          typeOfShipment: typeOfShipment.value,
                          portOfLoading: portOfLoading.value,
                          portOfDischarge: portOfDischarge.value,
                          totalAmount: totalAmount,
                          additionalInformation: additionalInformation,
                          placeOfIssue: placeOfIssue.value,
                          date: date,
                          signatoryCompany: signatoryCompany,
                          nameOfAuthorizedSignatory: nameOfAuthorizedSignatory,
                          signature: signature,
                          bankDetails: bankDetails,
                          shipperDataTableValue: shipperDataTableValue,
                        }}
                      />
                    }
                    fileName="Document"
                  >
                    {({ loading }) =>
                      loading ? (
                        <VuiButton
                          variant="gradient"
                          component="span"
                          color="info"
                          sx={{ minWidth: "100px" }}
                        >
                          Loading.....
                        </VuiButton>
                      ) : (
                        <VuiButton
                          variant="gradient"
                          component="span"
                          color="info"
                          sx={{ minWidth: "100px" }}
                        >
                          Download
                        </VuiButton>
                      )
                    }
                  </PDFDownloadLink>
                  {/* <VuiButton
                    variant="gradient"
                    component="span"
                    color="info"
                    sx={{ minWidth: "100px" }}
                  >
                    Save as
                  </VuiButton>
                  <VuiButton
                    variant="gradient"
                    component="span"
                    color="info"
                    sx={{ minWidth: "100px" }}
                    onClick={handleSavePDF}
                  >
                    Save
                  </VuiButton> */}
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={2} className={"inv-upload-clear"}>
              <Grid
                item
                xs={12}
                lg={6}
                style={{ display: "flex", alignItems: "flex-start", justifyContent: "flex-start" }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    flexDirection: "column",
                    marginTop: "10px",
                  }}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={2}
                    style={{ marginRight: "30px" }}
                  >
                    <label htmlFor="contained-button-file">
                      <Input
                        accept="image/*"
                        id="contained-button-file"
                        type="file"
                        onChange={(e) => handleUploadLogo(e)}
                      />
                      {/* <Button variant="contained" component="span">
                  Upload
                </Button> */}
                      <VuiButton
                        variant="gradient"
                        component="span"
                        color="info"
                        sx={{ minWidth: "100px" }}
                      >
                        Change Logo
                      </VuiButton>
                    </label>
                  </Stack>
                  {/* {imgSrc ? (
                    <VuiButton
                      variant="gradient"
                      component="span"
                      color="info"
                      sx={{ minWidth: "100px" }}
                      style={{ marginTop: "10px" }}
                      onClick={() => handleDeleteLogo()}
                    >
                      Delete Logo
                    </VuiButton>
                  ) : (
                    ""
                  )} */}
                </div>
                <img src={imgSrc} style={{ width: "50px", height: "50px" }} />
              </Grid>
              {/* <Grid item xs={12} lg={6} textAlign={"right"}>
                <VuiTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  color="text"
                  className={"inv-clear"}
                >
                  Clear
                </VuiTypography>
              </Grid> */}
            </Grid>
            <VuiBox component="form">
              <div container spacing={2} className={"inv-custom-grid-form"}>
                <div item xs={12} lg={6} className="inv-from">
                  <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <VuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      color="text"
                    >
                      From
                    </VuiTypography>
                  </VuiBox>
                  {/* <VuiInput
                    inputProps={{ type: "string" }}
                    defaultValue={from.companyName}
                    sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
                      border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
                    })}
                    placeholder={"Select"}
                    value={from.companyName}
                  /> */}
                  <VuiSelect
                    placeholder="Add info"
                    options={company}
                    value={from}
                    error={errorMsg.from.errorMsg.length > 0}
                    onChange={(e) => handleChangeInput("from", e)}
                  />
                  <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                    {errorMsg.from.errorMsg}
                  </VuiTypography>
                  <div className={"inv-add-from"}>
                    <VuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      color="text"
                      onClick={() => handleAddShipper("from")}
                    >
                      <AddIcon size={18} />
                      Add from info
                    </VuiTypography>
                  </div>
                </div>

                <div item xs={12} lg={6} className="inv-page">
                  <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <VuiTypography component="label" variant="h6" fontWeight="bold" color="text">
                      Pages <br />1 of 1
                    </VuiTypography>
                  </VuiBox>
                </div>

                <div item xs={12} lg={6} className={"inv-number"}>
                  <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <VuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      color="text"
                    >
                      Invoice Number
                    </VuiTypography>
                  </VuiBox>
                  <VuiInput
                    name="invoiceNumber"
                    placeholder="Invoice Number"
                    inputProps={{ type: "string" }}
                    value={invoiceNumber}
                    error={errorMsg.invoiceNumber.errorMsg.length > 0}
                    onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                  />
                  <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                    {errorMsg.invoiceNumber.errorMsg}
                  </VuiTypography>
                </div>

                <div item xs={12} lg={6} className={"inv-date1"}>
                  <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <VuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      color="text"
                    >
                      Date
                    </VuiTypography>
                  </VuiBox>
                  <VuiInput
                    name="datee"
                    inputProps={{ type: "date", min: currentDate }}
                    defaultValue={datee}
                    value={datee}
                    error={errorMsg.datee.errorMsg.length > 0}
                    onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                  />
                  <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                    {errorMsg.datee.errorMsg}
                  </VuiTypography>
                </div>

                {/* <div item xs={12} lg={6} columnSpacing={2}></div> */}

                <div item xs={12} lg={6} className={"inv-buyer"}>
                  <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <VuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      color="text"
                    >
                      Buyer Reference
                    </VuiTypography>
                  </VuiBox>
                  <VuiInput
                    name="buyerReference"
                    placeholder="Buyer Reference"
                    inputProps={{ type: "string" }}
                    defaultValue={""}
                    value={buyerReference}
                    error={errorMsg.buyerReference.errorMsg.length > 0}
                    onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                  />
                  <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                    {errorMsg.buyerReference.errorMsg}
                  </VuiTypography>
                </div>

                <div item xs={12} lg={6} className={"inv-to"}>
                  <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <VuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      color="text"
                    >
                      To
                    </VuiTypography>
                  </VuiBox>
                  {/* <VuiInput
                    inputProps={{ type: "string" }}
                    defaultValue={to.companyName}
                    sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
                      border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
                    })}
                    placeholder={"Select"}
                    value={to.companyName}
                  /> */}
                  <VuiSelect
                    placeholder="Add info"
                    options={company}
                    value={to}
                    // defaultValue={{ value: "", label: "" }}
                    error={errorMsg.to.errorMsg.length > 0}
                    onChange={(e) => handleChangeInput("to", e)}
                  />
                  <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                    {errorMsg.to.errorMsg}
                  </VuiTypography>
                  <div className={"inv-add-from"}>
                    <VuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      color="text"
                      onClick={() => handleAddShipper("to")}
                    >
                      <AddIcon size={18} />
                      Add to info
                    </VuiTypography>
                  </div>
                </div>

                <div item xs={12} lg={6} className={"inv-method-of-dispatch"}>
                  <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <VuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      color="text"
                    >
                      Method of Dispatch
                    </VuiTypography>
                  </VuiBox>
                  <VuiSelect
                    placeholder="Select"
                    options={[
                      { value: "Truck", label: "Truck" },
                      { value: "Container", label: "Container" },
                      { value: "Wagon", label: "Wagon" },
                    ]}
                    value={methodOfDispatch}
                    error={errorMsg.methodOfDispatch.errorMsg.length > 0}
                    onChange={(e) => handleChangeInput("methodOfDispatch", e)}
                  />
                  <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                    {errorMsg.methodOfDispatch.errorMsg}
                  </VuiTypography>
                </div>

                <div item xs={12} lg={6} className={"inv-type-of-shipment"}>
                  <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <VuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      color="text"
                    >
                      Type of Shipment
                    </VuiTypography>
                  </VuiBox>
                  <VuiSelect
                    placeholder="Select"
                    options={[
                      { value: "FCL", label: "FCL" },
                      { value: "LCL", label: "LCL" },
                      { value: "BULK", label: "BULK" },
                      { value: "FTL", label: "FTL" },
                      { value: "LTL", label: "LTL" },
                      { value: "RAIL", label: "RAIL" },
                      { value: "AIR", label: "AIR" },
                    ]}
                    value={typeOfShipment}
                    error={errorMsg.typeOfShipment.errorMsg.length > 0}
                    onChange={(e) => handleChangeInput("typeOfShipment", e)}
                  />
                  <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                    {errorMsg.typeOfShipment.errorMsg}
                  </VuiTypography>
                </div>

                <div item xs={12} lg={6} className={"inv-port-loading"}>
                  <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <VuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      color="text"
                    >
                      Port of Loading
                    </VuiTypography>
                  </VuiBox>
                  <VuiSelect
                    placeholder="Port of Loading"
                    options={searchedPorts}
                    value={portOfLoading}
                    onInputChange={(e) => findPort(e)}
                    error={errorMsg.portOfLoading.errorMsg.length > 0}
                    onChange={(e) => handleChangeInput("portOfLoading", e)}
                  />
                  <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                    {errorMsg.portOfLoading.errorMsg}
                  </VuiTypography>
                </div>

                <div item xs={12} lg={6} className={"inv-port-discharge"}>
                  <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <VuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      color="text"
                    >
                      Port of Discharge
                    </VuiTypography>
                  </VuiBox>
                  <VuiSelect
                    placeholder="Port of Discharge"
                    options={searchedPorts}
                    value={portOfDischarge}
                    onInputChange={(e) => findPort(e)}
                    error={errorMsg.portOfDischarge.errorMsg.length > 0}
                    onChange={(e) => handleChangeInput("portOfDischarge", e)}
                  />
                  <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                    {errorMsg.portOfDischarge.errorMsg}
                  </VuiTypography>
                </div>

                <div item xs={12} lg={6} className={"inv-data-table inv-datatable"}>
                  <DataTable
                    showTotalEntries={false}
                    entriesPerPage={false}
                    table={{
                      columns: [
                        {
                          Header: "Product Code",
                          accessor: "productCode",
                          align: "center",
                        },
                        {
                          Header: "Description of Goods",
                          accessor: "descriptionOfGoods",
                          align: "center",
                        },
                        {
                          Header: "Unit Qty",
                          accessor: "unitQuantity",
                          align: "center",
                        },
                        {
                          Header: "Unit Type",
                          accessor: "unitType",
                          align: "center",
                        },
                        {
                          Header: "Price",
                          accessor: "price",
                          align: "center",
                        },
                        {
                          Header: "Amount",
                          accessor: "amount",
                          align: "center",
                        },
                        { Header: "Remove", accessor: "remove", width: "1%", align: "center" },
                      ],
                      rows: shipperDataTableValue.map((item, index) => {
                        return {
                          productCode: (
                            <VuiInput
                              name="productCode"
                              inputProps={{ type: "string" }}
                              defaultValue={item.productCode}
                              // style={{width: "auto", minWidth: "160px"}}
                              placeholder={"Marks and Numbers"}
                              value={item.productCode}
                              onChange={(e) =>
                                handleOnChangeCommodity(e.target.name, e.target.value, index)
                              }
                            />
                          ),

                          descriptionOfGoods: (
                            <VuiInput
                              name="descriptionOfGoods"
                              inputProps={{ type: "string" }}
                              defaultValue={item.descriptionOfGoods}
                              placeholder={"Description Of Goods"}
                              value={item.descriptionOfGoods}
                              onChange={(e) =>
                                handleOnChangeCommodity(e.target.name, e.target.value, index)
                              }
                            />
                          ),
                          unitQuantity: (
                            <VuiInput
                              name="unitQuantity"
                              placeholder={"Unit quantity"}
                              inputProps={{ type: "number" }}
                              value={item.unitQuantity}
                              onChange={(e) =>
                                handleOnChangeCommodity(e.target.name, e.target.value, index)
                              }
                            />
                          ),
                          unitType: (
                            <VuiSelect
                              name="unitType"
                              options={[
                                { value: "units", label: "Units" },
                                { value: "boxes", label: "Boxes" },
                                { value: "packages", label: "Packages" },
                                { value: "barrels", label: "Barrels" },
                                { value: "sacks", label: "Sacks" },
                                { value: "bigbags", label: "Bigbags" },
                                { value: "tons", label: "Tons" },
                                { value: "m3", label: "M3" },
                                { value: "kg", label: "Kg" },
                                { value: "lbs", label: "Lbs" },
                                { value: "ft3", label: "Ft3" },
                                { value: "litres", label: "Litres" },
                                { value: "gallons", label: "Gallons" },
                              ]}
                              value={item.unitType}
                              onChange={(e) => handleOnChangeCommodity("unitType", e, index)}
                            />
                          ),
                          price: (
                            <VuiInput
                              name="price"
                              inputProps={{ type: "number" }}
                              defaultValue={item.price}
                              placeholder={"Price"}
                              value={item.price}
                              onChange={(e) =>
                                handleOnChangeCommodity(e.target.name, e.target.value, index)
                              }
                            />
                          ),
                          amount: (
                            <VuiInput
                              name="amount"
                              inputProps={{ type: "number" }}
                              placeholder={"Amount"}
                              value={item.amount}
                              // onChange={(e) =>
                              //   handleOnChangeCommodity(e.target.name, e.target.value, index)
                              // }
                            />
                          ),
                          remove: (
                            <>
                              {shipperDataTableValue && shipperDataTableValue.length > 1 ? (
                                <IconButton onClick={() => handleDeleteCommodity(index)}>
                                  <MdDelete color="#110F26" />
                                </IconButton>
                              ) : (
                                ""
                              )}
                            </>
                          ),
                        };
                      }),
                    }}
                  />
                  {shipperDataTableValue.length < 10 && (
                    <div className={"inv-add-data-table-row"}>
                      <VuiTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                        color="text"
                        onClick={hanldeAddDataTableRow}
                      >
                        <AddIcon size={18} />
                        Add
                      </VuiTypography>
                    </div>
                  )}
                </div>

                <div className="inv-unitType-price-amount">
                  <div item xs={12} lg={6}>
                    <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <VuiTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                        color="text"
                      >
                        Total Amount
                      </VuiTypography>
                    </VuiBox>
                    <VuiInput
                      name="totalAmount"
                      placeholder="Total amount"
                      inputProps={{ type: "number" }}
                      defaultValue={""}
                      value={totalAmount}
                      error={errorMsg.totalAmount.errorMsg.length > 0}
                      // onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                    />
                    <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                      {errorMsg.totalAmount.errorMsg}
                    </VuiTypography>
                  </div>
                </div>

                <div item xs={12} lg={6} className={"inv-additional-inform"}>
                  <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <VuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      color="text"
                    >
                      Additional information
                    </VuiTypography>
                  </VuiBox>
                  <VuiInput
                    name="additionalInformation"
                    placeholder="Additional information"
                    inputProps={{ type: "string" }}
                    defaultValue={""}
                    multiline
                    rows={8}
                    value={additionalInformation}
                    error={errorMsg.additionalInformation.errorMsg.length > 0}
                    onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                  />
                  <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                    {errorMsg.additionalInformation.errorMsg}
                  </VuiTypography>
                </div>

                <div item xs={12} lg={6} className={"inv-place-of-issue"}>
                  <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <VuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      color="text"
                    >
                      Place of Issue
                    </VuiTypography>
                  </VuiBox>
                  <VuiSelect
                    placeholder="Select"
                    options={searchedPorts}
                    value={placeOfIssue}
                    onInputChange={(e) => findPort(e)}
                    error={errorMsg.placeOfIssue.errorMsg.length > 0}
                    onChange={(e) => handleChangeInput("placeOfIssue", e)}
                  />
                  <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                    {errorMsg.placeOfIssue.errorMsg}
                  </VuiTypography>
                </div>

                <div item xs={12} lg={6} className={"inv-date"}>
                  <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <VuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      color="text"
                    >
                      date
                    </VuiTypography>
                  </VuiBox>
                  <VuiInput
                    name="date"
                    inputProps={{ type: "date", min: currentDate }}
                    defaultValue={date}
                    placeholder={"Date"}
                    value={date}
                    error={errorMsg.date.errorMsg.length > 0}
                    onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                  />
                  <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                    {errorMsg.date.errorMsg}
                  </VuiTypography>
                </div>

                <div item xs={12} lg={6} className={"inv-signatory-company"}>
                  <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <VuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      color="text"
                    >
                      Signatory Company
                    </VuiTypography>
                  </VuiBox>
                  <VuiInput
                    name="signatoryCompany"
                    placeholder="Signatory company"
                    inputProps={{ type: "string" }}
                    value={signatoryCompany}
                    error={errorMsg.signatoryCompany.errorMsg.length > 0}
                    onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                  />
                  <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                    {errorMsg.signatoryCompany.errorMsg}
                  </VuiTypography>
                </div>

                <div item xs={12} lg={6} className={"inv-authorized-Signatory"}>
                  <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <VuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      color="text"
                    >
                      Name of Authorized Signatory
                    </VuiTypography>
                  </VuiBox>
                  <VuiInput
                    name="nameOfAuthorizedSignatory"
                    inputProps={{ type: "string" }}
                    placeholder={"Name of Authorized Signatory"}
                    value={nameOfAuthorizedSignatory}
                    error={errorMsg.nameOfAuthorizedSignatory.errorMsg.length > 0}
                    onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                  />
                  <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                    {errorMsg.nameOfAuthorizedSignatory.errorMsg}
                  </VuiTypography>
                </div>

                <div item xs={12} lg={6} className={"inv-bank-details"}>
                  <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <VuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      color="text"
                    >
                      Bank Details
                    </VuiTypography>
                  </VuiBox>
                  <VuiInput
                    name="bankDetails"
                    placeholder="Bank details"
                    inputProps={{ type: "string" }}
                    defaultValue={""}
                    multiline
                    rows={8}
                    value={bankDetails}
                    error={errorMsg.bankDetails.errorMsg.length > 0}
                    onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                  />
                  <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                    {errorMsg.bankDetails.errorMsg}
                  </VuiTypography>
                </div>

                <div item xs={12} lg={6} className={"inv-signature"}>
                  <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <VuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      color="text"
                    >
                      Signature
                    </VuiTypography>
                  </VuiBox>
                  <VuiBox style={{ display: "flex" }}>
                    <SignaturePad
                      ref={sigCanvas}
                      penColor="#607d8b"
                      onEnd={handleSaveSignature}
                      canvasProps={{
                        className: "inv-signatureCanvas",
                      }}
                    />
                    <VuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      color="text"
                      onClick={clearCanvas}
                      style={{ cursor: "pointer", marginLeft: "10px" }}
                    >
                      Clear
                    </VuiTypography>
                    {/* <VuiTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  color="white"
                  onClick={handleShowSinature}
                >
                  Show
                </VuiTypography>
                */}
                  </VuiBox>
                </div>
              </div>
            </VuiBox>{" "}
          </>
        ) : (
          ""
        )}
        <Dialog
          sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 510 } }}
          maxWidth="xs"
          open={open}
        >
          <DialogTitle>{dialogInformation.title}</DialogTitle>
          <DialogContent dividers>
            <div item xs={12} lg={6} className="inv-from">
              <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <VuiTypography component="label" variant="caption" fontWeight="bold" color="text">
                  Company name
                </VuiTypography>
              </VuiBox>
              <VuiInput
                name="companyName"
                inputProps={{ type: "string" }}
                defaultValue={commonCompany.companyName}
                placeholder={"Company Name"}
                // value={commodityValue[0].description}
                error={errorMsg.companyName.errorMsg.length > 0}
                onChange={(e) => handleDialogInformation(e.target.name, e.target.value)}
              />
              <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                {errorMsg.companyName.errorMsg}
              </VuiTypography>
            </div>
            <div item xs={12} lg={6} className="inv-from">
              <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <VuiTypography component="label" variant="caption" fontWeight="bold" color="text">
                  Place
                </VuiTypography>
              </VuiBox>
              <VuiSelect
                placeholder="Select Place"
                options={searchedPorts}
                value={searchedPorts.find((element) => element.label === commonCompany.place)}
                onInputChange={(e) => findPort(e)}
                error={errorMsg.place.errorMsg.length > 0}
                onChange={(e) => handleDialogInformation("place", e.value)}
              />
              <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                {errorMsg.place.errorMsg}
              </VuiTypography>
            </div>
            <div item xs={12} lg={6} className="inv-from">
              <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <VuiTypography component="label" variant="caption" fontWeight="bold" color="text">
                  Address
                </VuiTypography>
              </VuiBox>
              <VuiInput
                name="address"
                inputProps={{ type: "string" }}
                defaultValue={commonCompany.address}
                placeholder={"Address"}
                // value={commodityValue[0].description}
                error={errorMsg.address.errorMsg.length > 0}
                onChange={(e) => handleDialogInformation(e.target.name, e.target.value)}
              />
              <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                {errorMsg.address.errorMsg}
              </VuiTypography>
            </div>
            <div item xs={12} lg={6} className="inv-from">
              <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <VuiTypography component="label" variant="caption" fontWeight="bold" color="text">
                  Phone
                </VuiTypography>
              </VuiBox>
              <VuiInput
                name="phone"
                inputProps={{ type: "number" }}
                defaultValue={commonCompany.phone}
                placeholder={"Phone"}
                // value={commodityValue[0].description}
                error={errorMsg.phone.errorMsg.length > 0}
                onChange={(e) => handleDialogInformation(e.target.name, e.target.value)}
              />
              <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                {errorMsg.phone.errorMsg}
              </VuiTypography>
            </div>
            <div item xs={12} lg={6} className="inv-from">
              <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <VuiTypography component="label" variant="caption" fontWeight="bold" color="text">
                  Bank Details
                </VuiTypography>
              </VuiBox>
              <VuiInput
                name="bankDetails"
                multiline={true}
                rows={4}
                inputProps={{ type: "string" }}
                defaultValue={commonCompany.bankDetails}
                placeholder={"Bank Details"}
                // value={commodityValue[0].description}
                error={errorMsg.bankDetails.errorMsg.length > 0}
                onChange={(e) => handleDialogInformation(e.target.name, e.target.value)}
              />
              <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                {errorMsg.bankDetails.errorMsg}
              </VuiTypography>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleSaveDialogData}>Save</Button>
          </DialogActions>
        </Dialog>
        {showPreview ? (
          <div>
            <div style={{ marginBottom: "10px", display: "flex", justifyContent: "flex-end" }}>
              <VuiButton
                variant="gradient"
                component="span"
                color="info"
                sx={{ minWidth: "100px" }}
                onClick={handleClosePreview}
              >
                Close
              </VuiButton>
            </div>
            <div>
              <PDFViewer style={{ height: "500px", width: "100%" }}>
                <PDFDocument
                  value={{
                    logo: imgSrc,
                    fromCompany: from,
                    invoiceNumber: invoiceNumber,
                    datee: datee,
                    toCompany: to,
                    buyerReference: buyerReference,
                    methodOfDispatch: methodOfDispatch.value,
                    typeOfShipment: typeOfShipment.value,
                    portOfLoading: portOfLoading.value,
                    portOfDischarge: portOfDischarge.value,
                    totalAmount: totalAmount,
                    additionalInformation: additionalInformation,
                    placeOfIssue: placeOfIssue.value,
                    date: date,
                    signatoryCompany: signatoryCompany,
                    nameOfAuthorizedSignatory: nameOfAuthorizedSignatory,
                    signature: signature,
                    bankDetails: bankDetails,
                    shipperDataTableValue: shipperDataTableValue,
                  }}
                />
              </PDFViewer>
            </div>
          </div>
        ) : (
          ""
        )}
        <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
          <VuiButton
            variant="gradient"
            component="span"
            color="info"
            sx={{ minWidth: "100px" }}
            // onClick={handleSubmitBillOfLading}
            onClick={() => handleSubmitProformaInvoice()}
          >
            Submit
          </VuiButton>
        </div>
      </Card>
    </DashboardLayout>
  );
}

export default ProfomaInvoice;
