import React from "react";
import { useState, useRef, useEffect, useMemo } from "react";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
// import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";
// Vision UI Dashboard PRO React components
// import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiSelect from "components/VuiSelect";
import VuiDatePicker from "components/VuiDatePicker";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import Button from "@mui/material/Button";
// import VuiButton from "components/VuiButton";
import VuiInput from "components/VuiInput";
import VuiSwitch from "components/VuiSwitch";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Checkbox from "@mui/material/Checkbox";
import jwt_decode from "jwt-decode";
import Avatar from "./avatar.svg";
import validator from "validator";
import { changePassword } from "services/profileService";
import Validations from "../../../shared/validations";
import ValidationService from "../../../shared/validationService";
import "./profile.css";
import { getActivePlan } from "services/pricingService";
import DefaultItem from "examples/Items/DefaultItem";
import { IoMdNotifications } from "react-icons/io";
import { IoWallet } from "react-icons/io5";
import Calendar from "examples/Calendar";
import calendarEventsData from "layouts/pages/widgets/data/calendarEventsData";
import InvoicesList from "examples/Lists/InvoicesList";
import invoicesListData from "layouts/pages/widgets/data/invoicesListData";
import OrdersOverview from "layouts/pages/widgets/components/OrdersOverview";
import MediaPlayer from "layouts/pages/widgets/components/MediaPlayer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import Footer from "examples/Footer";

const Profile = () => {
  const [errorMsg, setErrorMsg] = useState({
    password: { errorMsg: "" },
    confirmPassword: { errorMsg: "" },
  });
  const [newPassword, setNewPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [isShowPassword, setIsShowPassword] = useState(true);
  const [isShowPassword2, setIsShowPassword2] = useState(true);
  const [activePlanData, setActivePlanData] = useState("");

  const decoded = jwt_decode(localStorage.jwtTokenBitDocs);
  console.log(">>>>>>>>>>>>>>>>>> decoded", decoded);

  useEffect(() => {
    fetchActivePlan();
  }, []);

  const fetchActivePlan = async () => {
    const res = await getActivePlan();
    console.log("activeplan", res);
    setActivePlanData(res);
  };

  const customValidations = (name, value) => {
    let errorLength = 0;
    const args = [
      {
        name,
        value: value,
        validations: Validations.signUpValidation[name],
      },
    ];

    ValidationService.validate(args, (validationErr, validationErrMsgs) => {
      if (validationErrMsgs.length > 0) {
        errorLength = errorLength + 1;
        errorMsg[name].errorMsg = validationErrMsgs[0].validations[0].message;
        setErrorMsg({ ...errorMsg });
      } else {
        errorMsg[name].errorMsg = "";
        setErrorMsg({ ...errorMsg });
      }
    });
    return errorLength;
  };

  const handlePassword = (name, value) => {
    console.log(name, value);
    customValidations(name, value);
    if (name === "password") {
      setNewPassword(value);
    } else if (name === "confirmPassword") {
      setConfirmPassword(value);
    }
  };

  const handleShowPassword = (type) => {
    console.log(">>>>>>>>>>>>>>>>> handle show password");
    setIsShowPassword(type);
  };

  const handleShowPassword2 = (type) => {
    console.log(">>>>>>>>>>>>>>>>> handle show password");
    setIsShowPassword2(type);
  };

  const handleChangePassword = async () => {
    console.log(">>>>>>>> new Password", newPassword);
    console.log(">>>>>>>> confirm password", confirmPassword);
    let errorLengthPassword = customValidations("password", newPassword);
    let errorLengthConfirmPassword = customValidations("confirmPassword", confirmPassword);
    // if (!validator.isStrongPassword(newPassword)) {
    //   swal(
    //     "Need Strong Password",
    //     "Minimum Length: 8 AlphaNumCharacters (1 Uppercase, 1 Lowercase, and 1 Symbol)",
    //     "error"
    //   );
    //   return;
    // }

    if (errorLengthPassword !== 1 && errorLengthConfirmPassword !== 1) {
      console.log("Alll okkkkkkk");
    } else {
      console.log(errorLengthPassword, errorLengthConfirmPassword, "passord errrrorrrrr");
      return;
    }

    if (newPassword !== confirmPassword) {
      swal("Try again", "New password & confirm password did not match", "error");
      return;
    }

    changePassword({ password: newPassword, cpassword: confirmPassword })
      .then((response) => {
        console.log(">>>>>>>>>>>> password chnaged");

        Swal.fire("", "Password changed successfully", "success");
      })
      .catch((error) => {
        Swal.fire("", "Something went wrong", "Try again", "error");
      });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox>
        <Card sx={{ marginBottom: "1rem", overflow: "visible" }}>
          <VuiBox sx={{ display: "flex" }}>
            <VuiBox>
              <img src={Avatar} height="40rem" />
            </VuiBox>
            <VuiBox
              sx={{
                marginLeft: "0.5rem",
                alignItems: "center",
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <VuiTypography color="text" fontSize="1rem">
                Profile Information
              </VuiTypography>
              <VuiTypography color="text" fontSize="1rem">
                Btnt-Docs
              </VuiTypography>
            </VuiBox>
          </VuiBox>
        </Card>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Card sx={{}}>
              <VuiBox display="flex" flexDirection="column" lineHeight={1} mb="24px">
                <VuiTypography variant="lg" color="text" fontWeight="bold">
                  Active Plans
                </VuiTypography>
                <VuiTypography variant="button" color="text" fontWeight="regular">
                  Joined
                </VuiTypography>
              </VuiBox>
              <VuiBox>
                <DefaultItem
                  color="info"
                  icon={<IoWallet color="white" size="22px" />}
                  title="Total documents count:"
                  description={activePlanData.totalDocumentCountAvailable}
                />
                <VuiBox mt={3.5}>
                  <DefaultItem
                    color="info"
                    icon={<IoMdNotifications color="white" size="22px" />}
                    title="Total documents used:"
                    description={activePlanData.totalDocumentCountUsed}
                  />
                </VuiBox>
                <VuiBox mt={3.5}>
                  <DefaultItem
                    color="info"
                    icon={<IoMdNotifications color="white" size="22px" />}
                    title="Total BTNT price:"
                    description={activePlanData.btntPrice}
                  />
                </VuiBox>
              </VuiBox>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card sx={{ marginBottom: "1rem", overflow: "visible" }}>
              <VuiBox>
                <VuiBox sx={{ marginBottom: "1rem" }}>
                  <VuiTypography color="text" fontWeight="bold" fontSize="1rem">
                    CHANGE PASSWORD
                  </VuiTypography>
                </VuiBox>

                <VuiBox sx={{ width: "50%" }}>
                  <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <VuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      color="text"
                    >
                      NEW PASSWORD
                    </VuiTypography>
                  </VuiBox>

                  {isShowPassword ? (
                    <VuiInput
                      name="password"
                      type="password"
                      // sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
                      //     border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
                      // })}
                      placeholder="New password"
                      value={newPassword}
                      error={errorMsg.password.errorMsg.length > 0}
                      onChange={(e) => handlePassword(e.target.name, e.target.value)}
                    />
                  ) : (
                    <VuiInput
                      name="password"
                      type="text"
                      // sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
                      //     border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
                      // })}
                      placeholder="New password"
                      value={newPassword}
                      error={errorMsg.password.errorMsg.length > 0}
                      onChange={(e) => handlePassword(e.target.name, e.target.value)}
                    />
                  )}

                  <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                    {errorMsg.password.errorMsg}
                  </VuiTypography>
                </VuiBox>

                {isShowPassword ? (
                  <VuiBox
                    sx={{
                      display: "flex",
                    }}
                  >
                    <VuiBox sx={{ cursor: "pointer" }} onClick={() => handleShowPassword(false)}>
                      <VuiTypography color="text" fontWeight="light" fontSize="0.7rem">
                        Show password
                      </VuiTypography>
                    </VuiBox>
                  </VuiBox>
                ) : (
                  <VuiBox
                    sx={{
                      display: "flex",
                    }}
                  >
                    <VuiBox sx={{ cursor: "pointer" }} onClick={() => handleShowPassword(true)}>
                      <VuiTypography color="text" fontWeight="light" fontSize="0.7rem">
                        Hide password
                      </VuiTypography>
                    </VuiBox>
                  </VuiBox>
                )}

                <VuiBox sx={{ width: "50%", marginBottom: "1rem" }}>
                  <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <VuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      color="text"
                    >
                      CONFIRM PASSWORD
                    </VuiTypography>
                  </VuiBox>
                  {isShowPassword2 ? (
                    <VuiInput
                      name="confirmPassword"
                      type="password"
                      // sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
                      //     border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
                      // })}
                      placeholder={"Enter new password again"}
                      value={confirmPassword}
                      error={errorMsg.confirmPassword.errorMsg.length > 0}
                      onChange={(e) => handlePassword(e.target.name, e.target.value)}
                    />
                  ) : (
                    <VuiInput
                      name="confirmPassword"
                      type="text"
                      // sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
                      //     border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
                      // })}
                      placeholder={"Enter new password again"}
                      value={confirmPassword}
                      error={errorMsg.confirmPassword.errorMsg.length > 0}
                      onChange={(e) => handlePassword(e.target.name, e.target.value)}
                    />
                  )}

                  <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                    {errorMsg.confirmPassword.errorMsg}
                  </VuiTypography>

                  {isShowPassword2 ? (
                    <VuiBox
                      sx={{
                        display: "flex",
                      }}
                    >
                      <VuiBox sx={{ cursor: "pointer" }} onClick={() => handleShowPassword2(false)}>
                        <VuiTypography color="text" fontWeight="light" fontSize="0.7rem">
                          Show password
                        </VuiTypography>
                      </VuiBox>
                    </VuiBox>
                  ) : (
                    <VuiBox
                      sx={{
                        display: "flex",
                      }}
                    >
                      <VuiBox sx={{ cursor: "pointer" }} onClick={() => handleShowPassword2(true)}>
                        <VuiTypography color="text" fontWeight="light" fontSize="0.7rem">
                          Hide password
                        </VuiTypography>
                      </VuiBox>
                    </VuiBox>
                  )}
                </VuiBox>
                <VuiBox>
                  <VuiButton variant="gradient" color="info" onClick={() => handleChangePassword()}>
                    Change Password
                  </VuiButton>
                </VuiBox>
              </VuiBox>
            </Card>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          {/* <Grid item xs={12} lg={5}>
            {useMemo(
              () => (
                <Calendar
                  header={{ title: "calendar", date: "Monday, 2021" }}
                  headerToolbar={false}
                  initialView="dayGridMonth"
                  initialDate="2021-08-10"
                  events={calendarEventsData}
                  isWidgets // takes the styles if calendar is in the widgets page
                  selectable
                  editable
                />
              ),
              [calendarEventsData]
            )}
          </Grid> */}
          {/* <Grid item xs={12} lg={3}>
            <VuiBox mb={3}>
              <InvoicesList title="invoices" invoices={invoicesListData} />
            </VuiBox>
            <MediaPlayer />
          </Grid> */}
          {/* <Grid item xs={12} lg={4}>
            <ProfileInfoCard
              title="profile information"
              description="Hi, I’m Mark Johnson, Decisions: If you can’t decide, the answer is no. If two equally difficult paths, choose the one more painful in the short term (pain avoidance is creating an illusion of equality)."
              info={{
                fullName: "Mark Johnson",
                mobile: "(44) 123 1234 123",
                email: "mark@simmmple.com",
                location: "United States",
              }}
              social={[
                {
                  link: "https://www.facebook.com/CreativeTim/",
                  icon: <FacebookIcon />,
                  color: "facebook",
                },
                {
                  link: "https://twitter.com/creativetim",
                  icon: <TwitterIcon />,
                  color: "twitter",
                },
                {
                  link: "https://www.instagram.com/creativetimofficial/",
                  icon: <InstagramIcon />,
                  color: "instagram",
                },
              ]}
            />
          </Grid> */}
        </Grid>
      </VuiBox>
      {/* <Footer /> */}
    </DashboardLayout>
  );
};

export default Profile;
