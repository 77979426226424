import { useState, useEffect } from "react";

// react-router-dom components

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";

import Validations from "shared/validations";
import ValidationService from "shared/validationService";

// Vision UI Dashboard custom components
import GradientBorder from "examples/GradientBorder";

// Vision UI Dashboard assets
import radialGradient from "assets/theme/functions/radialGradient";
import palette from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images

import illustrationImage from "assets/images/illustration-image.png";

import { forgotPassword } from "services/userService";
import Swal from "sweetalert2";

function ForgotPassword() {
  const [errorMsg, setErrorMsg] = useState({
    email: { errorMsg: "" },
  });

  const [email, setEmail] = useState("");

  const [emailBox, setEmailBox] = useState(true);

  const customValidations = (name, value) => {
    let errorLength = 0;
    const args = [
      {
        name,
        value: value,
        validations: Validations.signValidation[name],
      },
    ];

    ValidationService.validate(args, (validationErr, validationErrMsgs) => {
      if (validationErrMsgs.length > 0) {
        errorLength = errorLength + 1;
        errorMsg[name].errorMsg = validationErrMsgs[0].validations[0].message;
        setErrorMsg({ ...errorMsg });
      } else {
        errorMsg[name].errorMsg = "";
        setErrorMsg({ ...errorMsg });
      }
    });
    return errorLength;
  };

  const handleResetData = (value) => {
    console.log("email", value);
    setEmail(value);
  };

  const handleReset = async () => {
    let errorLengthEmail = customValidations("email", email);

    if (errorLengthEmail !== 1) {
      console.log("Alll okkkkkkk");
    } else {
      console.log(errorLengthEmail, "email errrrorrrrr");
      return;
    }

    let resetData = {
      email: email,
    };

    const res = await forgotPassword(resetData);
    console.log("reset res", res);
    if (res.success === false) {
      Swal.fire("", "We don’t recognize this email address. Want to try again?", "error");
    } else {
      Swal.fire("Reset link sent", "Please check your email", "success");
      setEmailBox(false);
    }
  };

  return (
    <CoverLayout
      // logo={NewBitnauticLogo}
      title="Welcome to BitNautic!"
      caption="Forgot your password?"
      description="No problem. Just enter your email address and we’ll send you a reset link."
      color="text"
      image={illustrationImage}
      premotto={"INSPIRED BY THE FUTURE:"}
      motto={"DECENTRALIZED SHIPPING PLATFORM"}
    >
      {emailBox ? (
        <VuiBox component="form" role="form">
          <VuiBox mb={2}>
            <VuiBox mb={1} ml={0.5}>
              <VuiTypography component="label" variant="button" color="text" fontWeight="medium">
                Email
              </VuiTypography>
            </VuiBox>
            <GradientBorder
              minWidth="100%"
              borderRadius={borders.borderRadius.lg}
              padding="1px"
              backgroundImage={radialGradient(
                palette.gradients.borderLight.main,
                palette.gradients.borderLight.state,
                palette.gradients.borderLight.angle
              )}
            >
              <VuiInput
                type="email"
                placeholder="eg. email@gmail.com"
                sx={({ typography: { size } }) => ({
                  fontSize: size.sm,
                })}
                value={email}
                name="email"
                error={errorMsg.email.errorMsg.length > 0}
                onChange={(e) => handleResetData(e.target.value)}
                // onChange={(e) => setEmail(e.target.value)}
              />
            </GradientBorder>
            <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
              {errorMsg.email.errorMsg}
            </VuiTypography>
          </VuiBox>

          <VuiBox mt={4} mb={1}>
            <VuiButton color="info" variant="gradient" fullWidth onClick={() => handleReset()}>
              Get reset Link
            </VuiButton>
          </VuiBox>
        </VuiBox>
      ) : null}
    </CoverLayout>
  );
}

export default ForgotPassword;
