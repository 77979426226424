import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import Modal from "@mui/material/Modal";
import CircularProgress from "@mui/material/CircularProgress";

import ProductView from "./ProductView";

import { getTraceableProductViewableDataById } from "services/traceabilityService";

function TraceView() {
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const [productData, setProductData] = useState(null);

	useEffect(() => {
		setLoading(true);
		const urlParams = new URLSearchParams(window.location.search);

		let idNum = urlParams.get('id') ? Number(urlParams.get('id')) : null;
		idNum = Number.isInteger(idNum) ? idNum : null;

		if (idNum === null) {
			history.push('/btnt-docs/');
			return;
		}

		getTraceableProductViewableDataById(idNum)
			.then((response) => {
				setLoading(false);
				setProductData(response);
			})
			.catch((err) => {
				history.push("/btnt-docs/");
			});
	}, []);

	return (
		<div>
			<Modal open={loading} >
				<CircularProgress disableShrink sx={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}/>
			</Modal>
			{productData && <ProductView data={productData}/> }
		</div>
	);
}

export default TraceView;