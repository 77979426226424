import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";

// Vision UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import dataTableData from "layouts/applications/data-tables/data/dataTableData";


// import { getSmartDocuments } from "services/documentService";
import { isMobile, isBrowser } from "react-device-detect";
import { PDFViewer } from "@react-pdf/renderer";
import { PDFDownloadLink } from "@react-pdf/renderer";
// import PDFDocument from "../add-bill-of-lading/pdfDocument";
import QRCode from "qrcode";
import Swal from "sweetalert2";

import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { MdDelete } from "react-icons/md";
import { MdModeEdit } from "react-icons/md";
import { FaEdit } from "react-icons/fa";

import { getTraceableProducts, deleteTraceableProduct, uploadToBlockChainById } from "services/traceabilityService";
import Modal from "@mui/material/Modal";
import CircularProgress from "@mui/material/CircularProgress";


import QrCode2Icon from '@mui/icons-material/QrCode2';
import UploadIcon from '@mui/icons-material/Upload';
import DeleteIcon from "@mui/icons-material/Delete";

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';


/*
 const upload = (e, item) => {
	console.log(e);
	console.log(item.id, "THIS IDD>>>>>>>>>>>>>>");
	// e.disabled = true;
	Swal.fire({
	  position: "center",

	  title: "Upload Initiated",
	  showConfirmButton: false,
	  allowOutsideClick: false,

	  didOpen: () => {
		Swal.showLoading();

		updateBillOfLadingOnChain(item.id).then((response) => {
		  // console.log("response>>", response);

		  if (response.success === false) {
			Swal.fire({
			  title: "You've reached limit as per your plan.",
			  text: "Upgrade plan",
			  icon: "warning",
			  showCancelButton: true,
			  showConfirmButton: true,
			  // confirmButtonText: "Yes, delete it!",
			  // cancelButtonText: 'No, cancel!',
			}).then((result) => {
			  if (result.isConfirmed) {
				history.push(`/btnt-docs/pricings`);
			  }
			});
			return;
		  }

		  if (response.OnBlockChain === true) {
			setUploaded((current) => !current);
			// setTitle("Uploaded");
			// Swal.hideLoading();
			Swal.close();
		  } else {
			Swal.fire({
			  title: "Upload Failed",
			  text: "Please try again",
			  icon: "error",
			  showConfirmButton: true,
			});
		  }
		});
	  },
	}).then(() => {
	  Swal.fire({
		title: "Upload Successful",
		icon: "success",
		showConfirmButton: true,
	  });
	});
  };
-- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- 

export async function updateProformaOnChain(id) {
  // console.log(id, "updateProformaOnChain id");
  var tokenStr = localStorage.getItem("jwtTokenBitDocs");
  axios.defaults.headers.common["authorization"] = tokenStr;
  try {
	const response = await axios.get(
	  `${baseUrl}/serviceProvider/smartDocuments/proformaInvoice/${id}`
	);
	// console.log(response, "updateProformaOnChainRes");
	return response.data;
  } catch (err) {
	return err.response;
  }
}

-- -- -- -- -- -- -- -- --

export async function updateBillOfLadingOnChain(id) {
  // console.log(id, "updateProformaOnChain id");
  var tokenStr = localStorage.getItem("jwtTokenBitDocs");
  axios.defaults.headers.common["authorization"] = tokenStr;
  try {
	const response = await axios.get(
	  `${baseUrl}/serviceProvider/billOfLading/addBillOfLadingBlockchain/${id}`
	);
	// console.log(response, "updateBillOfLadingOnChainRes");
	return response.data;
  } catch (err) {
	return err.response;
  }
}

*/

const TRACEABLE_HEADER_COLUMNS = [
	{
		Header: "name",
		accessor: "name",
		align: "left"
	},
	{
		Header: "Actions",
		accessor: "actions",
		align: "center",
		width: "30px"
	},
];

function ListTraceability() {
	let history = useHistory();

	const [loading, setLoading] = useState(true);
	const [traceableProductsData, setTraceableProductsData] = useState([]);
	const [isQRCodeShown, setShowQRCode] = useState(false);
	const [qrCodeDataURL, setQRCodeDataURL] = useState("");

	const handleQRCode = (productId, index) => {
		if (loading) return;
		setLoading(true);

		QRCode.toDataURL(`${window.location.origin}/trace-view?id=${productId}`)
			.then(data => {
				setShowQRCode(true);
				setQRCodeDataURL(data);
				setLoading(false);
			})
			.catch(error => {
				console.error(error);
				setLoading(false);
			});
	}

	const closeQRCodeModal = () => {
		if (loading) return;
		setShowQRCode(false);
	}

	const handleEdit = (productId, index) => {
		if (loading) return;
		setLoading(true);

		if (false /*traceableProductsData[index].blockchainHash == null*/) {
			setLoading(false);
		} else {
			uploadToBlockChainById(productId)
				.then((response) => {
					traceableProductsData[index].blockchainHash = response;
					setTraceableProductsData(traceableProductsData);
					Swal.fire({
						icon: "success",
						text: "Product data is now on blockchain.",
						showConfirmButton: true
					})
						.finally(() => { setLoading(false) });
				})
				.catch((error) => {
					const data = error.response ? error.response.data : null;
					const defaultError = error.response ? `Error: ${error.response.status}` : `Unexpected: ${error}`;
					const text = data ? data.result : defaultError;

					Swal.fire({
						icon: "error",
						text: text,
						footer: data ? defaultError : '',
						showConfirmButton: true
					})
						.finally(() => { setLoading(false) });
				})
				.finally(() => { setLoading(false) });
		}
	}
	const handleDelete = (productId, index) => {
		if (loading) return;
		setLoading(true);
		Swal.fire({
			text: "Are you sure you want to delete? (Non-reversible)",
			showConfirmButton: true,
			showDenyButton: true
		}).then((result) => {
			if (result.isConfirmed) {
				// api delete traceable product
				// if api then delete manually traceablePorductsData through index
				deleteTraceableProduct(productId)
					.then(() => {
						traceableProductsData.splice(index, 1);
						setTraceableProductsData(traceableProductsData);
						setLoading(false);
					})
					.catch((err) => {
						console.log(err);
						Swal.fire({
							icon: "error",
							text: "Something went wrong!",
							footer: err,
							showConfirmButton: true
						})
						setLoading(false);
					});
			} else {
				setLoading(false);
			}
		});
	}

	useEffect(() => {
		setLoading(true);
		getTraceableProducts()
			.then((response) => {
				setLoading(false);
				setTraceableProductsData(response);
			})
			.catch((err) => {
				Swal.fire({
					icon: "error",
					text: "Something went wrong!",
					footer: err,
					showConfirmButton: true
				}).then(() => {
					history.push("/btnt-docs/");
				});
			});
	}, []);

	const onSeeSuppliersListClicked = () => {
		if (loading) return;
		history.push({
			pathname: "/btnt-docs/list-suppliers",
		});
	}

	const addNewProduct = () => {
		if (loading) return;
		history.push({
			pathname: "/btnt-docs/add-traceable-product",
		});
	}

	return (
		<DashboardLayout>
			<Modal open={isQRCodeShown} onClose={closeQRCodeModal}>
				<Box sx={{
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					width: 400,
					bgcolor: 'background.paper',
					border: '2px solid #000',
					boxShadow: 24,
					p: 4,
				}}>
					<Typography variant="h6" component="h2">View Product</Typography>
					<img src={qrCodeDataURL} style={{display: "block", margin: "auto", height: "100%"}} />
				</Box>
			</Modal>
			<Modal open={loading} >
				<CircularProgress disableShrink sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />
			</Modal>
			<ButtonGroup size="small" style={{ marginBottom: "10px" }}>
				<Button style={{ backgroundColor: "#5081d9", color: "#fff" }} onClick={() => {history.push('/btnt-docs/add-traceable-product')}}>Add Product</Button>
				<Button style={{ backgroundColor: "#5081d9", color: "#fff" }} disabled>See Products</Button>
				<Button style={{ backgroundColor: "#5081d9", color: "#fff" }} onClick={() => {history.push('/btnt-docs/list-suppliers')}}>Suppliers</Button>
			</ButtonGroup>
			<Card>
				{/*
				<VuiBox sx={{ display: "flex", justifyContent: "flex-end" }}>
					<VuiButton
						variant="gradient"
						component="span"
						color="info"
						onClick={onSeeSuppliersListClicked}
						style={{ marginRight: "5px" }}
					>
						See Suppliers List
					</VuiButton>
					<VuiButton
						variant="gradient"
						component="span"
						color="info"
						onClick={addNewProduct}
					>
						Add New Product
					</VuiButton>
				</VuiBox>
				*/}

				<VuiBox p={3} pl={0} lineHeight={1}>
					<VuiTypography variant="h5" fontWeight="medium" color="text">
						Traceable Products Table
					</VuiTypography>
					<VuiTypography variant="button" fontWeight="regular" color="text">
						List of all Traceable Products.
					</VuiTypography>
				</VuiBox>
				<DataTable canSearch table={{
					columns: TRACEABLE_HEADER_COLUMNS,
					rows: traceableProductsData.map((el, index) => {
						return {
							name: el.name,
							actions: (
								<ButtonGroup>
									<VuiButton variant="gradient" color="info" onClick={() => handleQRCode(el.id, index)}>
										<QrCode2Icon />
									</VuiButton>
									<VuiButton variant="gradient" color="info" onClick={() => handleEdit(el.id, index)}>
										<UploadIcon />
									</VuiButton>
									<VuiButton variant="gradient" color="info" onClick={() => handleDelete(el.id, index)}>
										<DeleteIcon />
									</VuiButton>
								</ButtonGroup>
							)
						}
					})
				}} />
			</Card>
			<Footer />
		</DashboardLayout>
	);
}

export default ListTraceability;


/*

	{!showPreview ? (
						<>
							<VuiBox sx={{ display: "flex", justifyContent: "flex-end" }}>
								<VuiButton
									variant="gradient"
									component="span"
									color="info"
									onClick={addNewBillOfLading}
								>
									Add New Bill of Lading
								</VuiButton>
							</VuiBox>

							<VuiBox p={3} pl={0} lineHeight={1}>
								<VuiTypography variant="h5" fontWeight="medium" color="text">
									Bill of Lading Table
								</VuiTypography>
								<VuiTypography variant="button" fontWeight="regular" color="text">
									List of all Bills of Lading.
								</VuiTypography>
							</VuiBox>
							<DataTable table={billTable} canSearch />
						</>
					) : (
						""
					)}

					{showPreview && isBrowser ? (
						<>
							<div style={{ marginBottom: "10px", display: "flex", justifyContent: "flex-end" }}>
								<VuiButton
									variant="gradient"
									component="span"
									color="info"
									sx={{ minWidth: "100px" }}
									onClick={() => {
										handleClosePreview();
									}}
								>
									Close
								</VuiButton>
							</div>
							<div>
								<PDFViewer style={{ height: "500px", width: "100%" }}>
									<PDFDOCLIST
										value={{
											logo: bill.logo_url,
											shipperComapny: fromCompanyData,
											shipperReference: bill.shipper_reference_one,
											shipperReferenceSecond: bill.shipper_reference_two,
											carrierReference: bill.carrierReference,
											uniqueConsignmentReference: bill.unique_consignment_reference,
											consigneeCompany: toCompanyData,
											carrierCompanyName: carrierData,
											notifyPartyCompanyName: notifyPartyData,
											addNotifyPartyCompanyName: additionalNotifyPartyData,
											preCarriageBy: bill.pre_carriage_by,
											placeOfReceipt: bill.place_of_reciept,
											additionalInformation: bill.additional_information,
											transport: bill.transport,
											voyageNo: bill.voyage_no,
											placeOfDelivery: bill.place_of_delivery,
											portOfLoading: bill.port_of_landing,
											portOfDischarge: bill.port_of_discharge,
											totalNetWeight: bill.total_net_weight,
											totalGrossWeight: bill.total_gross_weight,
											totalVolume: bill.total_volume,
											additionalInformationSecond: bill.additional_info,
											placeOfIssue: bill.place_of_issue,
											date: dates(bill.date),
											signatoryCompany: bill.signatory_company,
											nameOfAuthorizedSignatory: bill.nameOfAuthorizedSignatory,
											signature: bill.signature_url,
											shipperDataTableValue: bill.goodsAndPackages,
											qrData: qrData,
										}}
									/>
								</PDFViewer>
							</div>
						</>
					) : showPreview && isMobile ? (
						<VuiBox sx={{ textAlign: "center", height: "500px" }}>
							<VuiTypography marginBottom="2rem">
								Click on the button below to download Bill of Lading uploaded on blockchain
							</VuiTypography>
							<PDFDownloadLink
								document={
									<PDFDOCLIST
										value={{
											logo: bill.logo_url,
											shipperComapny: fromCompanyData,
											shipperReference: bill.shipper_reference_one,
											shipperReferenceSecond: bill.shipper_reference_two,
											carrierReference: bill.carrierReference,
											uniqueConsignmentReference: bill.unique_consignment_reference,
											consigneeCompany: toCompanyData,
											carrierCompanyName: carrierData,
											notifyPartyCompanyName: notifyPartyData,
											addNotifyPartyCompanyName: additionalNotifyPartyData,
											preCarriageBy: bill.pre_carriage_by,
											placeOfReceipt: bill.place_of_reciept,
											additionalInformation: bill.additional_information,
											transport: bill.transport,
											voyageNo: bill.voyage_no,
											placeOfDelivery: bill.place_of_delivery,
											portOfLoading: bill.port_of_landing,
											portOfDischarge: bill.port_of_discharge,
											totalNetWeight: bill.total_net_weight,
											totalGrossWeight: bill.total_gross_weight,
											totalVolume: bill.total_volume,
											additionalInformationSecond: bill.additional_info,
											placeOfIssue: bill.place_of_issue,
											date: dates(bill.date),
											signatoryCompany: bill.signatory_company,
											nameOfAuthorizedSignatory: bill.nameOfAuthorizedSignatory,
											signature: bill.signature_url,
											shipperDataTableValue: bill.goodsandpackagesbilloflading,
											qrData: qrData,
										}}
									/>
								}
								fileName="Document"
							>
								{({ loading }) =>
									loading ? (
										<VuiButton variant="gradient" color="info">
											Please wait...
										</VuiButton>
									) : (
										<VuiButton variant="gradient" color="info">
											Download Pdf
										</VuiButton>
									)
								}
							</PDFDownloadLink>
						</VuiBox>
					) : null}
*/