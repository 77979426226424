import React from "react";
import { Page, Text, View, Document, StyleSheet, Image } from "@react-pdf/renderer";
import Picture from "./merchant.jpg";

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "white",
    padding: "20px",
    width: "100%",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  image: {
    flexDirection: "row",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    flexDirection: "row",
    textAlign: "right",
    fontSize: "14px",
    display: "flex",
  },
  borderView: {
    marginTop: "10px",
    marginBottom: "10px",
    width: "100%",
  },
  border: {
    border: "2px solid #e0e0e0",
  },
});

// Create Document Component
const MyDocument = (props) => {
  // console.log(">>>>>>>>>>> props.value", props.value);
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.image}>
          {props.value.logo ? (
            <Image
              allowDangerousPath
              src={props.value.logo}
              style={{ width: "30px", height: "30px" }}
            />
          ) : (
            <Image allowDangerousPath src={Picture} style={{ width: "30px", height: "30px" }} />
          )}
          <Text style={styles.title}>Certificate Of Origin</Text>
        </View>

        <View style={{ flexDirection: "row", width: "100%", marginTop: "20px" }}>
          <View style={{ width: "49%", marginRight: "1%" }}>
            <View style={{ flexDirection: "row", width: "100%" }}>
              <Text style={{ fontSize: "9px", color: "#757575" }}>Exporter</Text>
            </View>
            <View style={{ flexDirection: "column", width: "100%" }}>
              <Text
                style={{
                  fontSize: "7px",
                  color: "#212121",
                  padding: "2px 0px 2px 5px",
                  marginBottom: "2px",
                }}
              >
                {props.value.exporterCompany.company_name
                  ? props.value.exporterCompany.company_name
                  : ""}
              </Text>
              <Text
                style={{
                  fontSize: "7px",
                  color: "#212121",
                  padding: "2px 0px 2px 5px",
                  marginBottom: "2px",
                }}
              >
                {props.value.exporterCompany.place ? props.value.exporterCompany.place : ""}
              </Text>
              <Text
                style={{
                  fontSize: "7px",
                  color: "#212121",
                  padding: "2px 0px 2px 5px",
                  marginBottom: "2px",
                }}
              >
                {props.value.exporterCompany.address ? props.value.exporterCompany.address : ""}
              </Text>
              <Text
                style={{
                  fontSize: "7px",
                  color: "#212121",
                  padding: "2px 0px 2px 5px",
                  marginBottom: "2px",
                }}
              >
                {props.value.exporterCompany.phone ? props.value.exporterCompany.phone : ""}
              </Text>
              <Text
                style={{
                  fontSize: "7px",
                  color: "#212121",
                  padding: "2px 0px 2px 5px",
                  marginBottom: "2px",
                }}
              >
                {props.value.exporterCompany.bank_details
                  ? props.value.exporterCompany.bank_details
                  : ""}
              </Text>
            </View>
          </View>
          <View style={{ width: "49%", marginLeft: "1%" }}>
            <View style={{ width: "100%", flexDirection: "row" }}>
              <View style={{ width: "48%", marginRight: "2%" }}>
                <View style={{ flexDirection: "row", width: "100%" }}>
                  <Text style={{ fontSize: "9px", color: "#757575" }}>Invoice Number</Text>
                </View>
                <View style={{ flexDirection: "row", width: "100%", minHeight: "30px" }}>
                  <Text style={{ fontSize: "7px", color: "#212121", padding: "5px" }}>
                    {props.value.invoiceNumber ? props.value.invoiceNumber : ""}
                  </Text>
                </View>
              </View>

              <View style={{ width: "48%", marginLeft: "2%" }}>
                <View style={{ flexDirection: "row", width: "100%" }}>
                  <Text style={{ fontSize: "9px", color: "#757575" }}>Date</Text>
                </View>
                <View style={{ flexDirection: "row", width: "100%", minHeight: "30px" }}>
                  <Text style={{ fontSize: "7px", color: "#212121", padding: "5px" }}>
                    {props.value.datee ? props.value.datee : ""}
                  </Text>
                </View>
              </View>
            </View>

            <View style={{ width: "100%" }}>
              <View style={{ flexDirection: "row", width: "100%" }}>
                <Text style={{ fontSize: "9px", color: "#757575" }}>letter Of Credit No</Text>
              </View>
              <View style={{ flexDirection: "row", width: "100%", minHeight: "30px" }}>
                <Text style={{ fontSize: "7px", color: "#212121", padding: "5px" }}>
                  {props.value.letterOfCredit ? props.value.letterOfCredit : ""}
                </Text>
              </View>
            </View>
          </View>
        </View>

        <View style={styles.borderView}>
          <Text style={styles.border}></Text>
        </View>

        <View style={{ flexDirection: "row", width: "100%" }}>
          <View style={{ width: "49%", marginRight: "1%" }}>
            <View style={{ flexDirection: "row", width: "100%" }}>
              <Text style={{ fontSize: "9px", color: "#757575" }}>Consignee</Text>
            </View>
            <View style={{ flexDirection: "column", width: "100%" }}>
              <Text
                style={{
                  fontSize: "7px",
                  color: "#212121",
                  padding: "2px 0px 2px 5px",
                  marginBottom: "2px",
                }}
              >
                {props.value.consigneeCompany.company_name
                  ? props.value.consigneeCompany.company_name
                  : ""}
              </Text>
              <Text
                style={{
                  fontSize: "7px",
                  color: "#212121",
                  padding: "2px 0px 2px 5px",
                  marginBottom: "2px",
                }}
              >
                {props.value.consigneeCompany.place ? props.value.consigneeCompany.place : ""}
              </Text>
              <Text
                style={{
                  fontSize: "7px",
                  color: "#212121",
                  padding: "2px 0px 2px 5px",
                  marginBottom: "2px",
                }}
              >
                {props.value.consigneeCompany.address ? props.value.consigneeCompany.address : ""}
              </Text>
              <Text
                style={{
                  fontSize: "7px",
                  color: "#212121",
                  padding: "2px 0px 2px 5px",
                  marginBottom: "2px",
                }}
              >
                {props.value.consigneeCompany.phone ? props.value.consigneeCompany.phone : ""}
              </Text>
              <Text
                style={{
                  fontSize: "7px",
                  color: "#212121",
                  padding: "2px 0px 2px 5px",
                  marginBottom: "2px",
                }}
              >
                {props.value.consigneeCompany.bank_details
                  ? props.value.consigneeCompany.bank_details
                  : ""}
              </Text>
            </View>
          </View>
          <View style={{ width: "49%", marginLeft: "1%" }}>
            {/* <View style={{ width: "100%", flexDirection: "row" }}>
              <View style={{ width: "48%", marginRight: "2%" }}>
                <View style={{ flexDirection: "row", width: "100%" }}>
                  <Text style={{ fontSize: "9px", color: "#757575" }}>Reference</Text>
                </View>
                <View style={{ flexDirection: "row", width: "100%", minHeight: "30px" }}>
                  <Text style={{ fontSize: "7px", color: "#212121", padding: "5px" }}>
                    {props.value.reference ? props.value.reference : ""}
                  </Text>
                </View>
              </View>

              <View style={{ width: "48%", marginLeft: "2%" }}>
                <View style={{ flexDirection: "row", width: "100%" }}>
                  <Text style={{ fontSize: "9px", color: "#757575" }}>Buyer Reference</Text>
                </View>
                <View style={{ flexDirection: "row", width: "100%", minHeight: "30px" }}>
                  <Text style={{ fontSize: "7px", color: "#212121", padding: "5px" }}>
                    {props.value.buyerReference ? props.value.buyerReference : ""}
                  </Text>
                </View>
              </View>
            </View> */}

            <View style={{ width: "100%" }}>
              <View style={{ flexDirection: "row", width: "100%" }}>
                <Text style={{ fontSize: "9px", color: "#757575" }}>Buyer (If not Consignee)</Text>
              </View>
              <View style={{ flexDirection: "column", width: "100%", minHeight: "60px" }}>
                <Text style={{ fontSize: "7px", color: "#212121", padding: "2px 0px 2px 5px" }}>
                  {props.value.buyerCompany.company_name
                    ? props.value.buyerCompany.company_name
                    : ""}
                </Text>
                <Text style={{ fontSize: "7px", color: "#212121", padding: "2px 0px 2px 5px" }}>
                  {props.value.buyerCompany.place ? props.value.buyerCompany.place : ""}
                </Text>
                <Text style={{ fontSize: "7px", color: "#212121", padding: "2px 0px 2px 5px" }}>
                  {props.value.buyerCompany.address ? props.value.buyerCompany.address : ""}
                </Text>
                <Text style={{ fontSize: "7px", color: "#212121", padding: "2px 0px 2px 5px" }}>
                  {props.value.buyerCompany.phone ? props.value.buyerCompany.phone : ""}
                </Text>
                <Text style={{ fontSize: "7px", color: "#212121", padding: "2px 0px 2px 5px" }}>
                  {props.value.buyerCompany.bank_details
                    ? props.value.buyerCompany.bank_details
                    : ""}
                </Text>
              </View>
            </View>
          </View>
        </View>

        <View style={styles.borderView}>
          <Text style={styles.border}></Text>
        </View>

        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
            marginTop: "5px",
          }}
        >
          <View style={{ width: "100%" }}>
            <View style={{ height: "30px" }}>
              <Text style={{ fontSize: "9px", color: "#757575" }}>Method of Dispatch</Text>
              <Text style={{ fontSize: "7px", color: "#212121", padding: "5px" }}>
                {props.value.methodOfDispatch ? props.value.methodOfDispatch : ""}
              </Text>
            </View>
            <View style={{ height: "30px" }}>
              <Text style={{ fontSize: "9px", color: "#757575" }}>Transport</Text>
              <Text style={{ fontSize: "7px", color: "#212121", padding: "5px" }}>
                {props.value.transport ? props.value.transport : ""}
              </Text>
            </View>
            <View style={{ height: "30px" }}>
              <Text style={{ fontSize: "9px", color: "#757575" }}>Port of Loading</Text>
              <Text style={{ fontSize: "7px", color: "#212121", padding: "5px" }}>
                {props.value.portOfLoading ? props.value.portOfLoading : ""}
              </Text>
            </View>
          </View>

          <View style={{ width: "100%", textAlign: "start" }}>
            <View style={{ height: "30px" }}>
              <Text style={{ fontSize: "9px", color: "#757575" }}>Type of Shipment</Text>
              <Text style={{ fontSize: "7px", color: "#212121", padding: "5px" }}>
                {props.value.typeOfShipment ? props.value.typeOfShipment : ""}
              </Text>
            </View>
            <View style={{ height: "30px" }}>
              <Text style={{ fontSize: "9px", color: "#757575" }}>Voyage No</Text>
              <Text style={{ fontSize: "7px", color: "#212121", padding: "5px" }}>
                {props.value.voyageNo ? props.value.voyageNo : ""}
              </Text>
            </View>
            <View style={{ height: "30px" }}>
              <Text style={{ fontSize: "9px", color: "#757575" }}>Port of Discharge</Text>
              <Text style={{ fontSize: "7px", color: "#212121", padding: "5px" }}>
                {props.value.portOfLoading ? props.value.portOfLoading : ""}
              </Text>
            </View>
          </View>

          <View style={{ width: "100%", textAlign: "start" }}>
            <View>
              <Text style={{ fontSize: "9px", color: "#757575" }}>Packing Information</Text>
              <Text style={{ fontSize: "7px", color: "#212121", padding: "5px" }}>
                {props.value.packingInformation ? props.value.packingInformation : ""}
              </Text>
            </View>
          </View>

          {/* <View style={{ width: "34%", marginRight: "1%" }}>
            <View style={{ width: "100%", flexDirection: "row" }}>
              <View style={{ width: "60%", marginRight: "1%" }}>
                <View style={{ flexDirection: "row", width: "100%" }}>
                  <Text style={{ fontSize: "9px", color: "#757575" }}>Method of Dispatch</Text>
                </View>
                <View style={{ flexDirection: "row", width: "100%", minHeight: "25px" }}>
                  <Text style={{ fontSize: "7px", color: "#212121", padding: "5px" }}>
                    {props.value.methodOfDispatch ? props.value.methodOfDispatch : ""}
                  </Text>
                </View>
              </View>
            </View>
          </View>

          <View style={{ width: "32%", marginLeft: "1%" }}>
            <View style={{ flexDirection: "row", width: "100%" }}>
              <Text style={{ fontSize: "9px", color: "#757575" }}>Type of Shipment</Text>
            </View>
            <View style={{ flexDirection: "row", width: "100%", minHeight: "25px" }}>
              <Text style={{ fontSize: "7px", color: "#212121", padding: "5px" }}>
                {props.value.typeOfShipment ? props.value.typeOfShipment : ""}
              </Text>
            </View>
          </View> */}

          {/* <View style={{ width: "32%", marginLeft: "1%" }}>
            <View style={{ flexDirection: "row", width: "100%" }}>
              <Text style={{ fontSize: "9px", color: "#757575" }}>Packing Information</Text>
            </View>
            <View style={{ flexDirection: "row", width: "100%", minHeight: "25px" }}>
              <Text style={{ fontSize: "7px", color: "#212121", padding: "5px" }}>
                {props.value.typeOfShipment ? props.value.typeOfShipment : ""}
               
              </Text>
            </View>
          </View> */}
        </View>

        {/* <View style={{ flexDirection: "row", width: "100%", marginTop: "5px" }}>
          <View style={{ width: "34%", marginRight: "1%" }}>
            <View style={{ width: "100%", flexDirection: "row" }}>
              <View style={{ width: "60%", marginRight: "1%" }}>
                <View style={{ flexDirection: "row", width: "100%" }}>
                  <Text style={{ fontSize: "9px", color: "#757575" }}>Transport</Text>
                </View>
                <View style={{ flexDirection: "row", width: "100%", minHeight: "25px" }}>
                  <Text style={{ fontSize: "7px", color: "#212121", padding: "5px" }}>
                    {props.value.transport ? props.value.transport : ""}
                  </Text>
                </View>
              </View>
            </View>
          </View>

          <View style={{ width: "32%", marginLeft: "1%" }}>
            <View style={{ flexDirection: "row", width: "100%" }}>
              <Text style={{ fontSize: "9px", color: "#757575" }}>Voyage No</Text>
            </View>
            <View style={{ flexDirection: "row", width: "100%", minHeight: "25px" }}>
              <Text style={{ fontSize: "7px", color: "#212121", padding: "5px" }}>
                {props.value.voyageNo ? props.value.voyageNo : ""}
              </Text>
            </View>
          </View>
        </View> */}

        {/* <View style={{ flexDirection: "row", width: "100%", marginTop: "5px" }}>
          <View style={{ width: "34%", marginRight: "1%" }}>
            <View style={{ width: "100%", flexDirection: "row" }}>
              <View style={{ width: "60%", marginRight: "1%" }}>
                <View style={{ flexDirection: "row", width: "100%" }}>
                  <Text style={{ fontSize: "9px", color: "#757575" }}>Port of Loading</Text>
                </View>
                <View style={{ flexDirection: "row", width: "100%", minHeight: "25px" }}>
                  <Text style={{ fontSize: "7px", color: "#212121", padding: "5px" }}>
                    {props.value.portOfLoading ? props.value.portOfLoading : ""}
                  </Text>
                </View>
              </View>
            </View>
          </View>

          <View style={{ width: "32%", marginLeft: "1%" }}>
            <View style={{ flexDirection: "row", width: "100%" }}>
              <Text style={{ fontSize: "9px", color: "#757575" }}>Port of Discharge</Text>
            </View>
            <View style={{ flexDirection: "row", width: "100%", minHeight: "25px" }}>
              <Text style={{ fontSize: "7px", color: "#212121", padding: "5px" }}>
                {props.value.portOfDischarge ? props.value.portOfDischarge : ""}
              </Text>
            </View>
          </View>
        </View> */}

        {/* <View style={{ flexDirection: "row", width: "100%", marginTop: "5px" }}>
          <View style={{ width: "34%", marginRight: "1%" }}>
            <View style={{ width: "100%", flexDirection: "row" }}>
              <View style={{ width: "60%", marginRight: "1%" }}>
                <View style={{ flexDirection: "row", width: "100%" }}>
                  <Text style={{ fontSize: "9px", color: "#757575" }}>Date of Departure</Text>
                </View>
                <View style={{ flexDirection: "row", width: "100%", minHeight: "25px" }}>
                  <Text style={{ fontSize: "7px", color: "#212121", padding: "5px" }}>
                    {props.value.dateOfDeparture ? props.value.dateOfDeparture : ""}
                  </Text>
                </View>
              </View>
            </View>
          </View>

          <View style={{ width: "32%", marginLeft: "1%" }}>
            <View style={{ flexDirection: "row", width: "100%" }}>
              <Text style={{ fontSize: "9px", color: "#757575" }}>Final Destination</Text>
            </View>
            <View style={{ flexDirection: "row", width: "100%", minHeight: "25px" }}>
              <Text style={{ fontSize: "7px", color: "#212121", padding: "5px" }}>
                {props.value.finalDestination ? props.value.finalDestination : ""}
              </Text>
            </View>
          </View>
        </View> */}

        <View style={{ flexDirection: "row", width: "100%", marginTop: "10px" }}>
          <View style={{ width: "16.66%", backgroundColor: "#607d8b", padding: "5px 0px" }}>
            <View
              style={{
                flexDirection: "row",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <Text style={{ fontSize: "9px", fontWeight: "bold", color: "white" }}>
                Product Code
              </Text>
            </View>
          </View>

          <View style={{ width: "16.66%", backgroundColor: "#607d8b", padding: "5px 0px" }}>
            <View
              style={{
                flexDirection: "row",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <Text style={{ fontSize: "9px", fontWeight: "bold", color: "white" }}>
                Description of Goods
              </Text>
            </View>
          </View>

          <View style={{ width: "16.66%", backgroundColor: "#607d8b", padding: "5px 0px" }}>
            <View
              style={{
                flexDirection: "row",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <Text style={{ fontSize: "9px", fontWeight: "bold", color: "white" }}>Unit qty</Text>
            </View>
          </View>

          <View style={{ width: "16.66%", backgroundColor: "#607d8b", padding: "5px 0px" }}>
            <View
              style={{
                flexDirection: "row",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <Text style={{ fontSize: "9px", fontWeight: "bold", color: "white" }}>Unit Type</Text>
            </View>
          </View>

          <View style={{ width: "16.66%", backgroundColor: "#607d8b", padding: "5px 0px" }}>
            <View
              style={{
                flexDirection: "row",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <Text style={{ fontSize: "9px", fontWeight: "bold", color: "white" }}>Price</Text>
            </View>
          </View>

          <View style={{ width: "16.66%", backgroundColor: "#607d8b", padding: "5px 0px" }}>
            <View
              style={{
                flexDirection: "row",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <Text style={{ fontSize: "9px", fontWeight: "bold", color: "white" }}>Amount</Text>
            </View>
          </View>
        </View>

        <View style={{ width: "100%", minHeight: "40px" }}>
          {props.value.shipperDataTableValue &&
            props.value.shipperDataTableValue.length > 0 &&
            props.value.shipperDataTableValue.map((data) => {
              return (
                <View
                  style={{
                    flexDirection: "row",
                    width: "100%",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                  }}
                >
                  <View style={{ width: "16.66%", marginRight: "1%" }}>
                    <View style={{ flexDirection: "row", width: "100%" }}>
                      <Text style={{ fontSize: "7px", color: "#212121", padding: "5px" }}>
                        {data.marksAndNumbers ? data.marksAndNumbers : ""}
                      </Text>
                    </View>
                  </View>

                  <View style={{ width: "16.66%", marginRight: "1%" }}>
                    <View style={{ flexDirection: "row", width: "100%" }}>
                      <Text style={{ fontSize: "7px", color: "#212121", padding: "5px" }}>
                        {data.noOfPackages ? data.noOfPackages : ""}
                      </Text>
                    </View>
                  </View>

                  <View style={{ width: "16.66%", marginRight: "1%" }}>
                    <View style={{ flexDirection: "row", width: "100%" }}>
                      <Text style={{ fontSize: "7px", color: "#212121", padding: "5px" }}>
                        {data.descriptionOfGoods ? data.descriptionOfGoods : ""}
                      </Text>
                    </View>
                  </View>

                  <View style={{ width: "16.66%", marginRight: "1%" }}>
                    <View style={{ flexDirection: "row", width: "100%" }}>
                      <Text style={{ fontSize: "7px", color: "#212121", padding: "5px" }}>
                        {data.tariffCode ? data.tariffCode : ""}
                      </Text>
                    </View>
                  </View>

                  <View style={{ width: "16.66%", marginRight: "1%" }}>
                    <View style={{ flexDirection: "row", width: "100%" }}>
                      <Text style={{ fontSize: "7px", color: "#212121", padding: "5px" }}>
                        {data.grossWeight ? data.grossWeight : ""}
                      </Text>
                    </View>
                  </View>
                </View>
              );
            })}
        </View>

        <View style={styles.borderView}>
          <Text style={styles.border}></Text>
        </View>

        <View style={{ flexDirection: "row-reverse", width: "100%", marginTop: "5px" }}>
          <View style={{ width: "30%", marginLeft: "1%" }}>
            <View style={{ flexDirection: "row", width: "100%" }}>
              <Text style={{ fontSize: "9px", color: "#757575" }}>Total Amount:</Text>
            </View>
            <View style={{ flexDirection: "row", width: "100%", minHeight: "25px" }}>
              <Text style={{ fontSize: "7px", color: "#212121", padding: "5px" }}>
                {props.value.totalAmount ? props.value.totalAmount : ""}
              </Text>
            </View>
          </View>
        </View>

        <View style={styles.borderView}>
          <Text style={styles.border}></Text>
        </View>

        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <View style={{ width: "50%" }}>
            <View>
              <Text style={{ fontSize: "9px", color: "#757575" }}>Declaration by Chamber</Text>
              <Text style={{ fontSize: "7px", color: "#212121", paddingTop: "5px" }}>
                The undersigned certifies on the basis of information provided by the exporter that
                to the best of it’s knowledge and belief, the goods are of designated origin,
                production or manufacture.
              </Text>
            </View>
          </View>

          <View style={{ width: "50%" }}>
            <View style={{ marginBottom: "10px" }}>
              <Text style={{ fontSize: "9px", color: "#757575" }}>Declaration by Exporter</Text>
              <Text
                style={{
                  fontSize: "7px",
                  color: "#212121",
                  paddingTop: "5px",
                }}
              >
                I, the undersigned, being duly authorized by the Consignor, and having made the
                necessary enquiries hereby certify that based on the rules of origin of the country
                of destination, all the goods listed originate in the country and place of
                designated. I further declare that I will furnish to the Customs authorities of the
                importing or their nominee, for inspection at any time, such as evidence as may be
                required for the purpose of verifying this certificate.
              </Text>
            </View>
            <View style={{ height: "30px" }}>
              <Text style={{ fontSize: "9px", color: "#757575" }}>
                The goods where produced/manufactured at
              </Text>
              <Text style={{ fontSize: "7px", color: "#212121", paddingTop: "5px" }}>
                {props.value.goodsProducedAt ? props.value.goodsProducedAt : ""}
              </Text>
            </View>
          </View>
        </View>

        <View style={styles.borderView}>
          <Text style={styles.border}></Text>
        </View>

        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <View
            style={{
              width: "50%",
              flexDirection: "row",
            }}
          >
            <View style={{ width: "100%" }}>
              <View style={{ height: "30px" }}>
                <Text style={{ fontSize: "9px", color: "#757575" }}>Place of Issue</Text>
                <Text style={{ fontSize: "7px", color: "#212121", padding: "5px" }}>
                  {props.value.placeOfIssue ? props.value.placeOfIssue : ""}
                </Text>
              </View>

              <View style={{ height: "30px" }}>
                <Text style={{ fontSize: "9px", color: "#757575" }}>Signatory Company</Text>
                <Text style={{ fontSize: "7px", color: "#212121", padding: "5px" }}>
                  {props.value.signatoryCompany ? props.value.signatoryCompany : ""}
                </Text>
              </View>

              <View style={{ height: "30px" }}>
                <Text style={{ fontSize: "9px", color: "#757575" }}>Signature</Text>
                <Text style={{ fontSize: "7px", color: "#212121", padding: "5px" }}>
                  {props.value.signature ? props.value.signature1 : ""}
                </Text>
              </View>
            </View>

            <View style={{ width: "100%" }}>
              <Text style={{ fontSize: "9px", color: "#757575" }}>Date</Text>
              <Text style={{ fontSize: "7px", color: "#212121", padding: "5px" }}>
                {props.value.date ? props.value.date : ""}
              </Text>
            </View>
          </View>
          <View style={{ width: "50%", flexDirection: "row" }}>
            <View style={{ width: "100%" }}>
              <View style={{ height: "30px" }}>
                <Text style={{ fontSize: "9px", color: "#757575" }}>Place of Issue</Text>
                <Text style={{ fontSize: "7px", color: "#212121", padding: "5px" }}>
                  {props.value.placeOfIssue2 ? props.value.placeOfIssue2 : ""}
                </Text>
              </View>

              <View style={{ height: "30px" }}>
                <Text style={{ fontSize: "9px", color: "#757575" }}>Signatory Company</Text>
                <Text style={{ fontSize: "7px", color: "#212121", padding: "5px" }}>
                  {props.value.signatoryCompany2 ? props.value.signatoryCompany2 : ""}
                </Text>
              </View>

              <View style={{ height: "30px" }}>
                <Text style={{ fontSize: "9px", color: "#757575" }}>Signature</Text>
                <Text style={{ fontSize: "7px", color: "#212121", padding: "5px" }}>
                  {props.value.signature2 ? props.value.signature2 : ""}
                </Text>
              </View>
            </View>

            <View style={{ width: "100%" }}>
              <Text style={{ fontSize: "9px", color: "#757575" }}>Date</Text>
              <Text style={{ fontSize: "7px", color: "#212121", padding: "5px" }}>
                {props.value.date2 ? props.value.date2 : ""}
                Date
              </Text>
            </View>
          </View>
        </View>

        {/* <View style={{ flexDirection: "row", width: "100%" }}>
          <View style={{ width: "49%", marginLeft: "1%" }}>
            <View style={{ width: "100%", flexDirection: "row" }}>
              <View style={{ width: "48%", marginRight: "2%" }}>
                <View style={{ flexDirection: "row", width: "100%" }}>
                  <Text style={{ fontSize: "9px", color: "#757575" }}>Place of Issue</Text>
                </View>
                <View style={{ flexDirection: "row", width: "100%", minHeight: "30px" }}>
                  <Text style={{ fontSize: "7px", color: "#212121", padding: "5px" }}>
                    {props.value.placeOfIssue ? props.value.placeOfIssue : ""}
                  </Text>
                </View>
              </View>

              <View style={{ width: "48%", marginLeft: "2%" }}>
                <View style={{ flexDirection: "row", width: "100%" }}>
                  <Text style={{ fontSize: "9px", color: "#757575" }}>Date</Text>
                </View>
                <View style={{ flexDirection: "row", width: "100%", minHeight: "30px" }}>
                  <Text style={{ fontSize: "7px", color: "#212121", padding: "5px" }}>
                    {props.value.date ? props.value.date : ""}
                  </Text>
                </View>
              </View>
            </View>

            <View style={{ width: "100%" }}>
              <View style={{ flexDirection: "row", width: "100%" }}>
                <Text style={{ fontSize: "9px", color: "#757575" }}>Signatory Company</Text>
              </View>
              <View style={{ flexDirection: "row", width: "100%", height: "30px" }}>
                <Text style={{ fontSize: "7px", color: "#212121", padding: "5px" }}>
                  {props.value.signatoryCompany ? props.value.signatoryCompany : ""}
                </Text>
              </View>
            </View>
          </View>
        </View> */}

        {/* <View style={{ flexDirection: "row", width: "100%" }}>
          <View style={{ width: "49%", marginLeft: "1%" }}>
            <View style={{ width: "100%", flexDirection: "row" }}>
              <View style={{ width: "100%", marginRight: "2%" }}>
                <View style={{ flexDirection: "row", width: "100%" }}>
                  <Text style={{ fontSize: "9px", color: "#757575" }}>
                    Name of Authorized Signatory
                  </Text>
                </View>
                <View style={{ flexDirection: "row", width: "100%", height: "30px" }}>
                  <Text style={{ fontSize: "7px", color: "#212121", padding: "5px" }}>
                    {props.value.nameOfAuthorizedSignatory
                      ? props.value.nameOfAuthorizedSignatory
                      : ""}
                  </Text>
                </View>
              </View>
            </View>

            <View style={{ width: "100%" }}>
              <View style={{ flexDirection: "row", width: "100%" }}>
                <Text style={{ fontSize: "9px", color: "#757575" }}>Signature</Text>
              </View>
              <View style={{ flexDirection: "row", width: "100%" }}>
                <Text style={{ fontSize: "7px", color: "#212121", padding: "5px" }}>
                  {props.value.signature ? (
                    <Image
                      allowDangerousPath
                      src={props.value.signature}
                      style={{ width: "100px", height: "30px" }}
                    />
                  ) : (
                    ""
                  )}{" "}
                </Text>
              </View>
            </View>
          </View>
        </View> */}
      </Page>
    </Document>
  );
};

export default MyDocument;
