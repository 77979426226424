import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";


import { styled } from '@mui/material/styles';
// @mui material components
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import AddIcon from "@mui/icons-material/Add";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
// @mui material components
// import VuiTypography from "components/VuiTypography";
// Vision UI Dashboard PRO React components
// import VuiBox from "components/VuiBox";;
import VuiSelect from "components/VuiSelect";
import VuiDatePicker from "components/VuiDatePicker";
// import VuiButton from "components/VuiButton";
import VuiInput from "components/VuiInput";
import VuiSwitch from "components/VuiSwitch";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";

// Vision UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import dataTableData from "layouts/applications/data-tables/data/dataTableData";

import { getProformaInvoice } from "services/documentService";
import { PDFViewer } from "@react-pdf/renderer";
import QRCode from "qrcode";
import Picture from "./ship.png";
import { getCompanyById } from "services/documentService";
import { isMobile, isBrowser } from "react-device-detect";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { updateProformaOnChain } from "services/documentService";
import Swal from "sweetalert2";
import { getActivePlan } from "services/pricingService";

import { checkCreateDocs } from "services/pricingService";
import ButtonGroup from "@mui/material/ButtonGroup";
import { deleteProformaInvoiceById } from "services/documentService";
import { MdDelete } from "react-icons/md";
import { MdModeEdit } from "react-icons/md";
import { FaEdit } from "react-icons/fa";

import ShipImage from "./ship.png";
import AttributeDescriptionTable from "./AttributeDescriptionTable";


const Label = ({label = "Label", error}) => {
	//const error = !error ? "" : error; //In case error is false, do not print false.

	return (
		<div>
			<VuiTypography variant="body2" fontWeight="bold" color="text" style={{marginTop: "2px", marginBottom: "2px"}}>{label}</VuiTypography>
			{error && <VuiTypography variant="body2" color="error" style={{marginTop: "2px", marginBottom: "2px"}}>{error}</VuiTypography>}
		</div>
	);
}

const LabelAndInput = ({label = "Label", placeholder}) => {
	return (
		<>
			<VuiTypography variant="body2" fontWeight="bold" color="text">
				{label}
			</VuiTypography>
			<input placeholder={placeholder ?? label} style={{display: "block", width: "100px", border: "black solid 1px"}}></input>
		</>
	)
}



const VisuallyHiddenInput = styled('input')({
	clip: 'rect(0 0 0 0)',
	clipPath: 'inset(50%)',
	height: 1,
	overflow: 'hidden',
	position: 'absolute',
	bottom: 0,
	left: 0,
	whiteSpace: 'nowrap',
	width: 1,
});

function Supplier({index, suppliersData, onSuppliersDataChanged, validationDetails}) {
	const supplierName	= suppliersData[index].name;
	const supplierEmail	= suppliersData[index].email;

	const onSupplierNameChanged = (supplierName) => {
		suppliersData[index].name = supplierName;
		onSuppliersDataChanged(suppliersData);
	}

	const onSupplierEmailChanged = (supplierEmail) => {
		suppliersData[index].email = supplierEmail;
		onSuppliersDataChanged(suppliersData);
	}

	const onSupplierDeleted = () => {
		suppliersData.splice(index, 1)
		onSuppliersDataChanged(suppliersData);
	}


	return (
		<Stack container direction="row" spacing={2}>
			<Stack item>
				<VuiTypography variant="body2" fontWeight="bold" color="text" style={{margin: "auto", border: "2px solid", borderRadius: "50%", width: "25px", textAlign: "center"}}>
					{index+1}
				</VuiTypography>
				<Divider orientation="vertical" />
			</Stack>
			<Stack item style={{ flexGrow: 1 }}>
				<Label label="Supplier" error={validationDetails && validationDetails.name} />
				<VuiInput placeholder="Supplier Name" value={supplierName} onChange={(e) => {onSupplierNameChanged(e.target.value)}} fullwidth></VuiInput>
				<Label label="Supplier Email" error={validationDetails && validationDetails.email} />
				<VuiInput placeholder="Supplier Email" value={supplierEmail} onChange={(e) => {onSupplierEmailChanged(e.target.value)}} fullwidth></VuiInput>
				{ suppliersData.length > 1 && 
				<VuiButton startIcon={<DeleteIcon />} style={{margin: "10px 0 1px 6px", width: "100px"}} onClick={onSupplierDeleted}>
					Delete
				</VuiButton>
				}
			</Stack>
		</Stack>
	);
}

// Could be broken down into Product component as well
function ProductSection({ productData, onShowPreview, onSubmit, validationDetails, onProductDataChanged }) {
	const companyLogo					= productData.companyLogo;
	const productName					= productData.product.name;
	const productDescription			= productData.product.description;
	const productImage					= productData.productImage;
	const attributeDescriptionData 		= productData.product.attributes;
	
	const onCompanyLogoAdded = (e) => {
		// 12kb file limit
		if (e.target.files[0].size <= 12000) {
			let file = e.target.files[0];
			let reader = new FileReader();
			reader.readAsDataURL(file);

			reader.onloadend = function (e) {
				productData.companyLogoFile = file;
				productData.companyLogo = reader.result;
				onProductDataChanged(productData);
			};
			
			
		} else {
			Swal.fire("Company Logo too large", "Logo size should be at max 12 kb", "error");
		}
	}

	const onProductImageAdded = (e) => {
		// 25kb file limit
		if (e.target.files[0].size <= 25000) {
			let file = e.target.files[0];
			let reader = new FileReader();
			reader.readAsDataURL(file);

			reader.onloadend = function (e) {
				productData.productImageFile = file;
				productData.productImage = reader.result;
				onProductDataChanged(productData);
			};
		} else {
			Swal.fire("Product Image too large", "Product Image should be at max 25 kb", "error");
		}
	}

	const onProductNameChanged = (name) => {
		productData.product.name = name;
		onProductDataChanged(productData);
	}

	const onProductDescriptionChanged = (description) => {
		productData.product.description = description;
		onProductDataChanged(productData);
	}

	const onProductAttributesChanged = (attributes) => {
		productData.product.attributes = attributes;
		onProductDataChanged(productData);
	}

	const onSuppliersChanged = (suppliers) => {
		productData.suppliers = suppliers;
		onProductDataChanged(productData);
	}

	const onSupplierAdded = () => {
		productData.suppliers.push({name: "", email: ""});
		onProductDataChanged(productData);	
	}

	return (
		<>
			{/* Change logo Section */}
			<Grid style={{marginBottom: "20px"}} container alignItems="center" spacing={1}>
				<Grid item>
					<img src={companyLogo} style={{width: "50px", height: "50px"}} alt="company logo" />
				</Grid>

				<Grid item>
					<VuiButton variant="gradient" component="label" color="info" sx={{ maxWidth: "200px" }} startIcon={<AddPhotoAlternateIcon size={6}/>}>
						Change Company Logo
						<VisuallyHiddenInput type="file" accept=".png, .jpg, .jpeg" onChange={onCompanyLogoAdded} />
					</VuiButton>
				</Grid>
			</Grid>


			{/* Product Information Section */}
			<VuiTypography variant="body1" fontWeight="bold" color="text">
				Your Product
			</VuiTypography>

			<Label label="Product Name" error={validationDetails && validationDetails.productName}/>
			<VuiInput placeholder="Product Name" value={productName} onChange={(e) => {onProductNameChanged(e.target.value)}}></VuiInput>
			<Stack container mt={"10px"}
 			  direction="row" 
 			  alignItem="flex-start"
			  spacing={6}
			>
				<Stack item style={{width: "200px"}}>
					<Label label="Product Image"/>
					<img src={productImage} style={{width: "200px", margin: "5px 0 5px 0"}} alt="company logo" />
					<VuiButton variant="gradient" component="label" color="info" startIcon={<AddPhotoAlternateIcon size={18}/>} fullwidth>
						Change Image
						<VisuallyHiddenInput type="file" accept=".png, .jpg, .jpeg" onChange={onProductImageAdded} />
					</VuiButton>
				</Stack>
				<Stack item style={{ flexGrow: 1 }}>
					<Label label="Product Short Description" error={validationDetails && validationDetails.productDescription}/>
					<VuiInput fullwidth placeholder="Short Description" value={productDescription} onChange={(e) => {onProductDescriptionChanged(e.target.value)}}></VuiInput>
					<Label label="Product Attributes" error={validationDetails && validationDetails.productAttributes}/>
					<AttributeDescriptionTable data={attributeDescriptionData} setData={onProductAttributesChanged}/>
				</Stack>
			</Stack>

			<hr></hr>
		
			{/* Product Supply Chain Section, basically for user to add locations here  */}
			<VuiTypography variant="body1" fontWeight="bold" color="text">
				Product's Supply Chain Details
			</VuiTypography>
			<VuiTypography variant="body2" fontWeight="regular" color="text">
				The chain ends with you, what do you once you receive material processed or unprocesssed, do you process it further, do you package and label, or do you only distribute?
			</VuiTypography>
			
			<Stack container divider={<Divider orientation="horizontal" />}>
				{
					productData.suppliers.map((el, index) => 
						<Supplier index={index} suppliersData={productData.suppliers} onSuppliersDataChanged={onSuppliersChanged} validationDetails={(validationDetails && validationDetails.suppliers) && validationDetails.suppliers[index]} />
					)
				}
				
				<VuiButton variant="gradient" component="span" size="large" color="info" onClick={onSupplierAdded} fullwidth>
					<AddIcon size={18}/> Add new supplier
				</VuiButton>
			</Stack>


			<div style={{margin: "auto", paddingTop: "40px"}} >
				

				<VuiButton variant="gradient" component="span" size="medium" color="info" sx={{ width: "200px", minWidth: "75px" }} onClick={onSubmit}>
					Submit
				</VuiButton>
			</div>
		</>
	);
}


export default ProductSection;