import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Picture from "./ship.png";

// @mui material components
import Card from "@mui/material/Card";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";

// Vision UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import dataTableData from "layouts/applications/data-tables/data/dataTableData";
import { getBillOfLading } from "services/documentService";

// import { getSmartDocuments } from "services/documentService";
import { isMobile, isBrowser } from "react-device-detect";
// import { PDFViewer } from "@react-pdf/renderer";
// import { PDFDownloadLink } from "@react-pdf/renderer";
// import PDFDocument from "../add-bill-of-lading/pdfDocument";
// import PDFDOCLIST from "./pdfDocList";
import QRCode from "qrcode";
import Swal from "sweetalert2";
import Verified from "./Verified2.png";
import { getCompanyById } from "services/documentService";

import { addBillOfLadingBlockChain } from "services/documentService";
import { updateBillOfLadingOnChain } from "services/documentService";
import ButtonGroup from "@mui/material/ButtonGroup";
import { checkCreateDocs } from "services/pricingService";
import { deleteBillOfLadingById } from "services/documentService";
import { MdDelete } from "react-icons/md";
import { MdModeEdit } from "react-icons/md";
import { FaEdit } from "react-icons/fa";

function ListAgreement() {
  let history = useHistory();

  const [billOfLading, setBillOfLading] = useState("");
  const [bill, setBill] = useState({});
  const [qrData, setQrData] = useState("");
  const [fromCompanyData, setFromCompanyData] = useState({});
  const [toCompanyData, setToCompanyData] = useState({});
  const [carrierData, setCarrierData] = useState({});
  const [notifyPartyData, setNotifyPartyData] = useState({});
  const [additionalNotifyPartyData, setAdditionalNotifyPartyData] = useState({});
  const [showPreview, setShowPreview] = useState(false);
  const [uploaded, setUploaded] = useState(false);

  useEffect(() => {
    localStorage.removeItem("timer");
  });

  useEffect(() => {
    fetchBillOfLading();
  }, [uploaded]);

  // const handleShowPreview = () => {
  //   if (showPreview) {
  //     setShowPreview(false);
  //   } else {
  //     setShowPreview(true);
  //   }
  // };

  const handleClosePreview = () => {
    setShowPreview(false);
  };

  const fetchBillOfLading = () => {
    getBillOfLading().then((Response) => {
      console.log(">>>>>>>>>>>>>>>>>>> bill of lading", Response);

      // setBillOfLading(Response);
      // console.log(Response);

      let newResponse = [];
      for (let item of Response) {
        newResponse.push({
          ...item,
          action1: (
            <VuiButton variant="text" color="info" size="medium" onClick={() => viewDoc(item)}>
              View
            </VuiButton>
          ),

          action2: (
            <VuiButton
              variant="gradient"
              color="info"
              size="medium"
              disabled={item.onBlockChain === 1}
              onClick={(e) => upload(e.target, item)}
            >
              Upload on BlockChain
            </VuiButton>
          ),

          action3: (
            <ButtonGroup disabled={item.onBlockChain === 1}>
              <VuiButton variant="gradient" color="info" onClick={() => handleEdit(item)}>
                <MdModeEdit />
              </VuiButton>
              <VuiButton variant="gradient" color="info" onClick={() => handleDelete(item)}>
                <MdDelete />
              </VuiButton>
            </ButtonGroup>
          ),

          action4: (
            <VuiButton variant="gradient" color="info" size="medium" onClick={() => handleTrack()}>
              Track
            </VuiButton>
          ),
        });
      }

      console.log(newResponse);
      setBillOfLading(newResponse);
    });
  };

  const dates = (time) => {
    var d = new Date(time);
    var time = d.getUTCMonth() + 1 + " / " + d.getUTCDate() + " / " + d.getUTCFullYear();
    return time;
  };

  const datesWithTime = (time) => {
    var d = new Date(time);
    var time =
      d.getUTCDate() +
      " / " +
      (d.getUTCMonth() + 1) +
      " / " +
      d.getUTCFullYear() +
      " " +
      d.getUTCHours() +
      ":" +
      d.getUTCMinutes();
    return time;
  };

  const getTime = (time) => {
    var d = new Date(time);
    var time = d.getUTCHours() + ":" + d.getUTCMinutes();
    return time;
  };

  // const showShipDetail = (data) => {
  //   console.log(data);
  //   history.push({
  //     pathname: "/merchant/active-ship-details",
  //     details: data,
  //   });
  // };

  const handleTrack = () => {
    history.push("/btnt-docs/timeline");
  };

  const viewDoc = (item) => {
    console.log(item, "billitem");

    setBill(item);
    setFromCompanyData(item.shipperCompanyData[0]);
    setToCompanyData(item.consigneeCompanyData[0]);
    setCarrierData(item.carrierNameCompanyData[0]);
    setNotifyPartyData(item.notifyPartyCompanyData[0]);
    setAdditionalNotifyPartyData(item.additionalNotifyPartyCompanyData[0]);

    console.log("checkblockchainData", item.onBlockChain);

    if (item.onBlockChain === 1) {
      const generateQrCode = async () => {
        try {
          const res = await QRCode.toDataURL(
            `https://bitdocs.bitnautic.com/btnt-docs/public-billOfLading/?onBlockChainHash=${item.onBlockChainHash}&transactionHash=${item.transactionHash}`
          );
          console.log(res);
          setQrData(res);
        } catch (err) {
          console.log(err);
        }
      };
      generateQrCode();
    } else {
      setQrData(null);
    }

    setShowPreview(true);

    // if (item.id !== "12") {
    //   console.log("false");
    // } else {
    //   console.log("true");
    // }
    // swal("", "Upload Initiated...", "success");
  };

  // const upload = (item) => {
  //   console.log(item);
  //   swal("", "Upload Initiated...", "success");
  // };

  const upload = (e, item) => {
    console.log(e);
    console.log(item.id, "THIS IDD>>>>>>>>>>>>>>");
    // e.disabled = true;
    Swal.fire({
      position: "center",

      title: "Upload Initiated",
      showConfirmButton: false,
      allowOutsideClick: false,

      didOpen: () => {
        Swal.showLoading();

        updateBillOfLadingOnChain(item.id).then((response) => {
          // console.log("response>>", response);

          if (response.success === false) {
            Swal.fire({
              title: "You've reached limit as per your plan.",
              text: "Upgrade plan",
              icon: "warning",
              showCancelButton: true,
              showConfirmButton: true,
              // confirmButtonText: "Yes, delete it!",
              // cancelButtonText: 'No, cancel!',
            }).then((result) => {
              if (result.isConfirmed) {
                history.push(`/btnt-docs/pricings`);
              }
            });
            return;
          }

          if (response.OnBlockChain === true) {
            setUploaded((current) => !current);
            // setTitle("Uploaded");
            // Swal.hideLoading();
            Swal.close();
          } else {
            Swal.fire({
              title: "Upload Failed",
              text: "Please try again",
              icon: "error",
              showConfirmButton: true,
            });
          }
        });
      },
    }).then(() => {
      Swal.fire({
        title: "Upload Successful",
        icon: "success",
        showConfirmButton: true,
      });
    });
  };

  const handleDelete = (item) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      // cancelButtonText: 'No, cancel!',
    }).then((result) => {
      if (result.isConfirmed) {
        deleteBillOfLadingById(item.id).then((res) => {
          Swal.fire("", "Deleted successfully", "success");
          setUploaded((current) => !current);
          console.log("deleted", res);
        });
      }
    });

    // deleteBillOfLadingById(item.id).then((res) => {
    //   Swal.fire("", "Deleted successfully", "success");
    //   setUploaded((current) => !current);
    //   console.log("deleted", res);
    // });
  };

  const handleEdit = (item) => {
    console.log("item", item);
    history.push(`/btnt-docs/bill-of-lading/?id=${item.id}`);
  };

  const billTable = {
    columns: [
      // { Header: "MmsiKey", accessor: "mmsiKey"},
      // { Header: "IMO", accessor: "imo"},
      {
        Header: "UCR",
        accessor: "unique_consignment_reference",
        align: "center",
      },
      {
        Header: "Date",
        accessor: "date",
        align: "center",
        Cell: (cell) => (
          <>
            <VuiTypography variant="sm" color="text" component="td">
              {dates(cell.row.values.date)}
            </VuiTypography>
          </>
        ),
      },

      {
        Header: "Transport",
        accessor: "transport",
        align: "center",
        // Cell: (cell) => (
        //   <>
        //     <VuiTypography variant="sm" color="text" component="td">
        //       {dates(cell.row.values.departureTime)}
        //     </VuiTypography>
        //     <VuiTypography variant="sm" color="text" component="p">
        //       {getTime(cell.row.values.departureTime)}
        //     </VuiTypography>
        //   </>
        // ),
      },

      // {
      //   Header: "To",
      //   accessor: "transport",
      //   align: "center",
      //   // Cell: (cell) => (
      //   //   <>
      //   //     <VuiTypography variant="sm" color="text" component="td">
      //   //       {dates(cell.row.values.departureTime)}
      //   //     </VuiTypography>
      //   //     <VuiTypography variant="sm" color="text" component="p">
      //   //       {getTime(cell.row.values.departureTime)}
      //   //     </VuiTypography>
      //   //   </>
      //   // ),
      // },

      {
        Header: "Port of Loading",
        accessor: "port_of_landing",
        align: "center",
      },
      {
        Header: "Port of Delivery",
        accessor: "place_of_delivery",
        align: "center",
        // Cell: (cell) => (
        //   <>
        //     <VuiTypography variant="sm" color="text" component="td">
        //       {dates(cell.row.values.readyToLoad)}
        //     </VuiTypography>
        //     <VuiTypography variant="sm" color="text" component="p">
        //       {getTime(cell.row.values.readyToLoad)}
        //     </VuiTypography>
        //   </>
        // ),
      },

      {
        Header: "Action",
        accessor: "action1",
        align: "center",
        // Cell: (cell) => {
        //   return (
        //     <VuiButton
        //       variant="text"
        //       color="info"
        //       size="medium"
        //       onClick={() => upload(cell.row.original)}
        //     >
        //       View
        //     </VuiButton>
        //   );
        // },
      },

      {
        Header: "Action",
        accessor: "action3",
        align: "center",
      },

      {
        Header: "Action",
        accessor: "action2",
        align: "center",
        // Cell: (cell) => {
        //   return (
        //     <VuiButton
        //       variant="gradient"
        //       color="info"
        //       size="medium"
        //       onClick={() => upload(cell.row.original)}
        //     >
        //       Upload on BlockChain
        //     </VuiButton>
        //   );
        // },
      },

      {
        Header: "Action",
        accessor: "action4",
        align: "center",
      },
    ],

    rows: billOfLading ? billOfLading : [],
  };

  const addNewBillOfLading = async () => {
    const verifyRes = await checkCreateDocs();

    if (verifyRes.success === false) {
      Swal.fire({
        title: "You've reached limit as per your plan.",
        text: "Upgrade plan",
        icon: "warning",
        showCancelButton: true,
        showConfirmButton: true,
        // confirmButtonText: "Yes, delete it!",
        // cancelButtonText: 'No, cancel!',
      }).then((result) => {
        if (result.isConfirmed) {
          history.push(`/btnt-docs/pricings`);
        }
      });

      return;
    }

    // getVerifyDocs().then((response) => {
    //   console.log("response>>", response);

    //   if (response.succes === false) {
    //     console.log("testing");

    //     Swal.fire("You've reached limit as per your plan.", "Buy a new plan", "warning");
    //     return;
    //   }
    // });

    history.push({
      pathname: "/btnt-docs/Agreement",
    });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox pt={6} pb={3}>
        <Card>
          {!showPreview ? (
            <>
              <VuiBox sx={{ display: "flex", justifyContent: "flex-end" }}>
                <VuiButton
                  variant="gradient"
                  component="span"
                  color="info"
                  onClick={addNewBillOfLading}
                >
                  Add New Agreement
                </VuiButton>
              </VuiBox>

              <VuiBox p={3} pl={0} lineHeight={1}>
                <VuiTypography variant="h5" fontWeight="medium" color="text">
                  Agreements
                </VuiTypography>
                <VuiTypography variant="button" fontWeight="regular" color="text">
                  List of all agreements
                </VuiTypography>
              </VuiBox>
              <DataTable table={billTable} canSearch />
            </>
          ) : (
            ""
          )}

          {showPreview && isBrowser ? (
            <>
              <div style={{ marginBottom: "10px", display: "flex", justifyContent: "flex-end" }}>
                <VuiButton
                  variant="gradient"
                  component="span"
                  color="info"
                  sx={{ minWidth: "100px" }}
                  onClick={() => {
                    handleClosePreview();
                  }}
                >
                  Close
                </VuiButton>
              </div>
              <div>
                <PDFViewer style={{ height: "500px", width: "100%" }}>
                  <PDFDOCLIST
                    value={{
                      logo: bill.logo_url,
                      shipperComapny: fromCompanyData,
                      shipperReference: bill.shipper_reference_one,
                      shipperReferenceSecond: bill.shipper_reference_two,
                      carrierReference: bill.carrierReference,
                      uniqueConsignmentReference: bill.unique_consignment_reference,
                      consigneeCompany: toCompanyData,
                      carrierCompanyName: carrierData,
                      notifyPartyCompanyName: notifyPartyData,
                      addNotifyPartyCompanyName: additionalNotifyPartyData,
                      preCarriageBy: bill.pre_carriage_by,
                      placeOfReceipt: bill.place_of_reciept,
                      additionalInformation: bill.additional_information,
                      transport: bill.transport,
                      voyageNo: bill.voyage_no,
                      placeOfDelivery: bill.place_of_delivery,
                      portOfLoading: bill.port_of_landing,
                      portOfDischarge: bill.port_of_discharge,
                      totalNetWeight: bill.total_net_weight,
                      totalGrossWeight: bill.total_gross_weight,
                      totalVolume: bill.total_volume,
                      additionalInformationSecond: bill.additional_info,
                      placeOfIssue: bill.place_of_issue,
                      date: dates(bill.date),
                      signatoryCompany: bill.signatory_company,
                      nameOfAuthorizedSignatory: bill.nameOfAuthorizedSignatory,
                      signature: bill.signature_url,
                      shipperDataTableValue: bill.goodsAndPackages,
                      qrData: qrData,
                    }}
                  />
                </PDFViewer>
              </div>
            </>
          ) : showPreview && isMobile ? (
            <VuiBox sx={{ textAlign: "center", height: "500px" }}>
              <VuiTypography marginBottom="2rem">
                Click on the button below to download Bill of Lading uploaded on blockchain
              </VuiTypography>
              <PDFDownloadLink
                document={
                  <PDFDOCLIST
                    value={{
                      logo: bill.logo_url,
                      shipperComapny: fromCompanyData,
                      shipperReference: bill.shipper_reference_one,
                      shipperReferenceSecond: bill.shipper_reference_two,
                      carrierReference: bill.carrierReference,
                      uniqueConsignmentReference: bill.unique_consignment_reference,
                      consigneeCompany: toCompanyData,
                      carrierCompanyName: carrierData,
                      notifyPartyCompanyName: notifyPartyData,
                      addNotifyPartyCompanyName: additionalNotifyPartyData,
                      preCarriageBy: bill.pre_carriage_by,
                      placeOfReceipt: bill.place_of_reciept,
                      additionalInformation: bill.additional_information,
                      transport: bill.transport,
                      voyageNo: bill.voyage_no,
                      placeOfDelivery: bill.place_of_delivery,
                      portOfLoading: bill.port_of_landing,
                      portOfDischarge: bill.port_of_discharge,
                      totalNetWeight: bill.total_net_weight,
                      totalGrossWeight: bill.total_gross_weight,
                      totalVolume: bill.total_volume,
                      additionalInformationSecond: bill.additional_info,
                      placeOfIssue: bill.place_of_issue,
                      date: dates(bill.date),
                      signatoryCompany: bill.signatory_company,
                      nameOfAuthorizedSignatory: bill.nameOfAuthorizedSignatory,
                      signature: bill.signature_url,
                      shipperDataTableValue: bill.goodsandpackagesbilloflading,
                      qrData: qrData,
                    }}
                  />
                }
                fileName="Document"
              >
                {({ loading }) =>
                  loading ? (
                    <VuiButton variant="gradient" color="info">
                      Please wait...
                    </VuiButton>
                  ) : (
                    <VuiButton variant="gradient" color="info">
                      Download Pdf
                    </VuiButton>
                  )
                }
              </PDFDownloadLink>
            </VuiBox>
          ) : null}
        </Card>
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ListAgreement;