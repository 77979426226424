import React from "react";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import Checkbox from "@mui/material/Checkbox";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import VuiBadgeDot from "components/VuiBadgeDot";
import { Divider } from "@mui/material";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiInput from "components/VuiInput";

// Vision UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import Table from "examples/Tables/Table";
import DataTable from "examples/Tables/DataTable";

import { FaUser, FaThumbsUp } from "react-icons/fa";
import { IoIosRocket } from "react-icons/io";
import { IoCart } from "react-icons/io5";
import { FaSearch } from "react-icons/fa";
import { IoNotificationsOutline } from "react-icons/io5";

// Reports page components
import Reviews from "layouts/pages/users/reports/components/Reviews";

// Data
import tableData from "layouts/pages/users/reports/data/tableData";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";
import { emailInboxReceived } from "services/mailService";
import { BiCheckbox } from "react-icons/bi";
import { AiOutlineStar } from "react-icons/ai";
import { AiFillStar } from "react-icons/ai";
import Avatar from "./Avatar.png";

import { CgMailReply } from "react-icons/cg";

import { CgMailForward } from "react-icons/cg";
import { BiArrowBack } from "react-icons/bi";
import VuiAvatar from "components/VuiAvatar";
import StepContent from "@mui/material/StepContent";
import { isMobile, isBrowser } from "react-device-detect";
import { emailInboxSentById } from "services/mailService";
import Loader from "layouts/loader/loader";
import Moment from "react-moment";

function SentPage() {
  let history = useHistory();
  const { id } = useParams();

  const [subject, setSubject] = useState("");
  const [date, setDate] = useState("");
  const [senderEmail, setSenderEmail] = useState("");
  const [recieverEmail, setRecieverEmail] = useState("");
  const [message, setMessage] = useState("");
  const [steps, setSteps] = useState([]);
  const [forwardArray, setForwardArray] = useState(["8"]);
  const [forwardArrayData, setForwardArrayData] = useState([]);
  const [responseLoader, setResponseLoader] = useState(true);

  useEffect(() => {
    fetchEmailSentById();
  }, []);

  // const dates = (time) => {
  //   var d = new Date(time);
  //   var time = d.getUTCMonth() + 1 + " / " + d.getUTCDate() + " / " + d.getUTCFullYear();
  //   return time;
  // };

  // let getTime = (time) => {
  //   var d = new Date(time);
  //   var time = d.getUTCHours() + " : " + d.getUTCMinutes();
  //   return time;
  // };

  // let timeStamp = (time) => {
  //   var d = new Date(time);
  //   var time = d.getTime();
  //   return time;
  // };

  const fetchEmailSentById = async () => {
    setResponseLoader(true);
    const resp = await emailInboxSentById(id);
    console.log("sentById", resp);
    setSenderEmail(resp.SenderEmailID);
    setRecieverEmail(resp.ReceiverEmailID);
    setSubject(resp.subject);
    setDate(resp.timesent);
    setMessage(resp.message);
    setSteps([
      { name: resp.SenderEmailID, docs: resp.onBlockChainDocs },
      { name: resp.ReceiverEmailID, docs: resp.onBlockChainDocs },
    ]);

    setResponseLoader(false);
  };

  const [hide, setHide] = useState(true);
  const [testHide, setTestHide] = useState("hidden");

  const test = () => {
    if (testHide === "hidden") {
      setTestHide("visible");
    } else {
      setTestHide("hidden");
    }
  };

  const handleDoc = async (route, hash) => {
    // console.log("docitem", docitem);
    window.open(
      `http://bitdocs.bitnautic.com/btnt-docs/${route}/?onBlockChainHash=${hash}`,
      `_blank`,
      `noopener,noreferrer`
    );
  };

  const goBack = () => {
    history.goBack();
  };

  // const dateFormatter = {
  //   day: "2-digit",
  //   month: "2-digit",
  //   year: "numeric",
  //   hour: "2-digit",
  //   minute: "2-digit",
  // };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {responseLoader ? (
        <Loader />
      ) : (
        <Card>
          <VuiBox sx={{ marginBottom: "1rem" }}>
            <VuiButton
              sx={{ borderRadius: "50%" }}
              color="info"
              variant="gradient"
              iconOnly
              onClick={() => goBack()}
            >
              <BiArrowBack />
            </VuiButton>
          </VuiBox>
          <VuiBox sx={{ marginBottom: "2rem" }}>
            <VuiBox sx={{ paddingLeft: "4rem", paddingRight: "4rem" }}>
              <VuiTypography color="text" fontWeight="bold">
                {subject}
              </VuiTypography>
            </VuiBox>
          </VuiBox>
          <VuiBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            flexDirection={{ xs: "column", sm: "row" }}
            marginBottom="3rem"
          >
            <VuiAvatar src={Avatar} alt="profile-image" variant="rounded" />
            <VuiBox mx={2} lineHeight={1}>
              <VuiTypography color="text" fontSize="1rem">
                {senderEmail}
              </VuiTypography>
              <VuiTypography color="text" fontSize="0.9rem">
                to {recieverEmail}
              </VuiTypography>
            </VuiBox>
            <VuiBox ml={{ xs: 0, sm: "auto" }}>
              <VuiTypography color="text" fontSize="1rem">
                <Moment format="DD/MM/YYYY, hh:mm A">{date}</Moment>
                &nbsp;(<Moment fromNow>{date}</Moment>)
              </VuiTypography>
            </VuiBox>
          </VuiBox>

          <VuiBox>
            <VuiBox sx={{ marginBottom: "2rem" }}>
              {isMobile ? (
                <Stepper
                  sx={{
                    "& svg.Mui-active": {
                      color: "#1e2762 !important",
                    },

                    "& svg": {
                      color: "#1e2762 !important",
                    },
                  }}
                  activeStep={-1}
                  orientation="vertical"
                >
                  {steps.map((val, idx) => (
                    <Step key={idx}>
                      <StepLabel>
                        <VuiBox>{val.name}</VuiBox>
                        <VuiBox sx={{ cursor: "pointer" }} onClick={() => test()}>
                          <VuiTypography
                            marginTop="0.5rem"
                            color="text"
                            fontSize="1rem"
                            sx={{ textDecoration: "underline" }}
                          >
                            See Details
                          </VuiTypography>
                        </VuiBox>

                        <VuiBox sx={{ visibility: testHide, cursor: "pointer" }}>
                          {val.docs.map((document, idx) => {
                            let documentRoute;

                            if (document.name === "billOfLading") {
                              documentRoute = "public-billOfLading";
                            } else if (document.name === "proformaInvoice") {
                              documentRoute = "public-proformaInvoice";
                            } else if (document.name === "commercialInvoice") {
                              documentRoute = "public-commercialInvoice";
                            } else if (document.name === "purchaseOrder") {
                              documentRoute = "public-purchaseOrder";
                            } else {
                              documentRoute = "public-quotation";
                            }

                            return (
                              <VuiBox key={idx}>
                                {/* <VuiBadgeDot
                              variant="gradient"
                              font={{ color: "text" }}
                              size="md"
                              badgeContent={array}
                            /> */}
                                <VuiButton
                                  onClick={() =>
                                    handleDoc(
                                      documentRoute,
                                      document.hash

                                      // `http://localhost:3000/bit-docs/${documentRoute}/?onBlockChainHash=${document.hash}`
                                      // document
                                    )
                                  }
                                  color="info"
                                  variant="text"
                                >
                                  {document.name}
                                </VuiButton>
                              </VuiBox>
                            );
                          })}
                        </VuiBox>
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
              ) : (
                <Stepper
                  sx={{
                    "& svg.Mui-active": {
                      color: "#1e2762 !important",
                    },

                    "& svg": {
                      color: "#1e2762 !important",
                    },
                  }}
                  activeStep={-1}
                  alternativeLabel
                >
                  {steps.map((val, idx) => (
                    <Step key={idx}>
                      <StepLabel>
                        <VuiBox>{val.name}</VuiBox>
                        <VuiBox sx={{ cursor: "pointer" }} onClick={() => test()}>
                          <VuiTypography
                            marginTop="0.5rem"
                            color="text"
                            fontSize="1rem"
                            sx={{ textDecoration: "underline" }}
                          >
                            See Details
                          </VuiTypography>
                        </VuiBox>

                        <VuiBox sx={{ visibility: testHide, cursor: "pointer" }}>
                          {val.docs.map((document, idx) => {
                            let documentRoute;

                            if (document.name === "billOfLading") {
                              documentRoute = "public-billOfLading";
                            } else if (document.name === "proformaInvoice") {
                              documentRoute = "public-proformaInvoice";
                            } else if (document.name === "commercialInvoice") {
                              documentRoute = "public-commercialInvoice";
                            } else if (document.name === "purchaseOrder") {
                              documentRoute = "public-purchaseOrder";
                            } else {
                              documentRoute = "public-quotation";
                            }

                            return (
                              <VuiBox key={idx}>
                                {/* <VuiBadgeDot
                              variant="gradient"
                              font={{ color: "text" }}
                              size="md"
                              badgeContent={array}
                            /> */}
                                <VuiButton
                                  onClick={() =>
                                    handleDoc(
                                      documentRoute,
                                      document.hash

                                      // `http://localhost:3000/bit-docs/${documentRoute}/?onBlockChainHash=${document.hash}`
                                      // document
                                    )
                                  }
                                  color="info"
                                  variant="text"
                                >
                                  {document.name}
                                </VuiButton>
                              </VuiBox>
                            );
                          })}
                        </VuiBox>
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
              )}
            </VuiBox>

            <VuiBox sx={{ marginBottom: "2rem" }}>
              <VuiBox sx={{ paddingLeft: "4rem", paddingRight: "4rem" }}>
                <VuiTypography color="text" fontSize="1rem">
                  {message}
                </VuiTypography>
              </VuiBox>
            </VuiBox>

            {/* <VuiBox sx={{ paddingLeft: "4rem" }}>
            <VuiButton size="small" color="info" variant="gradient">
              <CgMailForward size={25} /> Forward
            </VuiButton>
          </VuiBox> */}
          </VuiBox>
        </Card>
      )}

      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default SentPage;
