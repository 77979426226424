import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";


import { styled } from '@mui/material/styles';
// @mui material components
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import AddIcon from "@mui/icons-material/Add";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
// @mui material components
// import VuiTypography from "components/VuiTypography";
// Vision UI Dashboard PRO React components
// import VuiBox from "components/VuiBox";;
import VuiSelect from "components/VuiSelect";
import VuiDatePicker from "components/VuiDatePicker";
// import VuiButton from "components/VuiButton";
import VuiInput from "components/VuiInput";
import VuiSwitch from "components/VuiSwitch";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";

// Vision UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import dataTableData from "layouts/applications/data-tables/data/dataTableData";

import { getProformaInvoice } from "services/documentService";
import { PDFViewer } from "@react-pdf/renderer";
import QRCode from "qrcode";

import { getCompanyById } from "services/documentService";
import { isMobile, isBrowser } from "react-device-detect";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { updateProformaOnChain } from "services/documentService";
import Swal from "sweetalert2";
import { getActivePlan } from "services/pricingService";

import { checkCreateDocs } from "services/pricingService";
import ButtonGroup from "@mui/material/ButtonGroup";
import { deleteProformaInvoiceById } from "services/documentService";
import { MdDelete } from "react-icons/md";
import { MdModeEdit } from "react-icons/md";
import { FaEdit } from "react-icons/fa";



import validator from "validator";
import isEmpty from "../../../../utils/isEmpty";


import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

import { getSupplierDetailsById, updateSupplierDetailsById } from "services/traceabilityService";

import AttributeDescriptionTable from "./AttributeDescriptionTable";


const Label = ({label = "Label", error}) => {
	//const error = !error ? "" : error; //In case error is false, do not print false.

	return (
		<div>
			<VuiTypography variant="body2" fontWeight="bold" color="text" style={{marginTop: "2px", marginBottom: "2px"}}>{label}</VuiTypography>
			{error && <VuiTypography variant="body2" color="error" style={{marginTop: "2px", marginBottom: "2px"}}>{error}</VuiTypography>}
		</div>
	);
}



function UpdateSupplier() {
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const [productName, setProductName] = useState(null);
	const [supplierId, setSupplierId] = useState(null);
	const [supplierName, setSupplierName] = useState(null);
	const [supplierDetails, setSupplierDetails] = useState([{attribute: "", description: ""}]);
	const [supplierDetailsError, setSupplierDetailsError] = useState(null);
	
	useEffect(() => {
		setLoading(true);
		const urlParams = new URLSearchParams(window.location.search);

		let idNum = urlParams.get('id') ? Number(urlParams.get('id')) : null;
		idNum = Number.isInteger(idNum) ? idNum : null;

		if (idNum === null) {
			history.push('/btnt-docs/');
			return;
		}
		
		if (urlParams.get('productname') && urlParams.get('productname').trim().length > 0) {
			setProductName(urlParams.get('productname'))
		}


		getSupplierDetailsById(idNum)
			.then((response) => {
				setLoading(false);
				setSupplierId(response.id); // basically idNum
				setSupplierName(response.name);
				setSupplierDetails(response.attributes ? JSON.parse(response.attributes) : supplierDetails);
				console.log(response);
			})
			.catch((err) => {
				Swal.fire({
					icon: "error",
					text: "Something went wrong!",
					footer: err,
					showConfirmButton: true
				}).then(() => {
					history.push("/btnt-docs/");
				});
			});
	}, []);

	const onSupplierAttributesChanged = (newData) => {
		if (loading) {
			return;
		}

		setSupplierDetails(newData);
	}

	const onSubmit = () => {
		if (loading) {
			return;
		}
		setLoading(true);

		for (let i=0; i < supplierDetails.length; i++) {
			const el = supplierDetails[i];
			if (el.attribute.trim().length < 1 || el.attribute.trim().length > 30) {
				setSupplierDetailsError("Attribute name needs to be within 1 to 30 characters long");
				setLoading(false);
				return;
			}
			else if (el.description.trim().length < 1 || el.description.trim().length > 180) {
				setSupplierDetailsError("Attribute description needs to be within 1 to 180 characters long");
				setLoading(false);	
				return;
			}
		}
		setSupplierDetailsError(null);

		let formData = new FormData();

		formData.append("data", JSON.stringify(supplierDetails));

		updateSupplierDetailsById(supplierId, formData)
			.then((response) => {
				setLoading(false);
				Swal.fire({text: "Updated successfully!", icon: "success", showConfirmButton: false, timer: 1500}).then(() => {
					history.push("/btnt-docs/list-suppliers");
				});
				console.log(response);
			})
			.catch((err) => {
				Swal.fire({
					icon: "error",
					text: "Something went wrong!",
					footer: err,
					showConfirmButton: true
				}).then(() => {
					setLoading(false);
				});
			});
	}

	

	return (
		<DashboardLayout>
			<Modal open={loading} >
				<CircularProgress disableShrink sx={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}/>
			</Modal>
			<DashboardNavbar />
			{supplierId && <Card id="basic-info" sx={{ overflow: "visible" }}>
				{/* Page title */}
				<div style={{marginBottom: "5px"}}>
					<VuiTypography variant="h5" fontWeight="bold" color="text">
						Update&nbsp;
						{productName && `${productName}'s `}
						<u>{supplierName}</u>
					</VuiTypography>
					<VuiTypography variant="body2" fontWeight="regular" color="text">
						List your details for what you do as {supplierName}.
					</VuiTypography>
				</div>
				<div style={{margin: "10px"}}>
					<Label label="Supplier Attributes" error={supplierDetailsError}/>
					<AttributeDescriptionTable data={supplierDetails} setData={onSupplierAttributesChanged}/>
				</div>

				<div style={{margin: "auto", paddingTop: "40px"}} >	
					<VuiButton variant="gradient" component="span" size="medium" color="info" sx={{ width: "200px", minWidth: "75px" }} onClick={onSubmit}>
						Submit
					</VuiButton>
				</div>
			</Card>}
		</DashboardLayout>
	)
}

export default UpdateSupplier;