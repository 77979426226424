// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

// Vision UI Dashboard PRO React example components
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import PageLayout from "examples/LayoutContainers/PageLayout";

// Authentication layout components
import Footer from "layouts/authentication/components/Footer";

// Vision UI Dashboard PRO React page layout routes
import pageRoutes from "page.routes";

function BasicLayoutDoc({ title, description, image, children }) {
  return (
    <PageLayout>
      <VuiBox
        display="flex"
        justifyContent="center"
        mx={{ xs: "auto", lg: "auto" }}
        maxWidth={{ xs: "90%", md: "436px" }}
      >
        {children}
      </VuiBox>
    </PageLayout>
  );
}

// Setting default values for the props of BasicLayout
BasicLayoutDoc.defaultProps = {
  title: "",
  description: "",
};

// Typechecking props for the BasicLayout
BasicLayoutDoc.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default BasicLayoutDoc;
