import VuiBox from "components/VuiBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React from "react";
import StripeCheckout from "react-stripe-checkout";
import CheckoutSuccess from "./checkoutSuccess";
import PayButton from "./payButton";

function PaymentGateway() {
  // const onToken = (token, address) => {
  //   console.log("details", token);
  //   console.log("details", address);

  //   fetch("/save-stripe-token", {
  //     method: "POST",
  //     body: JSON.stringify(token),
  //   }).then((response) => {
  //     response.json().then((data) => {
  //       alert(`We are in business, ${data.email}`);
  //     });
  //   });
  // };

  const details = [
    {
      name: "test",
      cardNumber: "test123",
    },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox>Test</VuiBox>
      <StripeCheckout
        token={(e) => onToken(e)}
        stripeKey="pk_test_51LlAb8ABaeYGwH3nmCY14tdV2HF7z3V0x8Gu3zFLNp2YFKis4gKGDk7oanwmYcDxnKQYXMrsSu9lCJG00LCt8WfK00hRIDhrYa"
        name="Bitnautic" // the pop-in header title
        description="Big Data Stuff" // the pop-in header subtitle
        email="info@vidhub.co"
        currency="INR"
        // shippingAddress
        alipay={true}
        bitcoin={true}
        amount={1000000} // cents
      />

      {/* <VuiBox>
        <PayButton details={details} />
      </VuiBox> */}
    </DashboardLayout>
  );
}

export default PaymentGateway;
