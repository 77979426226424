import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

function ProductView({ data }) {
	const productAttributes = JSON.parse(data.product.attributes);

	return (
		<div style={{ backgroundColor: "rgb(242 240 254)", fontFamily: "'Libre Franklin', sans-serif" }}>
			<header style={{ width: "100%", height: "60px", backgroundColor: "#fff"}}>
				<img src={data.companyLogo} style={{ height: "100%" }} />
			</header>
			<Stack container direction="column" justifyContent="flex-start" style={{ padding: "10px", backgroundColor: "#fff", borderTopLeftRadius: "20px", borderTopRightRadius: "20px", maxWidth: "600px", margin: "40px auto auto auto"}}>
				<h1 style={{ textAlign: "center", fontSize: "25px", fontWeight: "600" }}>{data.product.name}</h1>
				<p style={{ textAlign: "center", fontSize: "15px" }}>{data.product.description}</p>
				{
					data.blockchainHash ?
					<a style={{ textAlign: "center", fontSize: "14px", margin: "5px", fontWeight: "600"}} href={`https://bscscan.com/tx/${data.blockchainHash}`}>Verified on Blockchain</a>  : 
					<p style={{ textAlign: "center", fontSize: "14px", fontWeight: "600" }}>Not Verified on Blockchain</p>
				}
				{ (data.blockchainHash && data.updatedFlag == 1) && <p style={{ textAlign: "center", fontSize: "14px", fontWeight: "600" }}>Verification out of date</p> }
				<img src={data.productImage} style={{ maxWidth: "600px", margin: "auto" }} />

				<table>
					<tbody style={{ fontSize: "16px" }}>
						{productAttributes.map((el, index) =>
							<tr key={index}>
								<th scope="row">{el.attribute}: </th>
								<th>{el.description}</th>
							</tr>
						)}
					</tbody>
				</table>
				<hr />
			</Stack>
			
			<div style={{ maxWidth: "600px", margin: "auto", backgroundColor: "#fff", color: "#000", padding: "10px 5px 30px 5px", borderBottomLeftRadius: "20px", borderBottomRightRadius: "20px",}}>
				{data.suppliers.map((el, index) =>
					<Stack key={index} container direction="row" spacing={2} >
						<Stack item>
							<Typography variant="body2" fontWeight="bold" color="text" style={{ margin: "auto", border: "2px solid", borderRadius: "50%", width: "25px", textAlign: "center", backgroundColor: "rgb(23 117 197)", color: "#fff", borderColor: "#000" }}>
								{index + 1}
							</Typography>
							<Divider orientation="vertical" />
						</Stack>
						<Stack item style={{ flexGrow: 1, paddingBottom: "20px" }}>
							<p style={{fontWeight: "600", margin: "0"}}>{el.name}</p>
							{el.attributes && JSON.parse(el.attributes).map((el, index) => (
								<p style={{fontSize: "12px", margin: "0"}}>{el.attribute}: {el.description}</p>
							))}
						</Stack>
					</Stack>
				)}
			</div>
			{/*data.suppliers.map((el, index) =>
					<div key={index}>
						<h4 style={{fontFamily: "16px", fontWeight: "600"}}>{el.name}</h4>
						<table>
							<tbody style={{ fontSize: "16px" }}>);
								{el.attributes && JSON.parse(el.attributes).map((el, index) =>
									<tr key={index}>
										<th scope="row" style={{fontSize: "12px"}}>{el.attribute}: </th>
										<th style={{fontSize: "12px"}}>{el.description}</th>
									</tr>
								)}
							</tbody>
						</table>
					</div>
								)*/}
		</div>
	);
}


export default ProductView;