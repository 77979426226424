const axios = require("axios");
import swal from "sweetalert";

import { baseUrl } from "./../helpers/baseUrl";

export async function addBillOfLading(data) {
  var tokenStr = localStorage.getItem("jwtTokenBitDocs");
  axios.defaults.headers.common["authorization"] = tokenStr;
  try {
    const response = await axios.post(
      `${baseUrl}/serviceProvider/billOfLading/addBillOfLading`,
      data
    );
    // console.log("addBillOfLading", response);
    return response;
  } catch (err) {
    // console.log("addBillOfLadingError", err.response);
    return err.response;
  }

  // const response = await axios.post(
  //   `${baseUrl}/serviceProvider/billOfLading/addBillOfLading`,
  //   data
  // );
  // if (response) {
  //   return response;
  // } else {
  //   swal("", response.message, "error");
  // }
}

export async function getBillOfLading() {
  var tokenStr = localStorage.getItem("jwtTokenBitDocs");
  axios.defaults.headers.common["authorization"] = tokenStr;
  try {
    const response = await axios.get(`${baseUrl}/serviceProvider/billOfLading/getBillOfLading`);
    // console.log("getBillOfLading", response);
    return response.data;
  } catch (err) {
    console.log(err);
    console.log(err.response);
    console.log(err.response.data);
    return err.response.data;

    // if (err.message === "Network Error") {
    //     swal("Server is not responding", "Try again!", "error");

    // }
    // if (err.response) {

    //     const errors = []
    //     if (err.response && err.response.data.msg.includes('already')) {
    //         swal(`Container for KEY = ${container.containerKey} is already exist`, "Try again!", "error");
    //     }
    //     if (err.response && err.response.data.msg.includes('Key')) {

    //         errors.push(['Key', err.response.data.msg])
    //     }

    //     if (err.response && err.response.data.msg.includes('record')) {
    //         swal("Something went wrong", "Try again!", "error");
    //     }
    //     if (err.response && err.response.data.msg.includes('fail')) {
    //         swal("Container is not added due to internel issue", "Please Try again!", "error");
    //     }
    // }
  }
}

export async function getBillOfLadingById(id) {
  var tokenStr = localStorage.getItem("jwtTokenBitDocs");
  axios.defaults.headers.common["authorization"] = tokenStr;
  const response = await axios.get(
    `${baseUrl}/serviceProvider/billOfLading/getBillOfLadingById?id=${id}`
  );
  // console.log(response, "getBillOfLadingById");
  if (response) {
    return response.data;
  } else {
    swal("", response.message, "getBillOfLadingByIdError");
  }
}

export async function editBillOfLading(editData) {
  // console.log("editDataBL", editData);
  var tokenStr = localStorage.getItem("jwtTokenBitDocs");
  axios.defaults.headers.common["authorization"] = tokenStr;
  try {
    const response = await axios.post(
      `${baseUrl}/serviceProvider/billOfLading/updateBillOfLading`,
      editData
    );
    // console.log(response, "editBillOfLading");
    return response.data;
  } catch (err) {
    return err.response;
  }
}

export async function updateBillOfLadingOnChain(id) {
  // console.log(id, "updateProformaOnChain id");
  var tokenStr = localStorage.getItem("jwtTokenBitDocs");
  axios.defaults.headers.common["authorization"] = tokenStr;
  try {
    const response = await axios.get(
      `${baseUrl}/serviceProvider/billOfLading/addBillOfLadingBlockchain/${id}`
    );
    // console.log(response, "updateBillOfLadingOnChainRes");
    return response.data;
  } catch (err) {
    return err.response;
  }
}

export async function deleteBillOfLadingById(id) {
  var tokenStr = localStorage.getItem("jwtTokenBitDocs");
  axios.defaults.headers.common["authorization"] = tokenStr;
  try {
    const response = await axios.get(
      `${baseUrl}/serviceProvider/billOfLading/deleteBillOfLading?id=${id}`
    );
    // console.log(response, "deleteBillOfLading");
    return response.data;
  } catch (err) {
    console.log(err, "deleteBillOfLading");
    return err.response;
  }
}

export async function addProformaInvoice(data) {
  var tokenStr = localStorage.getItem("jwtTokenBitDocs");
  axios.defaults.headers.common["authorization"] = tokenStr;
  try {
    const response = await axios.post(
      `${baseUrl}/serviceProvider/smartDocuments/addProformaInvoice`,
      data
    );
    // console.log("addProformaInvoice", response);
    return response;
  } catch (err) {
    console.log("addProformaInvoiceError", err.response);
    return err.response;
  }
}

export async function getProformaInvoice() {
  var tokenStr = localStorage.getItem("jwtTokenBitDocs");
  axios.defaults.headers.common["authorization"] = tokenStr;
  try {
    const response = await axios.get(`${baseUrl}/serviceProvider/smartDocuments/proformaInvoice`);
    // console.log("getProformaInvoice", response);
    return response.data;
  } catch (err) {
    console.log("getProformaInvoiceError", err.response);
    return err.response;
  }
}

export async function getProformaInvoiceById(id) {
  var tokenStr = localStorage.getItem("jwtTokenBitDocs");
  axios.defaults.headers.common["authorization"] = tokenStr;

  try {
    const response = await axios.get(
      `${baseUrl}/serviceProvider/smartDocuments/sd/proformaInvoice/${id}`
    );
    // console.log("getProformaById", response);
    return response.data;
  } catch (err) {
    console.log("getProformaByIdError", err.response);
    return response;
  }
}

export async function editProformaInvoiceById(editData) {
  // console.log("editDataPL", editData);
  var tokenStr = localStorage.getItem("jwtTokenBitDocs");
  axios.defaults.headers.common["authorization"] = tokenStr;
  try {
    const response = await axios.post(
      `${baseUrl}/serviceProvider/smartDocuments/updateProformaInvoice`,
      editData
    );
    // console.log(response, "updateProforma");
    return response.data;
  } catch (err) {
    return err.response;
  }
}

export async function updateProformaOnChain(id) {
  // console.log(id, "updateProformaOnChain id");
  var tokenStr = localStorage.getItem("jwtTokenBitDocs");
  axios.defaults.headers.common["authorization"] = tokenStr;
  try {
    const response = await axios.get(
      `${baseUrl}/serviceProvider/smartDocuments/proformaInvoice/${id}`
    );
    // console.log(response, "updateProformaOnChainRes");
    return response.data;
  } catch (err) {
    return err.response;
  }
}

export async function deleteProformaInvoiceById(id) {
  var tokenStr = localStorage.getItem("jwtTokenBitDocs");
  axios.defaults.headers.common["authorization"] = tokenStr;

  try {
    const response = await axios.get(
      `${baseUrl}/serviceProvider/smartDocuments/delete/proformaInvoice/${id}`
    );
    // console.log("deleteProformaById", response);
    return response.data;
  } catch (err) {
    console.log("deleteProformaByIdError", err.response);
    return response;
  }
}

export async function addCommercialInvoice(data) {
  var tokenStr = localStorage.getItem("jwtTokenBitDocs");
  axios.defaults.headers.common["authorization"] = tokenStr;
  try {
    const response = await axios.post(
      `${baseUrl}/serviceProvider/smartDocuments/addCommericalInvoice`,
      data
    );
    // console.log("addCommercialInvoice", response);
    return response;
  } catch (err) {
    console.log("addCommercialInvoiceError", err.response);
    return err.response;
  }
}

export async function getCommercialInvoice() {
  var tokenStr = localStorage.getItem("jwtTokenBitDocs");
  axios.defaults.headers.common["authorization"] = tokenStr;
  try {
    const response = await axios.get(`${baseUrl}/serviceProvider/smartDocuments/commercialInvoice`);
    // console.log("getCommercialInvoice", response);
    return response.data;
  } catch (err) {
    console.log("getCommercialInvoiceError", err.response);
    return err.response;
  }
}

export async function getCommercialInvoiceById(id) {
  var tokenStr = localStorage.getItem("jwtTokenBitDocs");
  axios.defaults.headers.common["authorization"] = tokenStr;

  try {
    const response = await axios.get(
      `${baseUrl}/serviceProvider/smartDocuments/sd/commercialInvoice/${id}`
    );
    // console.log("getCommById", response);
    return response.data;
  } catch (err) {
    console.log("getCommByIdError", err.response);
    return response;
  }
}

export async function editCommercialInvoiceById(editData) {
  var tokenStr = localStorage.getItem("jwtTokenBitDocs");
  axios.defaults.headers.common["authorization"] = tokenStr;

  try {
    const response = await axios.post(
      `${baseUrl}/serviceProvider/smartDocuments/updateCommericalInvoice`,
      editData
    );
    // console.log("editCommById", response);
    return response.data;
  } catch (err) {
    console.log("editCommByIdError", err.response);
    return response;
  }
}

export async function updateCommercialOnChain(id) {
  // console.log(id, "updateCommercialOnChain id");
  var tokenStr = localStorage.getItem("jwtTokenBitDocs");
  axios.defaults.headers.common["authorization"] = tokenStr;
  try {
    const response = await axios.get(
      `${baseUrl}/serviceProvider/smartDocuments/commercialInvoice/${id}`
    );
    // console.log(response, "updateCommOnChainRes");
    return response.data;
  } catch (err) {
    return err.response;
  }
}

export async function deleteCommercialInvoiceById(id) {
  var tokenStr = localStorage.getItem("jwtTokenBitDocs");
  axios.defaults.headers.common["authorization"] = tokenStr;

  try {
    const response = await axios.get(
      `${baseUrl}/serviceProvider/smartDocuments/delete/commercialInvoice/${id}`
    );
    // console.log("deleteCommById", response);
    return response.data;
  } catch (err) {
    console.log("deleteCommByIdError", err.response);
    return response;
  }
}

export async function addPurchaseOrder(data) {
  var tokenStr = localStorage.getItem("jwtTokenBitDocs");
  axios.defaults.headers.common["authorization"] = tokenStr;
  try {
    const response = await axios.post(
      `${baseUrl}/serviceProvider/smartDocuments/addPurchaseOrder`,
      data
    );
    // console.log("addPurchaseOrder", response);
    return response;
  } catch (err) {
    console.log("addPurchaseOrderError", err.response);
    return err.response;
  }
}

export async function getPurchaseOrder() {
  var tokenStr = localStorage.getItem("jwtTokenBitDocs");
  axios.defaults.headers.common["authorization"] = tokenStr;
  try {
    const response = await axios.get(`${baseUrl}/serviceProvider/smartDocuments/purchaseOrder`);
    // console.log("getPurchaseOrder", response);
    return response.data;
  } catch (err) {
    console.log("getPurchaseOrderError", err.response);
    return err.response;
  }
}

export async function getPurchaseOrderById(id) {
  var tokenStr = localStorage.getItem("jwtTokenBitDocs");
  axios.defaults.headers.common["authorization"] = tokenStr;
  try {
    const response = await axios.get(
      `${baseUrl}/serviceProvider/smartDocuments/sd/purchaseOrder/${id}`
    );
    // console.log("getPurchaseOrderById", response);
    return response.data;
  } catch (err) {
    console.log("getPurchaseOrderByIdError", err.response);
    return response;
  }
}

export async function editPurchaseOrderById(editData) {
  var tokenStr = localStorage.getItem("jwtTokenBitDocs");
  axios.defaults.headers.common["authorization"] = tokenStr;
  try {
    const response = await axios.post(
      `${baseUrl}/serviceProvider/smartDocuments/updatePurchaseOrder`,
      editData
    );
    // console.log("editPurchaseOrderById", response);
    return response.data;
  } catch (err) {
    console.log("editPurchaseOrderByIdError", err.response);
    return response;
  }
}

export async function updatePurchaseOrderOnChain(id) {
  console.log(id, "updatePurchaseOrderOnChain id");
  var tokenStr = localStorage.getItem("jwtTokenBitDocs");
  axios.defaults.headers.common["authorization"] = tokenStr;
  try {
    const response = await axios.get(
      `${baseUrl}/serviceProvider/smartDocuments/purchaseOrder/${id}`
    );
    // console.log(response, "updatePurchaseOrderOnChainRes");
    return response.data;
  } catch (err) {
    return err.response;
  }
}

export async function deletePurchaseOrderById(id) {
  var tokenStr = localStorage.getItem("jwtTokenBitDocs");
  axios.defaults.headers.common["authorization"] = tokenStr;
  try {
    const response = await axios.get(
      `${baseUrl}/serviceProvider/smartDocuments/delete/purchaseOrder/${id}`
    );
    // console.log("deletePurchaseOrderById", response);
    return response.data;
  } catch (err) {
    console.log("deletePurchaseOrderByIdError", err.response);
    return response;
  }
}

export async function addQuotation(data) {
  var tokenStr = localStorage.getItem("jwtTokenBitDocs");
  axios.defaults.headers.common["authorization"] = tokenStr;
  try {
    const response = await axios.post(
      `${baseUrl}/serviceProvider/smartDocuments/addQuotation`,
      data
    );
    // console.log("addQuotation", response);
    return response;
  } catch (err) {
    console.log("addQuotationError", err.response);
    return err.response;
  }
}

export async function getQuotation() {
  var tokenStr = localStorage.getItem("jwtTokenBitDocs");
  axios.defaults.headers.common["authorization"] = tokenStr;
  try {
    const response = await axios.get(`${baseUrl}/serviceProvider/smartDocuments/quotation`);
    // console.log("getQuotation", response);
    return response.data;
  } catch (err) {
    console.log("getQuotationError", err.response);
    return err.response;
  }
}

export async function getQuotationById(id) {
  var tokenStr = localStorage.getItem("jwtTokenBitDocs");
  axios.defaults.headers.common["authorization"] = tokenStr;

  try {
    const response = await axios.get(
      `${baseUrl}/serviceProvider/smartDocuments/sd/quotation/${id}`
    );
    // console.log("getQuotationById", response);
    return response.data;
  } catch (err) {
    console.log("getQuotationByIdError", err.response);
    return response;
  }
}

export async function editQuotationById(editData) {
  var tokenStr = localStorage.getItem("jwtTokenBitDocs");
  axios.defaults.headers.common["authorization"] = tokenStr;

  try {
    const response = await axios.post(
      `${baseUrl}/serviceProvider/smartDocuments/updateQuotation`,
      editData
    );
    // console.log("editQuotationById", response);
    return response.data;
  } catch (err) {
    console.log("editQuotationByIdError", err.response);
    return response;
  }
}

export async function updateQuotationOnChain(id) {
  // console.log(id, "updatePurchaseOrderOnChain id");
  var tokenStr = localStorage.getItem("jwtTokenBitDocs");
  axios.defaults.headers.common["authorization"] = tokenStr;
  try {
    const response = await axios.get(`${baseUrl}/serviceProvider/smartDocuments/quotation/${id}`);
    // console.log(response, "updateQuotationOnChainRes");
    return response.data;
  } catch (err) {
    return err.response;
  }
}

export async function deleteQuotationById(id) {
  var tokenStr = localStorage.getItem("jwtTokenBitDocs");
  axios.defaults.headers.common["authorization"] = tokenStr;

  try {
    const response = await axios.get(
      `${baseUrl}/serviceProvider/smartDocuments/delete/quotation/${id}`
    );
    // console.log("deleteQuotationById", response);
    return response.data;
  } catch (err) {
    console.log("deleteQuotationByIdError", err.response);
    return response;
  }
}

export async function addCompany(data) {
  var tokenStr = localStorage.getItem("jwtTokenBitDocs");
  axios.defaults.headers.common["authorization"] = tokenStr;

  try {
    const response = await axios.post(`${baseUrl}/serviceProvider/billOfLading/addCompany`, data);
    // console.log("addCompany", response);
    return response.data;
  } catch (err) {
    console.log("addCompanyError", err.response);
    return err.response;
  }
}

export async function getCompany() {
  var tokenStr = localStorage.getItem("jwtTokenBitDocs");
  axios.defaults.headers.common["authorization"] = tokenStr;

  try {
    const response = await axios.get(`${baseUrl}/serviceProvider/billOfLading/getCompany`);
    // console.log("getCompany", response);
    return response.data;
  } catch (err) {
    console.log("getCompany", err.response);
    return response;
  }
}

export async function getCompanyById(id) {
  // console.log(id, "ID checkkkk");
  // var tokenStr = localStorage.getItem("jwtTokenBitDocs");
  // axios.defaults.headers.common["authorization"] = tokenStr;
  const response = await axios.get(`${baseUrl}/serviceProvider/common/getCompanyById/${id}`);
  // console.log(response, "newRessss");
  if (response) {
    return response.data;
  } else {
    swal("", response.message, "error");
  }
}

// export async function addBillOfLadingBlockChain(id) {
//   console.log(id, "ID checkkkk");
//   // var tokenStr = localStorage.getItem("jwtTokenBitDocs");
//   // axios.defaults.headers.common["authorization"] = tokenStr;
//   const response = await axios.get(
//     `${baseUrl}/serviceProvider/billOfLading/addBillOfLadingBlockchain`
//   );
//   console.log(response, "newRessss");
//   if (response) {
//     return response.data;
//   } else {
//     swal("", response.message, "error");
//   }
// }

export async function getIpfsHashData(hash) {
  // console.log("hash", hash);
  // var tokenStr = localStorage.getItem("jwtTokenBitDocs");
  // axios.defaults.headers.common["authorization"] = tokenStr;

  try {
    const response = await axios.get(`${baseUrl}/serviceProvider/common/hash?hash=${hash}`);
    // console.log("getDataIpfs", response);
    return response.data;
  } catch (err) {
    console.log("getDataIpfsError", err.response);
    return response;
  }
}

export async function postVerifyDocs(verifyData) {
  // console.log("verifyData", verifyData);
  // var tokenStr = localStorage.getItem("jwtTokenBitDocs");
  // axios.defaults.headers.common["authorization"] = tokenStr;

  try {
    const response = await axios.post(`${baseUrl}/serviceProvider/common/hash/verify`, verifyData);
    // console.log("verifyData", response);
    return response.data;
  } catch (err) {
    console.log("verifyDataError", err.response);
    return response;
  }
}

export async function getBase64Img(imgHash) {
  // console.log("imgHash", imgHash);
  var tokenStr = localStorage.getItem("jwtTokenBitDocs");
  axios.defaults.headers.common["authorization"] = tokenStr;

  try {
    const response = await axios.get(
      `${baseUrl}/serviceProvider/common/image/hash?hash=${imgHash}`
    );
    // console.log("imgHash", response);
    return response.data;
  } catch (err) {
    console.log("imgHashError", err.response);
    return response;
  }
}
