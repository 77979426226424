import react from "react";
import { Page, Text, View, Document, StyleSheet, Image } from "@react-pdf/renderer";
import Picture from "./ship.png";
// import { style } from "@mui/material/node_modules/@mui/system";

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "white",
    padding: "20px",
    width: "100%",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  image: {
    flexDirection: "row",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "10px",
  },
  title: {
    flexDirection: "row",
    textAlign: "right",
    fontSize: "14px",
    display: "flex",
  },
  borderView: {
    marginTop: "10px",
    marginBottom: "10px",
    width: "100%",
  },
  border: {
    border: "1px solid #9e9e9e",
  },
});
// main #212121
// secondary "#757575
// Create Document Component
const MyDocument = (props) => {
  // console.log(">>>>>>>>>>> props.value", props.value);
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.image}>
          {props.value.logo ? (
            <Image
              allowDangerousPath
              src={props.value.logo}
              style={{ width: "30px", height: "30px" }}
            />
          ) : (
            <Image allowDangerousPath src={Picture} style={{ width: "30px", height: "30px" }} />
          )}
          <Text style={styles.title}>Bill of Lading</Text>
        </View>

        <View style={{ flexDirection: "row", width: "100%" }}>
          <View style={{ width: "49%", marginRight: "1%" }}>
            <View style={{ flexDirection: "row", width: "100%" }}>
              <Text style={{ fontSize: "9px", color: "#757575" }}>Shipper</Text>
            </View>
            <View style={{ flexDirection: "column", width: "100%" }}>
              <Text
                style={{
                  fontSize: "7px",
                  color: "#212121",
                  padding: "2px 0px 2px 5px",
                  marginBottom: "2px",
                }}
              >
                {props.value.shipperComapny.company_name
                  ? props.value.shipperComapny.company_name
                  : ""}
              </Text>
              <Text
                style={{
                  fontSize: "7px",
                  color: "#212121",
                  padding: "2px 0px 2px 5px",
                  marginBottom: "2px",
                }}
              >
                {props.value.shipperComapny.place ? props.value.shipperComapny.place : ""}
              </Text>
              <Text
                style={{
                  fontSize: "7px",
                  color: "#212121",
                  padding: "2px 0px 2px 5px",
                  marginBottom: "2px",
                }}
              >
                {props.value.shipperComapny.address ? props.value.shipperComapny.address : ""}
              </Text>
              <Text
                style={{
                  fontSize: "7px",
                  color: "#212121",
                  padding: "2px 0px 2px 5px",
                  marginBottom: "2px",
                }}
              >
                {props.value.shipperComapny.phone ? props.value.shipperComapny.phone : ""}
              </Text>
              <Text
                style={{
                  fontSize: "7px",
                  color: "#212121",
                  padding: "2px 0px 2px 5px",
                  marginBottom: "2px",
                }}
              >
                {props.value.shipperComapny.bank_details
                  ? props.value.shipperComapny.bank_details
                  : ""}
              </Text>
            </View>
          </View>
          <View style={{ width: "49%", marginLeft: "1%" }}>
            <View style={{ width: "100%", flexDirection: "row" }}>
              <View style={{ width: "48%", marginRight: "2%" }}>
                <View style={{ flexDirection: "row", width: "100%" }}>
                  <Text style={{ fontSize: "9px", color: "#757575" }}>Shipper's Reference1 :</Text>
                </View>
                <View style={{ flexDirection: "row", width: "100%", minHeight: "30px" }}>
                  <Text style={{ fontSize: "7px", color: "#212121", padding: "5px" }}>
                    {props.value.shipperReference ? props.value.shipperReference : ""}
                  </Text>
                </View>
              </View>

              <View style={{ width: "48%", marginLeft: "2%" }}>
                <View style={{ flexDirection: "row", width: "100%" }}>
                  <Text style={{ fontSize: "9px", color: "#757575" }}>Shipper's Reference2 :</Text>
                </View>
                <View style={{ flexDirection: "row", width: "100%", minHeight: "30px" }}>
                  <Text style={{ fontSize: "7px", color: "#212121", padding: "5px" }}>
                    {props.value.shipperReferenceSecond ? props.value.shipperReferenceSecond : ""}
                  </Text>
                </View>
              </View>
            </View>

            <View style={{ width: "100%" }}>
              <View style={{ flexDirection: "row", width: "100%" }}>
                <Text style={{ fontSize: "9px", color: "#757575" }}>Carrier's Reference :</Text>
              </View>
              <View style={{ flexDirection: "row", width: "100%", minHeight: "30px" }}>
                <Text style={{ fontSize: "7px", color: "#212121", padding: "5px" }}>
                  {props.value.carrierReference ? props.value.carrierReference : ""}
                </Text>
              </View>
            </View>

            <View style={{ width: "100%" }}>
              <View style={{ flexDirection: "row", width: "100%" }}>
                <Text style={{ fontSize: "9px", color: "#757575" }}>
                  Unique Consignment Reference :
                </Text>
              </View>
              <View style={{ flexDirection: "row", width: "100%", minHeight: "30px" }}>
                <Text style={{ fontSize: "7px", color: "#212121", padding: "5px" }}>
                  {props.value.uniqueConsignmentReference
                    ? props.value.uniqueConsignmentReference
                    : ""}
                </Text>
              </View>
            </View>
          </View>
        </View>

        <View style={styles.borderView}>
          <Text style={styles.border}></Text>
        </View>

        <View style={{ flexDirection: "row", width: "100%", marginTop: "5px" }}>
          <View style={{ width: "49%", marginRight: "1%" }}>
            <View style={{ flexDirection: "row", width: "100%" }}>
              <Text style={{ fontSize: "9px", color: "#757575" }}>Consignee :</Text>
            </View>
            <View style={{ flexDirection: "column", width: "100%", minHeight: "60px" }}>
              <Text style={{ fontSize: "7px", color: "#212121", padding: "2px 0px 2px 5px" }}>
                {props.value.consigneeCompany.company_name
                  ? props.value.consigneeCompany.company_name
                  : ""}
              </Text>
              <Text style={{ fontSize: "7px", color: "#212121", padding: "2px 0px 2px 5px" }}>
                {props.value.consigneeCompany.place ? props.value.consigneeCompany.place : ""}
              </Text>
              <Text style={{ fontSize: "7px", color: "#212121", padding: "2px 0px 2px 5px" }}>
                {props.value.consigneeCompany.address ? props.value.consigneeCompany.address : ""}
              </Text>
              <Text style={{ fontSize: "7px", color: "#212121", padding: "2px 0px 2px 5px" }}>
                {props.value.consigneeCompany.phone ? props.value.consigneeCompany.phone : ""}
              </Text>
              <Text style={{ fontSize: "7px", color: "#212121", padding: "2px 0px 2px 5px" }}>
                {props.value.consigneeCompany.bank_details
                  ? props.value.consigneeCompany.bank_details
                  : ""}
              </Text>
            </View>
          </View>
          <View style={{ width: "49%", marginLeft: "1%" }}>
            <View style={{ flexDirection: "row", width: "100%" }}>
              <Text style={{ fontSize: "9px", color: "#757575" }}>Carrier Name :</Text>
            </View>
            <View style={{ flexDirection: "column", width: "100%", minHeight: "60px" }}>
              <Text style={{ fontSize: "7px", color: "#212121", padding: "2px 0px 2px 5px" }}>
                {props.value.carrierCompanyName.company_name
                  ? props.value.carrierCompanyName.company_name
                  : ""}
              </Text>
              <Text style={{ fontSize: "7px", color: "#212121", padding: "2px 0px 2px 5px" }}>
                {props.value.carrierCompanyName.place ? props.value.carrierCompanyName.place : ""}
              </Text>
              <Text style={{ fontSize: "7px", color: "#212121", padding: "2px 0px 2px 5px" }}>
                {props.value.carrierCompanyName.address
                  ? props.value.carrierCompanyName.address
                  : ""}
              </Text>
              <Text style={{ fontSize: "7px", color: "#212121", padding: "2px 0px 2px 5px" }}>
                {props.value.carrierCompanyName.phone ? props.value.carrierCompanyName.phone : ""}
              </Text>
              <Text style={{ fontSize: "7px", color: "#212121", padding: "2px 0px 2px 5px" }}>
                {props.value.carrierCompanyName.bank_details
                  ? props.value.carrierCompanyName.bank_details
                  : ""}
              </Text>
            </View>
          </View>
        </View>

        <View style={styles.borderView}>
          <Text style={styles.border}></Text>
        </View>

        <View style={{ flexDirection: "row", width: "100%", marginTop: "5px" }}>
          <View style={{ width: "49%", marginRight: "1%" }}>
            <View style={{ flexDirection: "row", width: "100%" }}>
              <Text style={{ fontSize: "9px", color: "#757575" }}>
                Notify Party (If not Consignee) :
              </Text>
            </View>
            <View style={{ flexDirection: "column", width: "100%", minHeight: "50px" }}>
              <Text style={{ fontSize: "7px", color: "#212121", padding: "2px 0px 2px 5px" }}>
                {props.value.notifyPartyCompanyName.company_name
                  ? props.value.notifyPartyCompanyName.company_name
                  : ""}
              </Text>
              <Text style={{ fontSize: "7px", color: "#212121", padding: "2px 0px 2px 5px" }}>
                {props.value.notifyPartyCompanyName.place
                  ? props.value.notifyPartyCompanyName.place
                  : ""}
              </Text>
              <Text style={{ fontSize: "7px", color: "#212121", padding: "2px 0px 2px 5px" }}>
                {props.value.notifyPartyCompanyName.address
                  ? props.value.notifyPartyCompanyName.address
                  : ""}
              </Text>
              <Text style={{ fontSize: "7px", color: "#212121", padding: "2px 0px 2px 5px" }}>
                {props.value.notifyPartyCompanyName.phone
                  ? props.value.notifyPartyCompanyName.phone
                  : ""}
              </Text>
              <Text style={{ fontSize: "7px", color: "#212121", padding: "2px 0px 2px 5px" }}>
                {props.value.notifyPartyCompanyName.bank_details
                  ? props.value.notifyPartyCompanyName.bank_details
                  : ""}
              </Text>
            </View>
          </View>
          <View style={{ width: "49%", marginLeft: "1%" }}>
            <View style={{ flexDirection: "row", width: "100%" }}>
              <Text style={{ fontSize: "9px", color: "#757575" }}>Additional Notify Party :</Text>
            </View>
            <View style={{ flexDirection: "column", width: "100%", minHeight: "50px" }}>
              <Text style={{ fontSize: "7px", color: "#212121", padding: "2px 0px 2px 5px" }}>
                {props.value.addNotifyPartyCompanyName.company_name
                  ? props.value.addNotifyPartyCompanyName.company_name
                  : ""}
              </Text>
              <Text style={{ fontSize: "7px", color: "#212121", padding: "2px 0px 2px 5px" }}>
                {props.value.addNotifyPartyCompanyName.place
                  ? props.value.addNotifyPartyCompanyName.place
                  : ""}
              </Text>
              <Text style={{ fontSize: "7px", color: "#212121", padding: "2px 0px 2px 5px" }}>
                {props.value.addNotifyPartyCompanyName.address
                  ? props.value.addNotifyPartyCompanyName.address
                  : ""}
              </Text>
              <Text style={{ fontSize: "7px", color: "#212121", padding: "2px 0px 2px 5px" }}>
                {props.value.addNotifyPartyCompanyName.phone
                  ? props.value.addNotifyPartyCompanyName.phone
                  : ""}
              </Text>
              <Text style={{ fontSize: "7px", color: "#212121", padding: "2px 0px 2px 5px" }}>
                {props.value.addNotifyPartyCompanyName.bank_details
                  ? props.value.addNotifyPartyCompanyName.bank_details
                  : ""}
              </Text>
            </View>
          </View>
        </View>

        <View style={styles.borderView}>
          <Text style={styles.border}></Text>
        </View>

        <View style={{ flexDirection: "row", width: "100%", marginTop: "5px" }}>
          <View style={{ width: "49%", marginRight: "1%" }}>
            <View style={{ width: "100%", flexDirection: "row" }}>
              <View style={{ width: "48%", marginRight: "2%" }}>
                <View style={{ flexDirection: "row", width: "100%" }}>
                  <Text style={{ fontSize: "9px", color: "#757575" }}>Pre-Carriage By :</Text>
                </View>
                <View style={{ flexDirection: "row", width: "100%", minHeight: "30px" }}>
                  <Text style={{ fontSize: "7px", color: "#212121", padding: "5px" }}>
                    {props.value.preCarriageBy ? props.value.preCarriageBy : ""}
                  </Text>
                </View>
              </View>

              <View style={{ width: "48%", marginLeft: "2%" }}>
                <View style={{ flexDirection: "row", width: "100%" }}>
                  <Text style={{ fontSize: "9px", color: "#757575" }}>Place of Receipt :</Text>
                </View>
                <View style={{ flexDirection: "row", width: "100%", minHeight: "30px" }}>
                  <Text style={{ fontSize: "7px", color: "#212121", padding: "5px" }}>
                    {props.value.placeOfReceipt ? props.value.placeOfReceipt : ""}
                  </Text>
                </View>
              </View>
            </View>
          </View>
          <View style={{ width: "49%", marginLeft: "1%" }}>
            <View style={{ flexDirection: "row", width: "100%" }}>
              <Text style={{ fontSize: "9px", color: "#757575" }}>Additional Information :</Text>
            </View>
            <View style={{ flexDirection: "row", width: "100%", minHeight: "30px" }}>
              <Text style={{ fontSize: "7px", color: "#212121", padding: "5px" }}>
                {props.value.additionalInformation ? props.value.additionalInformation : ""}
              </Text>
            </View>
          </View>
        </View>

        <View style={{ flexDirection: "row", width: "100%", marginTop: "5px" }}>
          <View style={{ width: "49%", marginRight: "1%" }}>
            <View style={{ width: "100%", flexDirection: "row" }}>
              <View style={{ width: "22%", marginRight: "1%" }}>
                <View style={{ flexDirection: "row", width: "100%" }}>
                  <Text style={{ fontSize: "9px", color: "#757575" }}>Transport :</Text>
                </View>
                <View style={{ flexDirection: "row", width: "100%", minHeight: "30px" }}>
                  <Text style={{ fontSize: "7px", color: "#212121", padding: "5px" }}>
                    {props.value.transport ? props.value.transport : ""}
                  </Text>
                </View>
              </View>

              <View style={{ width: "24%", marginLeft: "1%" }}>
                <View style={{ flexDirection: "row", width: "100%" }}>
                  <Text style={{ fontSize: "9px", color: "#757575" }}>Voyage No :</Text>
                </View>
                <View style={{ flexDirection: "row", width: "100%", minHeight: "30px" }}>
                  <Text style={{ fontSize: "7px", color: "#212121", padding: "5px" }}>
                    {props.value.voyageNo ? props.value.voyageNo : ""}
                  </Text>
                </View>
              </View>

              <View style={{ width: "48%", marginLeft: "2%" }}>
                <View style={{ flexDirection: "row", width: "100%" }}>
                  <Text style={{ fontSize: "9px", color: "#757575" }}>Place of Delivery :</Text>
                </View>
                <View style={{ flexDirection: "row", width: "100%", minHeight: "30px" }}>
                  <Text style={{ fontSize: "7px", color: "#212121", padding: "5px" }}>
                    {props.value.placeOfDelivery ? props.value.placeOfDelivery : ""}
                  </Text>
                </View>
              </View>
            </View>
          </View>

          <View style={{ width: "49%", marginLeft: "1%" }}>
            <View style={{ width: "100%", flexDirection: "row" }}>
              <View style={{ width: "48%", marginRight: "2%" }}>
                <View style={{ flexDirection: "row", width: "100%" }}>
                  <Text style={{ fontSize: "9px", color: "#757575" }}>Port of Loading :</Text>
                </View>
                <View style={{ flexDirection: "row", width: "100%", minHeight: "30px" }}>
                  <Text style={{ fontSize: "7px", color: "#212121", padding: "5px" }}>
                    {props.value.portOfLoading ? props.value.portOfLoading : ""}
                  </Text>
                </View>
              </View>

              <View style={{ width: "48%", marginLeft: "2%" }}>
                <View style={{ flexDirection: "row", width: "100%" }}>
                  <Text style={{ fontSize: "9px", color: "#757575" }}>Port of Discharge :</Text>
                </View>
                <View style={{ flexDirection: "row", width: "100%", minHeight: "30px" }}>
                  <Text style={{ fontSize: "7px", color: "#212121", padding: "5px" }}>
                    {props.value.portOfDischarge ? props.value.portOfDischarge : ""}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>

        <View style={{ flexDirection: "row", width: "100%", marginTop: "10px" }}>
          <View style={{ width: "16.66%", backgroundColor: "#607d8b", padding: "5px 0px" }}>
            <View
              style={{
                flexDirection: "row",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Text style={{ fontSize: "9px", fontWeight: "bold", color: "white" }}>
                Marks & Numbers
              </Text>
            </View>
          </View>

          <View style={{ width: "16.66%", backgroundColor: "#607d8b", padding: "5px 0px" }}>
            <View
              style={{
                flexDirection: "row",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Text style={{ fontSize: "9px", fontWeight: "bold", color: "white" }}>
                Kind & No of Packages
              </Text>
            </View>
          </View>

          <View style={{ width: "16.66%", backgroundColor: "#607d8b", padding: "5px 0px" }}>
            <View
              style={{
                flexDirection: "row",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Text style={{ fontSize: "9px", fontWeight: "bold", color: "white" }}>
                Description of Goods
              </Text>
            </View>
          </View>

          <View style={{ width: "16.66%", backgroundColor: "#607d8b", padding: "5px 0px" }}>
            <View
              style={{
                flexDirection: "row",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Text style={{ fontSize: "9px", fontWeight: "bold", color: "white" }}>
                Net Weight (Kg)
              </Text>
            </View>
          </View>

          <View style={{ width: "16.66%", backgroundColor: "#607d8b", padding: "5px 0px" }}>
            <View
              style={{
                flexDirection: "row",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Text style={{ fontSize: "9px", fontWeight: "bold", color: "white" }}>
                Gross Weight (Kg)
              </Text>
            </View>
          </View>

          <View style={{ width: "16.66%", backgroundColor: "#607d8b", padding: "5px 0px" }}>
            <View
              style={{
                flexDirection: "row",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Text style={{ fontSize: "9px", fontWeight: "bold", color: "white" }}>
                Measurements (m³)
              </Text>
            </View>
          </View>
        </View>

        <View style={{ width: "100%", minHeight: "80px" }}>
          {props.value.shipperDataTableValue &&
            props.value.shipperDataTableValue.length > 0 &&
            props.value.shipperDataTableValue.map((data, idx) => {
              return (
                <View
                  key={idx}
                  style={{
                    flexDirection: "row",
                    width: "100%",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                  }}
                >
                  <View style={{ width: "16.66%", marginRight: "1%" }}>
                    <View style={{ flexDirection: "row", width: "100%" }}>
                      <Text style={{ fontSize: "7px", color: "#212121", padding: "5px" }}>
                        {data.marksAndNumbers ? data.marksAndNumbers : ""}
                      </Text>
                    </View>
                  </View>

                  <View style={{ width: "16.66%", marginRight: "1%" }}>
                    <View style={{ flexDirection: "row", width: "100%" }}>
                      <Text style={{ fontSize: "7px", color: "#212121", padding: "5px" }}>
                        {data.noOfPackages ? data.noOfPackages : ""}
                      </Text>
                    </View>
                  </View>

                  <View style={{ width: "16.66%", marginRight: "1%" }}>
                    <View style={{ flexDirection: "row", width: "100%" }}>
                      <Text style={{ fontSize: "7px", color: "#212121", padding: "5px" }}>
                        {data.descOfGoods ? data.descOfGoods : ""}
                      </Text>
                    </View>
                  </View>

                  <View style={{ width: "16.66%", marginRight: "1%" }}>
                    <View style={{ flexDirection: "row", width: "100%" }}>
                      <Text style={{ fontSize: "7px", color: "#212121", padding: "5px" }}>
                        {data.netWeight ? data.netWeight : ""}
                      </Text>
                    </View>
                  </View>

                  <View style={{ width: "16.66%", marginRight: "1%" }}>
                    <View style={{ flexDirection: "row", width: "100%" }}>
                      <Text style={{ fontSize: "7px", color: "#212121", padding: "5px" }}>
                        {data.grossWeight ? data.grossWeight : ""}
                      </Text>
                    </View>
                  </View>

                  <View style={{ width: "16.66%", marginRight: "1%" }}>
                    <View style={{ flexDirection: "row", width: "100%" }}>
                      <Text style={{ fontSize: "7px", color: "#212121", padding: "5px" }}>
                        {data.measurements ? data.measurements : ""}
                      </Text>
                    </View>
                  </View>
                </View>
              );
            })}
        </View>

        <View style={styles.borderView}>
          <Text style={styles.border}></Text>
        </View>

        <View style={{ flexDirection: "row", width: "100%", marginTop: "5px" }}>
          <View style={{ width: "34%", marginRight: "1%" }}>
            <View style={{ width: "100%", flexDirection: "row" }}>
              <View style={{ width: "60%", marginRight: "1%" }}>
                <View style={{ flexDirection: "row", width: "100%" }}>
                  <Text style={{ fontSize: "9px", color: "#757575" }}>Total net weight (kg) :</Text>
                </View>
                <View style={{ flexDirection: "row", width: "100%", minHeight: "25px" }}>
                  <Text style={{ fontSize: "7px", color: "#212121", padding: "5px" }}>
                    {props.value.totalNetWeight ? props.value.totalNetWeight : ""}
                  </Text>
                </View>
              </View>
            </View>
          </View>

          <View style={{ width: "32%", marginLeft: "1%" }}>
            <View style={{ flexDirection: "row", width: "100%" }}>
              <Text style={{ fontSize: "9px", color: "#757575" }}>Total gross weight (kg) :</Text>
            </View>
            <View style={{ flexDirection: "row", width: "100%", minHeight: "25px" }}>
              <Text style={{ fontSize: "7px", color: "#212121", padding: "5px" }}>
                {props.value.totalGrossWeight ? props.value.totalGrossWeight : ""}
              </Text>
            </View>
          </View>

          <View style={{ width: "30%", marginLeft: "1%" }}>
            <View style={{ flexDirection: "row", width: "100%" }}>
              <Text style={{ fontSize: "9px", color: "#757575" }}>Total volume (m³) :</Text>
            </View>
            <View style={{ flexDirection: "row", width: "100%", minHeight: "25px" }}>
              <Text style={{ fontSize: "7px", color: "#212121", padding: "5px" }}>
                {props.value.totalVolume ? props.value.totalVolume : ""}
              </Text>
            </View>
          </View>
        </View>

        <View style={styles.borderView}>
          <Text style={styles.border}></Text>
        </View>

        <View style={{ flexDirection: "row", width: "100%", marginTop: "10px" }}>
          <View style={{ width: "49%", marginRight: "1%" }}>
            <View style={{ flexDirection: "row", width: "100%" }}>
              <Text style={{ fontSize: "9px", color: "#757575" }}>Additional information :</Text>
            </View>
            <View style={{ flexDirection: "row", width: "100%", minHeight: "30px" }}>
              <Text style={{ fontSize: "7px", color: "#212121", padding: "5px" }}>
                {props.value.additionalInformationSecond
                  ? props.value.additionalInformationSecond
                  : ""}
              </Text>
            </View>
          </View>
          <View style={{ width: "49%", marginLeft: "1%" }}>
            <View style={{ width: "100%", flexDirection: "row" }}>
              <View style={{ width: "48%", marginRight: "2%" }}>
                <View style={{ flexDirection: "row", width: "100%" }}>
                  <Text style={{ fontSize: "9px", color: "#757575" }}>Place of Issue :</Text>
                </View>
                <View style={{ flexDirection: "row", width: "100%", minHeight: "30px" }}>
                  <Text style={{ fontSize: "7px", color: "#212121", padding: "5px" }}>
                    {props.value.placeOfIssue ? props.value.placeOfIssue : ""}
                  </Text>
                </View>
              </View>

              <View style={{ width: "48%", marginLeft: "2%" }}>
                <View style={{ flexDirection: "row", width: "100%" }}>
                  <Text style={{ fontSize: "9px", color: "#757575" }}>Date :</Text>
                </View>
                <View style={{ flexDirection: "row", width: "100%", minHeight: "30px" }}>
                  <Text style={{ fontSize: "7px", color: "#212121", padding: "5px" }}>
                    {props.value.date ? props.value.date : ""}
                  </Text>
                </View>
              </View>
            </View>

            <View style={{ width: "100%", flexDirection: "row" }}>
              <View style={{ width: "48%", marginRight: "2%" }}>
                <View style={{ flexDirection: "row", width: "100%" }}>
                  <Text style={{ fontSize: "9px", color: "#757575" }}>Signatory Company :</Text>
                </View>
                <View style={{ flexDirection: "row", width: "100%", height: "30px" }}>
                  <Text style={{ fontSize: "7px", color: "#212121", padding: "5px" }}>
                    {props.value.signatoryCompany ? props.value.signatoryCompany : ""}
                  </Text>
                </View>
              </View>

              <View style={{ width: "48%", marginLeft: "5%" }}>
                <View style={{ flexDirection: "row", width: "100%" }}>
                  <Text style={{ fontSize: "9px", color: "#757575" }}>
                    Name of Authorized Signatory :
                  </Text>
                </View>
                <View style={{ flexDirection: "row", width: "100%", height: "30px" }}>
                  <Text style={{ fontSize: "7px", color: "#212121", padding: "5px" }}>
                    {props.value.nameOfAuthorizedSignatory
                      ? props.value.nameOfAuthorizedSignatory
                      : ""}
                  </Text>
                </View>
              </View>
            </View>

            {/* <View style={{ width: "100%" }}>
              <View style={{ flexDirection: "row", width: "100%" }}>
                <Text style={{ fontSize: "9px", color: "#757575" }}>Signatory Company :</Text>
              </View>
              <View style={{ flexDirection: "row", width: "100%", minHeight: "30px" }}>
                <Text style={{ fontSize: "7px", color: "#212121", padding: "5px" }}>
                  {props.value.signatoryCompany ? props.value.signatoryCompany : ""}
                </Text>
              </View>
            </View>

            <View style={{ width: "100%" }}>
              <View style={{ flexDirection: "row", width: "100%" }}>
                <Text style={{ fontSize: "9px", color: "#757575" }}>
                  Name of Authorized Signatory :
                </Text>
              </View>
              <View style={{ flexDirection: "row", width: "100%", minHeight: "30px" }}>
                <Text style={{ fontSize: "7px", color: "#212121", padding: "5px" }}>
                  {props.value.nameOfAuthorizedSignatory
                    ? props.value.nameOfAuthorizedSignatory
                    : ""}
                </Text>
              </View>
            </View> */}

            <View style={{ width: "100%" }}>
              <View style={{ flexDirection: "row", width: "100%" }}>
                <Text style={{ fontSize: "9px", color: "#757575" }}>Signature :</Text>
              </View>
              <View style={{ flexDirection: "row", width: "100%" }}>
                <Text style={{ fontSize: "7px", color: "#212121", padding: "5px" }}>
                  {props.value.signature ? (
                    <Image
                      allowDangerousPath
                      src={props.value.signature}
                      style={{ width: "100px", height: "30px" }}
                    />
                  ) : (
                    ""
                  )}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default MyDocument;
