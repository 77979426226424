const axios = require("axios");
import swal from "sweetalert";

import { baseUrl } from "./../helpers/baseUrl";

export async function changePassword(data) {
  var tokenStr = localStorage.getItem("jwtTokenBitDocs");
  const response = await axios.post(`${baseUrl}/admin/users/update-password`, data);
  if (response) {
    console.log(response);
    return response;
  } else {
    swal("", response.message, "error");
  }
}
