import React from "react";
import { useState, useRef, useEffect } from "react";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
// import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";
// Vision UI Dashboard PRO React components
// import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiSelect from "components/VuiSelect";
import VuiDatePicker from "components/VuiDatePicker";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
// import VuiButton from "components/VuiButton";
import VuiInput from "components/VuiInput";
import VuiSwitch from "components/VuiSwitch";
// Settings page components
import FormField from "layouts/pages/account/components/FormField";
// Data

import { createShip } from "services/shipService";
import { getPorts } from "services/shipService";
import "./commercialInvoice.css";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { MdDelete } from "react-icons/md";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import Stack from "@mui/material/Stack";
import AddIcon from "@mui/icons-material/Add";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
import { PDFDownloadLink } from "@react-pdf/renderer";
import ReactPDF from "@react-pdf/renderer";
import { PDFViewer } from "@react-pdf/renderer";
import jsPDF from "jspdf";
import PDFDocument from "./commPdfoc";
import SignaturePad from "react-signature-canvas";
import Validations from "shared/validations";
import ValidationService from "shared/validationService";
import { addCommercialInvoice, addCompany, getCompany } from "services/documentService";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Swal from "sweetalert2";
import Ship from "./ship.png";
import { getCommercialInvoiceById } from "services/documentService";
import { editCommercialInvoiceById } from "services/documentService";
import { useHistory } from "react-router-dom";

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];

const pdfStyles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#E4E4E4",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
});

const Input = styled("input")({
  display: "none",
});

let currentDate = new Date();
currentDate = `${currentDate.getFullYear()}-${
  currentDate.getMonth() + 1 <= 9 ? "0" + (currentDate.getMonth() + 1) : currentDate.getMonth() + 1
}-${currentDate.getDate() <= 9 ? "0" + currentDate.getDate() : currentDate.getDate()}`;
// console.log(">>>>>>>>>>>>>>> current date", currentDate);

function CommercialInvoice() {
  let history = useHistory();
  const [errorMsg, setErrorMsg] = useState({
    companyName: { errorMsg: "" },
    place: { errorMsg: "" },
    address: { errorMsg: "" },
    phone: { errorMsg: "" },
    bankDetails: { errorMsg: "" },
    exporter: { errorMsg: "" },
    consignee: { errorMsg: "" },
    invoiceNumber: { errorMsg: "" },
    billOfLadingNumber: { errorMsg: "" },
    datee: { errorMsg: "" },
    reference: { errorMsg: "" },
    buyerReference: { errorMsg: "" },
    buyer: { errorMsg: "" },
    methodOfDispatch: { errorMsg: "" },
    typeOfShipment: { errorMsg: "" },
    countryOfGoods: { errorMsg: "" },
    transport: { errorMsg: "" },
    voyageNo: { errorMsg: "" },
    countryOfFinalDestination: { errorMsg: "" },
    portOfLoading: { errorMsg: "" },
    portOfDischarge: { errorMsg: "" },
    termOfPayment: { errorMsg: "" },
    placeOfOrigin: { errorMsg: "" },
    dateOfDeparture: { errorMsg: "" },
    finalDestination: { errorMsg: "" },
    marinePolicyNo: { errorMsg: "" },
    letterOfCredit: { errorMsg: "" },
    totalAmount: { errorMsg: "" },
    placeOfIssue: { errorMsg: "" },
    date: { errorMsg: "" },
    signatoryCompany: { errorMsg: "" },
    nameOfAuthorizedSignatory: { errorMsg: "" },
    additionalInformation: { errorMsg: "" },
    bankDetails: { errorMsg: "" },
    signature: { errorMsg: "" },
  });

  // let stateRandomNumber = Math.random();
  const [comInvoiceId, setComInvoiceId] = useState("");
  const [open, setOpen] = useState(false);
  const [logo, setLogo] = useState(Ship);
  const [imgSrc, setImgsrc] = useState(Ship);
  const [commonCompany, setCommonCompany] = useState({
    companyName: "",
    place: "",
    address: "",
    phone: "",
    bankDetails: "",
  });
  const [exporter, setExporter] = useState({
    companyName: "",
    place: "",
    address: "",
    phone: "",
    bankDetails: "",
  });
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [datee, setDatee] = useState(currentDate);
  const [billOfLadingNumber, setBillOfLadingNumber] = useState("");
  const [reference, setReference] = useState("");
  const [buyerReference, setBuyerReference] = useState("");
  const [consignee, setConsignee] = useState({
    companyName: "",
    place: "",
    address: "",
    phone: "",
    bankDetails: "",
  });
  const [buyer, setBuyer] = useState({
    companyName: "",
    place: "",
    address: "",
    phone: "",
    bankDetails: "",
  });
  const [showPreview, setShowPreview] = useState(false);
  const [methodOfDispatch, setMethodOfDispatch] = useState("");
  const [typeOfShipment, setTypeOfShipment] = useState("");
  const [countryOfGoods, setCountryOfGoods] = useState("");
  const [transport, setTransport] = useState("");
  const [voyageNo, setVoyageNo] = useState("");
  const [countryOfFinalDestination, setCountryOfFinalDestination] = useState("");
  const [portOfLoading, setPortOfLoading] = useState("");
  const [portOfDischarge, setPortOfDischarge] = useState("");
  const [termOfPayment, setTermOfPayment] = useState("");
  const [placeOfOrigin, setPlaceOfOrigin] = useState("");
  const [dateOfDeparture, setDateOfDeparture] = useState(currentDate);
  const [finalDestination, setFinalDestination] = useState("");
  const [marinePolicyNo, setMarinePolicyNo] = useState("");
  const [letterOfCredit, setLetterOfCredit] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const [additionalInformation, setAdditionalInformation] = useState("");
  const [bankDetails, setBankDetails] = useState("");
  const [placeOfIssue, setPlaceOfIssue] = useState("");
  const [date, setDate] = useState(currentDate);
  const [signatoryCompany, setSignatoryCompany] = useState("");
  const [nameOfAuthorizedSignatory, setNameOfAuthorizedSignatory] = useState("");
  const [shipperDataTableValue, setShipperDataTableValue] = useState([
    {
      // customId: stateRandomNumber,
      productCode: "",
      descriptionOfGoods: "",
      unitQuantity: "",
      unitType: "",
      price: "",
      amount: 0,
    },
  ]);

  const [signature, setSignature] = useState("");
  const [dialogInformation, setDialogInformation] = useState({
    title: "",
    type: "exporter",
  });

  const [imageURL, setImageURL] = useState(null);
  const sigCanvas = useRef({});
  const [searchedPorts, setSearchedPorts] = useState([]);
  const [company, setCompany] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [update, setUpdate] = useState(false);

  useEffect(() => {
    handleGetCompany();
  }, [shipperDataTableValue]);

  const customValidations = (name, value) => {
    let errorLength = 0;
    const args = [
      {
        name,
        value: value,
        validations: Validations.proformaInvoiceValidations[name],
      },
    ];

    ValidationService.validate(args, (validationErr, validationErrMsgs) => {
      if (validationErrMsgs.length > 0) {
        errorLength = errorLength + 1;
        errorMsg[name].errorMsg = validationErrMsgs[0].validations[0].message;
        setErrorMsg({ ...errorMsg });
      } else {
        errorMsg[name].errorMsg = "";
        setErrorMsg({ ...errorMsg });
      }
    });
    return errorLength;
  };

  const handleChangeInput = (name, value) => {
    if (name === "exporter") {
      setExporter(value);
    } else if (name === "consignee") {
      setConsignee(value);
    } else if (name === "invoiceNumber") {
      setInvoiceNumber(value);
    } else if (name === "datee") {
      setDatee(value);
    } else if (name === "billOfLadingNumber") {
      setBillOfLadingNumber(value);
    } else if (name === "reference") {
      setReference(value);
    } else if (name === "buyer") {
      setBuyer(value);
    } else if (name === "buyerReference") {
      setBuyerReference(value);
    } else if (name === "methodOfDispatch") {
      setMethodOfDispatch(value);
    } else if (name === "typeOfShipment") {
      setTypeOfShipment(value);
    } else if (name === "countryOfGoods") {
      setCountryOfGoods(value);
    } else if (name === "transport") {
      setTransport(value);
    } else if (name === "voyageNo") {
      setVoyageNo(value);
    } else if (name === "countryOfFinalDestination") {
      setCountryOfFinalDestination(value);
    } else if (name === "portOfLoading") {
      setPortOfLoading(value);
    } else if (name === "portOfDischarge") {
      setPortOfDischarge(value);
    } else if (name === "termOfPayment") {
      setTermOfPayment(value);
    } else if (name === "placeOfOrigin") {
      setPlaceOfOrigin(value);
    } else if (name === "dateOfDeparture") {
      setDateOfDeparture(value);
    } else if (name === "finalDestination") {
      setFinalDestination(value);
    } else if (name === "marinePolicyNo") {
      setMarinePolicyNo(value);
    } else if (name === "letterOfCredit") {
      setLetterOfCredit(value);
    } else if (name === "additionalInformation") {
      setAdditionalInformation(value);
    } else if (name === "placeOfIssue") {
      setPlaceOfIssue(value);
    } else if (name === "date") {
      setDate(value);
    } else if (name === "signatoryCompany") {
      setSignatoryCompany(value);
    } else if (name === "nameOfAuthorizedSignatory") {
      setNameOfAuthorizedSignatory(value);
    } else if (name === "bankDetails") {
      setBankDetails(value);
    }
  };

  // console.log("buyer>>>>>", buyer);

  useEffect(() => {
    if (window.location.search !== "") {
      let queryString = window.location.search;
      let urlParams = new URLSearchParams(queryString);
      let id = urlParams.get("id");
      setComInvoiceId(id);
      // console.log(id);

      getCommercialInvoiceById(id).then((resp) => {
        console.log("resp", resp);
        resp[0].fromCompanyDataId[0].label = resp[0].fromCompanyDataId[0].company_name;
        resp[0].toCompanyDataId[0].label = resp[0].toCompanyDataId[0].company_name;
        resp[0].buyerIfNotConsigneeCompanyId[0].label =
          resp[0].buyerIfNotConsigneeCompanyId[0].company_name;
        resp[0].goodsAndPackages.forEach((element) => {
          element.unitType = { value: element.unitType, label: element.unitType };
        });
        setExporter(resp[0].fromCompanyDataId[0]);
        setConsignee(resp[0].toCompanyDataId[0]);
        setBuyer(resp[0].buyerIfNotConsigneeCompanyId[0]);
        setInvoiceNumber(resp[0].invoiceNumber);
        setBillOfLadingNumber(resp[0].billOfLadingNo);
        setReference(resp[0].reference);
        setBuyerReference(resp[0].buyersReference);
        setCountryOfGoods(resp[0].countryOfOrigin);
        setTransport({ value: resp[0].transport, label: resp[0].transport });
        setVoyageNo(resp[0].voyageNo);
        setCountryOfFinalDestination(resp[0].countryOfFinalDestination);
        setTermOfPayment({ value: resp[0].termsOfPayment, label: resp[0].termsOfPayment });
        setPlaceOfOrigin(resp[0].placeOfOrigin);
        setFinalDestination(resp[0].finalDestination);
        setMarinePolicyNo(resp[0].marineCoverPolicyNumber);
        setLetterOfCredit(resp[0].letterOfCreditNo);
        setMethodOfDispatch({ value: resp[0].methodOfDispatch, label: resp[0].methodOfDispatch });
        setTypeOfShipment({ value: resp[0].typeOfShipment, label: resp[0].typeOfShipment });
        setPortOfLoading({ value: resp[0].portOfLoading, label: resp[0].portOfLoading });
        setPortOfDischarge({ value: resp[0].portOfDischarge, label: resp[0].portOfDischarge });
        setShipperDataTableValue(resp[0].goodsAndPackages);
        setTotalAmount(resp[0].totalAmount);
        setPlaceOfIssue({ value: resp[0].placeOfIssue, label: resp[0].placeOfIssue });
        setAdditionalInformation(resp[0].additionalInfo);
        setBankDetails(resp[0].bankDetails);
        setSignatoryCompany(resp[0].signatoryCompany);
        setNameOfAuthorizedSignatory(resp[0].nameOfAuthorizedSignatory);

        setLogo(resp[0].logourl);
        setImgsrc(resp[0].logourl);

        getBase64FromUrl(resp[0].signatureUrl).then((base) => {
          // console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", base);

          setSignature(base);
        });

        setUpdate(true);
      });
    } else {
      console.log("emptyurl");
    }
  }, []);

  const getBase64FromUrl = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
  };

  const hanldeAddDataTableRow = () => {
    let totalLength = shipperDataTableValue.length;
    // console.log(">>>>>>>>>>>>>> totalLength", totalLength);
    // let randomNumber = Math.random();
    shipperDataTableValue.push({
      // customId: randomNumber,
      productCode: "",
      descriptionOfGoods: "",
      unitQuantity: "",
      unitType: "",
      price: "",
      amount: 0,
    });

    setShipperDataTableValue([...shipperDataTableValue]);
  };

  const totalAmountAdder = (tableData) => {
    let sum = 0;
    tableData.forEach((obj) => {
      // console.log("test>>>>", obj.amount);
      sum += obj.amount;
      setTotalAmount(sum);
    });
  };

  const handleDeleteCommodity = (id) => {
    // console.log(">>>>>>>>>>>>>>>>>>>>. delete id", value);
    // console.log(">>>>>>>>>>>>>>> shipper datatable value", shipperDataTableValue);
    // console.log(">>>>>>>>>>>>>>> delete index", id);
    // let indexNumber = shipperDataTableValue.findIndex((element) => element.customId == value)
    let newShipperData = [...shipperDataTableValue];
    newShipperData.splice(id, 1);
    console.log(">>>>>>>>>>>>. new shipper data", newShipperData);
    setShipperDataTableValue([...newShipperData]);
    totalAmountAdder(newShipperData);
  };

  const handleOnChangeCommodity = (name, value, id) => {
    console.log(">>>>>>>>>>>>>>>>>>.handleOnChangeCommodity", name, value, id - 1);
    if (name === "productCode") {
      shipperDataTableValue[id].productCode = value;
    } else if (name === "descriptionOfGoods") {
      shipperDataTableValue[id].descriptionOfGoods = value;
    } else if (name === "unitQuantity") {
      shipperDataTableValue[id].unitQuantity = value;
    } else if (name === "unitType") {
      shipperDataTableValue[id].unitType = value;
    } else if (name === "price") {
      shipperDataTableValue[id].price = value;
    }

    shipperDataTableValue[id].amount =
      shipperDataTableValue[id].unitQuantity * shipperDataTableValue[id].price;

    totalAmountAdder(shipperDataTableValue);
    console.log(">>>>>>>>>>>>>> new commodity", shipperDataTableValue);
    setShipperDataTableValue([...shipperDataTableValue]);
  };

  const handleGetCompany = () => {
    getCompany().then((response) => {
      let newResponse = [];
      if (response.length > 0) {
        for (let data of response) {
          newResponse.push({
            ...data,
            label: data.company_name,
          });
        }
      }
      setCompany(newResponse);
      // console.log(">>>>>>>>>>>> get Company new response", newResponse);
    });
  };

  const findPort = (e) => {
    if (e != undefined || e != "") {
      if (e.length < 2) return;
      getPorts(e).then((response) => {
        const resp = response.map((data) => {
          data.label = `${data.portName} (${data.unloCode}) (${data.countryName})`;
          data.value = data.portName;
          return data;
        });
        // console.log(resp, "resp");
        setSearchedPorts(resp);
      });
    }
  };

  const handleUploadLogo = (e) => {
    console.log(">>>>>>>>>>>>>> upload file", e.target.files[0]);
    setLogo(e.target.files[0]);
    var file = e.target.files[0];
    var reader = new FileReader();
    var url = reader.readAsDataURL(file);

    reader.onloadend = function (e) {
      setImgsrc(reader.result);
    };
  };

  // const handleDeleteLogo = () => {
  //   setImgsrc("");
  // };

  const handleAddShipper = (type) => {
    if (type === "exporter") {
      setDialogInformation({
        title: "Add exporter",
        type: "exporter",
      });
    } else if (type === "consignee") {
      setDialogInformation({
        title: "Add consignee",
        type: "consignee",
      });
    } else if (type === "buyer") {
      setDialogInformation({
        title: "Add buyer",
        type: "buyer",
      });
    }

    setOpen(true);
  };

  const handleClose = () => {
    setDialogInformation({
      title: "",
      type: "exporter",
    });
    setOpen(false);
  };

  const handleSaveDialogData = () => {
    let errorLengthCompanyName = customValidations("companyName", commonCompany.companyName);
    let errorLengthPlace = customValidations("place", commonCompany.place);
    let errorLengthAddress = customValidations("address", commonCompany.address);
    let errorLengthPhone = customValidations("phone", commonCompany.phone);
    let errorLengthbankDetails = customValidations("bankDetails", commonCompany.bankDetails);

    if (
      errorLengthCompanyName !== 1 &&
      errorLengthPlace !== 1 &&
      errorLengthAddress !== 1 &&
      errorLengthPhone !== 1 &&
      errorLengthbankDetails !== 1
    ) {
      console.log("all ok ");
    } else {
      console.log("not ok");

      return;
    }

    // let errorLength = 0;
    let type = dialogInformation.type;

    // console.log(
    //   "conditions--------",
    //   commonCompany.companyName !== "",
    //   commonCompany.place !== "",
    //   commonCompany.address !== "",
    //   "" + commonCompany.phone !== "",
    //   "" + commonCompany.bankDetails !== "",
    //   commonCompany
    // );

    // if (
    //      commonCompany.companyName !== "" &&
    //     commonCompany.place !== "" &&
    //     commonCompany.address !== "" &&
    //    "" + commonCompany.phone !== "" &&
    //     "" + commonCompany.bankDetails !== ""
    //    ){
    //   errorLength = 1;
    // }

    addCompany({ ...commonCompany })
      .then((response) => {
        if (type === "exporter") {
          setExporter({ ...commonCompany });
        } else if (type === "consignee") {
          setConsignee({ ...commonCompany });
        } else if (type === "buyer") {
          setBuyer({ ...commonCompany });
        }
        handleAddCompany();
      })
      .catch((error) => {
        console.log("add company error", error);
      });
  };

  const handleAddCompany = () => {
    handleGetCompany();
    handleClose();
    setCommonCompany({
      companyName: "",
      place: "",
      address: "",
      phone: "",
      bankDetails: "",
    });
    Swal.fire("", "Company added successfully", "success");
  };

  const handleDialogInformation = (name, value) => {
    console.log(">>>>>>>>>>>>>> handleDialogInformation", name, value);
    setCommonCompany({ ...commonCompany, [name]: value });
  };

  const clearCanvas = () => {
    sigCanvas.current.clear();
    setSignature("");
  };

  const showSignature = () => {
    sigCanvas.current?.fromDataURL(signature);
  };

  useEffect(() => {
    showSignature();
  }, [showPreview, signature]);

  const handleSaveSignature = () => {
    setSignature(sigCanvas.current.toDataURL("image/png"));
    // setSignature("");
  };

  const handleShowSinature = () => {
    sigCanvas.current.fromDataURL(signature);
  };

  const handleSavePDF = () => {
    ReactPDF.render(
      <PDFDocument
        value={{
          logo: imgSrc,
          exporterCompany: exporter,
          invoiceNumber: invoiceNumber,
          datee: datee,
          billOfLadingNumber: billOfLadingNumber,
          consigneeCompany: consignee,
          reference: reference,
          buyerReference: buyerReference,
          buyerCompany: buyer,
          methodOfDispatch: methodOfDispatch,
          typeOfShipment: typeOfShipment,
          countryOfGoods: countryOfGoods,
          transport: transport,
          voyageNo: voyageNo,
          countryOfFinalDestination: countryOfFinalDestination,
          portOfLoading: portOfLoading.value,
          portOfDischarge: portOfDischarge.value,
          termOfPayment: termOfPayment,
          placeOfOrigin: placeOfOrigin,
          dateOfDeparture: dateOfDeparture,
          finalDestination: finalDestination,
          marinePolicyNo: marinePolicyNo,
          letterOfCredit: letterOfCredit,
          totalAmount: totalAmount,
          additionalInformation: additionalInformation,
          placeOfIssue: placeOfIssue.value,
          date: date,
          signatoryCompany: signatoryCompany,
          nameOfAuthorizedSignatory: nameOfAuthorizedSignatory,
          signature: signature,
          bankDetails: bankDetails,
          shipperDataTableValue: shipperDataTableValue,
        }}
      />,
      `${__dirname}/example.pdf`
    );
  };

  const handleShowPreview = () => {
    if (showPreview) {
      setShowPreview(false);
    } else {
      setShowPreview(true);
    }
  };

  const handleClosePreview = () => {
    setShowPreview(false);
  };

  const handleCheckedDataTableValue = () => {
    let error = false;
    for (let data of shipperDataTableValue) {
      if (
        !error &&
        (data.productCode === "" ||
          data.descriptionOfGoods === "" ||
          data.unitQuantity === "" ||
          data.unitType === "" ||
          data.price === "" ||
          data.amount === "")
      ) {
        console.log(">>>>>>>>>>>>>>>>if error");
        error = true;
      }
    }
    console.log(">>>>>>>>>>>>> error", error);
    return error;
  };

  const handleSubmitCommercialInvoice = async () => {
    let tableError = handleCheckedDataTableValue();

    let errorLengthExporter = customValidations("exporter", exporter.id);
    let errorLengthConsignee = customValidations("consignee", consignee.id);
    let errorLengthInvoiceNumber = customValidations("invoiceNumber", invoiceNumber);
    let errorLengthBillOfLadingNumber = customValidations("billOfLadingNumber", billOfLadingNumber);
    let errorLengthDatee = customValidations("datee", datee);
    let errorLengthReference = customValidations("reference", reference);
    let errorLengthBuyerReference = customValidations("buyerReference", buyerReference);
    let errorLengthBuyer = customValidations("buyer", buyer.id);
    let errorLengthMethodOfDispatch = customValidations("methodOfDispatch", methodOfDispatch);
    let errorLengthTypeOfShipment = customValidations("typeOfShipment", typeOfShipment);
    let errorLengthCountryOfGoods = customValidations("countryOfGoods", countryOfGoods);
    let errorLengthTransport = customValidations("transport", transport);
    let errorLengthVoyageNo = customValidations("voyageNo", voyageNo);
    let errorLengthCountryOfFinalDestination = customValidations(
      "countryOfFinalDestination",
      countryOfFinalDestination
    );
    let errorLengthPortOfLoading = customValidations("portOfLoading", portOfLoading);
    let errorLengthPortOfDischarge = customValidations("portOfDischarge", portOfDischarge);
    let errorLengthTermOfPayment = customValidations("termOfPayment", termOfPayment);
    let errorLengthPlaceOfOrigin = customValidations("placeOfOrigin", placeOfOrigin);
    let errorLengthDateOfDepartrue = customValidations("dateOfDeparture", dateOfDeparture);
    let errorLengthFinalDestination = customValidations("finalDestination", finalDestination);
    let errorLengthMarinePolicyNo = customValidations("marinePolicyNo", marinePolicyNo);
    let errorLengthLetterOfCredit = customValidations("letterOfCredit", letterOfCredit);
    let errorLengthTotalAmount = customValidations("totalAmount", totalAmount);
    let errorLengthAdditionalInformation = customValidations(
      "additionalInformation",
      additionalInformation
    );
    let errorLengthPlaceOfIssue = customValidations("placeOfIssue", placeOfIssue);
    let errorLengthDate = customValidations("date", date);
    let errorLengthSignatoryCompany = customValidations("signatoryCompany", signatoryCompany);
    let errorLengthNameOfAuthorizedSignatory = customValidations(
      "nameOfAuthorizedSignatory",
      nameOfAuthorizedSignatory
    );
    let errorLengthBankDetails = customValidations("bankDetails", bankDetails);

    if (
      // imgSrc !== "" &&
      // exporter.companyName !== "" &&
      // invoiceNumber !== "" &&
      // datee !== "" &&
      // billOfLadingNumber !== "" &&
      // consignee.companyName !== "" &&
      // reference !== "" &&
      // buyerReference !== "" &&
      // buyer.companyName !== "" &&
      // methodOfDispatch !== "" &&
      // typeOfShipment !== "" &&
      // countryOfGoods !== "" &&
      // transport !== "" &&
      // voyageNo !== "" &&
      // countryOfFinalDestination !== "" &&
      // portOfLoading !== "" &&
      // portOfDischarge !== "" &&
      // termOfPayment !== "" &&
      // placeOfOrigin !== "" &&
      // dateOfDeparture !== "" &&
      // finalDestination !== "" &&
      // marinePolicyNo !== "" &&
      // letterOfCredit !== "" &&
      // totalAmount !== "" &&
      // additionalInformation !== "" &&
      // placeOfIssue !== "" &&
      // date !== "" &&
      // signatoryCompany !== "" &&
      // nameOfAuthorizedSignatory !== "" &&
      // signature !== "" &&
      // bankDetails !== "" &&
      // shipperDataTableValue !== "" &&
      // tableError === false

      errorLengthExporter !== 1 &&
      errorLengthConsignee !== 1 &&
      errorLengthInvoiceNumber !== 1 &&
      errorLengthBillOfLadingNumber !== 1 &&
      errorLengthDatee !== 1 &&
      errorLengthReference !== 1 &&
      errorLengthBuyerReference !== 1 &&
      errorLengthBuyer !== 1 &&
      errorLengthMethodOfDispatch !== 1 &&
      errorLengthTypeOfShipment !== 1 &&
      errorLengthCountryOfGoods !== 1 &&
      errorLengthTransport !== 1 &&
      errorLengthVoyageNo !== 1 &&
      errorLengthCountryOfFinalDestination !== 1 &&
      errorLengthPortOfLoading !== 1 &&
      errorLengthPortOfDischarge !== 1 &&
      errorLengthTermOfPayment !== 1 &&
      errorLengthPlaceOfOrigin !== 1 &&
      errorLengthDateOfDepartrue !== 1 &&
      errorLengthFinalDestination !== 1 &&
      errorLengthMarinePolicyNo !== 1 &&
      errorLengthLetterOfCredit !== 1 &&
      errorLengthTotalAmount !== 1 &&
      errorLengthAdditionalInformation !== 1 &&
      errorLengthPlaceOfIssue !== 1 &&
      errorLengthDate !== 1 &&
      errorLengthSignatoryCompany !== 1 &&
      errorLengthNameOfAuthorizedSignatory !== 1 &&
      errorLengthBankDetails !== 1 &&
      signature !== "" &&
      tableError === false
    ) {
      console.log("all ok");
      let goodsAndPackages = [];
      for (let item of shipperDataTableValue) {
        goodsAndPackages.push({
          productCode: item.productCode,
          descriptionOfGoods: item.descriptionOfGoods,
          unitQuantity: item.unitQuantity,
          unitType: item.unitType.value,
          price: item.price,
          amount: item.amount,
        });
      }
      let formData = new FormData();
      // console.log( this.state.invoice,"invoic");
      let finalData = {
        id: comInvoiceId,
        exporterCompanyDataId: exporter.id,
        consigneeCompanyDataId: consignee.id,
        invoiceNumber: invoiceNumber,
        dateOfInvoice: datee,
        billOfLadingNo: billOfLadingNumber,
        reference: reference,
        buyersReference: buyerReference,
        buyerIfNotConsigneeCompanyId: buyer.id,
        methodOfDispatch: methodOfDispatch.value,
        typeOfShipment: typeOfShipment.value,
        countryOfOrigin: countryOfGoods,
        transport: transport.value,
        voyageNo: voyageNo,
        countryOfFinalDestination: countryOfFinalDestination,
        portOfLoading: portOfLoading.value,
        portOfDischarge: portOfDischarge.value,
        termsOfPayment: termOfPayment.value,
        placeOfOrigin: placeOfOrigin,
        dateOfDeparture: dateOfDeparture,
        finalDestination: finalDestination,
        marineCoverPolicyNumber: marinePolicyNo,
        letterOfCreditNo: letterOfCredit,
        goodsAndPackages: goodsAndPackages,
        totalAmount: totalAmount,
        placeOfIssue: placeOfIssue.value,
        dateOfIssue: date,
        signatoryCompany: signatoryCompany,
        nameOfAuthorizedSignatory: nameOfAuthorizedSignatory,
        additionalInfo: additionalInformation,
        bankDetails: bankDetails,
        // from: exporter.id,
        // to: consignee.id,
        // quoteNumber: invoiceNumber,
        // date: datee,
        // portOfLoading: portOfLoading.value,
        // portOfDischarge: portOfDischarge.value,
        // methodsOfDispatch: methodOfDispatch.value,
        // typeOfShipement: typeOfShipment.value,
        // additionalInformation: additionalInformation,
        // placeOfIssue: placeOfIssue.value,
        // placeOfDate: date,
        // signatoryCompany: signatoryCompany,
        // nameOfAuthorized: nameOfAuthorizedSignatory,
        // signature: signature,
        // products: goodsAndPackages,
      };
      console.log(">>>>>>>>>>>>>finale data", finalData);

      if (logo instanceof File !== true) {
        const logoToFile = await fetch(logo).then((it) => it.blob());
        const logoFile = new File([logoToFile], "logo.png", {
          type: "image/png",
          lastModified: new Date(),
        });

        formData.append("logo", logoFile);
      } else {
        formData.append("logo", logo);
      }

      const signatureUrltoFile = await fetch(signature).then((it) => it.blob());
      const signatureFile = new File([signatureUrltoFile], "signature.png", {
        type: "image/png",
        lastModified: new Date(),
      });
      console.log("signatureFile", signatureFile);
      formData.append("data", JSON.stringify(finalData));

      formData.append("signature", signatureFile);

      if (update === true) {
        editCommercialInvoiceById(formData).then((response) => {
          console.log("comm invoice", response);
          Swal.fire("", "Commercial Invoice Updated", "success").then(() => {
            history.push("/btnt-docs/list-commercial-invoice");
          });
        });
      } else {
        addCommercialInvoice(formData).then((response) => {
          console.log("comm invoice", response);
          Swal.fire("", "Commercial Invoice Added", "success").then(() => {
            history.push("/btnt-docs/list-commercial-invoice");
          });
        });
      }
    } else {
      if (tableError === true) {
        Swal.fire("", "Please fill all the product data fields", "warning");
        console.log("not ok", tableError);
      } else if (signature === "") {
        Swal.fire("", "Please enter your signature", "warning");
      }
    }
  };

  // const handleSubmitBillOfLading = () => {
  //   let tableError = handleCheckedDataTableValue();
  //   if (
  //     imgSrc !== "" &&
  //     exporter.companyName !== "" &&
  //     invoiceNumber !== "" &&
  //     datee !== "" &&
  //     billOfLadingNumber !== "" &&
  //     consignee.companyName !== "" &&
  //     reference !== "" &&
  //     buyerReference !== "" &&
  //     buyer.companyName !== "" &&
  //     methodOfDispatch !== "" &&
  //     typeOfShipment !== "" &&
  //     countryOfGoods !== "" &&
  //     transport !== "" &&
  //     voyageNo !== "" &&
  //     countryOfFinalDestination !== "" &&
  //     portOfLoading !== "" &&
  //     portOfDischarge !== "" &&
  //     termOfPayment !== "" &&
  //     placeOfOrigin !== "" &&
  //     dateOfDeparture !== "" &&
  //     finalDestination !== "" &&
  //     marinePolicyNo !== "" &&
  //     letterOfCredit !== "" &&
  //     totalAmount !== "" &&
  //     additionalInformation !== "" &&
  //     placeOfIssue !== "" &&
  //     date !== "" &&
  //     signatoryCompany !== "" &&
  //     nameOfAuthorizedSignatory !== "" &&
  //     signature !== "" &&
  //     bankDetails !== "" &&
  //     shipperDataTableValue !== "" &&
  //     tableError === false
  //   ) {
  //     console.log("all ok");
  //     let goodsAndPackages = [];
  //     for (let item of shipperDataTableValue) {
  //       goodsAndPackages.push({
  //         productCode: item.productCode,
  //         descriptionOfGoods: item.descriptionOfGoods,
  //         unitQty: item.unitQty,
  //         unitType: item.unitType,
  //         price: item.price,
  //         amount: item.amount,
  //       });
  //     }

  //     let formData = new FormData();
  //     // console.log( this.state.invoice,"invoic");
  //     let finalData = {
  //       invoiceNumber: invoiceNumber,
  //       datee: datee,
  //       billOfLadingNumber: billOfLadingNumber,
  //       reference: reference,
  //       buyerReference: buyerReference,
  //       methodOfDispatch: methodOfDispatch,
  //       typeOfShipment: typeOfShipment,
  //       countryOfGoods: countryOfGoods,
  //       transport: transport,
  //       voyageNo: voyageNo,
  //       countryOfFinalDestination: countryOfFinalDestination,
  //       portOfLoading: portOfLoading,
  //       portOfDischarge: portOfDischarge,
  //       termOfPayment: termOfPayment,
  //       placeOfOrigin: placeOfOrigin,
  //       dateOfDeparture: dateOfDeparture,
  //       finalDestination: finalDestination,
  //       marinePolicyNo: marinePolicyNo,
  //       letterOfCredit: letterOfCredit,
  //       totalAmount: totalAmount,
  //       additionalInformation: additionalInformation,
  //       placeOfIssue: placeOfIssue,
  //       placeOfdate: date,
  //       signatoryCompany: signatoryCompany,
  //       nameOfAuthorizedSignatory: nameOfAuthorizedSignatory,
  //       signatureUrl: signature,
  //       bankDetails: bankDetails,
  //       merchantId: "CIF",
  //       shipperCompanyDataId: shipper.id,
  //       consigneeCompanyDataId: consignee.id,
  //       carrierNameCompanyDataId: carrierName.id,
  //       notifyPartyCompanyDataId: notifyParty.id,
  //       additionalNotifyPartyCompanyDataId: addNotifyParty.id,
  //       goodsAndPackages: goodsAndPackages,
  //     };
  //     console.log(">>>>>>>>>>>>>finale data", finalData);
  //     formData.append("logo", logo ? logo : null);
  //     formData.append("data", JSON.stringify(finalData));

  //     postBillOfLading(formData).then((response) => {
  //       console.log("response bill of lading", response);
  //       swal("", "All Fields are required", "success");
  //     });
  //   } else {
  //     swal("", "All Fields are required", "error");
  //   }
  //   // console.log("shipper", shipper);
  //   // console.log("shipper Reference 1", shipperReference);
  //   // console.log("shipper Reference 2", shipperReferenceSecond);
  //   // console.log("Carrier reference", carrierReference);
  //   // console.log("unique consignment reference", uniqueConsignmentReference);
  //   // console.log("consignee", consignee);
  //   // console.log("carrier name", carrierName);
  //   // console.log("notifyParty", notifyParty);
  //   // console.log("addition notify party", addNotifyParty);
  //   // console.log("pre carriage by", preCarriageBy);
  //   // console.log("placeOfReceipt", placeOfReceipt);
  //   // console.log("additional info", additionalInformation);
  //   // console.log("transport", transport);
  //   // console.log("voyage", voyageNo);
  //   // console.log("placeOfDelivery", placeOfDelivery);
  //   // console.log("portofloading", portOfLoading);
  //   // console.log("port of discharge", portOfDischarge);
  //   // console.log("total net weight", totalNetWeight);
  //   // console.log("total gross weight", totalGrossWeight);
  //   // console.log("total volume", totalVolume);
  //   // console.log("additional information", additionalInformationSecond);
  //   // console.log("place of issue", placeOfIssue);
  //   // console.log("date",date);
  //   // console.log("signatory company", signatoryCompany);
  //   // console.log("name of authorizes", nameOfAuthorizedSignatory);
  //   // console.log("signature", signature);
  //   // console.log("shipperDataTableValue", shipperDataTableValue);
  // };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card id="basic-info" sx={{ overflow: "visible" }}>
        {!showPreview ? (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6}>
                <VuiBox mb="40px">
                  <VuiTypography variant="lg" color="text" fontWeight="bold">
                    Commercial Invoice
                  </VuiTypography>
                </VuiBox>
              </Grid>
              <Grid item xs={12} lg={6}>
                <div className={"com-invo-download-preview-save"}>
                  <VuiButton
                    variant="gradient"
                    component="span"
                    color="info"
                    sx={{ minWidth: "100px" }}
                    onClick={handleShowPreview}
                  >
                    Preview
                  </VuiButton>

                  <PDFDownloadLink
                    document={
                      <PDFDocument
                        value={{
                          logo: imgSrc,
                          exporterCompany: exporter,
                          invoiceNumber: invoiceNumber,
                          datee: datee,
                          billOfLadingNumber: billOfLadingNumber,
                          consigneeCompany: consignee,
                          reference: reference,
                          buyerReference: buyerReference,
                          buyerCompany: buyer,
                          methodOfDispatch: methodOfDispatch.value,
                          typeOfShipment: typeOfShipment.value,
                          countryOfGoods: countryOfGoods,
                          transport: transport.value,
                          voyageNo: voyageNo,
                          countryOfFinalDestination: countryOfFinalDestination,
                          portOfLoading: portOfLoading.value,
                          portOfDischarge: portOfDischarge.value,
                          termOfPayment: termOfPayment.value,
                          placeOfOrigin: placeOfOrigin.value,
                          dateOfDeparture: dateOfDeparture,
                          finalDestination: finalDestination,
                          marinePolicyNo: marinePolicyNo,
                          letterOfCredit: letterOfCredit,
                          totalAmount: totalAmount,
                          additionalInformation: additionalInformation,
                          placeOfIssue: placeOfIssue.value,
                          date: date,
                          signatoryCompany: signatoryCompany,
                          nameOfAuthorizedSignatory: nameOfAuthorizedSignatory,
                          signature: signature,
                          bankDetails: bankDetails,
                          shipperDataTableValue: shipperDataTableValue,
                        }}
                      />
                    }
                    fileName="Document"
                  >
                    {({ loading }) =>
                      loading ? (
                        <VuiButton
                          variant="gradient"
                          component="span"
                          color="info"
                          sx={{ minWidth: "100px" }}
                        >
                          Loading.....
                        </VuiButton>
                      ) : (
                        <VuiButton
                          variant="gradient"
                          component="span"
                          color="info"
                          sx={{ minWidth: "100px" }}
                        >
                          Download
                        </VuiButton>
                      )
                    }
                  </PDFDownloadLink>

                  {/* <VuiButton
                    variant="gradient"
                    component="span"
                    color="info"
                    sx={{ minWidth: "100px" }}
                  >
                    Save as
                  </VuiButton>
                  <VuiButton
                    variant="gradient"
                    component="span"
                    color="info"
                    sx={{ minWidth: "100px" }}
                    onClick={handleSavePDF}
                  >
                    Save
                  </VuiButton> */}
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={2} className={"com-invo-upload-clear"}>
              <Grid
                item
                xs={12}
                lg={6}
                style={{ display: "flex", alignItems: "flex-start", justifyContent: "flex-start" }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    flexDirection: "column",
                    marginTop: "10px",
                  }}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={2}
                    style={{ marginRight: "30px" }}
                  >
                    <label htmlFor="contained-button-file">
                      <Input
                        accept="image/*"
                        id="contained-button-file"
                        type="file"
                        onChange={(e) => handleUploadLogo(e)}
                      />
                      {/* <Button variant="contained" component="span">
                  Upload
                </Button> */}
                      <VuiButton
                        variant="gradient"
                        component="span"
                        color="info"
                        sx={{ minWidth: "100px" }}
                      >
                        Change Logo
                      </VuiButton>
                    </label>
                  </Stack>
                  {/* {imgSrc ? (
                    <VuiButton
                      variant="gradient"
                      component="span"
                      color="info"
                      sx={{ minWidth: "100px" }}
                      style={{ marginTop: "10px" }}
                      onClick={handleDeleteLogo}
                    >
                      Delete Logo
                    </VuiButton>
                  ) : (
                    ""
                  )} */}
                </div>
                <img src={imgSrc} style={{ width: "50px", height: "50px" }} />
              </Grid>
              {/* <Grid item xs={12} lg={6} textAlign={"right"}>
                <VuiTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  color="text"
                  className={"com-invo-clear"}
                >
                  Clear
                </VuiTypography>
              </Grid> */}
            </Grid>
            <VuiBox component="form">
              <div container spacing={2} className={"com-invo-custom-grid-form"}>
                <div item xs={12} lg={6} className={"com-invo-exporter"}>
                  <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <VuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      color="text"
                    >
                      Exporter
                    </VuiTypography>
                  </VuiBox>
                  {/* <VuiInput
                inputProps={{ type: "string" }}
                defaultValue={exporter.companyName}
                sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
                  border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
                })}
                placeholder={"Select"}
                value={exporter.companyName}
              /> */}
                  <VuiSelect
                    placeholder="Add exporter info"
                    options={company}
                    value={exporter}
                    error={errorMsg.exporter.errorMsg.length > 0}
                    onChange={(e) => handleChangeInput("exporter", e)}
                  />
                  <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                    {errorMsg.exporter.errorMsg}
                  </VuiTypography>
                  <div className={"com-invo-add-exporter"}>
                    <VuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      color="text"
                      onClick={() => handleAddShipper("exporter")}
                    >
                      <AddIcon size={18} />
                      Add exporter info
                    </VuiTypography>
                  </div>
                </div>

                <div item xs={12} lg={6} className={"com-invo-page"}>
                  <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <VuiTypography component="label" variant="h6" fontWeight="bold" color="text">
                      Pages <br />1 of 1
                    </VuiTypography>
                  </VuiBox>
                </div>

                <div item xs={12} lg={6} className={"com-invo-invoice-number"}>
                  <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <VuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      color="text"
                    >
                      Invoice Number
                    </VuiTypography>
                  </VuiBox>
                  <VuiInput
                    name="invoiceNumber"
                    placeholder="Invoice Number"
                    inputProps={{ type: "string" }}
                    value={invoiceNumber}
                    error={errorMsg.invoiceNumber.errorMsg.length > 0}
                    onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                  />
                  <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                    {errorMsg.invoiceNumber.errorMsg}
                  </VuiTypography>
                </div>

                <div item xs={12} lg={6} className={"com-invo-invoice-date"}>
                  <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <VuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      color="text"
                    >
                      Date
                    </VuiTypography>
                  </VuiBox>
                  <VuiInput
                    name="datee"
                    inputProps={{ type: "date", mini: currentDate }}
                    defaultValue={datee}
                    value={datee}
                    error={errorMsg.datee.errorMsg.length > 0}
                    onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                  />
                  <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                    {errorMsg.datee.errorMsg}
                  </VuiTypography>
                </div>
                {console.log(">>>", billOfLadingNumber)}
                <div item xs={12} lg={6} className={"com-invo-bill-of-lading-number"}>
                  <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <VuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      color="text"
                    >
                      Bill of Lading Number
                    </VuiTypography>
                  </VuiBox>
                  <VuiInput
                    name="billOfLadingNumber"
                    placeholder="Bill of Lading Number"
                    inputProps={{ type: "string" }}
                    value={billOfLadingNumber}
                    error={errorMsg.billOfLadingNumber.errorMsg.length > 0}
                    onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                  />
                  <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                    {errorMsg.billOfLadingNumber.errorMsg}
                  </VuiTypography>
                </div>

                {/* <div item xs={12} lg={6} columnSpacing={2}></div> */}

                <div item xs={12} lg={6} className={"com-invo-reference"}>
                  <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <VuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      color="text"
                    >
                      Reference
                    </VuiTypography>
                  </VuiBox>
                  <VuiInput
                    name="reference"
                    placeholder="Reference"
                    inputProps={{ type: "string" }}
                    value={reference}
                    error={errorMsg.reference.errorMsg.length > 0}
                    onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                  />
                  <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                    {errorMsg.reference.errorMsg}
                  </VuiTypography>
                </div>

                <div item xs={12} lg={6} className={"com-invo-buyer-reference"}>
                  <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <VuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      color="text"
                    >
                      Buyer Reference
                    </VuiTypography>
                  </VuiBox>
                  <VuiInput
                    name="buyerReference"
                    placeholder="Buyer Reference"
                    inputProps={{ type: "string" }}
                    value={buyerReference}
                    error={errorMsg.buyerReference.errorMsg.length > 0}
                    onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                  />
                  <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                    {errorMsg.buyerReference.errorMsg}
                  </VuiTypography>
                </div>

                <div item xs={12} lg={6} className={"com-invo-consignee"}>
                  <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <VuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      color="text"
                    >
                      Consignee
                    </VuiTypography>
                  </VuiBox>
                  {/* <VuiInput
                inputProps={{ type: "string" }}
                defaultValue={consignee.companyName}
                sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
                  border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
                })}
                placeholder={"Select"}
                value={consignee.companyName}
              /> */}
                  <VuiSelect
                    placeholder="Add consignee info"
                    options={company}
                    value={consignee}
                    error={errorMsg.consignee.errorMsg.length > 0}
                    onChange={(e) => handleChangeInput("consignee", e)}
                  />
                  <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                    {errorMsg.consignee.errorMsg}
                  </VuiTypography>
                  <div className={"com-invo-add-exporter"}>
                    <VuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      color="text"
                      onClick={() => handleAddShipper("consignee")}
                    >
                      <AddIcon size={18} />
                      Add consignee info
                    </VuiTypography>
                  </div>
                </div>

                <div item xs={12} lg={6} className={"com-invo-buyer-if-not-consignee"}>
                  <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <VuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      color="text"
                    >
                      Buyer (IF not Consignee)
                    </VuiTypography>
                  </VuiBox>
                  {/* <VuiInput
                inputProps={{ type: "string" }}
                defaultValue={buyer.companyName}
                sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
                  border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
                })}
                value={buyer.companyName}
              /> */}
                  <VuiSelect
                    placeholder="Add buyer (if not consignee) info"
                    options={company}
                    value={buyer}
                    error={errorMsg.buyer.errorMsg.length > 0}
                    onChange={(e) => handleChangeInput("buyer", e)}
                  />
                  <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                    {errorMsg.buyer.errorMsg}
                  </VuiTypography>
                  <div className={"com-invo-add-exporter"}>
                    <VuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      color="text"
                      onClick={() => handleAddShipper("buyer")}
                    >
                      <AddIcon size={18} />
                      Add buyer (if not consignee) info
                    </VuiTypography>
                  </div>
                </div>

                <div item xs={12} lg={6} className={"com-invo-method-of-dispatch"}>
                  <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <VuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      color="text"
                    >
                      Method of Dispatch
                    </VuiTypography>
                  </VuiBox>
                  <VuiSelect
                    placeholder="Select"
                    options={[
                      { value: "Container", label: "Container" },
                      { value: "Truck", label: "Truck" },
                      { value: "Wagon", label: "Wagon" },
                    ]}
                    value={methodOfDispatch}
                    error={errorMsg.methodOfDispatch.errorMsg.length > 0}
                    onChange={(e) => handleChangeInput("methodOfDispatch", e)}
                  />
                  <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                    {errorMsg.methodOfDispatch.errorMsg}
                  </VuiTypography>
                </div>

                <div item xs={12} lg={6} className={"com-invo-type"}>
                  <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <VuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      color="text"
                    >
                      Type of Shipment
                    </VuiTypography>
                  </VuiBox>
                  <VuiSelect
                    placeholder="Select"
                    options={[
                      { value: "FCL", label: "FCL" },
                      { value: "LCL", label: "LCL" },
                      { value: "BULK", label: "BULK" },
                      { value: "FTL", label: "FTL" },
                      { value: "LTL", label: "LTL" },
                      { value: "RAIL", label: "RAIL" },
                      { value: "AIR", label: "AIR" },
                    ]}
                    value={typeOfShipment}
                    error={errorMsg.typeOfShipment.errorMsg.length > 0}
                    onChange={(e) => handleChangeInput("typeOfShipment", e)}
                  />
                  <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                    {errorMsg.typeOfShipment.errorMsg}
                  </VuiTypography>
                </div>

                <div item xs={12} lg={6} className={"com-invo-country-of-goods"}>
                  <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <VuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      color="text"
                    >
                      Country of Origin of Goods
                    </VuiTypography>
                  </VuiBox>
                  <VuiInput
                    name="countryOfGoods"
                    placeholder="Country of origin of goods"
                    inputProps={{ type: "string" }}
                    value={countryOfGoods}
                    onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                  />
                  <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                    {errorMsg.countryOfGoods.errorMsg}
                  </VuiTypography>
                </div>

                <div item xs={12} lg={6} className={"com-invo-transport"}>
                  <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <VuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      color="text"
                    >
                      Transport
                    </VuiTypography>
                  </VuiBox>
                  <VuiSelect
                    placeholder="Select"
                    options={[
                      { value: "Vessel", label: "Vessel" },
                      { value: "Aircraft", label: "Aircraft" },
                      { value: "Truck", label: "Truck" },
                      { value: "Wagon", label: "Wagon" },
                    ]}
                    value={transport}
                    error={errorMsg.transport.errorMsg.length > 0}
                    onChange={(e) => handleChangeInput("transport", e)}
                  />
                  <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                    {errorMsg.transport.errorMsg}
                  </VuiTypography>
                </div>

                <div item xs={12} lg={6} className={"com-invo-voyage-no"}>
                  <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <VuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      color="text"
                    >
                      Voyage No
                    </VuiTypography>
                  </VuiBox>
                  <VuiInput
                    name="voyageNo"
                    placeholder="Voyage No."
                    inputProps={{ type: "string" }}
                    value={voyageNo}
                    error={errorMsg.voyageNo.errorMsg.length > 0}
                    onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                  />
                  <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                    {errorMsg.voyageNo.errorMsg}
                  </VuiTypography>
                </div>

                <div item xs={12} lg={6} className={"com-invo-country-of-final-destination"}>
                  <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <VuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      color="text"
                    >
                      Country of Final Destination
                    </VuiTypography>
                  </VuiBox>
                  <VuiInput
                    name="countryOfFinalDestination"
                    placeholder="Country of Final Destination"
                    inputProps={{ type: "string" }}
                    value={countryOfFinalDestination}
                    error={errorMsg.countryOfFinalDestination.errorMsg.length > 0}
                    onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                  />
                  <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                    {errorMsg.countryOfFinalDestination.errorMsg}
                  </VuiTypography>
                </div>

                <div item xs={12} lg={6} className={"com-invo-port-of-loading"}>
                  <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <VuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      color="text"
                    >
                      Port of Loading
                    </VuiTypography>
                  </VuiBox>
                  <VuiSelect
                    options={searchedPorts}
                    value={portOfLoading}
                    onInputChange={(e) => findPort(e)}
                    error={errorMsg.portOfLoading.errorMsg.length > 0}
                    onChange={(e) => handleChangeInput("portOfLoading", e)}
                  />
                  <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                    {errorMsg.portOfLoading.errorMsg}
                  </VuiTypography>
                </div>

                <div item xs={12} lg={6} className={"com-invo-port-of-discharge"}>
                  <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <VuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      color="text"
                    >
                      Port Of Discharge
                    </VuiTypography>
                  </VuiBox>
                  <VuiSelect
                    placeholder="Select"
                    options={searchedPorts}
                    value={portOfDischarge}
                    onInputChange={(e) => findPort(e)}
                    error={errorMsg.portOfDischarge.errorMsg.length > 0}
                    onChange={(e) => handleChangeInput("portOfDischarge", e)}
                  />
                  <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                    {errorMsg.portOfDischarge.errorMsg}
                  </VuiTypography>
                </div>

                <div item xs={12} lg={6} className={"com-invo-term-of-payment"}>
                  <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <VuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      color="text"
                    >
                      Terms/Method of Payment
                    </VuiTypography>
                  </VuiBox>
                  <VuiSelect
                    placeholder="Select"
                    options={[
                      { value: "100% Prepayment", label: "100% Prepayment" },
                      { value: "Partial Prepayment", label: "Partial Prepayment" },
                      { value: "LC", label: "LC" },
                      { value: "CAD", label: "CAD" },
                      { value: "Deferal", label: "Deferal" },
                    ]}
                    value={termOfPayment}
                    error={errorMsg.termOfPayment.errorMsg.length > 0}
                    onChange={(e) => handleChangeInput("termOfPayment", e)}
                  />
                  <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                    {errorMsg.termOfPayment.errorMsg}
                  </VuiTypography>
                </div>

                <div item xs={12} lg={6} className={"com-invo-place-of-origin"}>
                  <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <VuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      color="text"
                    >
                      Place of Origin
                    </VuiTypography>
                  </VuiBox>
                  <VuiInput
                    name="placeOfOrigin"
                    inputProps={{ type: "string" }}
                    value={placeOfOrigin}
                    error={errorMsg.portOfDischarge.errorMsg.length > 0}
                    onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                  />
                  <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                    {errorMsg.placeOfOrigin.errorMsg}
                  </VuiTypography>
                </div>

                <div item xs={12} lg={6} className={"com-invo-date-of-departure"}>
                  <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <VuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      color="text"
                    >
                      Date of Departure
                    </VuiTypography>
                  </VuiBox>
                  <VuiInput
                    name="dateOfDeparture"
                    inputProps={{ type: "date", min: currentDate }}
                    defaultValue={dateOfDeparture}
                    placeholder={"Date"}
                    value={dateOfDeparture}
                    error={errorMsg.dateOfDeparture.errorMsg.length > 0}
                    onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                  />
                  <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                    {errorMsg.dateOfDeparture.errorMsg}
                  </VuiTypography>
                  {/* <VuiSelect
                    placeholder="Select"
                    value={dateOfDeparture}
                    defaultValue={{ value: "", label: "" }}
                    onChange={(e) => setDateOfDeparture(e.target.value)}
                  /> */}
                </div>

                <div item xs={12} lg={6} className={"com-invo-final-destination"}>
                  <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <VuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      color="text"
                    >
                      Final Destination
                    </VuiTypography>
                  </VuiBox>
                  <VuiInput
                    name="finalDestination"
                    placeholder="Final Destination"
                    inputProps={{ type: "string" }}
                    value={finalDestination}
                    error={errorMsg.finalDestination.errorMsg.length > 0}
                    onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                  />
                  <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                    {errorMsg.finalDestination.errorMsg}
                  </VuiTypography>
                </div>

                <div item xs={12} lg={6} className={"com-invo-marine-cover-policy-no"}>
                  <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <VuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      color="text"
                    >
                      Marine Cover Policy No
                    </VuiTypography>
                  </VuiBox>
                  <VuiInput
                    name="marinePolicyNo"
                    placeholder="Marine Cover Policy No"
                    inputProps={{ type: "string" }}
                    value={marinePolicyNo}
                    error={errorMsg.marinePolicyNo.errorMsg.length > 0}
                    onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                  />
                  <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                    {errorMsg.portOfDischarge.errorMsg}
                  </VuiTypography>
                </div>

                <div item xs={12} lg={6} className={"com-invo-letter-of-credit-no"}>
                  <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <VuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      color="text"
                    >
                      Letter of Credit No
                    </VuiTypography>
                  </VuiBox>
                  <VuiInput
                    name="letterOfCredit"
                    placeholder="Letter of Credit No"
                    inputProps={{ type: "string" }}
                    value={letterOfCredit}
                    error={errorMsg.letterOfCredit.errorMsg.length > 0}
                    onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                  />
                  <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                    {errorMsg.letterOfCredit.errorMsg}
                  </VuiTypography>
                </div>

                <div item xs={12} lg={6} className={"com-invo-data-table com-invo-datatable"}>
                  <DataTable
                    showTotalEntries={false}
                    entriesPerPage={false}
                    table={{
                      columns: [
                        {
                          Header: "Product Code",
                          accessor: "productCode",
                          align: "center",
                        },
                        {
                          Header: "Description of Goods",
                          accessor: "descriptionOfGoods",
                          align: "center",
                        },
                        {
                          Header: "Unit Qty",
                          accessor: "unitQuantity",
                          align: "center",
                        },
                        {
                          Header: "Unit Type",
                          accessor: "unitType",
                          align: "center",
                        },
                        {
                          Header: "Price",
                          accessor: "price",
                          align: "center",
                        },
                        {
                          Header: "Amount",
                          accessor: "amount",
                          align: "center",
                        },
                        { Header: "Remove", accessor: "remove", width: "1%", align: "center" },
                      ],
                      rows: shipperDataTableValue.map((item, index) => {
                        return {
                          productCode: (
                            <VuiInput
                              name="productCode"
                              inputProps={{ type: "string" }}
                              defaultValue={item.productCode}
                              // style={{width: "auto", minWidth: "160px"}}
                              placeholder={"Marks and Numbers"}
                              value={item.productCode}
                              onChange={(e) =>
                                handleOnChangeCommodity(e.target.name, e.target.value, index)
                              }
                            />
                          ),

                          descriptionOfGoods: (
                            <VuiInput
                              name="descriptionOfGoods"
                              inputProps={{ type: "string" }}
                              defaultValue={item.descriptionOfGoods}
                              placeholder={"Description Of Goods"}
                              value={item.descriptionOfGoods}
                              onChange={(e) =>
                                handleOnChangeCommodity(e.target.name, e.target.value, index)
                              }
                            />
                          ),
                          unitQuantity: (
                            <VuiInput
                              name="unitQuantity"
                              inputProps={{ type: "number" }}
                              placeholder={"unitQty"}
                              value={item.unitQuantity}
                              onChange={(e) =>
                                handleOnChangeCommodity(e.target.name, e.target.value, index)
                              }
                            />
                          ),
                          unitType: (
                            <VuiSelect
                              name="unitType"
                              options={[
                                { value: "units", label: "Units" },
                                { value: "boxes", label: "Boxes" },
                                { value: "packages", label: "Packages" },
                                { value: "barrels", label: "Barrels" },
                                { value: "sacks", label: "Sacks" },
                                { value: "bigbags", label: "Bigbags" },
                                { value: "tons", label: "Tons" },
                                { value: "m3", label: "M3" },
                                { value: "kg", label: "Kg" },
                                { value: "lbs", label: "Lbs" },
                                { value: "ft3", label: "Ft3" },
                                { value: "litres", label: "Litres" },
                                { value: "gallons", label: "Gallons" },
                              ]}
                              value={item.unitType}
                              onChange={(e) => handleOnChangeCommodity("unitType", e, index)}
                            />
                          ),
                          price: (
                            <VuiInput
                              name="price"
                              inputProps={{ type: "number" }}
                              placeholder={"Price"}
                              value={item.price}
                              onChange={(e) =>
                                handleOnChangeCommodity(e.target.name, e.target.value, index)
                              }
                            />
                          ),
                          amount: (
                            <VuiInput
                              name="amount"
                              inputProps={{ type: "number" }}
                              placeholder={"Amount"}
                              value={item.amount}
                              // onChange={(e) =>
                              //   handleOnChangeCommodity(e.target.name, e.target.value, index)
                              // }
                            />
                          ),
                          remove: (
                            <>
                              {shipperDataTableValue && shipperDataTableValue.length > 1 ? (
                                <IconButton onClick={() => handleDeleteCommodity(index)}>
                                  <MdDelete color="#110F26" />
                                </IconButton>
                              ) : (
                                ""
                              )}
                            </>
                          ),
                        };
                      }),
                    }}
                  />
                  {shipperDataTableValue.length < 10 && (
                    <div className={"com-invo-add-data-table-row"}>
                      <VuiTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                        color="text"
                        onClick={hanldeAddDataTableRow}
                      >
                        <AddIcon size={18} />
                        Add
                      </VuiTypography>
                    </div>
                  )}
                </div>

                <div className={"com-invo-unitType-price-amount"}>
                  <div item xs={12} lg={6}>
                    <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <VuiTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                        color="text"
                      >
                        Total Amount
                      </VuiTypography>
                    </VuiBox>
                    <VuiInput
                      name="totalAmount"
                      inputProps={{ type: "number" }}
                      defaultValue={""}
                      value={totalAmount}
                      error={errorMsg.totalAmount.errorMsg.length > 0}
                      // onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                    />
                    <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                      {errorMsg.totalAmount.errorMsg}
                    </VuiTypography>
                  </div>
                </div>

                <div item xs={12} lg={6} className={"com-invo-additional-information"}>
                  <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <VuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      color="text"
                    >
                      Additional information
                    </VuiTypography>
                  </VuiBox>
                  <VuiInput
                    name="additionalInformation"
                    placeholder="Additional Information"
                    inputProps={{ type: "string" }}
                    multiline
                    rows={8}
                    value={additionalInformation}
                    error={errorMsg.additionalInformation.errorMsg.length > 0}
                    onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                  />
                  <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                    {errorMsg.additionalInformation.errorMsg}
                  </VuiTypography>
                </div>

                <div item xs={12} lg={6} className={"com-invo-place-of-issue"}>
                  <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <VuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      color="text"
                    >
                      Place of Issue
                    </VuiTypography>
                  </VuiBox>
                  <VuiSelect
                    placeholder="Select"
                    options={searchedPorts}
                    value={placeOfIssue}
                    onInputChange={(e) => findPort(e)}
                    error={errorMsg.placeOfIssue.errorMsg.length > 0}
                    onChange={(e) => handleChangeInput("placeOfIssue", e)}
                  />
                  <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                    {errorMsg.placeOfIssue.errorMsg}
                  </VuiTypography>
                </div>

                <div item xs={12} lg={6} className={"com-invo-date"}>
                  <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <VuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      color="text"
                    >
                      Date
                    </VuiTypography>
                  </VuiBox>
                  <VuiInput
                    name="date"
                    inputProps={{ type: "date", min: currentDate }}
                    placeholder={"Date"}
                    value={date}
                    error={errorMsg.date.errorMsg.length > 0}
                    onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                  />
                  <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                    {errorMsg.date.errorMsg}
                  </VuiTypography>
                </div>

                <div item xs={12} lg={6} className={"com-invo-signatory-company"}>
                  <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <VuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      color="text"
                    >
                      Signatory Company
                    </VuiTypography>
                  </VuiBox>
                  <VuiInput
                    name="signatoryCompany"
                    placeholder="Signatory company"
                    inputProps={{ type: "string" }}
                    value={signatoryCompany}
                    error={errorMsg.signatoryCompany.errorMsg.length > 0}
                    onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                  />
                  <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                    {errorMsg.signatoryCompany.errorMsg}
                  </VuiTypography>
                </div>

                <div item xs={12} lg={6} className={"com-invo-authorized-Signatory"}>
                  <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <VuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      color="text"
                    >
                      Name of Authorized Signatory
                    </VuiTypography>
                  </VuiBox>
                  <VuiInput
                    name="nameOfAuthorizedSignatory"
                    inputProps={{ type: "string" }}
                    placeholder={"Name of Authorized Signatory"}
                    value={nameOfAuthorizedSignatory}
                    error={errorMsg.nameOfAuthorizedSignatory.errorMsg.length > 0}
                    onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                  />
                  <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                    {errorMsg.nameOfAuthorizedSignatory.errorMsg}
                  </VuiTypography>
                </div>

                <div item xs={12} lg={6} className={"com-invo-bank-details"}>
                  <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <VuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      color="text"
                    >
                      Bank Details
                    </VuiTypography>
                  </VuiBox>
                  <VuiInput
                    name="bankDetails"
                    placeholder="Bank Details"
                    inputProps={{ type: "string" }}
                    multiline
                    rows={8}
                    value={bankDetails}
                    error={errorMsg.bankDetails.errorMsg.length > 0}
                    onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                  />
                  <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                    {errorMsg.bankDetails.errorMsg}
                  </VuiTypography>
                </div>

                <div item xs={12} lg={6} className={"com-invo-signature"}>
                  <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <VuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      color="text"
                    >
                      Signature
                    </VuiTypography>
                  </VuiBox>
                  <VuiBox style={{ display: "flex" }}>
                    <SignaturePad
                      ref={sigCanvas}
                      penColor="#607d8b"
                      onEnd={handleSaveSignature}
                      canvasProps={{
                        className: "com-invo-signatureCanvas",
                      }}
                    />
                    <VuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      color="text"
                      onClick={clearCanvas}
                      style={{ cursor: "pointer", marginLeft: "10px" }}
                    >
                      Clear
                    </VuiTypography>
                    {/*   <VuiTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  color="white"
                  onClick={handleShowSinature}
                >
                  Show
                </VuiTypography>
                */}
                  </VuiBox>
                </div>
              </div>
            </VuiBox>{" "}
          </>
        ) : (
          ""
        )}
        <Dialog
          sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 510 } }}
          maxWidth="xs"
          open={open}
        >
          <DialogTitle>{dialogInformation.title}</DialogTitle>
          <DialogContent dividers>
            <div item xs={12} lg={6} className="com-invo-exporter">
              <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <VuiTypography component="label" variant="caption" fontWeight="bold" color="text">
                  Company name
                </VuiTypography>
              </VuiBox>
              <VuiInput
                name="companyName"
                inputProps={{ type: "string" }}
                defaultValue={commonCompany.companyName}
                placeholder={"Company Name"}
                // value={commodityValue[0].description}
                error={errorMsg.companyName.errorMsg.length > 0}
                onChange={(e) => handleDialogInformation(e.target.name, e.target.value)}
              />
              <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                {errorMsg.companyName.errorMsg}
              </VuiTypography>
            </div>
            <div item xs={12} lg={6} className="com-invo-exporter">
              <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <VuiTypography component="label" variant="caption" fontWeight="bold" color="text">
                  Place
                </VuiTypography>
              </VuiBox>
              <VuiSelect
                placeholder="Select Place"
                options={searchedPorts}
                value={searchedPorts.find((element) => element.label === commonCompany.place)}
                onInputChange={(e) => findPort(e)}
                error={errorMsg.place.errorMsg.length > 0}
                onChange={(e) => handleDialogInformation("place", e.value)}
              />
              <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                {errorMsg.place.errorMsg}
              </VuiTypography>
            </div>
            <div item xs={12} lg={6} className="com-invo-exporter">
              <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <VuiTypography component="label" variant="caption" fontWeight="bold" color="text">
                  Address
                </VuiTypography>
              </VuiBox>
              <VuiInput
                name="address"
                inputProps={{ type: "string" }}
                defaultValue={commonCompany.address}
                sx={({ borders: { borderWidth }, palette: { inputColors } }) => ({
                  border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
                })}
                placeholder={"Address"}
                // value={commodityValue[0].description}
                error={errorMsg.address.errorMsg.length > 0}
                onChange={(e) => handleDialogInformation(e.target.name, e.target.value)}
              />
              <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                {errorMsg.address.errorMsg}
              </VuiTypography>
            </div>
            <div item xs={12} lg={6} className="com-invo-exporter">
              <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <VuiTypography component="label" variant="caption" fontWeight="bold" color="text">
                  Phone
                </VuiTypography>
              </VuiBox>
              <VuiInput
                name="phone"
                inputProps={{ type: "number" }}
                defaultValue={commonCompany.phone}
                placeholder={"Phone"}
                // value={commodityValue[0].description}
                error={errorMsg.phone.errorMsg.length > 0}
                onChange={(e) => handleDialogInformation(e.target.name, e.target.value)}
              />
              <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                {errorMsg.phone.errorMsg}
              </VuiTypography>
            </div>
            <div item xs={12} lg={6} className="com-invo-exporter">
              <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <VuiTypography component="label" variant="caption" fontWeight="bold" color="text">
                  Bank Details
                </VuiTypography>
              </VuiBox>
              <VuiInput
                name="bankDetails"
                type="string"
                multiline={true}
                rows={4}
                defaultValue={commonCompany.bankDetails}
                placeholder={"Bank Details"}
                // value={commodityValue[0].description}
                error={errorMsg.bankDetails.errorMsg.length > 0}
                onChange={(e) => handleDialogInformation(e.target.name, e.target.value)}
              />
              <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                {errorMsg.bankDetails.errorMsg}
              </VuiTypography>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleSaveDialogData}>Save</Button>
          </DialogActions>
        </Dialog>
        {showPreview ? (
          <div>
            <div style={{ marginBottom: "10px", display: "flex", justifyContent: "flex-end" }}>
              <VuiButton
                variant="gradient"
                component="span"
                color="info"
                sx={{ minWidth: "100px" }}
                onClick={handleClosePreview}
              >
                Close
              </VuiButton>
            </div>
            <div>
              <PDFViewer style={{ height: "500px", width: "100%" }}>
                <PDFDocument
                  value={{
                    logo: imgSrc,
                    exporterCompany: exporter,
                    invoiceNumber: invoiceNumber,
                    datee: datee,
                    billOfLadingNumber: billOfLadingNumber,
                    consigneeCompany: consignee,
                    reference: reference,
                    buyerReference: buyerReference,
                    buyerCompany: buyer,
                    methodOfDispatch: methodOfDispatch.value,
                    typeOfShipment: typeOfShipment.value,
                    countryOfGoods: countryOfGoods,
                    transport: transport.value,
                    voyageNo: voyageNo,
                    countryOfFinalDestination: countryOfFinalDestination,
                    portOfLoading: portOfLoading.value,
                    portOfDischarge: portOfDischarge.value,
                    termOfPayment: termOfPayment.value,
                    placeOfOrigin: placeOfOrigin,
                    dateOfDeparture: dateOfDeparture,
                    finalDestination: finalDestination,
                    marinePolicyNo: marinePolicyNo,
                    letterOfCredit: letterOfCredit,
                    totalAmount: totalAmount,
                    additionalInformation: additionalInformation,
                    placeOfIssue: placeOfIssue.value,
                    date: date,
                    signatoryCompany: signatoryCompany,
                    nameOfAuthorizedSignatory: nameOfAuthorizedSignatory,
                    signature: signature,
                    bankDetails: bankDetails,
                    shipperDataTableValue: shipperDataTableValue,
                  }}
                />
              </PDFViewer>
            </div>
          </div>
        ) : (
          ""
        )}
        <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
          <VuiButton
            variant="gradient"
            component="span"
            color="info"
            sx={{ minWidth: "100px" }}
            // onClick={handleSubmitBillOfLading}
            onClick={() => handleSubmitCommercialInvoice()}
          >
            Submit
          </VuiButton>
        </div>
      </Card>
    </DashboardLayout>
  );
}

export default CommercialInvoice;
