import React from "react";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

// @mui components
import { Card, Stack } from "@mui/material";

// Vision UI Dashboard assets
import balance from "assets/images/billing-background-balance.png";
import Graph from "assets/images/shapes/graph-billing.svg";

import palette from "assets/theme/base/colors";

// Vision UI Dashboard components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

// React icons
import { FaEllipsisH } from "react-icons/fa";
import { MdOutlineDomain } from "react-icons/md";
import VuiButton from "components/VuiButton";
import { getActivePlan } from "services/pricingService";
import { MdAccountBalance } from "react-icons/md";

const CreditBalance = () => {
  let history = useHistory();

  const [availableDoc, setAvailableDoc] = useState("0");
  const [amtUsd, setAmtUsd] = useState("");

  useEffect(() => {
    fetchActivePlan();
  }, []);

  const fetchActivePlan = async () => {
    const resp = await getActivePlan();
    console.log("resp", resp);

    if (resp.success === false) {
      setAvailableDoc("0");
    } else {
      setAvailableDoc(resp.totalDocumentCountAvailable - resp.totalDocumentCountUsed);
      setAmtUsd(resp.amountUsd);
    }
  };

  const handleUpgrade = () => {
    history.push("/btnt-docs/pricings");
  };

  return (
    <Card sx={{ padding: "30px" }}>
      <VuiBox display="flex" flexDirection="column">
        <VuiBox
          mb="32px"
          p="20px"
          display="flex"
          flexDirection="column"
          sx={{ backgroundImage: `url(${balance})`, backgroundSize: "cover", borderRadius: "18px" }}
        >
          <VuiBox display="flex" justifyContent="space-beetween" alignItems="center">
            <VuiTypography variant="caption" color="white" fontWeight="medium" mr="auto">
              Paid Amount
            </VuiTypography>
            {/* <FaEllipsisH color="white" size="18px" sx={{ cursor: "pointer" }} /> */}
          </VuiBox>
          <VuiBox display="flex" justifyContent="space-beetween" alignItems="center">
            <VuiTypography variant="h2" color="white" fontWeight="bold" mr="auto">
              {amtUsd}
            </VuiTypography>
            <VuiBox component="img" src={Graph} sx={{ width: "58px", height: "20px" }} />
          </VuiBox>
        </VuiBox>
        <VuiTypography color="text" variant="xxs" fontWeight="medium" mb="8px">
          NEWEST
        </VuiTypography>
        <VuiBox display="flex" justifyContent="space-beetween" alignItems="center">
          <Stack direction="row" spacing="10px" mr="auto">
            <VuiBox
              display="flex"
              mr="10px"
              justifyContent="center"
              alignItems="center"
              sx={{
                background: "#F2F0FE",
                borderRadius: "50%",
                width: "42px",
                height: "42px",
              }}
            >
              <MdAccountBalance size="20px" />
            </VuiBox>
            <VuiBox display="flex" flexDirection="column">
              <VuiTypography color="text" variant="button" fontWeight="medium">
                {/* Bill & Taxes */}
                Bill of Lading
              </VuiTypography>
              <VuiTypography color="text" variant="button" fontWeight="medium">
                Today, 16:36
              </VuiTypography>
            </VuiBox>
          </Stack>
          {/* <VuiTypography variant="button" color="text" fontWeight="bold">
            -$154.50
          </VuiTypography> */}
          <VuiButton color="info" variant="gradient" onClick={() => handleUpgrade()}>
            Upgrade plan
          </VuiButton>
        </VuiBox>
      </VuiBox>
    </Card>
  );
};

export default CreditBalance;
