import { useState } from "react"; // sign-up/illustration

// react-router-dom components
import { Link, Redirect } from "react-router-dom";

// @mui material components
import Switch from "@mui/material/Switch";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import VuiSwitch from "components/VuiSwitch";
import VuiButton from "components/VuiButton";
// Vision UI Dashboard custom components
import GradientBorder from "examples/GradientBorder";

// Vision UI Dashboard assets
import radialGradient from "assets/theme/functions/radialGradient";
import palette from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";
import { createUser } from "services/userService";

//import validator from "validator";
import Validations from "shared/validations";
import ValidationService from "shared/validationService";

// Images
import illustrationImage from "assets/images/illustration-image.png";

// Authentication layout components
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";

import swal from "sweetalert";

function Illustration() {
  const [redirectToSignIn, setRedirectToSignIn] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [cpassword, setCpassword] = useState("");
  const [isShowPassword, setIsShowPassword] = useState(true);
  const [isShowPassword2, setIsShowPassword2] = useState(true);

  // const [country, setCountry] = useState("");
  // const [states, setStates] = useState("");
  // const [city, setCity] = useState("");
  // const [postal, setPostal] = useState("");
  // const [contact, setContact] = useState("");

  if (redirectToSignIn) {
    return <Redirect to='/authentication/sign-in' />;
  }

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  function handleRegister() {
    console.log({
      name: firstName,
      email: email,
      password: password,
      cpassword: cpassword,
      role: "Shipper",
    });

    const customValidations = (name, value, altName=null) => {
      let errorLength = 0;
      const args = [
        {
          name,
          value: value,
          validations: Validations.signUpValidation[name],
        },
      ];
  
      ValidationService.validate(args, (validationErr, validationErrMsgs) => {
        if (validationErrMsgs.length > 0) {
          errorLength = errorLength + 1;
          swal(altName ? altName : name, validationErrMsgs[0].validations[0].message, "error");
        } else {
          //errorMsg[name].errorMsg = "";
          //swal(altName ? altName : name, validationErrMsgs[0].validations[0].message, "error");
        }
      });
      return errorLength;
    };

    if (password == "" || firstName == "" || email == "") {
      swal("", "All Fields are required", "error");
      return;
    }

    const validationErrors = customValidations("email", email, "Email")
                           + customValidations("password", password, "Password")
                           + customValidations("confirmPassword", cpassword, "Confirm Password");

    if (validationErrors > 0) {
        return;
    }
    
    if (password != cpassword) {
      swal("", "Confirm Password doesn't match", "error");
      return;
    }
    /*
    if (password == "" || firstName == "" || email == "") {
      swal("", "All Fields are required", "error");
      return;
    }
    if (!validator.isEmail(email)) {
      swal("", "Enter a correct email address", "error");
      return;
    }
    if (password != cpassword) {
      swal("", "Confirm Password doesn't match", "error");
      return;
    }
    if (!validator.isStrongPassword(cpassword)) {
      swal(
        "Need Strong Password",
        "Minimum Length: 8 AlphaNumCharacters (1 Uppercase, 1 Lowercase, and 1 Symbol)",
        "error"
      );
      return;
    }*/
    const userData = {
      name: firstName,
      email: email,
      password: password,
      cpassword: cpassword,
      role: "shipper",
    };
    createUser(userData).then((response) => {
      if (response.success) {
        swal(
          "Great!",
          " You have successfully registered! \n Check your email for verification",
          "success"
        ).then(() => {
          //window.location.href = "";
          //<Redirect from="*" to="/authentication/sign-in" />;
          setRedirectToSignIn(true);
        });
      } else {
        swal("", response.msg, "error");
      }
    });
  }

  const handleShowPassword = (type) => {
    console.log(">>>>>>>>>>>>>>>>> handle show password");
    setIsShowPassword(type);
  };

  const handleShowPassword2 = (type) => {
    console.log(">>>>>>>>>>>>>>>>> handle show password");
    setIsShowPassword2(type);
  };

  return (
    <IllustrationLayout
      title="Sign Up"
      color="text"
      description="Enter your name, email and password to sign up"
      illustration={{
        image: illustrationImage,
      }}
      top={20}
    >
      <VuiBox component="form" role="form">
        {/* Name */}
        <VuiBox mb={2}>
          <VuiBox mb={1} ml={0.5}>
            <VuiTypography component="label" variant="button" color="text" fontWeight="medium">
              Name
            </VuiTypography>
          </VuiBox>
          <GradientBorder
            minWidth="100%"
            borderRadius={borders.borderRadius.lg}
            padding="1px"
            backgroundImage={radialGradient(
              palette.gradients.borderLight.main,
              palette.gradients.borderLight.state,
              palette.gradients.borderLight.angle
            )}
          >
            <VuiInput
              placeholder="eg. Micheal"
              sx={({ typography: { size } }) => ({
                fontSize: size.sm,
              })}
              value={firstName}
              name="firstName"
              onChange={(e) => setFirstName(e.target.value)}
            />
          </GradientBorder>
        </VuiBox>

        {/* Email */}
        <VuiBox mb={2}>
          <VuiBox mb={1} ml={0.5}>
            <VuiTypography component="label" variant="button" color="text" fontWeight="medium">
              Email
            </VuiTypography>
          </VuiBox>
          <GradientBorder
            minWidth="100%"
            borderRadius={borders.borderRadius.lg}
            padding="1px"
            backgroundImage={radialGradient(
              palette.gradients.borderLight.main,
              palette.gradients.borderLight.state,
              palette.gradients.borderLight.angle
            )}
          >
            <VuiInput
              type="email"
              placeholder="eg. email@bitnautic.com"
              sx={({ typography: { size } }) => ({
                fontSize: size.sm,
              })}
              value={email}
              name="email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </GradientBorder>
        </VuiBox>

        {/* Password */}
        <VuiBox mb={2}>
          <VuiBox mb={1} ml={0.5}>
            <VuiTypography component="label" variant="button" color="text" fontWeight="medium">
              Password
            </VuiTypography>
          </VuiBox>
          <GradientBorder
            minWidth="100%"
            borderRadius={borders.borderRadius.lg}
            padding="1px"
            backgroundImage={radialGradient(
              palette.gradients.borderLight.main,
              palette.gradients.borderLight.state,
              palette.gradients.borderLight.angle
            )}
          >
            {isShowPassword ? (
              <VuiInput
                type="password"
                placeholder="Your password..."
                sx={({ typography: { size } }) => ({
                  fontSize: size.sm,
                })}
                value={password}
                name="password"
                onChange={(e) => setPassword(e.target.value)}
              />
            ) : (
              <VuiInput
                type="text"
                placeholder="Your password..."
                sx={({ typography: { size } }) => ({
                  fontSize: size.sm,
                })}
                value={password}
                name="password"
                onChange={(e) => setPassword(e.target.value)}
              />
            )}
            {isShowPassword ? (
              <VuiBox
                sx={{
                  display: "flex",
                }}
              >
                <VuiBox sx={{ cursor: "pointer" }} onClick={() => handleShowPassword(false)}>
                  <VuiTypography color="text" fontWeight="light" fontSize="0.7rem">
                    Show password
                  </VuiTypography>
                </VuiBox>
              </VuiBox>
            ) : (
              <VuiBox
                sx={{
                  display: "flex",
                }}
              >
                <VuiBox sx={{ cursor: "pointer" }} onClick={() => handleShowPassword(true)}>
                  <VuiTypography color="text" fontWeight="light" fontSize="0.7rem">
                    Hide password
                  </VuiTypography>
                </VuiBox>
              </VuiBox>
            )}
          </GradientBorder>
          <VuiTypography fontSize="0.8rem" fontWeight="light" color="text">
            Use 8 or more characters with a mix of 1 uppercase, 1 lowercase letters, numbers &
            symbols
          </VuiTypography>
        </VuiBox>

        {/* Confirm Password */}
        <VuiBox mb={2}>
          <VuiBox mb={1} ml={0.5}>
            <VuiTypography component="label" variant="button" color="text" fontWeight="medium">
              Confirm Password
            </VuiTypography>
          </VuiBox>
          <GradientBorder
            minWidth="100%"
            borderRadius={borders.borderRadius.lg}
            padding="1px"
            backgroundImage={radialGradient(
              palette.gradients.borderLight.main,
              palette.gradients.borderLight.state,
              palette.gradients.borderLight.angle
            )}
          >
            {isShowPassword2 ? (
              <VuiInput
                type="password"
                placeholder="Your password..."
                sx={({ typography: { size } }) => ({
                  fontSize: size.sm,
                })}
                value={cpassword}
                name="cpassword"
                onChange={(e) => setCpassword(e.target.value)}
              />
            ) : (
              <VuiInput
                type="text"
                placeholder="Your password..."
                sx={({ typography: { size } }) => ({
                  fontSize: size.sm,
                })}
                value={cpassword}
                name="cpassword"
                onChange={(e) => setCpassword(e.target.value)}
              />
            )}

            {isShowPassword2 ? (
              <VuiBox
                sx={{
                  display: "flex",
                }}
              >
                <VuiBox sx={{ cursor: "pointer" }} onClick={() => handleShowPassword2(false)}>
                  <VuiTypography color="text" fontWeight="light" fontSize="0.7rem">
                    Show password
                  </VuiTypography>
                </VuiBox>
              </VuiBox>
            ) : (
              <VuiBox
                sx={{
                  display: "flex",
                }}
              >
                <VuiBox sx={{ cursor: "pointer" }} onClick={() => handleShowPassword2(true)}>
                  <VuiTypography color="text" fontWeight="light" fontSize="0.7rem">
                    Hide password
                  </VuiTypography>
                </VuiBox>
              </VuiBox>
            )}
          </GradientBorder>
        </VuiBox>

        <VuiBox mt={4} mb={1}>
          <VuiButton color="info" fullWidth onClick={handleRegister}>
            SIGN UP
          </VuiButton>
        </VuiBox>
        <VuiBox mt={3} textAlign="center">
          <VuiTypography variant="button" color="text" fontWeight="regular">
            Already have an account?{" "}
            <VuiTypography
              component={Link}
              to="/authentication/sign-in"
              variant="button"
              color="text"
              fontWeight="medium"
            >
              Sign in
            </VuiTypography>
          </VuiTypography>
        </VuiBox>
      </VuiBox>
    </IllustrationLayout>
  );
}

export default Illustration;
