// @mui material components
import Card from "@mui/material/Card";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

// Billing page components
import Bill from "layouts/pages/billing-info/billing/components/Bill";

function BillingInformation() {
  return (
    <Card id="delete-account" sx={{ height: "400px" }}>
      <VuiBox>
        <VuiTypography variant="lg" color="text" fontWeight="bold">
          Billing Information
        </VuiTypography>
      </VuiBox>
      <VuiBox>
        <VuiBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          <Bill
          // name="oliver liam"
          // company="viking burrito"
          // email="oliver@burrito.com"
          // vat="FRB1235476"
          />
          {/* <Bill
            name="lucas harper"
            company="stone tech zone"
            email="lucas@stone-tech.com"
            vat="FRB1235476"
          /> */}
          {/* <Bill
            name="ethan james"
            company="fiber notion"
            email="ethan@fiber.com"
            vat="FRB1235476"
            noGutter
          /> */}
        </VuiBox>
      </VuiBox>
    </Card>
  );
}

export default BillingInformation;
