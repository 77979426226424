import { useState } from "react";

import VuiInput from "components/VuiInput";
import VuiSwitch from "components/VuiSwitch";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";
import DeleteIcon from "@mui/icons-material/Delete";
import DataTable from "examples/Tables/DataTable";
import dataTableData from "layouts/applications/data-tables/data/dataTableData";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";

function AttributeDescriptionTable({ data, setData }) {
	const handleOnAttributeDescriptionTableChanged = (targetName, targetValue, index) => {
		if ( data[index] != undefined && data[index][targetName] != undefined ) {
			data[index][targetName] = targetValue;
			setData([...data]);
		}
	}

	const handleOnAttributeDescriptionTableRemoved = (index) => {
		let newData = [...data];
		newData.splice(index, 1);
		setData([...newData]);
	}

	const handleOnAttributeDescriptionTableAdded = () => {
		setData(
			[...data, 
				{
					attribute: "",
					description: ""
				}
			]
		);
	}

	return (
		<>
			<DataTable
				showTotalEntries={false}
				entriesPerPage={false}
				table={{
					columns: [
						{
							Header: "Attribute", accessor: "attribute",
							width: "auto", align: "left"
						},
						{
							Header: "Description (name, quantity, process, weight,  location, ...)", accessor: "description",
							width: "auto", align: "left",
						},
						{
							Header: <DeleteIcon fontSize="medium" />, accessor: "remove",
							width: "auto", align: "center"
						},
					],
					rows: data.map((item, index) => {
						return {
							attribute: (
								<VuiInput
									name="attribute"
									inputProps={{ type: "string" }}
									// style={{width: "auto", minWidth: "160px"}}
									placeholder={"Enter a product's attribute here"}
									value={item.attribute}
									onChange={(e) =>
										handleOnAttributeDescriptionTableChanged(e.target.name, e.target.value, index)
									}
								/>
							),

							description: (
								<VuiInput
									name="description"
									inputProps={{ type: "string" }}
									placeholder={"Enter short description here"}
									value={item.description}
									fullwidth
									onChange={(e) =>
										handleOnAttributeDescriptionTableChanged(e.target.name, e.target.value, index)
									}
								/>
							),

							remove: (
								<>
									{data && data.length > 1 ? (
										<IconButton onClick={() => handleOnAttributeDescriptionTableRemoved(index)} style={{ color: "rgb(249 100 100)" }}>
											<DeleteIcon fontSize="medium" />
										</IconButton>
									) : (
										""
									)}
								</>
							),
						};
					}),
				}}
			/>
			{
				data.length < 10 && (
					<VuiButton
						component="span"
						variant="gradient"
						fontWeight="bold"
						color="info"
						onClick={handleOnAttributeDescriptionTableAdded}
						style={{ width: "100%" }}
					>
						<AddIcon size={18} /> Add Row
					</VuiButton>
				)
			}
		</>
	);
}



export default AttributeDescriptionTable;