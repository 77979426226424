import Default from "layouts/dashboards/default";
import CRM from "layouts/dashboards/crm";
import ProfileOverview from "layouts/pages/profile/profile-overview";
import Teams from "layouts/pages/profile/teams";
import AllProjects from "layouts/pages/profile/all-projects";
import Reports from "layouts/pages/users/reports";
import NewUser from "layouts/pages/users/new-user";
import Settings from "layouts/pages/account/settings";
import Billing from "layouts/pages/account/billing";
import Invoice from "layouts/pages/account/invoice";
import General from "layouts/pages/projects/general";
import Timeline from "layouts/pages/projects/timeline";
import Widgets from "layouts/pages/widgets";
import Charts from "layouts/pages/charts";
import Alerts from "layouts/pages/alerts";
import PricingPage from "layouts/pages/pricing-page";
import RTL from "layouts/pages/rtl";
import Kanban from "layouts/applications/kanban";
import Wizard from "layouts/applications/wizard";
import DataTables from "layouts/applications/data-tables";
import Calendar from "layouts/applications/calendar";
import NewProduct from "layouts/ecommerce/products/new-product";
import EditProduct from "layouts/ecommerce/products/edit-product";
import ProductPage from "layouts/ecommerce/products/product-page";
import OrderList from "layouts/ecommerce/orders/order-list";
import OrderDetails from "layouts/ecommerce/orders/order-details";

// Own Routes //

// auth //
// import SignIn from "layouts/pages/authentication/sign-in";
// import SignUp from "layouts/pages/authentication/sign-up";
// import userVerify from "layouts/pages/authentication/verify";

// dashboard //
import Dashboard from "layouts/pages/dashboard/crm";

// ship //
import AddShip from "layouts/pages/ship/add-ship";
import ActiveShip from "layouts/pages/ship/active-ship";
import InActiveShip from "layouts/pages/ship/inactive-ship";
import SubmitRequest from "layouts/pages/support/submit-request";
import activateShip from "layouts/pages/ship/active-ship/activateShip";

// container //
import AddContainer from "layouts/pages/container/add-container";
import ActiveContainers from "layouts/pages/container/active-containers";
import InactiveContainers from "layouts/pages/container/inactive-containers";
import LCLContainerQuoatation from "layouts/pages/container/LCL-container-quoatation";
import LCLContainerPendingQuoatation from "layouts/pages/container/LCL-container-pending-quoatation";
import FCLContainerQuoatation from "layouts/pages/container/FCL-container-quoatation";
import FCLContainerPendingQuoatation from "layouts/pages/container/FCL-container-pending-quoatation";

// smart doc //
import ProfomaInvoice from "layouts/pages/smart-document/profoma-invoice";
import CommercialInvoice from "layouts/pages/smart-document/commercial-invoice";
import Quoatation from "layouts/pages/smart-document/quotation";
// import BillofLading from "layouts/pages/smart-document/bill-of-lading";
import PurchaseOrder from "layouts/pages/smart-document/purchase-order";

// quotation //
import CompletedQuote from "layouts/pages/quotation/completed-quote";
import FclQuote from "layouts/pages/quotation/fcl-quote";
import LclQuote from "layouts/pages/quotation/lcl-quote";
import PendingQuote from "layouts/pages/quotation/pending-quote";
import ShipQuoatation from "layouts/pages/quotation/ship-quoatation";
import ShipPendingQuoatation from "layouts/pages/quotation/ship-pending-quoatation";

// React icons
import { IoDocument } from "react-icons/io5";
import { IoBuild } from "react-icons/io5";
import { IoCallSharp } from "react-icons/io5";
import {
  FaShoppingCart,
  FaShip,
  FaShippingFast,
  FaReceipt,
  FaFileInvoiceDollar,
} from "react-icons/fa";
import { IoHome } from "react-icons/io5";
import { IoDocumentText } from "react-icons/io5";
import { ImPriceTags } from "react-icons/im";
import { GrMail } from "react-icons/gr";

import GetRequest from "layouts/pages/Get-Request";

import ListBillOfLading from "layouts/pages/smart-document/list-bill-of-lading";
import ListProformaInvoice from "layouts/pages/smart-document/list-proforma-invoice";
import ListCommercialInvoice from "layouts/pages/smart-document/list-commercial-invoice";
import ListQuotation from "layouts/pages/smart-document/list-quotation";
import ListPurchaseOrder from "layouts/pages/smart-document/list-purchase-order";
import ListAgreement from "layouts/pages/smart-document/list-agreement";
import PricingList from "layouts/pages/pricing-list";

import AddBillOfLading from "layouts/pages/smart-document/add-bill-of-lading";
import InboxRecieved from "layouts/pages/smart-mail/Inbox";
import SentMail from "layouts/pages/smart-mail/Sent";
import BillingInfo from "layouts/pages/billing-info/billing";
import CertificateOfOrigin from "layouts/pages/smart-document/certificate-of-origin";
import FormBuilderPage from "layouts/pages/formBuilder";


import AddTraceableProduct from "layouts/pages/smart-document/add-traceable-product";
//import ListTraceability from "layouts/pages/smart-document/list-traceability";

const routes = [
  {
    type: "collapse",
    name: "Blockchain Docs",
    key: "btntdocs",
    icon: <IoDocumentText size="15px" color="inherit" />,
    collapse: [
      {
        name: "Bill of Lading",
        key: "list-billoflading",
        route: "/btnt-docs/list-billoflading",
        component: ListBillOfLading,
      },
      {
        name: "Proforma Invoice",
        key: "list-proforma-invoice",
        route: "/btnt-docs/list-proforma-invoice",
        component: ListProformaInvoice,
      },
      {
        name: "Commercial Invoice",
        key: "list-commercial-invoice",
        route: "/btnt-docs/list-commercial-invoice",
        component: ListCommercialInvoice,
      },
      {
        name: "Quotation",
        key: "list-quotation",
        route: "/btnt-docs/list-quotation",
        component: ListQuotation,
      },
      {
        name: "Purchase Order",
        key: "list-purchase-order",
        route: "/btnt-docs/list-purchase-order",
        component: ListPurchaseOrder,
      },
      {
        name: "Certificate Of Origin",
        key: "certificate-of-origin",
        route: "/btnt-docs/certificate-of-origin",
        component: CertificateOfOrigin,
      },
      {
        name: "Certificate Of Product",
        key: "certificate-of-product",
        route: "/btnt-docs/certificate-of-product",
        component: FormBuilderPage,
      },
      {
        name: "Agreement",
        key: "agreement",
        route: "/btnt-docs/agreement",
        component: ListAgreement,
      },
      {
        name: "Traceability",
        key: "traceability",
        route: "/btnt-docs/add-traceable-product",
        component: AddTraceableProduct,
      },
    ],
  },

  {
    type: "collapse",
    name: "Payments",
    key: "payments",
    icon: <ImPriceTags size="15px" color="inherit" />,
    collapse: [
      {
        name: "Billing",
        key: "billing",
        route: "/btnt-docs/billing",
        component: BillingInfo,
      },
    ],
  },

  {
    type: "collapse",
    name: "Blockchain Message",
    key: "blockchain-message",
    icon: <GrMail size="15px" color="inherit" />,
    collapse: [
      {
        name: "Inbox",
        key: "inbox",
        route: "/btnt-docs/inbox",
        component: InboxRecieved,
      },
      {
        name: "Sent",
        key: "sent",
        route: "/btnt-docs/sent",
        component: SentMail,
      },
    ],
  },

  {
    type: "collapse",
    name: "Dashboards",
    key: "dashboard",
    icon: <ImPriceTags size="15px" color="inherit" />,
    collapse: [
      {
        name: "Dashboard",
        key: "dashboard",
        route: "/btnt-docs/dashboard",
        component: Dashboard,
      },
    ],
  },
];

export default routes;
