const axios = require("axios");

import { baseUrl } from "./../helpers/baseUrl";

export async function getPlansList() {
  var tokenStr = localStorage.getItem("jwtTokenBitDocs");
  axios.defaults.headers.common["authorization"] = tokenStr;
  try {
    const response = await axios.get(`${baseUrl}/admin/bitdocs/plans/get`);
    console.log(response, "getPlansList");
    return response.data;
  } catch (err) {
    return err.response;
  }
}

export async function initiatePayment(paymentId) {
  console.log(paymentId, "id>>>>>>>>>>>>>");
  var tokenStr = localStorage.getItem("jwtTokenBitDocs");
  axios.defaults.headers.common["authorization"] = tokenStr;
  try {
    const response = await axios.post(`${baseUrl}/shipper/bitdocs/payment-gateway`, paymentId);
    console.log(response, "initiatePayment");
    return response.data;
  } catch (err) {
    return err.response;
  }
}

export async function getPaymentAddress() {
  var tokenStr = localStorage.getItem("jwtTokenBitDocs");
  axios.defaults.headers.common["authorization"] = tokenStr;
  try {
    const response = await axios.get(`${baseUrl}/shipper/shipment/payment/address`);
    console.log(response, "getPaymentAddress");
    return response.data;
  } catch (err) {
    return err.response;
  }
}

export async function checkPlanPayment(paymentData) {
  console.log("paymentData", paymentData);
  var tokenStr = localStorage.getItem("jwtTokenBitDocs");
  axios.defaults.headers.common["authorization"] = tokenStr;
  try {
    const response = await axios.post(`${baseUrl}/shipper/bitdocs/payment-gateway`, paymentData);
    console.log(response, "checkPlanPayment");
    return response.data;
  } catch (err) {
    return err.response.data;
  }
}

export async function getActivePlan() {
  var tokenStr = localStorage.getItem("jwtTokenBitDocs");
  axios.defaults.headers.common["authorization"] = tokenStr;
  try {
    const response = await axios.get(`${baseUrl}/shipper/bitdocs/active-order-detail`);
    // console.log(response, "getActivePlan");
    return response.data;
  } catch (err) {
    return err.response;
  }
}

export async function checkCreateDocs() {
  var tokenStr = localStorage.getItem("jwtTokenBitDocs");
  axios.defaults.headers.common["authorization"] = tokenStr;
  try {
    const response = await axios.get(`${baseUrl}/serviceProvider/smartDocuments/can-create`);
    console.log(response, "checkCreateDocs");
    return response.data;
  } catch (err) {
    return err.response;
  }
}

export async function createCheckoutSession(productData) {
  var tokenStr = localStorage.getItem("jwtTokenBitDocs");
  axios.defaults.headers.common["authorization"] = tokenStr;
  try {
    const response = await axios.post(
      `${baseUrl}/admin/bitdocs/plans/create-checkout-session`,
      productData
    );
    console.log(response, "createCheckoutSession");
    return response.data;
  } catch (err) {
    return err.response;
  }
}

export async function verifyCheckoutSession(sessionId) {
  var tokenStr = localStorage.getItem("jwtTokenBitDocs");
  axios.defaults.headers.common["authorization"] = tokenStr;
  try {
    const response = await axios.post(
      `${baseUrl}/admin/bitdocs/plans/verify-checkout-session`,
      sessionId
    );
    console.log(response, "verifyCheckoutSession");
    return response.data;
  } catch (err) {
    return err.response;
  }
}

export async function createBtntCheckoutSession(productData) {
  var tokenStr = localStorage.getItem("jwtTokenBitDocs");
  axios.defaults.headers.common["authorization"] = tokenStr;
  try {
    const response = await axios.post(
      `${baseUrl}/admin/bitdocs/plans/create-btnt-checkout`,
      productData
    );
    console.log(response, "createBtntCheckoutSession");
    return response.data;
  } catch (err) {
    return err.response;
  }
}

export async function verifyBtntCheckoutSession(verifyData) {
  var tokenStr = localStorage.getItem("jwtTokenBitDocs");
  axios.defaults.headers.common["authorization"] = tokenStr;
  try {
    const response = await axios.post(
      `${baseUrl}/admin/bitdocs/plans/verify-btnt-checkout`,
      verifyData
    );
    console.log(response, "verifyBtntCheckoutSession");
    return response.data;
  } catch (err) {
    return err.response;
  }
}
