// react-router-dom components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiBadge from "components/VuiBadge";
import VuiButton from "components/VuiButton";

// React-icons
import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";

function DefaultPricingCard({ bestValue, badge, price, specifications, action, action2 }) {
  const renderSpecifications = specifications.map(({ label, includes }) => (
    <VuiBox key={label} display="flex" alignItems="center" p={1}>
      <VuiBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="1.5rem"
        height="1.5rem"
        borderRadius="50%"
        shadow="md"
        mr={2}
      >
        <VuiTypography variant="button" color="text" sx={{ lineHeight: 0 }}>
          {includes ? (
            <AiFillCheckCircle size="22px" />
          ) : (
            <AiFillCloseCircle color="#424563" size="22px" />
          )}
        </VuiTypography>
      </VuiBox>
      <VuiTypography variant="body2" color="text">
        {label}
      </VuiTypography>
    </VuiBox>
  ));

  return (
    <Card>
      {bestValue === "Standard" ? (
        <VuiBox
          sx={{
            position: "absolute",
            marginLeft: "-35px",
            transform: "rotate(-39deg)",
          }}
        >
          <VuiBadge variant="contained" color="info" badgeContent="Save 20% off" />
        </VuiBox>
      ) : bestValue === "Advanced" ? (
        <VuiBox
          sx={{
            position: "absolute",
            marginLeft: "-35px",
            transform: "rotate(-39deg)",
          }}
        >
          <VuiBadge variant="contained" color="warning" badgeContent="Save 25% off" />
        </VuiBox>
      ) : bestValue === "Premium" ? (
        <VuiBox
          sx={{
            position: "absolute",
            marginLeft: "-35px",
            transform: "rotate(-39deg)",
          }}
        >
          <VuiBadge variant="contained" color="success" badgeContent="Save 40% off" />
        </VuiBox>
      ) : null}

      <VuiBox textAlign="center">
        <VuiBadge
          variant="basic"
          color={badge.color}
          size="sm"
          badgeContent={badge.label}
          circular
          container
        />
        {bestValue === "Enterprise" ? (
          <VuiBox>
            <VuiTypography variant="h1" color="text">
              Custom
            </VuiTypography>
          </VuiBox>
        ) : (
          <VuiBox my={1}>
            <VuiTypography display="inline" variant="h1" color="text">
              {price.currency}
            </VuiTypography>
            <VuiTypography display="inline" variant="h1" color="text">
              {price.value}
            </VuiTypography>
          </VuiBox>
        )}
      </VuiBox>
      <VuiBox>
        {renderSpecifications}
        {bestValue === "Enterprise" ? (
          <VuiBox sx={{ textAlign: "center" }}>
            <VuiButton color="info" variant="gradient">
              Contact Us
            </VuiButton>
          </VuiBox>
        ) : (
          <VuiBox>
            {action.type === "internal" ? (
              <VuiBox mt={3}>
                <VuiButton
                  // component={Link}
                  variant="gradient"
                  // to={action.route}
                  color={action.color}
                  onClick={action.fire}
                  fullWidth
                >
                  {action.label}&nbsp;
                </VuiButton>
              </VuiBox>
            ) : (
              <VuiBox mt={3}>
                <VuiButton
                  component="a"
                  variant="gradient"
                  href={action.route}
                  target="_blank"
                  rel="noreferrer"
                  color={action.color}
                  fullWidth
                >
                  {action.label}&nbsp;
                </VuiButton>
              </VuiBox>
            )}
            <VuiBox sx={{ textAlign: "center" }}>
              <VuiTypography fontWeight="bold">OR</VuiTypography>
            </VuiBox>
            {action2.type === "internal" ? (
              <VuiBox>
                <VuiButton
                  // component={Link}
                  variant="gradient"
                  // href={action2.route}
                  // to={action2.route}
                  color={action2.color}
                  onClick={action2.fire}
                  fullWidth
                >
                  {action2.label}&nbsp;
                </VuiButton>
              </VuiBox>
            ) : (
              <VuiBox>
                <VuiButton
                  component="a"
                  variant="gradient"
                  href={action2.route}
                  target="_blank"
                  rel="noreferrer"
                  color={action2.color}
                  fullWidth
                >
                  {action2.label}&nbsp;
                </VuiButton>
              </VuiBox>
            )}
          </VuiBox>
        )}
      </VuiBox>
    </Card>
  );
}

// Typechecking props for the DefaultPricingCard
DefaultPricingCard.propTypes = {
  badge: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "text",
      "warning",
      "error",
      "light",
      "dark",
    ]).isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  price: PropTypes.shape({
    currency: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  specifications: PropTypes.arrayOf(PropTypes.object).isRequired,
  action: PropTypes.shape({
    type: PropTypes.oneOf(["external", "internal"]).isRequired,
    route: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "light",
      "dark",
    ]).isRequired,
  }).isRequired,
};

export default DefaultPricingCard;
