import react from "react";
import { Page, Text, View, Document, StyleSheet, Image } from "@react-pdf/renderer";
import Picture from "./ship.png";
// import { style } from "@mui/material/node_modules/@mui/system";

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "white",
    padding: "20px",
    width: "100%",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  image: {
    flexDirection: "row",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "10px",
  },
  title: {
    flexDirection: "row",
    textAlign: "right",
    fontSize: "14px",
    display: "flex",
  },
  borderView: {
    marginTop: "10px",
    marginBottom: "10px",
    width: "100%",
  },
  border: {
    border: "1px solid #9e9e9e",
  },
});

// Create Document Component
const MyDocument = (props) => {
  console.log(">>>>>>>>>>> props.value test", props.value);
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.image}>
          {props.value.logo ? (
            <Image
              allowDangerousPath
              src={props.value.logo}
              style={{ width: "30px", height: "30px" }}
            />
          ) : (
            <Image allowDangerousPath src={Picture} style={{ width: "30px", height: "30px" }} />
          )}
          <Text style={styles.title}>Purchase Order</Text>
        </View>

        <View style={{ flexDirection: "row", width: "100%", marginTop: "20px" }}>
          <View style={{ width: "49%", marginRight: "1%" }}>
            <View style={{ flexDirection: "row", width: "100%" }}>
              <Text style={{ fontSize: "9px", color: "#757575" }}>From</Text>
            </View>
            <View style={{ flexDirection: "column", width: "100%" }}>
              <Text
                style={{
                  fontSize: "7px",
                  color: "#212121",
                  padding: "2px 0px 2px 5px",
                  marginBottom: "2px",
                }}
              >
                {props.value.fromCompany.company_name ? props.value.fromCompany.company_name : ""}
              </Text>
              <Text
                style={{
                  fontSize: "7px",
                  color: "#212121",
                  padding: "2px 0px 2px 5px",
                  marginBottom: "2px",
                }}
              >
                {props.value.fromCompany.place ? props.value.fromCompany.place : ""}
              </Text>
              <Text
                style={{
                  fontSize: "7px",
                  color: "#212121",
                  padding: "2px 0px 2px 5px",
                  marginBottom: "2px",
                }}
              >
                {props.value.fromCompany.address ? props.value.fromCompany.address : ""}
              </Text>
              <Text
                style={{
                  fontSize: "7px",
                  color: "#212121",
                  padding: "2px 0px 2px 5px",
                  marginBottom: "2px",
                }}
              >
                {props.value.fromCompany.phone ? props.value.fromCompany.phone : ""}
              </Text>
              <Text
                style={{
                  fontSize: "7px",
                  color: "#212121",
                  padding: "2px 0px 2px 5px",
                  marginBottom: "2px",
                }}
              >
                {props.value.fromCompany.bank_details ? props.value.fromCompany.bank_details : ""}
              </Text>
            </View>
          </View>
          <View style={{ width: "49%", marginLeft: "1%" }}>
            <View style={{ width: "100%", flexDirection: "row" }}>
              <View style={{ width: "48%", marginRight: "2%" }}>
                <View style={{ flexDirection: "row", width: "100%" }}>
                  <Text style={{ fontSize: "9px", color: "#757575" }}>Invoice Number</Text>
                </View>
                <View style={{ flexDirection: "row", width: "100%", minHeight: "30px" }}>
                  <Text style={{ fontSize: "7px", color: "#212121", padding: "5px" }}>
                    {props.value.invoiceNumber ? props.value.invoiceNumber : ""}
                  </Text>
                </View>
              </View>

              <View style={{ width: "48%", marginLeft: "2%" }}>
                <View style={{ flexDirection: "row", width: "100%" }}>
                  <Text style={{ fontSize: "9px", color: "#757575" }}>Date</Text>
                </View>
                <View style={{ flexDirection: "row", width: "100%", minHeight: "30px" }}>
                  <Text style={{ fontSize: "7px", color: "#212121", padding: "5px" }}>
                    {props.value.dateOfPurchaseOrder ? props.value.dateOfPurchaseOrder : ""}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>

        <View style={styles.borderView}>
          <Text style={styles.border}></Text>
        </View>

        <View style={{ flexDirection: "row", width: "100%" }}>
          <View style={{ width: "49%", marginRight: "1%" }}>
            <View style={{ flexDirection: "row", width: "100%" }}>
              <Text style={{ fontSize: "9px", color: "#757575" }}>To</Text>
            </View>
            <View style={{ flexDirection: "column", width: "100%" }}>
              <Text
                style={{
                  fontSize: "7px",
                  color: "#212121",
                  padding: "2px 0px 2px 5px",
                  marginBottom: "2px",
                }}
              >
                {props.value.toCompany.company_name ? props.value.toCompany.company_name : ""}
              </Text>
              <Text
                style={{
                  fontSize: "7px",
                  color: "#212121",
                  padding: "2px 0px 2px 5px",
                  marginBottom: "2px",
                }}
              >
                {props.value.toCompany.place ? props.value.toCompany.place : ""}
              </Text>
              <Text
                style={{
                  fontSize: "7px",
                  color: "#212121",
                  padding: "2px 0px 2px 5px",
                  marginBottom: "2px",
                }}
              >
                {props.value.toCompany.address ? props.value.toCompany.address : ""}
              </Text>
              <Text
                style={{
                  fontSize: "7px",
                  color: "#212121",
                  padding: "2px 0px 2px 5px",
                  marginBottom: "2px",
                }}
              >
                {props.value.fromCompany.phone ? props.value.fromCompany.phone : ""}
              </Text>
              <Text
                style={{
                  fontSize: "7px",
                  color: "#212121",
                  padding: "2px 0px 2px 5px",
                  marginBottom: "2px",
                }}
              >
                {props.value.toCompany.bank_details ? props.value.toCompany.bank_details : ""}
              </Text>
            </View>
          </View>

          <View style={{ width: "49%", marginLeft: "1%" }}>
            <View style={{ flexDirection: "row", width: "100%" }}>
              <Text style={{ fontSize: "9px", color: "#757575" }}>Supplier Reference</Text>
            </View>
            <View style={{ flexDirection: "row", width: "100%", minHeight: "30px" }}>
              <Text style={{ fontSize: "7px", color: "#212121", padding: "5px" }}>
                {props.value.supplierReference ? props.value.supplierReference : ""}
              </Text>
            </View>
          </View>
        </View>

        <View style={styles.borderView}>
          <Text style={styles.border}></Text>
        </View>

        <View style={{ flexDirection: "row", width: "100%", marginTop: "5px" }}>
          <View style={{ width: "100%", flexDirection: "row" }}>
            <View style={{ width: "48%", marginRight: "2%" }}>
              <View style={{ flexDirection: "row", width: "100%" }}>
                <Text style={{ fontSize: "9px", color: "#757575" }}>Port of Loading</Text>
              </View>
              <View style={{ flexDirection: "row", width: "100%", minHeight: "30px" }}>
                <Text style={{ fontSize: "7px", color: "#212121", padding: "5px" }}>
                  {props.value.portOfLoading ? props.value.portOfLoading : ""}
                </Text>
              </View>
            </View>

            <View style={{ width: "48%", marginLeft: "2%" }}>
              <View style={{ flexDirection: "row", width: "100%" }}>
                <Text style={{ fontSize: "9px", color: "#757575" }}>Port of Discharge</Text>
              </View>
              <View style={{ flexDirection: "row", width: "100%", minHeight: "30px" }}>
                <Text style={{ fontSize: "7px", color: "#212121", padding: "5px" }}>
                  {props.value.portOfDischarge ? props.value.portOfDischarge : ""}
                </Text>
              </View>
            </View>
          </View>
          <View style={{ width: "100%", flexDirection: "row" }}>
            <View style={{ width: "48%", marginRight: "2%" }}>
              <View style={{ flexDirection: "row", width: "100%" }}>
                <Text style={{ fontSize: "9px", color: "#757575" }}>Method of Dispatch</Text>
              </View>
              <View style={{ flexDirection: "row", width: "100%", minHeight: "30px" }}>
                <Text style={{ fontSize: "7px", color: "#212121", padding: "5px" }}>
                  {props.value.methodOfDispatch ? props.value.methodOfDispatch : ""}
                </Text>
              </View>
            </View>

            <View style={{ width: "48%", marginLeft: "2%" }}>
              <View style={{ flexDirection: "row", width: "100%" }}>
                <Text style={{ fontSize: "9px", color: "#757575" }}>Type of Shipment</Text>
              </View>
              <View style={{ flexDirection: "row", width: "100%", minHeight: "30px" }}>
                <Text style={{ fontSize: "7px", color: "#212121", padding: "5px" }}>
                  {props.value.typeOfShipment ? props.value.typeOfShipment : ""}
                </Text>
              </View>
            </View>
          </View>
        </View>

        {/* <View style={styles.borderView}>
          <Text style={styles.border}></Text>
        </View> */}

        <View style={{ flexDirection: "row", width: "100%", marginTop: "10px" }}>
          <View style={{ width: "15%", backgroundColor: "#607d8b", padding: "5px 0px" }}>
            <View
              style={{
                flexDirection: "row",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <Text style={{ fontSize: "9px", fontWeight: "bold", color: "white" }}>
                Product Code
              </Text>
            </View>
          </View>

          <View style={{ width: "20%", backgroundColor: "#607d8b", padding: "5px 0px" }}>
            <View
              style={{
                flexDirection: "row",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <Text style={{ fontSize: "9px", fontWeight: "bold", color: "white" }}>
                Description of Goods
              </Text>
            </View>
          </View>

          <View style={{ width: "15%", backgroundColor: "#607d8b", padding: "5px 0px" }}>
            <View
              style={{
                flexDirection: "row",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <Text style={{ fontSize: "9px", fontWeight: "bold", color: "white" }}>Unit qty</Text>
            </View>
          </View>

          <View style={{ width: "15%", backgroundColor: "#607d8b", padding: "5px 0px" }}>
            <View
              style={{
                flexDirection: "row",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <Text style={{ fontSize: "9px", fontWeight: "bold", color: "white" }}>Unit Type</Text>
            </View>
          </View>

          <View style={{ width: "15%", backgroundColor: "#607d8b", padding: "5px 0px" }}>
            <View
              style={{
                flexDirection: "row",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <Text style={{ fontSize: "9px", fontWeight: "bold", color: "white" }}>Price</Text>
            </View>
          </View>

          <View style={{ width: "15%", backgroundColor: "#607d8b", padding: "5px 0px" }}>
            <View
              style={{
                flexDirection: "row",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <Text style={{ fontSize: "9px", fontWeight: "bold", color: "white" }}>Amount</Text>
            </View>
          </View>
        </View>

        <View style={{ width: "100%", minHeight: "80px" }}>
          {props.value.goodsAndPackages &&
            props.value.goodsAndPackages.length > 0 &&
            props.value.goodsAndPackages.map((data, idx) => {
              return (
                <View
                  key={idx}
                  style={{
                    flexDirection: "row",
                    width: "100%",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                  }}
                >
                  <View style={{ width: "16.66%", marginRight: "1%" }}>
                    <View style={{ flexDirection: "row", width: "100%" }}>
                      <Text style={{ fontSize: "7px", color: "#212121", padding: "5px" }}>
                        {data.productCode ? data.productCode : ""}
                      </Text>
                    </View>
                  </View>

                  <View style={{ width: "16.66%", marginRight: "1%" }}>
                    <View style={{ flexDirection: "row", width: "100%" }}>
                      <Text style={{ fontSize: "7px", color: "#212121", padding: "5px" }}>
                        {data.descriptionOfGoods ? data.descriptionOfGoods : ""}
                      </Text>
                    </View>
                  </View>

                  <View style={{ width: "16.66%", marginRight: "1%" }}>
                    <View style={{ flexDirection: "row", width: "100%" }}>
                      <Text style={{ fontSize: "7px", color: "#212121", padding: "5px" }}>
                        {data.unitQuantity ? data.unitQuantity : ""}
                      </Text>
                    </View>
                  </View>

                  <View style={{ width: "16.66%", marginRight: "1%" }}>
                    <View style={{ flexDirection: "row", width: "100%" }}>
                      <Text style={{ fontSize: "7px", color: "#212121", padding: "5px" }}>
                        {data.unitType ? data.unitType : ""}
                      </Text>
                    </View>
                  </View>

                  <View style={{ width: "16.66%", marginRight: "1%" }}>
                    <View style={{ flexDirection: "row", width: "100%" }}>
                      <Text style={{ fontSize: "7px", color: "#212121", padding: "5px" }}>
                        {data.price ? data.price : ""}
                      </Text>
                    </View>
                  </View>

                  <View style={{ width: "16.66%", marginRight: "1%" }}>
                    <View style={{ flexDirection: "row", width: "100%" }}>
                      <Text style={{ fontSize: "7px", color: "#212121", padding: "5px" }}>
                        {data.amount ? data.amount : ""}
                      </Text>
                    </View>
                  </View>
                </View>
              );
            })}
        </View>

        <View style={styles.borderView}>
          <Text style={styles.border}></Text>
        </View>

        <View style={{ flexDirection: "row-reverse", width: "100%", marginTop: "5px" }}>
          <View style={{ width: "20%" }}>
            <View style={{ flexDirection: "row", width: "100%" }}>
              <Text style={{ fontSize: "9px", color: "#757575" }}>Total Amount:</Text>
            </View>
            <View style={{ flexDirection: "row", width: "100%", minHeight: "25px" }}>
              <Text style={{ fontSize: "7px", color: "#212121", padding: "5px" }}>
                {props.value.totalAmount ? props.value.totalAmount : ""}
              </Text>
            </View>
          </View>
        </View>

        <View style={styles.borderView}>
          <Text style={styles.border}></Text>
        </View>

        <View style={{ flexDirection: "row", width: "100%" }}>
          <View style={{ width: "49%", marginRight: "1%" }}>
            <View style={{ flexDirection: "row", width: "100%" }}>
              <Text style={{ fontSize: "9px", color: "#757575" }}>Additional information</Text>
            </View>
            <View style={{ flexDirection: "row", width: "100%", minHeight: "30px" }}>
              <Text style={{ fontSize: "7px", color: "#212121", padding: "5px" }}>
                {props.value.additionalInformation ? props.value.additionalInformation : ""}
              </Text>
            </View>

            {/* <View style={{ flexDirection: "row", width: "100%" }}>
              <Text style={{ fontSize: "9px", color: "#757575" }}>Additional information :</Text>
            </View>
            <View style={{ flexDirection: "row", width: "100%", minHeight: "30px" }}>
              

              {props.value.qrData ? (
                <Image
                  allowDangerousPath
                  src={props.value.qrData}
                  style={{ width: "100px", height: "100px" }}
                />
              ) : (
                ""
              )}
            </View> */}
          </View>
          <View style={{ width: "49%", marginLeft: "1%" }}>
            <View style={{ width: "100%", flexDirection: "row" }}>
              <View style={{ width: "48%", marginRight: "2%" }}>
                <View style={{ flexDirection: "row", width: "100%" }}>
                  <Text style={{ fontSize: "9px", color: "#757575" }}>Place of Issue</Text>
                </View>
                <View style={{ flexDirection: "row", width: "100%", minHeight: "30px" }}>
                  <Text style={{ fontSize: "7px", color: "#212121", padding: "5px" }}>
                    {props.value.placeOfIssue ? props.value.placeOfIssue : ""}
                  </Text>
                </View>
              </View>

              <View style={{ width: "48%", marginLeft: "2%" }}>
                <View style={{ flexDirection: "row", width: "100%" }}>
                  <Text style={{ fontSize: "9px", color: "#757575" }}>Date</Text>
                </View>
                <View style={{ flexDirection: "row", width: "100%", minHeight: "30px" }}>
                  <Text style={{ fontSize: "7px", color: "#212121", padding: "5px" }}>
                    {props.value.dateOfIssue ? props.value.dateOfIssue : ""}
                  </Text>
                </View>
              </View>
            </View>

            <View style={{ width: "100%" }}>
              <View style={{ flexDirection: "row", width: "100%" }}>
                <Text style={{ fontSize: "9px", color: "#757575" }}>Signatory Company</Text>
              </View>
              <View style={{ flexDirection: "row", width: "100%", height: "30px" }}>
                <Text style={{ fontSize: "7px", color: "#212121", padding: "5px" }}>
                  {props.value.signatoryCompany ? props.value.signatoryCompany : ""}
                </Text>
              </View>
            </View>
          </View>
        </View>

        <View style={{ flexDirection: "row", width: "100%" }}>
          <View style={{ width: "49%", marginRight: "1%" }}>
            <View style={{ flexDirection: "row", width: "100%" }}>
              <Text style={{ fontSize: "9px", color: "#757575" }}>Bank details</Text>
            </View>
            <View style={{ flexDirection: "row", width: "100%", height: "30px" }}>
              <Text style={{ fontSize: "7px", color: "#212121", padding: "5px" }}>
                {props.value.bankDetails ? props.value.bankDetails : ""}
              </Text>
            </View>

            <View style={{ flexDirection: "row", width: "100%" }}>
              <Text style={{ fontSize: "9px", color: "#757575" }}>Additional information :</Text>
            </View>
            <View style={{ flexDirection: "row", width: "100%", minHeight: "30px" }}>
              {props.value.qrData !== null ? (
                <Image allowDangerousPath src={props.value.qrData} style={{ width: "80px" }} />
              ) : (
                <Text style={{ fontSize: "7px", color: "#212121", padding: "5px" }}>N/A</Text>
              )}
              {props.value.verifiedImg ? (
                <Image
                  allowDangerousPath
                  src={props.value.verifiedImg}
                  style={{ width: "50px", height: "50px" }}
                />
              ) : (
                ""
              )}
            </View>
          </View>
          <View style={{ width: "49%", marginLeft: "1%" }}>
            <View style={{ width: "100%", flexDirection: "row" }}>
              <View style={{ width: "100%", marginRight: "2%" }}>
                <View style={{ flexDirection: "row", width: "100%" }}>
                  <Text style={{ fontSize: "9px", color: "#757575" }}>
                    Name of Authorized Signatory
                  </Text>
                </View>
                <View style={{ flexDirection: "row", width: "100%", height: "30px" }}>
                  <Text style={{ fontSize: "7px", color: "#212121", padding: "5px" }}>
                    {props.value.nameOfAuthorizedSignatory
                      ? props.value.nameOfAuthorizedSignatory
                      : ""}
                  </Text>
                </View>
              </View>
            </View>

            <View style={{ width: "100%" }}>
              <View style={{ flexDirection: "row", width: "100%" }}>
                <Text style={{ fontSize: "9px", color: "#757575" }}>Signature</Text>
              </View>
              <View style={{ flexDirection: "row", width: "100%", minHeight: "30px" }}>
                {/* <Text style={{ fontSize: "7px", color: "#212121", padding: "5px" }}> */}
                {props.value.signature ? (
                  <Image
                    allowDangerousPath
                    src={props.value.signature}
                    style={{ width: "100px", height: "30px" }}
                  />
                ) : (
                  <Text style={{ fontSize: "7px", color: "#212121", padding: "5px" }}>N/A</Text>
                )}
                {/* </Text> */}
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default MyDocument;
