import React from "react";
// @mui material components
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import Grid from "@mui/material/Grid";
import breakpoints from "assets/theme/base/breakpoints";
import { useHistory } from "react-router-dom";
import { GoogleMap, withScriptjs, withGoogleMap } from "react-google-maps";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";

// Vision UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import FormField from "layouts/pages/account/components/FormField";
import Footer from "examples/Footer";
import Globe from "examples/Globe";

let Map = () => {
    return <GoogleMap defaultZoom={10} defaultCenter={{ lat: 45.421532, lng: -75.697189 }} />;
};
  
const WrappedMap = withScriptjs(withGoogleMap(Map));

function activeContainerDetails(props) {
    let history = useHistory();
    let activeContainerDetails = props.location.details;
  
    let dates = (time) => {
      var d = new Date(time);
      var time = d.getUTCMonth() + 1 + " / " + d.getDate() + " / " + d.getUTCFullYear();
      return time;
    };

    let dateTime = (time) => {
      var d = new Date(time);
      var time = d.getUTCMonth() + 1 + " / " + d.getDate() + " / " + d.getUTCFullYear() + " - " + d.getHours() + " : " + d.getMinutes();
      return time;
    }

    let getTime = (time) => {
      var d = new Date(time);
      var time = "" + d.getHours() + " : " + d.getMinutes();
      return time;
    }
  
    const { values } = breakpoints;
  
    const bookShipHandler = (shipKey, shipOwner) => {
      let bookingDetail = {
        serviceProviderID: shipOwner,
        shipMmsiKey: shipKey,
        status: "Booked",
      };
      postBookShip(bookingDetail);
    };
  
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <VuiBox pt={6} pb={3}>
          <Grid container>
            <VuiBox sx={{ width: "100%", height: "60vh" }}>
              <WrappedMap
                googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyCiIgS-3TfbHNe2MJmxxn7GgXjyu7WKiNI`}
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `100%` }} />}
                mapElement={<div style={{ height: `100%` }} />}
              />
            </VuiBox>
          </Grid>
          <Grid container>
            <Grid item xs={12} lg={10}>
              <VuiBox mb={3} p={1}>
                <VuiTypography
                  variant={window.innerWidth < values.sm ? "h3" : "h2"}
                  textTransform="capitalize"
                  fontWeight="bold"
                  color="white"
                >
                  Active Container Detail
                </VuiTypography>
              </VuiBox>
            </Grid>
          </Grid>
          <Card sx={{ overflow: "visible", zIndex: 10 }}>
            <VuiBox>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <VuiTypography
                    fontSize="0.75rem"
                    color="white"
                    fontWeight="bold"
                    paddingBottom="0.3rem"
                  >
                    Container KEY : {activeContainerDetails.containerKey}
                  </VuiTypography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <VuiTypography
                    fontSize="0.75rem"
                    color="white"
                    fontWeight="bold"
                    paddingBottom="0.3rem"
                  >
                    Booking Type : {activeContainerDetails.bookingType}
                  </VuiTypography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <VuiTypography
                    fontSize="0.75rem"
                    color="white"
                    fontWeight="bold"
                    paddingBottom="0.3rem"
                  >
                    Name : {activeContainerDetails.name}
                  </VuiTypography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <VuiTypography
                    fontSize="0.75rem"
                    color="white"
                    fontWeight="bold"
                    paddingBottom="0.3rem"
                  >
                    Category : {activeContainerDetails.category}
                  </VuiTypography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <VuiTypography
                    fontSize="0.75rem"
                    color="white"
                    fontWeight="bold"
                    paddingBottom="0.3rem"
                  >
                    Capacity : {activeContainerDetails.capacity}
                  </VuiTypography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <VuiTypography
                    fontSize="0.75rem"
                    color="white"
                    fontWeight="bold"
                    paddingBottom="0.3rem"
                  >
                    Departure Time : &nbsp;&nbsp;{getTime(activeContainerDetails.departureTime)}
                  </VuiTypography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <VuiTypography
                    fontSize="0.75rem"
                    color="white"
                    fontWeight="bold"
                    paddingBottom="0.3rem"
                  >
                    Width : {activeContainerDetails.width}
                  </VuiTypography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <VuiTypography
                    fontSize="0.75rem"
                    color="white"
                    fontWeight="bold"
                    paddingBottom="0.3rem"
                  >
                    Destination Port : {activeContainerDetails.destinationPort}
                  </VuiTypography>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                  <VuiTypography
                    fontSize="0.75rem"
                    color="white"
                    fontWeight="bold"
                    paddingBottom="0.3rem"
                  >
                    Length : {activeContainerDetails.length}
                  </VuiTypography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <VuiTypography
                    fontSize="0.75rem"
                    color="white"
                    fontWeight="bold"
                    paddingBottom="0.3rem"
                  >
                    Arrival Time : &nbsp;&nbsp;{getTime(activeContainerDetails.arrivalTime)}
                  </VuiTypography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <VuiTypography
                    fontSize="0.75rem"
                    color="white"
                    fontWeight="bold"
                    paddingBottom="0.3rem"
                  >
                    Height : {activeContainerDetails.imo}
                  </VuiTypography>
                  </Grid>

                <Grid item xs={12} sm={6}>
                  <VuiTypography
                    fontSize="0.75rem"
                    color="white"
                    fontWeight="bold"
                    paddingBottom="0.3rem"
                  >
                    Current Port : {activeContainerDetails.currentPort}
                  </VuiTypography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <VuiTypography
                    fontSize="0.75rem"
                    color="white"
                    fontWeight="bold"
                    paddingBottom="0.3rem"
                  >
                    Status : {activeContainerDetails.status}
                  </VuiTypography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <VuiTypography
                    fontSize="0.75rem"
                    color="white"
                    fontWeight="bold"
                    paddingBottom="0.3rem"
                  >
                    Ship MMSIKey : {activeContainerDetails.shipMmsiKey}
                  </VuiTypography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <VuiTypography
                    fontSize="0.75rem"
                    color="white"
                    fontWeight="bold"
                    paddingBottom="0.3rem"
                  >
                    Year of build : {dates(activeContainerDetails.yearBuild)}
                  </VuiTypography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <VuiTypography
                    fontSize="0.75rem"
                    color="white"
                    fontWeight="bold"
                    paddingBottom="0.3rem"
                    align="right"
                  >
                    <VuiButton 
                        variant="gradient" 
                        color="info" 
                        size="medium" 
                        onClick={() =>  history.push({ pathname: "/merchant/active-containers"})}>
                            Go Back
                    </VuiButton>
                  </VuiTypography>
                </Grid>
                
              </Grid>
            </VuiBox>
          </Card>
        </VuiBox>
      </DashboardLayout>
    );
  }
  
  export default activeContainerDetails;