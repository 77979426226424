import React, { createRef, useEffect } from "react";

import VuiBox from "components/VuiBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { FormBuilder } from "cb-react-forms";
// import { FormGenerator } from "cb-react-forms";
// import { ReactFormBuilder } from "react-form-builder2";
// import "react-form-builder2/dist/app.css";
// import "bootstrap/dist/css/bootstrap.min.css";

// import $ from "jquery";
// window.jQuery = $;
// window.$ = $;

// require("jquery-ui-sortable");
// require("formBuilder");

// const formData = [
//   {
//     type: "header",
//     subtype: "h1",
//     label: "formBuilder in React",
//   },
//   {
//     type: "paragraph",
//     label: "This is a demonstration of formBuilder running in a React project.",
//   },
// ];

function FormBuilderPage() {
  //   const fb = createRef();
  //   useEffect(() => {
  //     $(fb.current).formBuilder({ formData });
  //   }, []);

  // const formData = [
  //   {
  //     id: "730b78fc-3816-47fe-a0a1-29be876f6a8e",
  //     element: "Header",
  //     label: {
  //       blocks: [
  //         {
  //           key: "2ba6l",
  //           text: "TestHeader",
  //           type: "unstyled",
  //           depth: 0,
  //           inlineStyleRanges: [],
  //           entityRanges: [],
  //           data: {},
  //         },
  //       ],
  //       entityMap: {},
  //     },
  //   },
  //   {
  //     id: "8e64e5b3-dfd9-4f0c-a51d-bf6be4a804a5",
  //     element: "Label",
  //     label: {
  //       blocks: [
  //         {
  //           key: "cgjof",
  //           text: "TestLabel",
  //           type: "unstyled",
  //           depth: 0,
  //           inlineStyleRanges: [],
  //           entityRanges: [],
  //           data: {},
  //         },
  //       ],
  //       entityMap: {},
  //     },
  //   },
  //   {
  //     id: "3383aa55-afe8-4c76-bd33-33d07b8fd66c",
  //     element: "Checkboxes",
  //     label: {
  //       blocks: [
  //         {
  //           key: "22f1f",
  //           text: "Select Any",
  //           type: "unstyled",
  //           depth: 0,
  //           inlineStyleRanges: [],
  //           entityRanges: [],
  //           data: {},
  //         },
  //       ],
  //       entityMap: {},
  //     },
  //     required: true,
  //     options: [{ id: "5db6fd62-d3dd-48d1-9cb8-743d5ae875b7", value: "Option1", checked: false }],
  //   },
  //   {
  //     id: "0d5d150f-344e-4ff9-9773-063d03d5ed80",
  //     element: "NumberInput",
  //     required: false,
  //     label: {
  //       blocks: [
  //         {
  //           key: "elic1",
  //           text: "123445566",
  //           type: "unstyled",
  //           depth: 0,
  //           inlineStyleRanges: [],
  //           entityRanges: [],
  //           data: {},
  //         },
  //       ],
  //       entityMap: {},
  //     },
  //     value: 0,
  //   },
  // ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox
        sx={{
          "& div.form_editor": {
            overflow: "scroll",
          },
        }}
      >
        <FormBuilder onSubmit={(data) => console.log(data)} />
        {/* <FormGenerator readOnly={true} formData={formData} /> */}
      </VuiBox>
    </DashboardLayout>
  );
}

export default FormBuilderPage;
