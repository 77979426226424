import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";

// Vision UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import TimelineList from "examples/Timeline/TimelineList";
import TimelineItem from "examples/Timeline/TimelineItem";
import { timelineDataDark, timelineDataLight } from "./data/timelineData";
import { Grid } from "@mui/material";
import { emailInboxReceived } from "services/mailService";
import Moment from "react-moment";

function TimeLine() {
  const [inbox, setInbox] = useState([]);

  // let timeStamp = (time) => {
  //   var d = new Date(time);
  //   var time = d.getTime();
  //   return time;
  // };

  useEffect(() => {
    fetchInbox();
  }, []);

  const fetchInbox = async () => {
    const resp = await emailInboxReceived();

    setInbox(resp);
  };

  console.log("inbox", inbox);

  const renderTimelineItemsLight = inbox.map((val, idx) => (
    <TimelineItem
      key={idx}
      // color={color}
      // icon={icon}
      title={val.subject}
      dateTime={<Moment format="DD/MM/YYYY, hh:mm A">{val.timesent}</Moment>}
      description={val.message}
      badges={{ color: "info", items: [val.onBlockChainDocs[0].name] }}
      lastItem={true}
    />
  ));

  const renderTimelineItemsDark = timelineDataDark.map(
    ({ color, icon, title, dateTime, description, badges, lastItem }) => (
      <TimelineItem
        key={title + color}
        color={color}
        icon={icon}
        title={title}
        dateTime={dateTime}
        description={description}
        badges={badges}
        lastItem={lastItem}
      />
    )
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox>
        <TimelineList title="Timeline Documents">{renderTimelineItemsLight}</TimelineList>

        {/* <Grid item xs={12} lg={6}>
            <TimelineList title="Timeline dark with dotted line" dark>
              {renderTimelineItemsDark}
            </TimelineList>
          </Grid> */}
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default TimeLine;
