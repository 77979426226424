const axios = require("axios");
import swal from "sweetalert";

import { baseUrl } from "./../helpers/baseUrl";

export async function createShip(data) {
  var tokenStr = localStorage.getItem("jwtTokenBitDocs");
  try {
    const response = await axios.post(`${baseUrl}/serviceProvider/ship/addShip`, data);
    if (response) {
      console.log(response);
      return response.data;
    } else {
      swal("", response.message, "error");
    }
  } catch (err) {
    console.log(err);
    console.log(err.response);
    return err.response.data;
  }
}

export async function getExpiredShips() {
  var tokenStr = localStorage.getItem("jwtTokenBitDocs");
  axios.defaults.headers.common["authorization"] = tokenStr;
  const response = await axios.get(`${baseUrl}/serviceProvider/common/expiredShip`);
  if (response) {
    return response;
  } else {
    swal("", response.message, "error");
  }
}

export async function getActiveShips() {
  var tokenStr = localStorage.getItem("jwtTokenBitDocs");
  axios.defaults.headers.common["authorization"] = tokenStr;
  const response = await axios.get(`${baseUrl}/serviceProvider/ship/getActiveShipsByUser`);
  if (response) {
    return response.data;
  } else {
    swal("", response.message, "error");
  }
}

export async function getInActiveShips() {
  var tokenStr = localStorage.getItem("jwtTokenBitDocs");
  axios.defaults.headers.common["authorization"] = tokenStr;
  const response = await axios.get(`${baseUrl}/serviceProvider/ship/getInactiveShipsByUser`);
  if (response) {
    return response.data;
  } else {
    swal("", response.message, "error");
  }
}

export async function activateShip(shipInfo) {
  try {
    const response = await axios.post(`${baseUrl}/serviceProvider/ship/activateShip`, shipInfo);
    if (response) {
      console.log(response);
      return response.data;
    } else {
      swal("", response.message, "error");
    }
  } catch (err) {
    console.log(err);
    console.log(err.response);
    console.log(err.response.data);
    return err.response.data;
    // if (err.message === "Network Error") {
    //   swal("Server is not responding", "Try again!", "error");
    // }
    // if (err.response) {
    //   if (err.response && err.response.data.msg.includes("record")) {
    //     swal("Something went wrong", "Try again!", "error");
    //   }
    //   if (err.response && err.response.data.msg.includes("status")) {
    //     swal("Problem in updating ship statud", "Try again!", "error");
    //   }
    //   if (err.response && err.response.data.msg.includes("fail")) {
    //     swal("Something went wrong in ship activation", "Try again!", "error");
    //   }
    //   if (err.response && err.response.data.msg.includes("already")) {
    //     swal("You already activated this ship", "Try again!", "error");
    //   }
    // }
  }
}

export async function getPorts(ports) {
  const response = await axios.get(`${baseUrl}/ports/getPorts?q=${ports}&limit=10`);
  if (response) {
    return response.data;
  } else {
    swal("", response.message, "error");
  }
}

export async function getUserDetails(id) {
  var tokenStr = localStorage.getItem("jwtTokenBitDocs");
  axios.defaults.headers.common["authorization"] = tokenStr;
  const response = await axios.get(`${baseUrl}/user/auth/getUser?id=${id}`);
  if (response) {
    return response.data;
  } else {
    swal("", response.message, "error");
  }
}

export async function updateInvoiceShipStatus(data) {
  var tokenStr = localStorage.getItem("jwtTokenBitDocs");
  const response = await axios.post(
    `${baseUrl}/serviceProvider/ship/updateInvoiceShipStatus`,
    data
  );
  if (response) {
    return response;
  } else {
    swal("", response.message, "error");
  }
}
