

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Vision UI Dashboard PRO React components
import VuiTypography from "components/VuiTypography";

function DefaultCell({ children }) {
  return (
    <VuiTypography variant="button" color="white" fontWeight="medium">
      {children}
    </VuiTypography>
  );
}

// Typechecking props for the DefaultCell
DefaultCell.propTypes = {
  children: PropTypes.string.isRequired,
};

export default DefaultCell;
