import VuiBox from "components/VuiBox";
// import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React from "react";
import { useState } from "react";
import axios from "axios";
import VuiButton from "components/VuiButton";

function PaymentForm() {
  const [success, setSuccess] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const CARD_OPTIONS = {
    iconStyle: "solid",
    style: {
      base: {
        iconColor: "#c4f0ff",
        color: "#c4f0ff",
        fontWeight: 500,
        fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
        fontSize: "16px",
        fontSmoothing: "antialiased",
        ":-webkit-autofill": { color: "#c4f0ff" },
        "::placeholder": { color: "#c4f0ff" },
      },
      invalid: {
        iconColor: "#ffc7ee",
        color: "#ffc7ee",
      },
    },
  };

  const handleSubmit = async () => {
    const [error, paymentMethod] = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });

    if (!error) {
      try {
        const { id } = paymentMethod;
        const res = await axios.post("http://localhost:3000/", {
          amount: "1000",
          id,
        });

        if (res.data.success) {
          console.log("successful payment");
          setSuccess(true);
        }
      } catch (err) {
        console.log("err", err);
      }
    } else {
      console.log("error", error.message);
    }
  };

  return (
    // <DashboardLayout>
    //   <DashboardNavbar />
    <>
      {!success ? (
        <form onSubmit={handleSubmit}>
          <fieldset>
            <VuiBox>
              <CardElement options={CARD_OPTIONS} />
            </VuiBox>
          </fieldset>
          <VuiButton>Pay</VuiButton>
        </form>
      ) : (
        <div>
          <h2>Payment Done</h2>
        </div>
      )}
    </>
    // <VuiBox>Stripe</VuiBox>
    // </DashboardLayout>
  );
}

export default PaymentForm;
