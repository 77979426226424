// @mui material components
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Icon from "@mui/material/Icon";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import HelpIcon from "@mui/icons-material/Help";
import { AiFillStar } from "react-icons/ai";
import { MdModeEdit } from "react-icons/md";

// Vision UI Dashboard PRO React components
import VuiButton from "components/VuiButton";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

// Custom styles for the SidenavCard
import { card, cardContent, cardIconBox, cardIcon } from "examples/Sidenav/styles/sidenavCard";

// Vision UI Dashboard PRO React context
import { useVisionUIController, setOpenCompose } from "context";
import colors from "assets/theme/base/colors";

function SidenavCard() {
  const [controller, dispatch] = useVisionUIController();
  const { miniSidenav, sidenavColor, transparentSidenav, openCompose } = controller;
  const { info } = colors;

  const handleCompose = () => setOpenCompose(dispatch, !openCompose);

  return (
    <VuiBox>
      {/* <VuiBox
          bgColor="white"
          width="2rem"
          height="2rem"
          borderRadius="md"
          shadow="md"
          mb={2}
          sx={cardIconBox}
        >
          <MdModeEdit color={info.main} />
        </VuiBox> */}
      <VuiBox lineHeight={1}>
        {/* <VuiTypography variant="h6" color="white">
            Need help?
          </VuiTypography> */}
        <VuiBox mb={1.825} mt={-1}>
          {/* <VuiTypography variant="caption" color="white" fontWeight="regular">
              Please check our docs
            </VuiTypography> */}
        </VuiBox>
        <VuiButton
          color="info"
          variant="contained"
          // component={Link}
          // href="/submit-request/"
          // target="_blank"
          // rel="noreferrer"
          size="large"
          style={{width: "98%"}}
          onClick={() => handleCompose()}
          // sx={({ palette: { gradients, white }, functions: { linearGradient } }) => ({
          //   color: `${white.main} !important`,
          //   background: linearGradient(
          //     gradients.cardDark.main,
          //     gradients.cardDark.state,
          //     gradients.cardDark.deg
          //   ),
          //   "&:hover": {
          //     background: linearGradient(
          //       gradients.cardDark.main,
          //       gradients.cardDark.state,
          //       gradients.cardDark.deg
          //     ),
          //   },
          // })}
          // fullWidth
        >
          <MdModeEdit /> Compose
        </VuiButton>
      </VuiBox>
    </VuiBox>
  );
}

export default SidenavCard;
