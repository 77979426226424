import { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
// import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";
// Vision UI Dashboard PRO React components
// import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiSelect from "components/VuiSelect";
import VuiDatePicker from "components/VuiDatePicker";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
// import VuiButton from "components/VuiButton";
import VuiInput from "components/VuiInput";
import VuiSwitch from "components/VuiSwitch";
// Settings page components
import FormField from "layouts/pages/account/components/FormField";
// Data
import selectData from "layouts/pages/ship/add-ship/data/selectData";

import { createShip } from "services/shipService";
import { getPorts } from "services/shipService";
import "layouts/pages/smart-document/add-bill-of-lading/bill-of-lading.css";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { MdDelete } from "react-icons/md";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import Stack from "@mui/material/Stack";
import AddIcon from "@mui/icons-material/Add";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
import { PDFDownloadLink } from "@react-pdf/renderer";
// import PDFDocument from "./pdfDocument";
import SignaturePad from "react-signature-canvas";
import ReactPDF from "@react-pdf/renderer";
import jsPDF from "jspdf";
import { addBillOfLading, addCompany, getCompany } from "services/documentService";

import { addSmartDocuments } from "services/documentService";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { PDFViewer } from "@react-pdf/renderer";
import Validations from "shared/validations";
import ValidationService from "shared/validationService";
import Swal from "sweetalert2";
import Ship from "./ship.png";
import { getBillOfLadingById } from "services/documentService";
import { editBillOfLading } from "services/documentService";
import VuiDropzone from "components/VuiDropzone";

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];

// Create styles
const pdfStyles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#E4E4E4",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
});

const Input = styled("input")({
  display: "none",
});

let currentDate = new Date();

// currentDate= `${currentDate.getDate() <= 9 ? "0" + currentDate.getDate() : currentDate.getDate()}-${
//   currentDate.getMonth() + 1 <= 9 ? "0" + (currentDate.getMonth() + 1) : currentDate.getMonth() + 1
// }-${currentDate.getFullYear()}`;

currentDate = `${currentDate.getFullYear()}-${
  currentDate.getMonth() + 1 <= 9 ? "0" + (currentDate.getMonth() + 1) : currentDate.getMonth() + 1
}-${currentDate.getDate() <= 9 ? "0" + currentDate.getDate() : currentDate.getDate()}`;
// console.log(">>>>>>>>>>>>>>> current date", currentDate);

function AddAgreement() {
  let history = useHistory();
  const [errorMsg, setErrorMsg] = useState({
    companyName: { errorMsg: "" },
    place: { errorMsg: "" },
    address: { errorMsg: "" },
    phone: { errorMsg: "" },
    bankDetails: { errorMsg: "" },
    shipper: { errorMsg: "" },
    consignee: { errorMsg: "" },
    carrierReference: { errorMsg: "" },
    shipperReference: { errorMsg: "" },
    shipperReferenceSecond: { errorMsg: "" },
    uniqueConsignmentReference: { errorMsg: "" },
    carrierName: { errorMsg: "" },
    notifyParty: { errorMsg: "" },
    addNotifyParty: { errorMsg: "" },
    preCarriageBy: { errorMsg: "" },
    placeOfReceipt: { errorMsg: "" },
    additionalInformation: { errorMsg: "" },
    transport: { errorMsg: "" },
    voyageNo: { errorMsg: "" },
    placeOfDelivery: { errorMsg: "" },
    portOfLoading: { errorMsg: "" },
    portOfDischarge: { errorMsg: "" },
    totalNetWeight: { errorMsg: "" },
    totalGrossWeight: { errorMsg: "" },
    totalVolume: { errorMsg: "" },
    placeOfIssue: { errorMsg: "" },
    date: { errorMsg: "" },
    signatoryCompany: { errorMsg: "" },
    nameOfAuthorizedSignatory: { errorMsg: "" },
    additionalInformationSecond: { errorMsg: "" },
    bankDetails: { errorMsg: "" },
    signature: { errorMsg: "" },
  });

  // let stateRandomNumber = Math.random();
  //New Filed for Bill of lading
  const [billId, setBillId] = useState("");
  const [showPreview, setShowPreview] = useState(false);
  const [open, setOpen] = useState(false);
  const [logo, setLogo] = useState(Ship);
  const [imgSrc, setImgsrc] = useState(Ship);
  const [commonCompany, setCommonCompany] = useState({
    companyName: "",
    place: "",
    address: "",
    phone: "",
    bankDetails: "",
  });
  const [shipper, setShipper] = useState({
    companyName: "",
    place: "",
    address: "",
    phone: "",
    bankDetails: "",
  });
  const [shipperReference, setShipperReference] = useState("");
  const [shipperReferenceSecond, setShipperReferenceSecond] = useState("");
  const [carrierReference, setCarrierReference] = useState("");
  const [uniqueConsignmentReference, setUniqueConsignmentReference] = useState();
  const [consignee, setConsignee] = useState({
    companyName: "",
    place: "",
    address: "",
    phone: "",
    bankDetails: "",
  });
  const [carrierName, setCarrierName] = useState({
    companyName: "",
    place: "",
    address: "",
    phone: "",
    bankDetails: "",
  });
  const [notifyParty, setNotifyParty] = useState({
    companyName: "",
    place: "",
    address: "",
    phone: "",
    bankDetails: "",
  });
  const [addNotifyParty, setAddNotifyParty] = useState({
    companyName: "",
    place: "",
    address: "",
    phone: "",
    bankDetails: "",
  });
  const [preCarriageBy, setpreCarriageBy] = useState("");
  const [placeOfReceipt, setPlaceOfReceipt] = useState("");
  const [additionalInformation, setAdditionalInformation] = useState("");
  const [transport, setTransport] = useState("");
  const [voyageNo, setVoyageNo] = useState("");
  const [placeOfDelivery, setPlaceOfDelivery] = useState("");
  const [portOfLoading, setPortOfLoading] = useState("");
  const [portOfDischarge, setPortOfDischarge] = useState("");
  const [totalNetWeight, setTotalNetWeight] = useState("");
  const [totalGrossWeight, setTotalGrossWeight] = useState("");
  const [totalVolume, setTotalVolume] = useState("");
  const [additionalInformationSecond, setAdditionalInformationSecond] = useState("");
  const [placeOfIssue, setPlaceOfIssue] = useState("");
  const [date, setDate] = useState(currentDate);
  const [signatoryCompany, setSignatoryCompany] = useState("");
  const [nameOfAuthorizedSignatory, setNameOfAuthorizedSignatory] = useState("");
  const [shipperDataTableValue, setShipperDataTableValue] = useState([
    {
      // customId: stateRandomNumber,
      marksAndNumbers: "",
      noOfPackages: "",
      descOfGoods: "",
      netWeight: "",
      grossWeight: "",
      measurements: "",
    },
  ]);

  const [signature, setSignature] = useState("");
  const [dialogInformation, setDialogInformation] = useState({
    title: "",
    type: "shipper",
  });

  const [imageURL, setImageURL] = useState(null);
  const sigCanvas = useRef({});
  const [searchedPorts, setSearchedPorts] = useState([]);
  const [company, setCompany] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [update, setUpdate] = useState(false);

  // useEffect(() => {
  //   fetchVerifyDocs();
  // }, []);

  // const fetchVerifyDocs = async () => {
  //   const verifyRes = await getVerifyDocs();

  //   if (verifyRes.success === false) {
  //     history.goBack();
  //     Swal.fire("You've reached limit as per your plan.", "Buy a new plan", "warning");
  //   }
  // };

  useEffect(() => {
    handleGetCompany();
  }, [shipperDataTableValue]);

  const customValidations = (name, value) => {
    let errorLength = 0;
    const args = [
      {
        name,
        value: value,
        validations: Validations.proformaInvoiceValidations[name],
      },
    ];

    ValidationService.validate(args, (validationErr, validationErrMsgs) => {
      if (validationErrMsgs.length > 0) {
        errorLength = errorLength + 1;
        errorMsg[name].errorMsg = validationErrMsgs[0].validations[0].message;
        setErrorMsg({ ...errorMsg });
      } else {
        errorMsg[name].errorMsg = "";
        setErrorMsg({ ...errorMsg });
      }
    });
    return errorLength;
  };

  const handleChangeInput = (name, value) => {
    if (name === "shipper") {
      setShipper(value);
    } else if (name === "consignee") {
      setConsignee(value);
    } else if (name === "carrierName") {
      setCarrierName(value);
    } else if (name === "notifyParty") {
      setNotifyParty(value);
    } else if (name === "addNotifyParty") {
      setAddNotifyParty(value);
    } else if (name === "uniqueConsignmentReference") {
      setUniqueConsignmentReference(value);
    } else if (name === "shipperReference") {
      setShipperReference(value);
    } else if (name === "shipperReferenceSecond") {
      setShipperReferenceSecond(value);
    } else if (name === "carrierReference") {
      setCarrierReference(value);
    } else if (name === "preCarriageBy") {
      setpreCarriageBy(value);
    } else if (name === "placeOfReceipt") {
      setPlaceOfReceipt(value);
    } else if (name === "additionalInformation") {
      setAdditionalInformation(value);
    } else if (name === "additionalInformationSecond") {
      setAdditionalInformationSecond(value);
    } else if (name === "transport") {
      setTransport(value);
    } else if (name === "voyageNo") {
      setVoyageNo(value);
    } else if (name === "placeOfDelivery") {
      setPlaceOfDelivery(value);
    } else if (name === "portOfLoading") {
      setPortOfLoading(value);
    } else if (name === "portOfDischarge") {
      setPortOfDischarge(value);
    } else if (name === "additionalInformation") {
      setAdditionalInformation(value);
    } else if (name === "placeOfIssue") {
      setPlaceOfIssue(value);
    } else if (name === "date") {
      setDate(value);
    } else if (name === "signatoryCompany") {
      setSignatoryCompany(value);
    } else if (name === "nameOfAuthorizedSignatory") {
      setNameOfAuthorizedSignatory(value);
    } else if (name === "bankDetails") {
      setBankDetails(value);
    }
  };

  useEffect(() => {
    if (window.location.search !== "") {
      let queryString = window.location.search;
      let urlParams = new URLSearchParams(queryString);
      let id = urlParams.get("id");
      setBillId(id);
      // console.log(id);

      getBillOfLadingById(id).then((resp) => {
        console.log("resp", resp);
        resp[0].shipperCompanyData[0].label = resp[0].shipperCompanyData[0].company_name;
        resp[0].consigneeCompanyData[0].label = resp[0].consigneeCompanyData[0].company_name;
        resp[0].carrierNameCompanyData[0].label = resp[0].carrierNameCompanyData[0].company_name;
        resp[0].notifyPartyCompanyData[0].label = resp[0].notifyPartyCompanyData[0].company_name;
        resp[0].additionalNotifyPartyCompanyData[0].label =
          resp[0].additionalNotifyPartyCompanyData[0].company_name;
        // resp[0].goodsAndPackages[unitType].label = resp[0].toCompanyDataId[0].company_name;
        resp[0].goodsAndPackages.forEach((element) => {
          element.unitType = { value: element.unitType, label: element.unitType };
        });
        setShipper(resp[0].shipperCompanyData[0]);
        setConsignee(resp[0].consigneeCompanyData[0]);
        setCarrierName(resp[0].carrierNameCompanyData[0]);
        setNotifyParty(resp[0].notifyPartyCompanyData[0]);
        setAddNotifyParty(resp[0].additionalNotifyPartyCompanyData[0]);

        setShipperReference(resp[0].shipper_reference_one);
        setShipperReferenceSecond(resp[0].shipper_reference_two);
        setCarrierReference(resp[0].carrierReference);
        setUniqueConsignmentReference(resp[0].unique_consignment_reference);
        setpreCarriageBy(resp[0].pre_carriage_by);
        setPlaceOfReceipt({ value: resp[0].place_of_reciept, label: resp[0].place_of_reciept });
        setAdditionalInformation(resp[0].additional_information);

        setTransport({ value: resp[0].transport, label: resp[0].transport });
        setVoyageNo(resp[0].voyage_no);
        setPlaceOfDelivery({ value: resp[0].place_of_delivery, label: resp[0].place_of_delivery });
        setPortOfLoading({ value: resp[0].port_of_landing, label: resp[0].port_of_landing });
        setPortOfDischarge({ value: resp[0].port_of_discharge, label: resp[0].port_of_discharge });
        setShipperDataTableValue(resp[0].goodsAndPackages);

        setTotalNetWeight(resp[0].total_net_weight);
        setTotalGrossWeight(resp[0].total_gross_weight);
        setTotalVolume(resp[0].total_volume);
        setPlaceOfIssue({ value: resp[0].place_of_issue, label: resp[0].place_of_issue });
        setAdditionalInformationSecond(resp[0].additional_info);

        setSignatoryCompany(resp[0].signatory_company);
        setLogo(resp[0].logo_url);
        setImgsrc(resp[0].logo_url);
        setNameOfAuthorizedSignatory(resp[0].nameOfAuthorizedSignatory);

        getBase64FromUrl(resp[0].signature_url).then((base) => {
          // console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", base);

          setSignature(base);
        });

        setUpdate(true);
      });
    } else {
      console.log("emptyurl");
    }
  }, []);

  const getBase64FromUrl = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
  };
  // console.log("SD", shipperDataTableValue.length);

  const hanldeAddDataTableRow = () => {
    let totalLength = shipperDataTableValue.length;
    console.log("SD", shipperDataTableValue);
    // let randomNumber = Math.random();
    console.log(">>>>>>>>>>>>>> totalLength", totalLength);
    shipperDataTableValue.push({
      // customId: randomNumber,
      marksAndNumbers: "",
      noOfPackages: "",
      descOfGoods: "",
      netWeight: "",
      grossWeight: "",
      measurements: "",
    });

    setShipperDataTableValue([...shipperDataTableValue]);
  };

  const totalAmountAdder = (tableData) => {
    let sumNet = 0;
    let sumGross = 0;
    let sumVol = 0;
    tableData.forEach((obj) => {
      console.log("obj", obj);
      console.log("test>>>>", obj.netWeight);
      sumNet += Number(obj.netWeight);
      sumGross += Number(obj.grossWeight);
      sumVol += Number(obj.measurements);
      setTotalNetWeight(sumNet);
      setTotalGrossWeight(sumGross);
      setTotalVolume(sumVol);
    });
    // tableData.forEach((obj) => {
    //   console.log("test>>>>", obj.amount);
    //   sumGross += parseInt(obj.grossWeight);
    //   setTotalGrossWeight(sumGross);
    // });
    // tableData.forEach((obj) => {
    //   console.log("test>>>>", obj.amount);
    //   sumVol += parseInt(obj.measurements);
    //   setTotalVolume(sumVol);
    // });
  };

  const handleDeleteCommodity = (id) => {
    // console.log(">>>>>>>>>>>>>>>>>>>>. delete id", value);
    console.log(">>>>>>>>>>>>>>> shipper datatable value", shipperDataTableValue);
    console.log(">>>>>>>>>>>>>>> delete index", id);
    // let indexNumber = shipperDataTableValue.findIndex((element) => element.customId == value)
    let newShipperData = [...shipperDataTableValue];
    newShipperData.splice(id, 1);
    console.log(">>>>>>>>>>>>. new shipper data", newShipperData);
    setShipperDataTableValue([...newShipperData]);

    totalAmountAdder(newShipperData);
  };

  const handleOnChangeCommodity = (name, value, id) => {
    if (name === "marksAndNumbers") {
      shipperDataTableValue[id].marksAndNumbers = value;
    } else if (name === "noOfPackages") {
      shipperDataTableValue[id].noOfPackages = value;
    } else if (name === "descOfGoods") {
      shipperDataTableValue[id].descOfGoods = value;
    } else if (name === "netWeight") {
      shipperDataTableValue[id].netWeight = value;
    } else if (name === "grossWeight") {
      shipperDataTableValue[id].grossWeight = value;
    } else if (name === "measurements") {
      shipperDataTableValue[id].measurements = value;
    }

    totalAmountAdder(shipperDataTableValue);

    setShipperDataTableValue([...shipperDataTableValue]);
  };

  const handleGetCompany = () => {
    getCompany().then((response) => {
      let newResponse = [];
      if (response.length > 0) {
        for (let data of response) {
          newResponse.push({
            ...data,
            label: data.company_name,
          });
        }
      }
      setCompany(newResponse);
      // console.log(">>>>>>>>>>>> get Company new response", newResponse);
    });
  };

  const findPort = (e) => {
    if (e != undefined || e != "") {
      if (e.length < 2) return;
      getPorts(e).then((response) => {
        const resp = response.map((data) => {
          data.label = `${data.portName} (${data.unloCode}) (${data.countryName})`;
          data.value = data.portName;

          return data;
        });

        const newRes = resp;

        console.log(resp, "resp");
        console.log(response);
        console.log(newRes);
        setSearchedPorts(resp);
      });
    }
  };

  const handleUploadLogo = (e) => {
    console.log(">>>>>>>>>>>>>> upload file", e.target.files[0]);
    setLogo(e.target.files[0]);
    var file = e.target.files[0];
    var reader = new FileReader();
    var url = reader.readAsDataURL(file);

    reader.onloadend = function (e) {
      setImgsrc(reader.result);
    };
  };

  // const handleDeleteLogo = () => {
  //   setImgsrc("");
  // };

  const handleAddShipper = (type) => {
    if (type === "shipper") {
      setDialogInformation({
        title: "Add Shipper",
        type: "shipper",
      });
    } else if (type === "consignee") {
      setDialogInformation({
        title: "Add consignee",
        type: "consignee",
      });
    } else if (type === "carrierName") {
      setDialogInformation({
        title: "Add carrierName",
        type: "carrierName",
      });
    } else if (type === "notifyParty") {
      setDialogInformation({
        title: "Add notifyParty",
        type: "notifyParty",
      });
    } else if (type === "addNotifyParty") {
      setDialogInformation({
        title: "Add addNotifyParty",
        type: "addNotifyParty",
      });
    }

    setOpen(true);
  };

  const handleClose = () => {
    setDialogInformation({
      title: "",
      type: "shipper",
    });
    setCommonCompany({
      companyName: "",
      place: "",
      address: "",
      phone: "",
      bankDetails: "",
    });
    setOpen(false);
  };

  const handleSaveDialogData = () => {
    let errorLengthCompanyName = customValidations("companyName", commonCompany.companyName);
    let errorLengthPlace = customValidations("place", commonCompany.place);
    let errorLengthAddress = customValidations("address", commonCompany.address);
    let errorLengthPhone = customValidations("phone", commonCompany.phone);
    let errorLengthbankDetails = customValidations("bankDetails", commonCompany.bankDetails);

    if (
      errorLengthCompanyName !== 1 &&
      errorLengthPlace !== 1 &&
      errorLengthAddress !== 1 &&
      errorLengthPhone !== 1 &&
      errorLengthbankDetails !== 1
    ) {
      console.log("all ok ");
    } else {
      console.log("not ok");

      return;
    }

    // let errorLength = 0;
    let type = dialogInformation.type;
    // console.log(
    //   "conditions--------",
    //   commonCompany.companyName !== "",
    //   commonCompany.place !== "",
    //   commonCompany.address !== "",
    //   "" + commonCompany.phone !== "",
    //   "" + commonCompany.bankDetails !== "",
    //   commonCompany
    // );
    // if (
    //   commonCompany.companyName !== "" &&
    //   commonCompany.place !== "" &&
    //   commonCompany.address !== "" &&
    //   "" + commonCompany.phone !== "" &&
    //   "" + commonCompany.bankDetails !== ""
    // ) {
    //   errorLength = 1;
    // }

    addCompany({ ...commonCompany })
      .then((response) => {
        if (type == "shipper") {
          setShipper({ ...commonCompany });
        } else if (type == "consignee") {
          setConsignee({ ...commonCompany });
        } else if (type == "carrierName") {
          setCarrierName({ ...commonCompany });
        } else if (type == "notifyParty") {
          setNotifyParty({ ...commonCompany });
        } else if (type == "addNotifyParty") {
          setAddNotifyParty({ ...commonCompany });
        }
        handleAddCompany();
      })
      .catch((error) => {
        console.log("add company error", error);
      });
  };

  const handleAddCompany = () => {
    handleGetCompany();
    handleClose();
    setCommonCompany({
      companyName: "",
      place: "",
      address: "",
      phone: "",
      bankDetails: "",
    });
    Swal.fire("", "Company added successfully", "success");
  };

  const handleDialogInformation = (name, value) => {
    console.log(">>>>>>>>>>>>>> handleDialogInformation", name, value);
    setCommonCompany({ ...commonCompany, [name]: value });
  };

  const clearCanvas = () => {
    sigCanvas.current.clear();
    setSignature("");
  };

  const handleSaveSignature = () => {
    setSignature(sigCanvas.current.toDataURL("image/png"));
  };

//   const showSignature = () => {
//     sigCanvas.current?.fromDataURL(signature);
//   };

//   useEffect(() => {
//     showSignature();
//   }, [showPreview, signature]);

  const handleSavePDF = () => {
    ReactPDF.render(
      <PDFDocument
        value={{
          logo: imgSrc,
          shipperComapny: shipper,
          shipperReference: shipperReference,
          shipperReferenceSecond: shipperReferenceSecond,
          carrierReference: carrierReference,
          uniqueConsignmentReference: uniqueConsignmentReference,
          consigneeCompany: consignee,
          carrierCompanyName: carrierName,
          notifyPartyCompanyName: notifyParty,
          addNotifyPartyCompanyName: addNotifyParty,
          preCarriageBy: preCarriageBy,
          placeOfReceipt: placeOfReceipt.value,
          additionalInformation: additionalInformation,
          transport: transport.value,
          voyageNo: voyageNo,
          placeOfDelivery: placeOfDelivery.value,
          portOfLoading: portOfLoading.value,
          portOfDischarge: portOfDischarge.value,
          totalNetWeight: totalNetWeight,
          totalGrossWeight: totalGrossWeight,
          totalVolume: totalVolume,
          additionalInformationSecond: additionalInformationSecond,
          placeOfIssue: placeOfIssue.value,
          date: date,
          signatoryCompany: signatoryCompany,
          nameOfAuthorizedSignatory: nameOfAuthorizedSignatory,
          signature: signature,
          shipperDataTableValue: shipperDataTableValue,
        }}
      />,
      `${__dirname}/example.pdf`
    );
  };

  const handleShowPreview = () => {
    if (showPreview) {
      setShowPreview(false);
    } else {
      setShowPreview(true);
    }
  };

  const handleClosePreview = () => {
    setShowPreview(false);
  };

  const handleCheckedDataTableValue = () => {
    let error = false;
    for (let data of shipperDataTableValue) {
      if (
        !error &&
        (data.marksAndNumbers === "" ||
          data.noOfPackages === "" ||
          data.descOfGoods === "" ||
          data.grossWeight === "" ||
          data.measurements === "" ||
          data.netWeight === "")
      ) {
        console.log(">>>>>>>>>>>>>>>>if error");
        error = true;
      }
    }
    console.log(">>>>>>>>>>>>> error", error);
    return error;
  };

  // const handleSubmitSmartDocument = () => {
  //   let tableError = handleCheckedDataTableValue();
  //   if (
  //     imgSrc !== "" &&
  //     shipper.companyName !== "" &&
  //     shipperReference !== "" &&
  //     shipperReferenceSecond !== "" &&
  //     carrierReference !== "" &&
  //     uniqueConsignmentReference !== "" &&
  //     consignee.companyName !== "" &&
  //     carrierName.companyName !== "" &&
  //     notifyParty.companyName !== "" &&
  //     addNotifyParty.companyName !== "" &&
  //     preCarriageBy !== "" &&
  //     placeOfReceipt !== "" &&
  //     additionalInformation !== "" &&
  //     transport !== "" &&
  //     voyageNo !== "" &&
  //     placeOfDelivery !== "" &&
  //     portOfLoading !== "" &&
  //     portOfDischarge !== "" &&
  //     totalNetWeight !== "" &&
  //     totalGrossWeight !== "" &&
  //     totalVolume !== "" &&
  //     additionalInformationSecond !== "" &&
  //     placeOfIssue !== "" &&
  //     date !== "" &&
  //     signatoryCompany !== "" &&
  //     nameOfAuthorizedSignatory !== "" &&
  //     signature !== "" &&
  //     shipperDataTableValue !== "" &&
  //     tableError === false
  //   ) {
  //     console.log("all ok");
  //     let goodsAndPackages = [];
  //     for (let item of shipperDataTableValue) {
  //       goodsAndPackages.push({
  //         productCode: item.marksAndNumber,
  //         productType: item.kindAndNoOfPackage,
  //         description: item.descriptionOfGoods,
  //         qty: item.netWeight,
  //         price: item.grossWeight,
  //         amount: item.measurements,
  //       });
  //     }

  //     let finalData = {
  //       from: shipper.id,
  //       to: consignee.id,
  //       quoteNumber: "ddada",
  //       date: "2022/02/3",
  //       portOfLoading: portOfLoading.value,
  //       portOfDischarge: portOfDischarge.value,
  //       methodsOfDispatch: "ddada",
  //       typeOfShipement: transport.value,
  //       additionalInformation: additionalInformation,
  //       placeOfIssue: placeOfIssue.value,
  //       placeOfDate: date,
  //       signatoryCompany: signatoryCompany,
  //       nameOfAuthorized: "ddada",
  //       signature: signature,
  //       products: goodsAndPackages,
  //     };
  //     console.log(">>>>>>>>>>>>>finale data", finalData);

  //     addSmartDocuments(finalData).then((response) => {
  //       console.log("response bill of lading", response);
  //       swal("", "Bill of Lading Added", "success");
  //     });
  //   } else {
  //     swal("", "All Fields are required", "error");
  //   }
  // };

  const handleSubmitBillOfLading = async () => {
    let tableError = handleCheckedDataTableValue();

    let errorLengthShipper = customValidations("shipper", shipper.id);
    let errorLengthConsignee = customValidations("consignee", consignee.id);
    let errorLengthCarrierName = customValidations("carrierName", carrierName.id);
    let errorLengthNotifyParty = customValidations("notifyParty", notifyParty.id);
    let errorLengthAddNotifyParty = customValidations("addNotifyParty", addNotifyParty.id);
    let errorLengthUniqueConsignmentReference = customValidations(
      "uniqueConsignmentReference",
      uniqueConsignmentReference
    );
    let errorLengthShipperReference = customValidations("shipperReference", shipperReference);
    let errorLengthShipperReferenceSecond = customValidations(
      "shipperReferenceSecond",
      shipperReferenceSecond
    );
    let errorLengthCarrierReference = customValidations("carrierReference", carrierReference);
    let errorLengthPreCarriageBy = customValidations("preCarriageBy", preCarriageBy);
    let errorLengthPlaceOfReceipt = customValidations("placeOfReceipt", placeOfReceipt);
    let errorLengthAdditionalInformation = customValidations(
      "additionalInformation",
      additionalInformation
    );
    let errorLengthTransport = customValidations("transport", transport);
    let errorLengthVoyageNo = customValidations("voyageNo", voyageNo);
    let errorLengthPlaceOfDelivery = customValidations("placeOfDelivery", placeOfDelivery);

    let errorLengthPortOfLoading = customValidations("portOfLoading", portOfLoading);
    let errorLengthPortOfDischarge = customValidations("portOfDischarge", portOfDischarge);
    let errorLengthTotalAmount = customValidations("totalNetWeight", totalNetWeight);
    let errorLengthTotalGrossWeight = customValidations("totalGrossWeight", totalGrossWeight);
    let errorLengthTotalVolume = customValidations("totalVolume", totalVolume);
    let errorLengthAdditionalInformationSecond = customValidations(
      "additionalInformationSecond",
      additionalInformationSecond
    );
    let errorLengthPlaceOfIssue = customValidations("placeOfIssue", placeOfIssue);
    let errorLengthDate = customValidations("date", date);
    let errorLengthSignatoryCompany = customValidations("signatoryCompany", signatoryCompany);
    let errorLengthNameOfAuthorizedSignatory = customValidations(
      "nameOfAuthorizedSignatory",
      nameOfAuthorizedSignatory
    );

    if (
      // imgSrc !== "" &&
      // shipper.companyName !== "" &&
      // shipperReference !== "" &&
      // shipperReferenceSecond !== "" &&
      // carrierReference !== "" &&
      // uniqueConsignmentReference !== "" &&
      // consignee.companyName !== "" &&
      // carrierName.companyName !== "" &&
      // notifyParty.companyName !== "" &&
      // addNotifyParty.companyName !== "" &&
      // preCarriageBy !== "" &&
      // placeOfReceipt !== "" &&
      // additionalInformation !== "" &&
      // transport !== "" &&
      // voyageNo !== "" &&
      // placeOfDelivery !== "" &&
      // portOfLoading !== "" &&
      // portOfDischarge !== "" &&
      // totalNetWeight !== "" &&
      // totalGrossWeight !== "" &&
      // totalVolume !== "" &&
      // additionalInformationSecond !== "" &&
      // placeOfIssue !== "" &&
      // date !== "" &&
      // signatoryCompany !== "" &&
      // nameOfAuthorizedSignatory !== "" &&
      // signature !== "" &&
      // shipperDataTableValue !== "" &&
      // tableError === false

      errorLengthShipper !== 1 &&
      errorLengthConsignee !== 1 &&
      errorLengthCarrierName !== 1 &&
      errorLengthNotifyParty !== 1 &&
      errorLengthAddNotifyParty !== 1 &&
      errorLengthUniqueConsignmentReference !== 1 &&
      errorLengthShipperReference !== 1 &&
      errorLengthShipperReferenceSecond !== 1 &&
      errorLengthCarrierReference !== 1 &&
      errorLengthAdditionalInformation !== 1 &&
      errorLengthPreCarriageBy !== 1 &&
      errorLengthPlaceOfReceipt !== 1 &&
      errorLengthTransport !== 1 &&
      errorLengthVoyageNo !== 1 &&
      errorLengthPlaceOfDelivery !== 1 &&
      errorLengthPortOfLoading !== 1 &&
      errorLengthPortOfDischarge !== 1 &&
      errorLengthTotalAmount !== 1 &&
      errorLengthTotalGrossWeight !== 1 &&
      errorLengthTotalVolume !== 1 &&
      errorLengthAdditionalInformationSecond !== 1 &&
      errorLengthPlaceOfIssue !== 1 &&
      errorLengthDate !== 1 &&
      errorLengthSignatoryCompany !== 1 &&
      errorLengthNameOfAuthorizedSignatory !== 1 &&
      signature !== "" &&
      tableError === false
    ) {
      console.log("all ok");
      let goodsAndPackages = [];
      for (let item of shipperDataTableValue) {
        goodsAndPackages.push({
          marksAndNumbers: item.marksAndNumbers,
          noOfPackages: item.noOfPackages,
          descOfGoods: item.descOfGoods,
          netWeight: item.netWeight,
          grossWeight: item.grossWeight,
          measurements: item.measurements,
        });
      }

      let formData = new FormData();
      // console.log( this.state.invoice,"invoic");
      let finalData = {
        id: billId,
        shipperReferenceOne: shipperReference,
        shipperReferenceTwo: shipperReferenceSecond,
        carrierReference: carrierReference,
        uniqueConsignmentReference: uniqueConsignmentReference,
        preCarriageBy: preCarriageBy,
        placeOfReciept: placeOfReceipt.value,
        additionalInformation: additionalInformation,
        transport: transport.value,
        voyageNo: voyageNo,
        placeOfDelivery: placeOfDelivery.value,
        portOfLanding: portOfLoading.value,
        portOfDischarge: portOfDischarge.value,
        totalNetWeight: totalNetWeight,
        totalGrossWeight: totalGrossWeight,
        totalVolume: totalVolume,
        additionalInfo: additionalInformationSecond,
        placeOfIssue: placeOfIssue.value,
        signatoryCompany: signatoryCompany,
        placeOfDate: date,
        // signatureUrl: signature,
        nameOfAuthorizedSignatory: nameOfAuthorizedSignatory,
        // merchantId: "CIF",
        shipperCompanyDataId: shipper.id,
        consigneeCompanyDataId: consignee.id,
        carrierNameCompanyDataId: carrierName.id,
        notifyPartyCompanyDataId: notifyParty.id,
        additionalNotifyPartyCompanyDataId: addNotifyParty.id,
        goodsAndPackages: goodsAndPackages,
      };
      console.log(">>>>>>>>>>>>>finale data", finalData);

      if (logo instanceof File !== true) {
        const logoToFile = await fetch(logo).then((it) => it.blob());
        const logoFile = new File([logoToFile], "logo.png", {
          type: "image/png",
          lastModified: new Date(),
        });

        formData.append("logo", logoFile);
      } else {
        formData.append("logo", logo);
      }

      const signatureUrltoFile = await fetch(signature).then((it) => it.blob());
      const signatureFile = new File([signatureUrltoFile], "signature.png", {
        type: "image/png",
        lastModified: new Date(),
      });
      formData.append("data", JSON.stringify(finalData));

      // console.log("logo", logo);
      console.log("sig", signatureFile);

      formData.append("signature", signatureFile);

      if (update === true) {
        editBillOfLading(formData).then((response) => {
          console.log("response bill of lading", response);
          Swal.fire("", "Bill of Lading Updated", "success").then(() => {
            history.push("/btnt-docs/list-billoflading");
          });
        });
      } else {
        addBillOfLading(formData).then((response) => {
          console.log("response bill of lading", response);
          Swal.fire("", "Bill of Lading Added", "success").then(() => {
            history.push("/btnt-docs/list-billoflading");
          });
        });
      }
    } else {
      if (tableError === true) {
        Swal.fire("", "Please fill all the product data fields", "warning");
        console.log("not ok", tableError);
      } else if (signature === "") {
        Swal.fire("", "Please enter your signature", "warning");
      }
    }
    //   console.log("shipper", shipper);
    //   console.log("shipper Reference 1", shipperReference);
    //   console.log("shipper Reference 2", shipperReferenceSecond);
    //   console.log("Carrier reference", carrierReference);
    //   console.log("unique consignment reference", uniqueConsignmentReference);
    //   console.log("consignee", consignee);
    //   console.log("carrier name", carrierName);
    //   console.log("notifyParty", notifyParty);
    //   console.log("addition notify party", addNotifyParty);
    //   console.log("pre carriage by", preCarriageBy);
    //   console.log("placeOfReceipt", placeOfReceipt);
    //   console.log("additional info", additionalInformation);
    //   console.log("transport", transport);
    //   console.log("voyage", voyageNo);
    //   console.log("placeOfDelivery", placeOfDelivery);
    //   console.log("portofloading", portOfLoading);
    //   console.log("port of discharge", portOfDischarge);
    //   console.log("total net weight", totalNetWeight);
    //   console.log("total gross weight", totalGrossWeight);
    //   console.log("total volume", totalVolume);
    //   console.log("additional information", additionalInformationSecond);
    //   console.log("place of issue", placeOfIssue.value);
    //   console.log("date", date);
    //   console.log("signatory company", signatoryCompany);
    //   console.log("name of authorizes", nameOfAuthorizedSignatory);
    //   console.log("signature", signature);
    //   console.log("shipperDataTableValue", shipperDataTableValue);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <Card id="basic-info" sx={{ overflow: "visible" }}>
        {!showPreview ? (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6}>
                <VuiBox mb="40px">
                  <VuiTypography variant="lg" color="text" fontWeight="bold">
                    Agreement
                  </VuiTypography>
                </VuiBox>
              </Grid>
              <Grid item xs={12} lg={6}>
                <div className={"download-preview-save"}>
                  <VuiButton
                    variant="gradient"
                    component="span"
                    color="info"
                    sx={{ minWidth: "100px" }}
                    onClick={handleShowPreview}
                  >
                    Preview
                  </VuiButton>
                  {/* <PDFDownloadLink
                    document={
                      <PDFDocument
                        value={{
                          logo: imgSrc,
                          shipperComapny: shipper,
                          shipperReference: shipperReference,
                          shipperReferenceSecond: shipperReferenceSecond,
                          carrierReference: carrierReference,
                          uniqueConsignmentReference: uniqueConsignmentReference,
                          consigneeCompany: consignee,
                          carrierCompanyName: carrierName,
                          notifyPartyCompanyName: notifyParty,
                          addNotifyPartyCompanyName: addNotifyParty,
                          preCarriageBy: preCarriageBy,
                          placeOfReceipt: placeOfReceipt.value,
                          additionalInformation: additionalInformation,
                          transport: transport.value,
                          voyageNo: voyageNo,
                          placeOfDelivery: placeOfDelivery.value,
                          portOfLoading: portOfLoading.value,
                          portOfDischarge: portOfDischarge.value,
                          totalNetWeight: totalNetWeight,
                          totalGrossWeight: totalGrossWeight,
                          totalVolume: totalVolume,
                          additionalInformationSecond: additionalInformationSecond,
                          placeOfIssue: placeOfIssue.value,
                          date: date,
                          signatoryCompany: signatoryCompany,
                          nameOfAuthorizedSignatory: nameOfAuthorizedSignatory,
                          signature: signature,
                          shipperDataTableValue: shipperDataTableValue,
                        }}
                      />
                    }
                    fileName="Document"
                  >
                    {({ loading }) =>
                      loading ? (
                        <VuiButton
                          variant="gradient"
                          component="span"
                          color="info"
                          sx={{ minWidth: "100px" }}
                        >
                          Loading.....
                        </VuiButton>
                      ) : (
                        <VuiButton
                          variant="gradient"
                          component="span"
                          color="info"
                          sx={{ minWidth: "100px" }}
                        >
                          Download
                        </VuiButton>
                      )
                    }
                  </PDFDownloadLink> */}

                  {/* <VuiButton
                    variant="gradient"
                    component="span"
                    color="info"
                    sx={{ minWidth: "100px" }}
                  >
                    Save as
                  </VuiButton> */}

                  {/* <VuiButton
                    variant="gradient"
                    component="span"
                    color="info"
                    sx={{ minWidth: "100px" }}
                    onClick={handleSavePDF}
                  >
                    Save
                  </VuiButton> */}
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={2} className={"upload-clear"}>
              <Grid item xs={12} lg={6} style={{ display: "flex", alignItems: "flex-start", justifyContent: "flex-start" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    flexDirection: "column",
                    marginTop: "10px",
                  }}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={2}
                    style={{ marginRight: "30px" }}
                  >
                    <label htmlFor="contained-button-file">
                      <Input
                        accept="image/*"
                        id="contained-button-file"
                        type="file"
                        onChange={handleUploadLogo}
                      />
                      <VuiButton
                        variant="gradient"
                        component="span"
                        color="info"
                        sx={{ minWidth: "100px" }}
                      >
                        Change Logo
                      </VuiButton>
                    </label>
                  </Stack>
                </div>
                <img src={imgSrc} style={{ width: "50px", height: "50px" }} />
              </Grid>
            </Grid>
            <VuiBox component="form">
              <div container spacing={2} className={"custom-grid-form"}>
                <div item xs={12} lg={6} className="shipper">
                  <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <VuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      color="text"
                    >
                      Document Title
                    </VuiTypography>
                  </VuiBox>
                  <VuiInput
                    name="from"
                    inputProps={{ type: "string" }}
                    placeholder={"From"}
                    value={uniqueConsignmentReference}
                    error={errorMsg.uniqueConsignmentReference.errorMsg.length > 0}
                    onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                  />
                  <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                    {errorMsg.uniqueConsignmentReference.errorMsg}
                  </VuiTypography>
                </div>
                <div item xs={12} lg={6} className={"additional-information"}>
                  <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <VuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      color="text"
                    >
                    Date
                    </VuiTypography>
                  </VuiBox>
                  <VuiInput
                    name="date"
                    inputProps={{ type: "date", min: currentDate }}
                    defaultValue={date}
                    placeholder={"Date"}
                    value={date}
                    error={errorMsg.date.errorMsg.length > 0}
                    onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                  />
                  <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                    {errorMsg.date.errorMsg}
                  </VuiTypography>
                </div>

                <div item xs={12} lg={6} className={"consignee"}>
                <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <VuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      color="text"
                    >
                      From
                    </VuiTypography>
                  </VuiBox>
                  <VuiInput
                    type="string"
                    name="preCarriageBy"
                    placeholder={"From"}
                    value={preCarriageBy}
                    error={errorMsg.preCarriageBy.errorMsg.length > 0}
                    onChange={(e) => handleChangeInput("preCarriageBy", e.target.value)}
                  />
                  <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                    {errorMsg.preCarriageBy.errorMsg}
                  </VuiTypography>
                </div>
                <div item xs={12} lg={6} className={"additional-notify-party"}>
                <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <VuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      color="text"
                    >
                     To
                    </VuiTypography>
                  </VuiBox>
                  <VuiInput
                    name="signatoryCompany"
                    inputProps={{ type: "string" }}
                    placeholder={"To"}
                    value={signatoryCompany}
                    error={errorMsg.signatoryCompany.errorMsg.length > 0}
                    onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                  />
                  <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                    {errorMsg.signatoryCompany.errorMsg}
                  </VuiTypography>
                </div>

                {/* <div item xs={12} lg={6} className={"notify-party"}>
                 <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <VuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      color="text"
                    >
                      Name of Authorized Signatory
                    </VuiTypography>
                  </VuiBox>
                  <VuiInput
                    name="nameOfAuthorizedSignatory"
                    inputProps={{ type: "string" }}
                    placeholder={"Name of Authorized Signatory"}
                    value={nameOfAuthorizedSignatory}
                    error={errorMsg.nameOfAuthorizedSignatory.errorMsg.length > 0}
                    onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                  />
                  <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                    {errorMsg.nameOfAuthorizedSignatory.errorMsg}
                  </VuiTypography>
                </div> */}

                {/* <div item xs={12} lg={6} className={"additional-notify-party"}>
                <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <VuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      color="text"
                    >
                      Signatory Company
                    </VuiTypography>
                  </VuiBox>
                  <VuiInput
                    name="signatoryCompany"
                    inputProps={{ type: "string" }}
                    placeholder={"Signatory Company"}
                    value={signatoryCompany}
                    error={errorMsg.signatoryCompany.errorMsg.length > 0}
                    onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                  />
                  <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                    {errorMsg.signatoryCompany.errorMsg}
                  </VuiTypography>
                </div> */}

                <div item xs={12} lg={6} className={"notify-party"}>
                <VuiBox mt={3}>
                    <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <VuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      color="text"
                    >
                      Upload Document
                    </VuiTypography>
                    </VuiBox>
                    <VuiDropzone options={{ addRemoveLinks: true }} />
                </VuiBox>
                  {/* <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <VuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      color="text"
                    >
                      Upload Document
                    </VuiTypography>
                  </VuiBox>
                  <VuiInput
                    name="Uploaddocumwnt"
                    inputProps={{ type: "string" }}
                    defaultValue={""}
                    multiline
                    rows={5}
                    placeholder={"Upload Documents``"}
                    value={additionalInformationSecond}
                    error={errorMsg.additionalInformationSecond.errorMsg.length > 0}
                    onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                  />
                  <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                    {errorMsg.additionalInformationSecond.errorMsg}
                  </VuiTypography> */}
                </div>

              </div>
            </VuiBox>
          </>
        ) : (
          ""
        )}

        <Dialog
          sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 510 } }}
          maxWidth="xs"
          open={open}
        >
          <DialogTitle>{dialogInformation.title}</DialogTitle>
          <DialogContent dividers>
            <div item xs={12} lg={6} className="shipper">
              <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <VuiTypography component="label" variant="caption" fontWeight="bold" color="text">
                  Company name
                </VuiTypography>
              </VuiBox>
              <VuiInput
                name="companyName"
                inputProps={{ type: "string" }}
                defaultValue={commonCompany.companyName}
                placeholder={"Company Name"}
                // value={commodityValue[0].description}
                error={errorMsg.companyName.errorMsg.length > 0}
                onChange={(e) => handleDialogInformation(e.target.name, e.target.value)}
              />
              <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                {errorMsg.companyName.errorMsg}
              </VuiTypography>
            </div>
            <div item xs={12} lg={6} className="shipper">
              <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <VuiTypography component="label" variant="caption" fontWeight="bold" color="text">
                  Place
                </VuiTypography>
              </VuiBox>
              <VuiSelect
                placeholder="Select Place"
                options={searchedPorts}
                value={searchedPorts.find((element) => element.label === commonCompany.place)}
                onInputChange={(e) => findPort(e)}
                error={errorMsg.place.errorMsg.length > 0}
                onChange={(e) => handleDialogInformation("place", e.value)}
              />
              <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                {errorMsg.place.errorMsg}
              </VuiTypography>
            </div>
            <div item xs={12} lg={6} className="shipper">
              <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <VuiTypography component="label" variant="caption" fontWeight="bold" color="text">
                  Address
                </VuiTypography>
              </VuiBox>
              <VuiInput
                name="address"
                inputProps={{ type: "string" }}
                defaultValue={commonCompany.address}
                placeholder={"Address"}
                // value={commodityValue[0].description}
                error={errorMsg.address.errorMsg.length > 0}
                onChange={(e) => handleDialogInformation(e.target.name, e.target.value)}
              />
              <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                {errorMsg.address.errorMsg}
              </VuiTypography>
            </div>
            <div item xs={12} lg={6} className="shipper">
              <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <VuiTypography component="label" variant="caption" fontWeight="bold" color="text">
                  Phone
                </VuiTypography>
              </VuiBox>
              <VuiInput
                name="phone"
                inputProps={{ type: "number" }}
                defaultValue={commonCompany.phone}
                placeholder={"Phone"}
                // value={commodityValue[0].description}
                error={errorMsg.phone.errorMsg.length > 0}
                onChange={(e) => handleDialogInformation(e.target.name, e.target.value)}
              />
              <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                {errorMsg.phone.errorMsg}
              </VuiTypography>
            </div>
            <div item xs={12} lg={6} className="shipper">
              <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <VuiTypography component="label" variant="caption" fontWeight="bold" color="text">
                  Bank Details
                </VuiTypography>
              </VuiBox>
              <VuiInput
                name="bankDetails"
                multiline={true}
                rows={4}
                defaultValue={commonCompany.bankDetails}
                placeholder={"Bank Details"}
                // value={commodityValue[0].description}
                error={errorMsg.bankDetails.errorMsg.length > 0}
                onChange={(e) => handleDialogInformation(e.target.name, e.target.value)}
              />
              <VuiTypography fontWeight="light" fontSize="0.7rem" sx={{ color: "red" }}>
                {errorMsg.bankDetails.errorMsg}
              </VuiTypography>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleSaveDialogData}>Save</Button>
          </DialogActions>
        </Dialog>

        {showPreview ? (
          <>
            <div style={{ marginBottom: "10px", display: "flex", justifyContent: "flex-end" }}>
              <VuiButton
                variant="gradient"
                component="span"
                color="info"
                sx={{ minWidth: "100px" }}
                onClick={() => {
                  handleClosePreview();
                }}
              >
                Close
              </VuiButton>
            </div>
            <div>
              <PDFViewer style={{ height: "500px", width: "100%" }}>
                <PDFDocument
                  value={{
                    logo: imgSrc,
                    shipperComapny: shipper,
                    shipperReference: shipperReference,
                    shipperReferenceSecond: shipperReferenceSecond,
                    carrierReference: carrierReference,
                    uniqueConsignmentReference: uniqueConsignmentReference,
                    consigneeCompany: consignee,
                    carrierCompanyName: carrierName,
                    notifyPartyCompanyName: notifyParty,
                    addNotifyPartyCompanyName: addNotifyParty,
                    preCarriageBy: preCarriageBy,
                    placeOfReceipt: placeOfReceipt.value,
                    additionalInformation: additionalInformation,
                    transport: transport.value,
                    voyageNo: voyageNo,
                    placeOfDelivery: placeOfDelivery.value,
                    portOfLoading: portOfLoading.value,
                    portOfDischarge: portOfDischarge.value,
                    totalNetWeight: totalNetWeight,
                    totalGrossWeight: totalGrossWeight,
                    totalVolume: totalVolume,
                    additionalInformationSecond: additionalInformationSecond,
                    placeOfIssue: placeOfIssue.value,
                    date: date,
                    signatoryCompany: signatoryCompany,
                    nameOfAuthorizedSignatory: nameOfAuthorizedSignatory,
                    signature: signature,
                    shipperDataTableValue: shipperDataTableValue,
                  }}
                />
              </PDFViewer>
            </div>
          </>
        ) : null}
        <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
          <VuiButton
            variant="gradient"
            component="span"
            color="info"
            sx={{ minWidth: "100px" }}
            onClick={() => handleSubmitBillOfLading()}
            // onClick={handleSubmitSmartDocument}
          >
            Submit
          </VuiButton>
        </div>
      </Card>
    </DashboardLayout>
  );
}

export default AddAgreement;