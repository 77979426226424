//import GoodCodeLogo from "./images/GOO_Logo_Light.svg";
import NewBitnauticLogo from "./images/bitnautic-white-log.png";

export const IMG_COMPANY_LOGO = NewBitnauticLogo; //GoodCodeLogo;
export const IMG_COMPANY_LOGO_WIDTH = "100%";


export const THEME_BASE							= "rgb(94, 191, 191)";
export const THEME_BASE_FOCUS					= "#0075ff";
export const THEME_BASE_LIGHTBLUE_MAIN			= "#4299e1";
export const THEME_BASE_LIGHTBLUE_FOCUS			= "#4299e1";
export const THEME_BASE_INFO_MAIN				= "rgb(94, 191, 191)";
export const THEME_BASE_INFO_FOCUS				= "#348080";
export const THEME_BASE_GRADIENT_INFO_MAIN		= "#0075ff";
export const THEME_BASE_GRADIENT_INFO_STATE		= "#21d4fd";
export const THEME_BASE_ALERT_INFO_MAIN			= "#2152ff";
export const THEME_BASE_ALERT_INFO_STATE		= "#02c6f3";
export const THEME_BASE_ALERT_INFO_BORDER		= "#b9ecf8";
export const THEME_BASE_BADGE_INFO_BASIC		= "#4299e1";
export const THEME_BASE_BADGE_INFO_BACKGROUND	= "#abe9f7";


export const THEME_LIGHT_BASE = "#4777c9";
export const THEME_LIGHT_BASE_FOCUS = "#0075FF";
export const THEME_LIGHT_BASE_TEXT = "#110F26";
export const THEME_LIGHT_LIGHTBLUE_MAIN = "#4299e1";
export const THEME_LIGHT_LIGHTBLUE_FOCUS = "#4299e1";
export const THEME_LIGHT_NAVICON_MAIN = "#2D3B92";
export const THEME_LIGHT_NAVICON_FOCUS = "#2D3B92";
export const THEME_LIGHT_INFO_MAIN = "#4fa1a1";
export const THEME_LIGHT_INFO_FOCUS = "#4fa1a1";

export const THEME_LIGHT_GRADIENT_SIDENAV_MAIN = "#00287a";
export const THEME_LIGHT_GRADIENT_SIDENAV_STATE = "#002163";

export const THEME_LIGHT_GRADIENT_INFO_MAIN = "#63A6FF";
export const THEME_LIGHT_GRADIENT_INFO_STATE = "#2D3B92";

export const THEME_LIGHT_SWAL_BUTTON = "#0075ff";


export const THEME_SMART_MAIL_COMPOSE_DIALOG_BACKGROUND			= "rgb(140, 210, 210)";
export const THEME_SMART_SMAIL_COMPOSE_DIALOG_TEXT_COLOR		= "#000";