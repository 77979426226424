import React from "react";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import Checkbox from "@mui/material/Checkbox";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiInput from "components/VuiInput";

// Vision UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import Table from "examples/Tables/Table";
import DataTable from "examples/Tables/DataTable";

import { FaUser, FaThumbsUp } from "react-icons/fa";
import { IoIosRocket } from "react-icons/io";
import { IoCart } from "react-icons/io5";
import { FaSearch } from "react-icons/fa";
import { IoNotificationsOutline } from "react-icons/io5";

// Reports page components
import Reviews from "layouts/pages/users/reports/components/Reviews";

// Data
import tableData from "layouts/pages/users/reports/data/tableData";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";
import { emailInboxReceived } from "services/mailService";
import { BiCheckbox } from "react-icons/bi";
import { AiOutlineStar } from "react-icons/ai";
import { AiFillStar } from "react-icons/ai";
import { emailInboxSent } from "services/mailService";
import { isMobile, isBrowser } from "react-device-detect";
import { useVisionUIController, setOpenCompose } from "context";
import Loader from "layouts/loader/loader";
import Moment from "react-moment";

function SentMail() {
  let history = useHistory();
  const [controller, dispatch] = useVisionUIController();
  const { openCompose } = controller;
  const [sentEmails, setSentEmails] = useState([]);
  const [sentStatus, setSentStatus] = useState(true);
  const [responseLoader, setResponseLoader] = useState(true);
  const { columns, rows } = tableData;

  const handleCompose = () => setOpenCompose(dispatch, !openCompose);

  useEffect(() => {
    fetchEmailInboxSent();
  }, []);

  const fetchEmailInboxSent = () => {
    setResponseLoader(true);
    emailInboxSent().then((response) => {
      console.log("response", response);

      if (response.length < 1) {
        console.log("empty arr", response);
        setSentStatus(false);
        return;
      }

      let newResponse = [];
      for (let item of response) {
        newResponse.push({
          ...item,

          // action1: <BiCheckbox size={25} />,
          // // <AiOutlineStar size={25} />

          action2: (
            <>
              <AiOutlineStar size={25} />
            </>
          ),
        });
      }
      setSentEmails(newResponse);
    });
    setResponseLoader(false);
  };

  // let getTime = (time) => {
  //   var d = new Date(time);
  //   var time = d.getUTCHours() + " : " + d.getUTCMinutes();
  //   return time;
  // };

  // let timeStamp = (time) => {
  //   var d = new Date(time);
  //   var time = d.getTime();
  //   return time;
  // };

  // const dateFormatter = {
  //   day: "2-digit",
  //   month: "2-digit",
  //   year: "numeric",
  //   hour: "2-digit",
  //   minute: "2-digit",
  // };

  const sentEmailTable = {
    columns: [
      // { Header: "MmsiKey", accessor: "mmsiKey"},
      // { Header: "IMO", accessor: "imo"},
      // {
      //   accessor: "action1",
      //   align: "center",
      //   width: "1%",
      // },
      {
        accessor: "action2",
        align: "center",
        width: "1%",
      },
      {
        accessor: "ReceiverEmailID",
        align: "center",
        Cell: (cell) => (
          <>
            <VuiTypography variant="sm" color="text">
              To: {cell.row.values.ReceiverEmailID}
            </VuiTypography>
          </>
        ),
      },
      {
        accessor: "subject",
        align: "center",
        Cell: (cell) => (
          <VuiTypography
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            width="130px"
            variant="sm"
            color="text"
            component="div"
          >
            {cell.row.values.subject}
          </VuiTypography>
        ),
      },
      {
        accessor: "message",
        align: "center",
        Cell: (cell) => (
          <VuiTypography
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            width="130px"
            variant="sm"
            color="text"
            component="div"
          >
            {cell.row.values.message}
          </VuiTypography>
        ),
      },
      {
        accessor: "timesent",
        align: "center",
        Cell: (cell) => (
          <>
            <VuiTypography variant="sm" color="text">
              <Moment format="DD/MM/YYYY, hh:mm A">{cell.row.values.timesent}</Moment>
            </VuiTypography>
          </>
        ),
      },
    ],

    rows: sentEmails ? sentEmails : [],
  };

  const handleRow = (row) => {
    console.log("test", row);
    history.push(`/btnt-docs/sent-page/${row.emailId}`);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <VuiBox py={3}>
        {responseLoader ? (
          <Loader />
        ) : (
          <Card sx={{ height: "100vh" }}>
            <DataTable
              table={sentEmailTable}
              isSorted={false}
              clickable={true}
              rowClick={(row) => handleRow(row)}
              canSearch
            />

            {sentStatus === false ? (
              <VuiBox sx={{ textAlign: "center" }}>
                <VuiTypography color="text" fontWeight="bold">
                  No sent messages! Send one now!
                </VuiTypography>
              </VuiBox>
            ) : null}
          </Card>
        )}
      </VuiBox>

      {isMobile && (
        <VuiBox position="fixed" right="2rem" bottom="2rem" zIndex={99}>
          <VuiButton
            onClick={() => handleCompose()}
            sx={{ height: "50px" }}
            color="info"
            variant="contained"
          >
            <MdModeEdit />
            &nbsp; Compose
          </VuiButton>
        </VuiBox>
      )}

      <Footer />
    </DashboardLayout>
  );
}

export default SentMail;
