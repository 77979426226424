import React from "react";
import { useState, useEffect } from "react";

// @mui material components
import Container from "@mui/material/Container";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Vision UI Dashboard PRO React example components
// import PageLayout from "examples/LayoutContainers/PageLayout";

// Pricing page components
// import Header from "layouts/pages/pricing-page/components/Header";
// import Footer from "layouts/pages/pricing-page/components/Footer";
// import PricingCards from "layouts/pages/pricing-list/components/PricingCards";
// import TrustedBrands from "layouts/pages/pricing-page/components/TrustedBrands";
import PricingCards from "./components/PricingCards/pricing-cards";
import { getPlansList } from "services/pricingService";
import VuiTypography from "components/VuiTypography";
import { getActivePlan } from "services/pricingService";
import { Grid } from "@mui/material";
import Footer from "examples/Footer";
// import Faq from "layouts/pages/pricing-page/components/Faq";

function PricingList() {
  //   const [tabValue, setTabValue] = useState(0);
  // const [prices, setPrices] = useState(["Free", "99", "399"]);

  const [availableDoc, setAvailableDoc] = useState("0");

  useEffect(() => {
    fetchActivePlan();
  }, []);

  const fetchActivePlan = async () => {
    const resp = await getActivePlan();
    console.log("resp", resp);

    if (resp.success === false) {
      setAvailableDoc("0");
    } else {
      setAvailableDoc(resp.totalDocumentCountAvailable - resp.totalDocumentCountUsed);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox>
        {/* <VuiBox sx={{ marginBottom: "2rem", textAlign: "center" }}>
          <VuiTypography color="text" fontWeight="bold">
            You have available credit balance of {availableDoc} documents
          </VuiTypography>
        </VuiBox> */}
        {/* <Header tabValue={tabValue} tabHandler={handleSetTabValue} /> */}
        {/* <Container> */}

        <Grid container justifyContent="center" textAlign="center" marginBottom="1rem">
          <Grid item xs={10} lg={5}>
            <VuiBox>
              <VuiTypography fontSize="2rem" color="text" fontWeight="bold">
                See our pricing
              </VuiTypography>
            </VuiBox>
            <VuiBox>
              <VuiTypography color="text" fontWeight="regular">
                You have available credit balance of {availableDoc} documents
              </VuiTypography>
            </VuiBox>
          </Grid>
        </Grid>

        <PricingCards
        // prices={prices}
        />
        {/* <TrustedBrands /> */}
        {/* <Faq /> */}
        {/* </Container> */}
        <VuiBox mt={8}>
          <Footer />
        </VuiBox>
      </VuiBox>
    </DashboardLayout>
  );
}

export default PricingList;
