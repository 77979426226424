import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import VuiBox from "components/VuiBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React from "react";
import PaymentForm from "./paymentForm";

const PUBLIC_KEY =
  "pk_test_51LlAb8ABaeYGwH3nmCY14tdV2HF7z3V0x8Gu3zFLNp2YFKis4gKGDk7oanwmYcDxnKQYXMrsSu9lCJG00LCt8WfK00hRIDhrYa";
const stripeTestPromise = loadStripe(PUBLIC_KEY);

function StripeContainer() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox>StripeContainer</VuiBox>
      <Elements stripe={stripeTestPromise}>
        <PaymentForm />
      </Elements>
    </DashboardLayout>
  );
}

export default StripeContainer;
