// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";

// Vision UI Dashboard React base styles
import borders from "assets/theme/base/borders";

// Images
import colors from "assets/theme/base/colors";

// Vision UI Dashboard assets
import radialGradient from "assets/theme/functions/radialGradient";
import palette from "assets/theme/base/colors";

// Vision UI Dashboard component exemples
import Mastercard from "examples/Icons/Mastercard";
import Visa from "examples/Icons/Visa";
import BtntLogo from "./ship.png";
import { useEffect, useState } from "react";
import { getPaymentAddress } from "services/pricingService";
import VuiInput from "components/VuiInput";

function PaymentMethod() {
  const { borderWidth, borderColor } = borders;
  const { grey } = colors;
  const [paymentAddress, setPaymentAddress] = useState("");

  useEffect(() => {
    fetchPaymentAddres();
  }, []);

  const fetchPaymentAddres = async () => {
    const resp = await getPaymentAddress();
    console.log("addd", resp);
    setPaymentAddress(resp);
  };

  return (
    <Card id="delete-account">
      <VuiBox display="flex" justifyContent="space-between" alignItems="center" mb="32px">
        <VuiTypography variant="lg" fontWeight="bold" color="text">
          Payment Method
        </VuiTypography>
        {/* <VuiButton variant="contained" color="info">
          ADD NEW CARD
        </VuiButton> */}
      </VuiBox>
      <VuiBox>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <VuiBox
              border="2px solid"
              borderRadius="20px"
              borderColor={grey[600]}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p="22px 20px"
            >
              <VuiBox
                sx={{
                  // border: "1px solid red",
                  width: "250px",
                  display: "flex",
                  gap: "0.3rem",
                  // overflow: "hidden",
                  // textOverflow: "ellipsis",
                  // whiteSpace: "nowrap",
                }}
              >
                <VuiBox>
                  <img src={BtntLogo} width="21px" />
                </VuiBox>
                <VuiBox>
                  <VuiTypography
                    // pl={2}
                    // variant="button"
                    fontSize="1rem"
                    color="text"
                    fontWeight="light"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                    width="250px"
                  >
                    7812 2139 0823 XXXX 0823 0823
                  </VuiTypography>
                </VuiBox>
              </VuiBox>
              <VuiBox lineHeight={0}>
                <Tooltip title="Edit Card" placement="top">
                  <Icon sx={{ cursor: "pointer" }} fontSize="small">
                    edit
                  </Icon>
                </Tooltip>
              </VuiBox>
            </VuiBox>
          </Grid>
          <Grid item xs={12} md={6}>
            <VuiBox
              border="2px solid"
              borderRadius="20px"
              borderColor={grey[600]}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p="22px 20px"
            >
              <VuiInput placeholder="7812 2139 0823 XXXX 0823 0823" sx={{ border: 0 }} />

              <VuiBox lineHeight={0}>
                <Tooltip title="Edit Card" placement="top">
                  <Icon sx={{ cursor: "pointer" }} fontSize="small">
                    edit
                  </Icon>
                </Tooltip>
              </VuiBox>
            </VuiBox>
          </Grid>
        </Grid>
      </VuiBox>
    </Card>
  );
}

export default PaymentMethod;
