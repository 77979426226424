import VuiBox from "components/VuiBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React from "react";
import { verifyCheckoutSession } from "services/pricingService";
// import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import VuiTypography from "components/VuiTypography";

export default function CheckoutSuccess() {
  const { id } = useParams();

  // const [searchParams] = useSearchParams();

  // console.log("SP", searchParams.get("sessionId"));
  console.log("ID", id);

  useEffect(() => {
    fetchVerifyCheckoutSession();
  }, []);

  const fetchVerifyCheckoutSession = () => {
    const checkoutSessionId = {
      sessionId: id,
    };

    const verifyCheckoutSessionRes = verifyCheckoutSession(checkoutSessionId);
    console.log("verifyCheckoutSessionRes", verifyCheckoutSessionRes);
  };

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <VuiBox sx={{ textAlign: "center", marginTop: "10rem" }}>
          <VuiTypography fontWeight="bold">Thank you for your purchase</VuiTypography>
        </VuiBox>
      </DashboardLayout>
    </>
  );
}
