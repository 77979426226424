import React from "react";
import BasicLayoutDoc from "layouts/authentication/components/BasicLayoutDoc";

import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";

// Vision UI Dashboard PRO React example components

// import Footer from "examples/Footer";

// import { getSmartDocuments } from "services/documentService";

// import PDFDocument from "../add-bill-of-lading/pdfDocument";

import { PDFViewer } from "@react-pdf/renderer";
import PDFDOCLIST from "./pdfQuotation";
import QRCode from "qrcode";
import { getBillOfLadingById } from "services/documentService";
import { getProformaInvoiceById } from "services/documentService";
import VerifiedImg from "./Verified2.png";
import Picture from "./ship.png";
import { getCompanyById } from "services/documentService";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { isMobile, isBrowser } from "react-device-detect";
import { getPurchaseOrderById } from "services/documentService";
import { getQuotationById } from "services/documentService";
import { getIpfsHashData } from "services/documentService";
import { postVerifyDocs } from "services/documentService";

function PublicQuotation() {
  let history = useHistory();
  const [quoData, setQuoData] = useState({});
  const [fromCompanyData, setFromCompanyData] = useState({});
  const [toCompanyData, setToCompanyData] = useState({});

  const [qrData, setQrData] = useState("");
  const [preview, setPreview] = useState(false);
  const params = useParams();

  const dates = (time) => {
    var d = new Date(time);
    var time = d.getUTCMonth() + 1 + " / " + d.getUTCDate() + " / " + d.getUTCFullYear();
    return time;
  };

  let resultVerifiedImg;

  const fetchQuotationById = async () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const ipfsHash = urlParams.get("onBlockChainHash");
    const hash = urlParams.get("transactionHash");

    const res = await getIpfsHashData(ipfsHash);
    console.log("prores", res);
    setQuoData(res.result);
    setFromCompanyData(res.result.fromCompanyDataId[0]);
    setToCompanyData(res.result.toCompanyDataId[0]);

    // if (hash !== "null" || undefined) {
    //   const verifyData = {
    //     merchant_id: res.result.merchant_id,
    //     unique_consignment_reference: `${res.result.id}${res.result.quoteNumber}`,
    //     ipfsHash: ipfsHash,
    //     hash: hash,
    //   };

    //   const verifiedRes = await postVerifyDocs(verifyData);

    //   console.log("veriedREs", verifiedRes);

    //   resultVerifiedImg = verifiedRes ? VerifiedImg : null;
    // }

    // console.log(res[0], "billlll");
    // console.log(res[0].shipper_company_data_id, "billlll");
    // console.log(res[0].consignee_company_data_id, "billlll");
    // console.log(res[0].carrier_name_company_data_id, "billlll");
    // console.log(res[0].notify_party_company_data_id, "billlll");
    // console.log(res[0].additional_notify_party_company_data_id, "billlll");
    // const fromCompanyId = await getCompanyById(res[0].shipper_company_data_id);
    // setFromCompanyData(fromCompanyId[0]);
    // const toCompanyId = await getCompanyById(res[0].consignee_company_data_id);
    // setToCompanyData(toCompanyId[0]);
    // const carrierNameId = await getCompanyById(res[0].carrier_name_company_data_id);
    // setCarrierData(carrierNameId[0]);
    // const notifyPartyId = await getCompanyById(res[0].notify_party_company_data_id);
    // setNotifyPartyData(notifyPartyId[0]);
    // const addNotifyId = await getCompanyById(res[0].additional_notify_party_company_data_id);
    // setAdditionalNotifyPartyData(addNotifyId[0]);

    const QrDataN = await QRCode.toDataURL(
      `https://bitdocs.bitnautic.com/btnt-docs/public-quotation/?onBlockChainHash=${ipfsHash}&transactionHash=${hash}`
    );
    console.log(QrDataN);
    setQrData(QrDataN);

    setPreview(true);
  };

  useEffect(() => {
    fetchQuotationById();
  }, []);

  return (
    <BasicLayoutDoc>
      {preview && isBrowser ? (
        <div>
          <PDFViewer style={{ height: "100vh", width: "100vw" }}>
            <PDFDOCLIST
              value={{
                logo: quoData.logourl,
                fromCompany: fromCompanyData,
                quoteNumber: quoData.quoteNumber,
                date: dates(quoData.date),
                toCompany: toCompanyData,
                methodOfDispatch: quoData.methodOfDispatch,
                typeOfShipment: quoData.typeOfShipment,
                portOfLoading: quoData.portOfLoading,
                portOfDischarge: quoData.portOfDischarge,
                totalAmount: quoData.totalAmount,
                additionalInformation: quoData.additionalInfo,
                placeOfIssue: quoData.placeOfIssue,
                dateOfIssue: dates(quoData.dateOfIssue),
                signatoryCompany: quoData.signatoryCompany,
                nameOfAuthorizedSignatory: quoData.nameOfAuthorizedSignatory,
                signature: quoData.signatureUrl,
                bankDetails: quoData.bankDetails,
                goodsAndPackages: quoData.goodsAndPackages,
                qrData: qrData,
                verifiedImg: VerifiedImg,
              }}
            />
          </PDFViewer>
        </div>
      ) : preview && isMobile ? (
        <VuiBox sx={{ textAlign: "center", height: "500px" }}>
          <VuiTypography marginBottom="2rem">
            Click on the button below to download Bill of Lading uploaded on blockchain
          </VuiTypography>
          <PDFDownloadLink
            document={
              <PDFDOCLIST
                value={{
                  logo: quoData.logourl,
                  fromCompany: fromCompanyData,
                  quoteNumber: quoData.quoteNumber,
                  date: dates(quoData.date),
                  toCompany: toCompanyData,
                  methodOfDispatch: quoData.methodOfDispatch,
                  typeOfShipment: quoData.typeOfShipment,
                  portOfLoading: quoData.portOfLoading,
                  portOfDischarge: quoData.portOfDischarge,
                  totalAmount: quoData.totalAmount,
                  additionalInformation: quoData.additionalInfo,
                  placeOfIssue: quoData.placeOfIssue,
                  dateOfIssue: dates(quoData.dateOfIssue),
                  signatoryCompany: quoData.signatoryCompany,
                  nameOfAuthorizedSignatory: quoData.nameOfAuthorizedSignatory,
                  signature: quoData.signatureUrl,
                  bankDetails: quoData.bankDetails,
                  goodsAndPackages: quoData.goodsAndPackages,
                  qrData: qrData,
                  verifiedImg: VerifiedImg,
                }}
              />
            }
            fileName="Document"
          >
            {({ loading }) =>
              loading ? (
                <VuiButton variant="gradient" color="info">
                  Please wait...
                </VuiButton>
              ) : (
                <VuiButton variant="gradient" color="info">
                  Download Pdf
                </VuiButton>
              )
            }
          </PDFDownloadLink>
        </VuiBox>
      ) : null}
    </BasicLayoutDoc>
  );
}

export default PublicQuotation;
