import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React from "react";
import { useEffect } from "react";

export default function Checkoutfailed() {
  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <VuiBox sx={{ textAlign: "center", marginTop: "10rem" }}>
          <VuiTypography fontWeight="bold">Payment Failed</VuiTypography>
          <VuiTypography fontWeight="bold">Please try again later</VuiTypography>
        </VuiBox>
      </DashboardLayout>
    </>
  );
}
