import { useState, useEffect, useMemo } from "react";

// react-router components
import { Route, Switch, Redirect, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";

// Vision UI Dashboard PRO React example components
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Vision UI Dashboard PRO React themes
import theme from "assets/theme";
import themeLight from "assets/themeLight";
import themeRTL from "assets/theme/theme-rtl";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// Vision UI Dashboard PRO React routes
import routes from "routes";

// Vision UI Dashboard PRO React contexts
import {
  useVisionUIController,
  setMiniSidenav,
  setOpenConfigurator,
  setOpenCompose,
} from "context";

// Plugins custom css
import "assets/theme/base/plugins.css";
import GuardedRoute from "./utils/GuardedRoute";
import { logoutUser } from "./services/userService";
import jwt_decode from "jwt-decode";
import setAuthToken from "utils/setAuthToken";
import isEmpty from "utils/isEmpty";
import "utils/dateFormat";

import { IMG_COMPANY_LOGO, IMG_COMPANY_LOGO_WIDTH } from './assets/brand';

// Component
import SignUpIllustration from "layouts/authentication/sign-up/illustration";
import userVerify from "layouts/authentication/verify";
import SignIn from "layouts/authentication/sign-in/cover";
import activateShip from "layouts/pages/ship/active-ship/activateShip";
import activateLclContainer from "layouts/pages/container/activate-lcl-container";
import activateFclContainer from "layouts/pages/container/activate-fcl-container";
import ActiveShipDetails from "layouts/pages/ship/active-ship/active-ship-details";
import ActiveContainerDetails from "layouts/pages/container/active-container-details/index.js";
import ApproveRequest from "layouts/pages/quotation/ship-pending-quoatation/approve-request";
import ApproveLCLRequest from "layouts/pages/container/LCL-container-pending-quoatation/approvelclcontainer.js";
import ApproverFclRequest from "layouts/pages/container/FCL-container-pending-quoatation/aprrovefclcontainer.js";
import ShipQuoatationDetails from "layouts/pages/quotation/ship-quoatation/ship-quoatation-details";
import LCLQuoatationDetails from "layouts/pages/container/LCL-container-quoatation/lcl-container-details";
import FCLQuoatationDetails from "layouts/pages/container/FCL-container-quoatation/fcl-container-details";

import AddBillOfLading from "layouts/pages/smart-document/add-bill-of-lading";
import AddAgreement from "layouts/pages/smart-document/agreement";
import ProfomaInvoice from "layouts/pages/smart-document/profoma-invoice";
import CommercialInvoice from "layouts/pages/smart-document/commercial-invoice";
import QuotationInvoice from "layouts/pages/smart-document/quotation";
import PurchaseOrder from "layouts/pages/smart-document/purchase-order";
import PublicBillOfLading from "layouts/authentication/PublicDocs/publicBillOfLading";
import PublicProformaInvoice from "layouts/authentication/PublicDocs/publicProformaInvoice";
import PublicPurchaseOrder from "layouts/authentication/PublicDocs/publicPurchaseOrder";
import PublicQuotation from "layouts/authentication/PublicDocs/publicQuotation";
import PublicCommercialInvoice from "layouts/authentication/PublicDocs/publicCommercialInvoice";
import Loader from "layouts/loader/loader";
import PaymentDetails from "layouts/pages/smart-document/paymentDetails";
import Profile from "layouts/pages/profilePage";
import VuiButton from "components/VuiButton";
import { MdModeEdit } from "react-icons/md";
import Compose from "layouts/pages/smart-mail/Compose";
import InboxPage from "layouts/pages/smart-mail/InboxPage";
import SentPage from "layouts/pages/smart-mail/SentPage";
import PricingList from "layouts/pages/pricing-list";
import TimeLine from "layouts/pages/timeLine/timeline";
import PaymentGateway from "layouts/pages/paymentGateway";
import StripeContainer from "layouts/pages/paymentGateway/stripeContainer";
import CheckoutSuccess from "layouts/pages/paymentGateway/checkoutSuccess";
import PaymentOptions from "layouts/pages/paymentGateway/paymentOptions";
import Checkoutfailed from "layouts/pages/paymentGateway/checkoutfailed";
import ForgotPassword from "layouts/authentication/forgotPassword";
import ResetPassword from "layouts/authentication/resetPassword";

import TraceView from "layouts/pages/trace-view";
import ListSuppliers from "layouts/pages/smart-document/list-suppliers";
import AddTraceableProduct from "layouts/pages/smart-document/add-traceable-product";
import UpdateSupplier from "layouts/pages/smart-document/update-supplier";
import ListTraceability from "layouts/pages/smart-document/list-traceability";

export default function App() {
  const [controller, dispatch] = useVisionUIController();
  const { miniSidenav, direction, layout, openConfigurator, sidenavColor, themeMode, openCompose } =
    controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [themeSetter, setThemeSetter] = useState(theme);

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  useEffect(() => {
    routes.push({
      name: "Profile",
      key: "profile",
      route: "/btnt-docs/profile",
      component: Profile,
    });

    if (localStorage.jwtTokenBitDocs) {
      // Set auth token header auth
      setAuthToken(localStorage.jwtTokenBitDocs);
      // Decode token and get user info and exp
      const decoded = jwt_decode(localStorage.jwtTokenBitDocs);
      setIsAuthenticated(true);
      // Check for expired token
      // setInterval(() => {
      //   const currentTime = Date.now() / 1000;
      //   if (decoded.iat < currentTime || isEmpty(localStorage.getItem("jwtToken"))) {
      //     setIsAuthenticated(false);
      //     localStorage.removeItem("jwtToken");
      //     setAuthToken(false);
      //   }
      // }, 600000);
    }
    setTimeout(() => {
      setLoading(true);
    }, 1500);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  const handleCompose = () => setOpenCompose(dispatch, !openCompose);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    // allRoutes.push({name: "Activate Ship",key: "activate-ship", route: "/merchant/activate-ship/:mmsikey", component: activateShip})
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return (
          <GuardedRoute
            exact
            path={route.route}
            component={route.component}
            key={route.key}
            auth={isAuthenticated}
          />
        );
      }

      return null;
    });

  useEffect(() => {
    if (themeMode === false) {
      setThemeSetter(themeLight);
    } else {
      setThemeSetter(theme);
    }
  }, [themeMode]);

  // const configsButton = (
  //   <VuiBox position="fixed" right="2rem" bottom="2rem" zIndex={99}>
  //     <VuiButton
  //       onClick={() => handleCompose()}
  //       sx={{ height: "50px" }}
  //       color="info"
  //       variant="contained"
  //     >
  //       <MdModeEdit />
  //       &nbsp; Compose
  //     </VuiButton>
  //   </VuiBox>
  // );
  return (
    <>
      {loading === true ? (
        <ThemeProvider theme={themeSetter}>
          <CssBaseline />
          {(layout === "dashboard" && !(pathname.substring(0, 11)=="/trace-view")) && (
            <>
              <Sidenav
                color={sidenavColor}
                brandName={<img src={IMG_COMPANY_LOGO} alt="logo" width={IMG_COMPANY_LOGO_WIDTH} />}
                routes={routes}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
              {/* <Configurator /> */}

              {isAuthenticated ? <Compose /> : null}

              {/* <Compose /> */}

              {/* {configsButton} */}
            </>
          )}
          <Switch>
            <Route exact path={"/trace-view"} component={TraceView} key="trace-view" />

            {isAuthenticated ? (
              getRoutes(routes)
            ) : (
              <Route exact path={"/authentication/sign-in"} component={SignIn} key={"signin"} />
            )}
            {isAuthenticated ? (
              ""
            ) : (
              <Route
                path={"/authentication/sign-up"}
                component={SignUpIllustration}
                key={"register"}
              />
            )}
            {isAuthenticated ? (
              ""
            ) : (
              <Route
                path={"/authentication/verify-user/:token"}
                component={userVerify}
                key={"verify"}
              />
            )}

            {isAuthenticated ? (
              <Route path={"/btnt-docs/quotation"} component={AddAgreement} key={"quotation"} />
            ) : (
              <Route exact path={"/authentication/sign-in"} component={SignIn} key={"signin"} />
            )}

            {isAuthenticated ? (
              <Route
                path={"/btnt-docs/public-billOfLading"}
                component={PublicBillOfLading}
                key={"public-billOfLading"}
              />
            ) : (
              <Route exact path={"/authentication/sign-in"} component={SignIn} key={"signin"} />
            )}

            {isAuthenticated ? (
              <Route
                path={"/btnt-docs/public-proformaInvoice"}
                component={PublicProformaInvoice}
                key={"public-proformaInvoice"}
              />
            ) : (
              <Route exact path={"/authentication/sign-in"} component={SignIn} key={"signin"} />
            )}

            {isAuthenticated ? (
              <Route
                path={"/btnt-docs/public-commercialInvoice"}
                component={PublicCommercialInvoice}
                key={"public-commercialInvoice"}
              />
            ) : (
              <Route exact path={"/authentication/sign-in"} component={SignIn} key={"signin"} />
            )}

            {isAuthenticated ? (
              <Route
                path={"/btnt-docs/public-purchaseOrder"}
                component={PublicPurchaseOrder}
                key={"public-purchaseOrder"}
              />
            ) : (
              <Route exact path={"/authentication/sign-in"} component={SignIn} key={"signin"} />
            )}

            {isAuthenticated ? (
              <Route
                path={"/btnt-docs/public-quotation"}
                component={PublicQuotation}
                key={"public-quotation"}
              />
            ) : (
              <Route exact path={"/authentication/sign-in"} component={SignIn} key={"signin"} />
            )}

            {isAuthenticated ? (
              <Route
                path={"/btnt-docs/bill-of-lading"}
                component={AddBillOfLading}
                key={"bill-of-lading"}
              />
            ) : (
              <Route exact path={"/authentication/sign-in"} component={SignIn} key={"signin"} />
            )}

            {isAuthenticated ? (
              <Route
                path={"/btnt-docs/profoma-invoice"}
                component={ProfomaInvoice}
                key={"profoma-invoice"}
              />
            ) : (
              <Route exact path={"/authentication/sign-in"} component={SignIn} key={"signin"} />
            )}

            {isAuthenticated ? (
              <Route
                path={"/btnt-docs/commercial-invoice"}
                component={CommercialInvoice}
                key={"commercial-invoice"}
              />
            ) : (
              <Route exact path={"/authentication/sign-in"} component={SignIn} key={"signin"} />
            )}

            {isAuthenticated ? (
              <Route path={"/btnt-docs/quotation"} component={QuotationInvoice} key={"quotation"} />
            ) : (
              <Route exact path={"/authentication/sign-in"} component={SignIn} key={"signin"} />
            )}

            {isAuthenticated ? (
              <Route
                path={"/btnt-docs/purchase-order"}
                component={PurchaseOrder}
                key={"purchase-order"}
              />
            ) : (
              <Route exact path={"/authentication/sign-in"} component={SignIn} key={"signin"} />
            )}

            {isAuthenticated ? (
              <Route 
                path={"/btnt-docs/list-suppliers"}
                component={ListSuppliers}
                key="list-suppliers"
              />
            ) : (
              <Route exact path={"/authentication/sign-in"} component={SignIn} key={"signin"} />
            )}

          {isAuthenticated ? (
              <Route 
                path={"/btnt-docs/list-traceability"}
                component={ListTraceability}
                key="list-traceable-products"
              />
            ) : (
              <Route exact path={"/authentication/sign-in"} component={SignIn} key={"signin"} />
            )}

            {isAuthenticated ? (
              <Route 
                path={"/btnt-docs/add-traceable-product"}
                component={AddTraceableProduct}
                key="add-traceable-product"
              />
            ) : (
              <Route exact path={"/authentication/sign-in"} component={SignIn} key={"signin"} />
            )}

            {isAuthenticated ? (
              <Route 
                path={"/btnt-docs/update-supplier"}
                component={UpdateSupplier}
                key="update-supplier"
              />
            ) : (
              <Route exact path={"/authentication/sign-in"} component={SignIn} key={"signin"} />
            )}
          

            {isAuthenticated ? (
              <Route path={"/btnt-docs/payment"} component={PaymentGateway} key={"payment"} />
            ) : (
              <Route exact path={"/authentication/sign-in"} component={SignIn} key={"signin"} />
            )}

            {isAuthenticated ? (
              <Route path={"/btnt-docs/stripe"} component={StripeContainer} key={"stripe"} />
            ) : (
              <Route exact path={"/authentication/sign-in"} component={SignIn} key={"signin"} />
            )}

            {isAuthenticated ? (
              <Route
                path={"/btnt-docs/payment-options"}
                component={PaymentOptions}
                key={"payment-options"}
              />
            ) : (
              <Route exact path={"/authentication/sign-in"} component={SignIn} key={"signin"} />
            )}

            {isAuthenticated ? (
              <Route
                path={"/btnt-docs/checkout-success/:id"}
                component={CheckoutSuccess}
                key={"checkout-success"}
              />
            ) : (
              <Route exact path={"/authentication/sign-in"} component={SignIn} key={"signin"} />
            )}

            {isAuthenticated ? (
              <Route
                path={"/btnt-docs/checkout-failed"}
                component={Checkoutfailed}
                key={"checkout-failed"}
              />
            ) : (
              <Route exact path={"/authentication/sign-in"} component={SignIn} key={"signin"} />
            )}

            {isAuthenticated ? (
              <Route path={"/btnt-docs/pricings"} component={PricingList} key={"pricings"} />
            ) : (
              <Route exact path={"/authentication/sign-in"} component={SignIn} key={"signin"} />
            )}

            {isAuthenticated ? (
              <Route
                path={"/btnt-docs/payment-details"}
                component={PaymentDetails}
                key={"payment-details"}
              />
            ) : (
              <Route exact path={"/authentication/sign-in"} component={SignIn} key={"signin"} />
            )}

            {isAuthenticated ? (
              <Route path={"/btnt-docs/inbox-page/:id"} component={InboxPage} key={"inbox-page"} />
            ) : (
              <Route exact path={"/authentication/sign-in"} component={SignIn} key={"signin"} />
            )}

            {isAuthenticated ? (
              <Route path={"/btnt-docs/sent-page/:id"} component={SentPage} key={"sent-page"} />
            ) : (
              <Route exact path={"/authentication/sign-in"} component={SignIn} key={"signin"} />
            )}

            {isAuthenticated ? (
              <Route path={"/btnt-docs/timeline"} component={TimeLine} key={"timeline"} />
            ) : (
              <Route exact path={"/authentication/sign-in"} component={SignIn} key={"signin"} />
            )}

            <Route path={"/forgot-password"} component={ForgotPassword} key={"forgot-password"} />
            <Route path={"/reset-password/:id"} component={ResetPassword} key={"reset-password"} />

            {isAuthenticated ? (
              <Redirect from="*" to="/btnt-docs/dashboard" />
            ) : (
              <Redirect from="*" to="/authentication/sign-in" />
            )}
          </Switch>
        </ThemeProvider>
      ) : (
        <Loader />
      )}
    </>
  );
}
