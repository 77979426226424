import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";

// Vision UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import dataTableData from "layouts/applications/data-tables/data/dataTableData";

import { getProformaInvoice } from "services/documentService";
import PDFDOCLIST from "./pdfPdocList";
import { PDFViewer } from "@react-pdf/renderer";
import QRCode from "qrcode";
import Picture from "./ship.png";
import { getCompanyById } from "services/documentService";
import { isMobile, isBrowser } from "react-device-detect";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { updateProformaOnChain } from "services/documentService";
import Swal from "sweetalert2";
import { getActivePlan } from "services/pricingService";

import { checkCreateDocs } from "services/pricingService";
import ButtonGroup from "@mui/material/ButtonGroup";
import { deleteProformaInvoiceById } from "services/documentService";
import { MdDelete } from "react-icons/md";
import { MdModeEdit } from "react-icons/md";
import { FaEdit } from "react-icons/fa";

function ListProformaInvoice() {
  let history = useHistory();

  const [proformaInvoice, setProformaInvoice] = useState("");
  const [proData, setProData] = useState({});
  const [fromCompanyData, setFromCompanyData] = useState({});
  const [toCompanyData, setToCompanyData] = useState({});
  const [qrData, setQrData] = useState("");
  const [showPreview, setShowPreview] = useState(false);
  const [uploaded, setUploaded] = useState(false);
  const [title, setTitle] = useState("Uploading");
  const [plan, setPlant] = useState();

  useEffect(() => {
    fetchProformaInvoice();
    fetchActivePLan();
  }, [uploaded]);

  const handleClosePreview = () => {
    setShowPreview(false);
  };

  const fetchActivePLan = async () => {
    const activePlanData = await getActivePlan();
    console.log(activePlanData);
  };

  const fetchProformaInvoice = () => {
    getProformaInvoice().then((Response) => {
      console.log(">>>>>>>>>>>>>>>>>>> proforma Invoice", Response);

      let newResponse = [];
      for (let item of Response) {
        newResponse.push({
          ...item,
          action1: (
            <VuiButton variant="text" color="info" size="medium" onClick={() => viewDoc(item)}>
              View
            </VuiButton>
          ),

          action2: (
            <VuiButton
              variant="gradient"
              color="info"
              size="medium"
              disabled={item.onBlockChain === 1}
              onClick={(e) => upload(e.target, item)}
            >
              Upload on BlockChain
            </VuiButton>
          ),

          action3: (
            <ButtonGroup disabled={item.onBlockChain === 1}>
              <VuiButton variant="gradient" color="info" onClick={() => handleEdit(item)}>
                <MdModeEdit />
              </VuiButton>
              <VuiButton variant="gradient" color="info" onClick={() => handleDelete(item)}>
                <MdDelete />
              </VuiButton>
            </ButtonGroup>
            // <VuiButton
            //   variant="gradient"
            //   color="info"
            //   size="medium"
            //   disabled={item.onBlockChain === 1}
            //   onClick={(e) => upload(e.target, item)}
            // >
            //   Upload on BlockChain
            // </VuiButton>
          ),
          action4: (
            <VuiButton variant="gradient" color="info" size="medium">
              Track
            </VuiButton>
          ),
        });
      }
      console.log("proforma res", newResponse);
      setProformaInvoice(newResponse);
    });
  };

  const dates = (time) => {
    var d = new Date(time);
    var time = d.getUTCMonth() + 1 + " / " + d.getUTCDate() + " / " + d.getUTCFullYear();
    return time;
  };

  const datesWithTime = (time) => {
    var d = new Date(time);
    var time =
      d.getUTCDate() +
      " / " +
      (d.getUTCMonth() + 1) +
      " / " +
      d.getUTCFullYear() +
      " " +
      d.getUTCHours() +
      ":" +
      d.getUTCMinutes();
    return time;
  };

  const getTime = (time) => {
    var d = new Date(time);
    var time = d.getUTCHours() + ":" + d.getUTCMinutes();
    return time;
  };

  // const showShipDetail = (data) => {
  //   console.log(data);
  //   history.push({
  //     pathname: "/merchant/active-ship-details",
  //     details: data,
  //   });
  // };

  const viewDoc = (item) => {
    console.log(item, "profitem");

    setProData(item);
    setFromCompanyData(item.fromCompanyDataId[0]);
    setToCompanyData(item.toCompanyDataId[0]);

    if (item.onBlockChain === 1) {
      const generateQrCode = async () => {
        try {
          const res = await QRCode.toDataURL(
            `https://bitdocs.bitnautic.com/btnt-docs/public-proformaInvoice/?onBlockChainHash=${item.onBlockChainHash}&transactionHash=${item.transactionHash}`
          );
          console.log(res);
          setQrData(res);
        } catch (err) {
          console.log(err);
        }
      };
      generateQrCode();
    } else {
      setQrData(null);
    }
    setShowPreview(true);

    // const fetchCompanyById = async () => {
    //   try {
    //     const fromCompanyId = await getCompanyById(item.from1);
    //     const toCompanyId = await getCompanyById(item.to1);

    //     setFromCompanyData(fromCompanyId[0]);
    //     setToCompanyData(toCompanyId[0]);

    //     setShowPreview(true);
    //   } catch (err) {
    //     console.log(err);
    //   }
    // };
    // fetchCompanyById();
  };

  const upload = (e, item) => {
    console.log(e);
    console.log(item.id, "Thiss IDD>>");
    // e.disabled = true;

    // let response = await updateProformaOnChain(item.id);
    // console.log("updateProformaOnChain resp", response);

    // updateProformaOnChain(item.id).then((response) => {
    //   console.log("response>>", response);
    // });

    // setUploaded((current) => !current);

    Swal.fire({
      position: "center",

      title: "Upload Initiated",
      showConfirmButton: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();

        updateProformaOnChain(item.id).then((response) => {
          console.log("response>>", response);

          if (response.success === false) {
            Swal.fire({
              title: "You've reached limit as per your plan.",
              text: "Upgrade plan",
              icon: "warning",
              showCancelButton: true,
              showConfirmButton: true,
              // confirmButtonText: "Yes, delete it!",
              // cancelButtonText: 'No, cancel!',
            }).then((result) => {
              if (result.isConfirmed) {
                history.push(`/btnt-docs/pricings`);
              }
            });
            return;
          }

          if (response.OnBlockChain.verifyHash === true) {
            setUploaded((current) => !current);
            // setTitle("Uploaded");
            // Swal.hideLoading();
            Swal.close();
          } else {
            Swal.fire({
              title: "Upload Failed",
              text: "Please try again",
              icon: "error",
              showConfirmButton: true,
            });
          }
        });
      },
    }).then(() => {
      Swal.fire({
        title: "Upload Successful",
        icon: "success",
        showConfirmButton: true,
      });
    });
  };

  const handleDelete = (item) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      // cancelButtonText: 'No, cancel!',
    }).then((result) => {
      if (result.isConfirmed) {
        deleteProformaInvoiceById(item.id).then((res) => {
          Swal.fire("", "Deleted successfully", "success");
          setUploaded((current) => !current);
          console.log("deleted", res);
        });
      }
    });

    // deleteProformaInvoiceById(item.id).then((res) => {
    //   Swal.fire("", "Deleted successfully", "success");
    //   setUploaded((current) => !current);
    //   console.log("deleted", res);
    // });
  };

  const handleEdit = (item) => {
    console.log("item", item);
    history.push(`/btnt-docs/profoma-invoice/?id=${item.id}`);
  };

  const proformaInvoiceTable = {
    columns: [
      // { Header: "MmsiKey", accessor: "mmsiKey"},
      // { Header: "IMO", accessor: "imo"},
      {
        Header: "UCR",
        accessor: "invoiceNumber",
        align: "center",
      },
      {
        Header: "Date",
        accessor: "dateOfInvoice",
        align: "center",
        Cell: (cell) => (
          <>
            <VuiTypography variant="sm" color="text" component="td">
              {dates(cell.row.values.dateOfInvoice)}
            </VuiTypography>
          </>
        ),
      },
      {
        Header: "Port of Loading",
        accessor: "portOfLoading",
        align: "center",
      },
      {
        Header: "Port of Discharge",
        accessor: "portOfDischarge",
        align: "center",
        // Cell: (cell) => (
        //   <>
        //     <VuiTypography variant="sm" color="text" component="td">
        //       {dates(cell.row.values.readyToLoad)}
        //     </VuiTypography>
        //     <VuiTypography variant="sm" color="text" component="p">
        //       {getTime(cell.row.values.readyToLoad)}
        //     </VuiTypography>
        //   </>
        // ),
      },
      {
        Header: "Transport",
        accessor: "methodOfDispatch",
        align: "center",
        // Cell: (cell) => (
        //   <>
        //     <VuiTypography variant="sm" color="text" component="td">
        //       {dates(cell.row.values.departureTime)}
        //     </VuiTypography>
        //     <VuiTypography variant="sm" color="text" component="p">
        //       {getTime(cell.row.values.departureTime)}
        //     </VuiTypography>
        //   </>
        // ),
      },

      {
        Header: "Action",
        accessor: "action1",
        align: "center",
        // Cell: (cell) => {
        //   return (
        //     <VuiButton
        //       variant="text"
        //       color="info"
        //       size="medium"
        //       onClick={() => upload(cell.row.original)}
        //     >
        //       View
        //     </VuiButton>
        //   );
        // },
      },

      {
        Header: "Action",
        accessor: "action3",
        align: "center",
      },

      {
        Header: "Action",
        accessor: "action2",
        align: "center",
        // Cell: (cell) => {
        //   return (
        //     <VuiButton
        //       variant="gradient"
        //       color="info"
        //       size="medium"
        //       onClick={() => upload(cell.row.original)}
        //     >
        //       Upload on BlockChain
        //     </VuiButton>
        //   );
        // },
      },

      {
        Header: "Action",
        accessor: "action4",
        align: "center",
      },
    ],

    rows: proformaInvoice ? proformaInvoice : [],
  };

  const addNewProformaInvoice = async () => {
    const verifyRes = await checkCreateDocs();

    if (verifyRes.success === false) {
      Swal.fire({
        title: "You've reached limit as per your plan.",
        text: "Upgrade plan",
        icon: "warning",
        showCancelButton: true,
        showConfirmButton: true,
        // confirmButtonText: "Yes, delete it!",
        // cancelButtonText: 'No, cancel!',
      }).then((result) => {
        if (result.isConfirmed) {
          history.push(`/btnt-docs/pricings`);
        }
      });
      return;
    }

    // getVerifyDocs().then((response) => {
    //   console.log("response>>", response);

    //   if (response.succes === false) {
    //     console.log("testing");

    //     Swal.fire("You've reached limit as per your plan.", "Buy a new plan", "warning");
    //     return;
    //   }
    // });

    history.push({
      pathname: "/btnt-docs/profoma-invoice",
    });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox pt={6} pb={3}>
        <Card>
          {!showPreview ? (
            <>
              <VuiBox sx={{ display: "flex", justifyContent: "flex-end" }}>
                <VuiButton
                  variant="gradient"
                  component="span"
                  color="info"
                  onClick={addNewProformaInvoice}
                >
                  Add New Proforma Invoice
                </VuiButton>
              </VuiBox>

              <VuiBox p={3} pl={0} lineHeight={1}>
                <VuiTypography variant="h5" fontWeight="medium" color="text">
                  Proforma Invoice Table
                </VuiTypography>
                <VuiTypography variant="button" fontWeight="regular" color="text">
                  List of all Proforma Invoices.
                </VuiTypography>
              </VuiBox>
              <DataTable table={proformaInvoiceTable} canSearch />
            </>
          ) : (
            ""
          )}

          {showPreview && isBrowser ? (
            <>
              <div style={{ marginBottom: "10px", display: "flex", justifyContent: "flex-end" }}>
                <VuiButton
                  variant="gradient"
                  component="span"
                  color="info"
                  sx={{ minWidth: "100px" }}
                  onClick={() => {
                    handleClosePreview();
                  }}
                >
                  Close
                </VuiButton>
              </div>
              <div>
                <PDFViewer style={{ height: "500px", width: "100%" }}>
                  <PDFDOCLIST
                    value={{
                      logo: proData.logourl,
                      fromCompany: fromCompanyData,
                      invoiceNumber: proData.invoiceNumber,
                      dateOfInvoice: dates(proData.dateOfInvoice),
                      toCompany: toCompanyData,
                      buyerReference: proData.buyersReference,
                      methodOfDispatch: proData.methodOfDispatch,
                      typeOfShipment: proData.typeOfShipment,
                      portOfLoading: proData.portOfLoading,
                      portOfDischarge: proData.portOfDischarge,
                      totalAmount: proData.totalAmount,
                      additionalInformation: proData.additionalInfo,
                      placeOfIssue: proData.placeOfIssue,
                      dateOfIssue: dates(proData.dateOfIssue),
                      signatoryCompany: proData.signatoryCompany,
                      nameOfAuthorizedSignatory: proData.nameOfAuthorizedSignatory,
                      signature: proData.signatureUrl,
                      bankDetails: proData.bankDetails,
                      goodsAndPackages: proData.goodsAndPackages,
                      qrData: qrData,
                    }}
                  />
                </PDFViewer>
              </div>
            </>
          ) : showPreview && isMobile ? (
            <VuiBox sx={{ textAlign: "center", height: "500px" }}>
              <VuiTypography marginBottom="2rem">
                Click on the button below to download Bill of Lading uploaded on blockchain
              </VuiTypography>
              <PDFDownloadLink
                document={
                  <PDFDOCLIST
                    value={{
                      logo: proData.logourl,
                      fromCompany: fromCompanyData,
                      invoiceNumber: proData.invoiceNumber,
                      dateOfInvoice: dates(proData.dateOfInvoice),
                      toCompany: toCompanyData,
                      buyerReference: proData.buyersReference,
                      methodOfDispatch: proData.methodOfDispatch,
                      typeOfShipment: proData.typeOfShipment,
                      portOfLoading: proData.portOfLoading,
                      portOfDischarge: proData.portOfDischarge,
                      totalAmount: proData.totalAmount,
                      additionalInformation: proData.additionalInfo,
                      placeOfIssue: proData.placeOfIssue,
                      dateOfIssue: dates(proData.dateOfIssue),
                      signatoryCompany: proData.signatoryCompany,
                      nameOfAuthorizedSignatory: proData.nameOfAuthorizedSignatory,
                      signature: proData.signatureUrl,
                      bankDetails: proData.bankDetails,
                      goodsAndPackages: proData.goodsAndPackages,
                      qrData: qrData,
                    }}
                  />
                }
                fileName="Document"
              >
                {({ loading }) =>
                  loading ? (
                    <VuiButton variant="gradient" color="info">
                      Please wait...
                    </VuiButton>
                  ) : (
                    <VuiButton variant="gradient" color="info">
                      Download Pdf
                    </VuiButton>
                  )
                }
              </PDFDownloadLink>
            </VuiBox>
          ) : null}
        </Card>
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ListProformaInvoice;
