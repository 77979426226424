// @mui material components
import Grid from "@mui/material/Grid";
import kalVisualsSquare from "assets/images/message-card-image.png";
// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import Calendar from "examples/Calendar";
import MessageCard from "examples/Cards/MessageCard";
import PlaceholderCard from "examples/Cards/PlaceholderCard";
import BasicLineChart from "examples/Charts/LineCharts/BasicLineChart";
import MiniGradientLineChart from "examples/Charts/LineCharts/MiniGradientLineChart";
import Footer from "examples/Footer";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import AnimatedStatisticsCard from "examples/Cards/StatisticsCards/AnimatedStatisticsCard";
import AnnouncementCard from "examples/Cards/AnnouncementCard";
// Vision UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import InvoicesList from "examples/Lists/InvoicesList";
import RankingsList from "examples/Lists/RankingsList";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Welcome from "layouts/pages/dashboard/crm/components/Welcome";
import calendarEventsData from "layouts/pages/dashboard/crm/data/calendarEventsData";
// Data
import invoicesListData from "layouts/pages/dashboard/crm/data/invoicesListData";
import rankingsListData from "layouts/pages/dashboard/crm/data/rankingsListData";
import { useMemo, useState, useEffect } from "react";
import DefaultDoughnutChart from "examples/Charts/DoughnutCharts/DefaultDoughnutChart";
import VerticalBarChart from "examples/Charts/BarCharts/VerticalBarChart";
import ProgressLineChart from "examples/Charts/LineCharts/ProgressLineChart";
import {
  lineChartDataCRM1,
  lineChartDataCRM2,
  lineChartOptionsCRM1,
  lineChartOptionsCRM2,
} from "./data/lineChart";
import {
  doughnutChartData,
  doughnutChartOptions,
} from "layouts/pages/dashboard/crm/data/defaultDoughnutChartData";
import {
  barChartDataVertical,
  barChartOptionsVertical,
} from "layouts/pages/dashboard/crm/data/verticalBarChartData";
import {
  lineChartDataGeneral1,
  lineChartOptionsGeneral1,
} from "layouts/pages/dashboard/crm/data/lineChartData";

import { FaShoppingCart } from "react-icons/fa";
import { BsGlobe } from "react-icons/bs";
import { IoWallet, IoDocumentText } from "react-icons/io5";
import { AiFillCheckCircle } from "react-icons/ai";
import { emailInboxReceived } from "services/mailService";
import { emailInboxSent } from "services/mailService";
import { getActivePlan } from "services/pricingService";
import { getUserFiles } from "services/mailService";
import team3 from "assets/images/avatar4.png";
import Moment from "react-moment";

function Dashboard() {
  const { transactionsData, revenueData } = rankingsListData;
  const [onBlockChainDoc, setOnBlockChainDoc] = useState("");
  const [billOfLadingCount, setBillOfLadingCount] = useState("0");
  const [commercialInvoiceCount, setCommercialInvoiceCount] = useState("0");
  const [proformaInvoiceCount, setProformaInvoiceCount] = useState("0");
  const [purchaseOrderCount, setPurchaseOrderCount] = useState("0");
  const [quotationCount, setQuotationCount] = useState("0");

  const [newData, setNewData] = useState([]);

  // let timeStamp = (time) => {
  //   var d = new Date(time);
  //   var time = d.getTime();
  //   return time;
  // };

  useEffect(() => {
    fetchActivePlan();
    fetchUserFiles();
    fetchNewData();
  }, []);

  const fetchUserFiles = async () => {
    const resp = await getUserFiles();
    // console.log("DashboardFiles", resp);
    setBillOfLadingCount(resp.billOflading.length);
    setCommercialInvoiceCount(resp.commercialInvoice.length);
    setProformaInvoiceCount(resp.proformaInvoice.length);
    setPurchaseOrderCount(resp.purchaseOrder.length);
    setQuotationCount(resp.quotation.length);
  };

  const fetchActivePlan = async () => {
    const activePlan = await getActivePlan();
    // console.log("ACTIVE", activePlan);
    setOnBlockChainDoc(activePlan.totalDocumentCountOnChain);
  };

  const fetchNewData = async () => {
    const resp = await emailInboxReceived();
    const resp2 = await emailInboxSent();

    const inboxMail = await resp.map((val, idx) => ({
      color: "success",
      icon: "arrow_upward",
      name: val.SenderEmailID,
      description: <Moment format="DD/MM/YYYY, hh:mm A">{val.timesent}</Moment>,
      value: "+ $ 2,000",
    }));

    //console.log("newAr", inboxMail);

    const sentMail = await resp2.map((val, idx) => ({
      color: "success",
      icon: "arrow_upward",
      name: val.SenderEmailID,
      description: <Moment format="DD/MM/YYYY, hh:mm A">{val.timesent}</Moment>,
      value: "+ $ 2,000",
    }));

    //console.log("Sent", sentMail);

    let newArr = inboxMail.concat(sentMail);

    let sorted = newArr.sort((x, y) => {
      return x.description - y.description;
    });

   // console.log("joined", sorted);

    setNewData(sorted);
  };

  // console.log("newData", newData);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={3}>
        <VuiBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                  <VuiBox mb={3}>
                    <MiniStatisticsCard
                      title={{ text: "Bill of Lading", fontWeight: "bold" }}
                      count={billOfLadingCount}
                      // percentage={{ color: "success", text: "+55%" }}
                      route="/btnt-docs/list-billoflading"
                      icon={<IoDocumentText color="white" />}
                    />
                  </VuiBox>

                  <MiniStatisticsCard
                    title={{ text: "Commercial Invoice", fontWeight: "bold" }}
                    count={commercialInvoiceCount}
                    // percentage={{ color: "success", text: "+3%" }}
                    route="/btnt-docs/list-commercial-invoice"
                    icon={<IoDocumentText color="white" />}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <VuiBox mb={3}>
                    <MiniStatisticsCard
                      title={{ text: "Proforma Invoice", fontWeight: "bold" }}
                      count={proformaInvoiceCount}
                      // percentage={{ color: "error", text: "-2%" }}
                      route="/btnt-docs/list-proforma-invoice"
                      icon={<IoDocumentText color="white" />}
                    />
                  </VuiBox>
                  <VuiBox mb={3}>
                    <MiniStatisticsCard
                      title={{ text: "Purchase Order", fontWeight: "bold" }}
                      count={purchaseOrderCount}
                      // percentage={{ color: "success", text: "+5%" }}
                      route="/btnt-docs/list-purchase-order"
                      icon={<IoDocumentText color="white" />}
                    />
                  </VuiBox>
                </Grid>

                <Grid item xs={12} md={4}>
                  <VuiBox mb={3}>
                    <MiniStatisticsCard
                      title={{ text: "Quotation", fontWeight: "bold" }}
                      count={quotationCount}
                      // percentage={{ color: "error", text: "-2%" }}
                      route="/btnt-docs/list-quotation"
                      icon={<IoDocumentText color="white" />}
                    />
                  </VuiBox>
                  <VuiBox mb={3}>
                    <MiniStatisticsCard
                      title={{ text: "On BlockChain Doc", fontWeight: "bold" }}
                      count={onBlockChainDoc}
                      // percentage={{ color: "success", text: "+5%" }}
                      icon={<IoDocumentText color="white" />}
                    />
                  </VuiBox>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid container item spacing={3} xs={8}>
                { /*<Grid item xs={12} md={12}>
                    <VuiBox mt={3}>
                      {useMemo(
                        () => (
                          <DefaultDoughnutChart
                            title="Doughnut chart"
                            data={doughnutChartData}
                            options={doughnutChartOptions}
                          />
                        ),
                        [doughnutChartData, doughnutChartOptions]
                      )}
                    </VuiBox>
                  </Grid> */ }
                  {/* <Grid item xs={12} md={4}>
                  
                  <VuiBox mt={2}>
                    <RankingsList
                      title="transactions"
                      date="23 - 30 March 2021"
                      rankings={newData}
                    />
                  </VuiBox>
                </Grid> */}

                  <Grid item xs={12} md={12}>
                    {useMemo(
                      () => (
                        <VerticalBarChart
                          title="Active Documents"
                          data={barChartDataVertical}
                          options={barChartOptionsVertical}
                        />
                      ),
                      [barChartDataVertical, barChartOptionsVertical]
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <VuiBox mt={2} height="100%">
                    <RankingsList
                      title="transactions"
                      action={{
                        label: "View all",
                        route: `/btnt-docs/inbox`,
                      }}
                      rankings={newData}
                    />
                  </VuiBox>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </VuiBox>
        <Grid container spacing={3}>
          {/* <Grid item xs={12} md={6}>
            <RankingsList
              title="transactions"
              date="23 - 30 March 2021"
              rankings={transactionsData}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <RankingsList title="revenue" date="01 - 07 June 2021" rankings={revenueData} />
          </Grid> */}

          {/* <Grid item xs={8}>
            {useMemo(
              () => (
                <VerticalBarChart
                  title="Bar chart"
                  data={barChartDataVertical}
                  options={barChartOptionsVertical}
                />
              ),
              [barChartDataVertical, barChartOptionsVertical]
            )}
          </Grid> */}
        </Grid>
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
