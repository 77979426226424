import React, { useEffect, useState } from "react";
// @mui material components
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import Grid from "@mui/material/Grid";
import breakpoints from "assets/theme/base/breakpoints";
import { useHistory } from "react-router-dom";
import { GoogleMap, withScriptjs, withGoogleMap, Polyline , Marker} from "react-google-maps";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";

// Vision UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import FormField from "layouts/pages/account/components/FormField";
import Footer from "examples/Footer";
import Globe from "examples/Globe";

let Map = () => {
    return <GoogleMap defaultZoom={10} defaultCenter={{ lat: 19.076090, lng: 72.877426 }} />;
};
  
const WrappedMap = withScriptjs(withGoogleMap(Map));

function activeShipDetails(props) {
    let history = useHistory();
    let activeShipDetails = props.location.details;
    const [markers, setMarkers] = useState([
      {lat: 19.076090, lng: 72.877426 }, 
      {lat: 19.997454, lng: 73.789803 }, 
      {lat: 21.004194, lng: 75.563942}, 
    ]);

    // useEffect(() => {
    //   if(JSON.parse(props.location.details.shipLocation).length > 0)
    //   {
    //     console.log(">>>>>>>>>>>.if", JSON.parse(props.location.details.shipLocation))
    //     let tempMarkers = []
    //     for(let item of JSON.parse(props.location.details.shipLocation))
    //     {
    //           tempMarkers.push({
    //             lat: item[0], lng: item[1]
    //           })
    //     }
    //     // setMarkers([...tempMarkers])
    //   }
    // },[])

   

    let dates = (time) => {
      var d = new Date(time);
      var time = d.getUTCMonth() + 1 + " / " + d.getDate() + " / " + d.getUTCFullYear();
      return time;
    };
  
    const { values } = breakpoints;
  
    const bookShipHandler = (shipKey, shipOwner) => {
      let bookingDetail = {
        serviceProviderID: shipOwner,
        shipMmsiKey: shipKey,
        status: "Booked",
      };
      postBookShip(bookingDetail);
    };

    let dateTime = (time) => {
      var d = new Date(time);
      var time = d.getUTCMonth() + 1 + " / " + d.getDate() + " / " + d.getUTCFullYear() + " - " + d.getHours() + " : " + d.getMinutes();
      return time;
    }

    let getTime = (time) => {
      var d = new Date(time);
      var time = "" + d.getHours() + " : " + d.getMinutes();
      return time;
    }
  
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <VuiBox pt={6} pb={3}>
          <Grid container>
            <VuiBox sx={{ width: "100%", height: "60vh" }}>
              <WrappedMap
                googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyCiIgS-3TfbHNe2MJmxxn7GgXjyu7WKiNI`}
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `100%` }} />}
                mapElement={<div style={{ height: `100%` }} />}
              >
                {markers.map((coords, index) => <Marker key={`marker-${index}`} position={coords}/>)}
               <Polyline 
                path={markers}
                strokeColor='#000000'
                strokeOpacity={0.8}
                strokeWeight={6}></Polyline>
              </WrappedMap>
            </VuiBox>
          </Grid>
          <Grid container>
            <Grid item xs={12} lg={10}>
              <VuiBox mb={3} p={1}>
                <VuiTypography
                  variant={window.innerWidth < values.sm ? "h3" : "h2"}
                  textTransform="capitalize"
                  fontWeight="bold"
                  color="white"
                >
                  Active Ship Details
                </VuiTypography>
              </VuiBox>
            </Grid>
          </Grid>
          <Card sx={{ overflow: "visible", zIndex: 10 }}>
            <VuiBox>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <VuiTypography
                    fontSize="0.75rem"
                    color="white"
                    fontWeight="bold"
                    paddingBottom="0.3rem"
                  >
                    MMSI KEY : {activeShipDetails.mmsiKey}
                  </VuiTypography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <VuiTypography
                    fontSize="0.75rem"
                    color="white"
                    fontWeight="bold"
                    paddingBottom="0.3rem"
                  >
                    Departure Time : &nbsp;&nbsp;{getTime(activeShipDetails.departureTime)}
                  </VuiTypography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <VuiTypography
                    fontSize="0.75rem"
                    color="white"
                    fontWeight="bold"
                    paddingBottom="0.3rem"
                  >
                    Name : {activeShipDetails.name}
                  </VuiTypography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <VuiTypography
                    fontSize="0.75rem"
                    color="white"
                    fontWeight="bold"
                    paddingBottom="0.3rem"
                  >
                    Destination Port : {activeShipDetails.destinationPort}
                  </VuiTypography>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                  <VuiTypography
                    fontSize="0.75rem"
                    color="white"
                    fontWeight="bold"
                    paddingBottom="0.3rem"
                  >
                    IMO : {activeShipDetails.imo}
                  </VuiTypography>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                  <VuiTypography
                    fontSize="0.75rem"
                    color="white"
                    fontWeight="bold"
                    paddingBottom="0.3rem"
                  >
                    Arrival Time : &nbsp;&nbsp;{getTime(activeShipDetails.arrivalTime)}
                  </VuiTypography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <VuiTypography
                    fontSize="0.75rem"
                    color="white"
                    fontWeight="bold"
                    paddingBottom="0.3rem"
                  >
                    Year of build : {dates(activeShipDetails.yearBuild)}
                  </VuiTypography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <VuiTypography
                    fontSize="0.75rem"
                    color="white"
                    fontWeight="bold"
                    paddingBottom="0.3rem"
                  >
                    Ready To Load : {dates(activeShipDetails.readyToLoad)}
                  </VuiTypography>
                </Grid>

                {/* <Grid item xs={12} sm={6}>
                  <VuiTypography
                    fontSize="0.75rem"
                    color="white"
                    fontWeight="bold"
                    paddingBottom="0.3rem"
                  >
                    Home Port : {activeShipDetails.homePort}
                  </VuiTypography>
                </Grid> */}

                <Grid item xs={12} sm={6}>
                  <VuiTypography
                    fontSize="0.75rem"
                    color="white"
                    fontWeight="bold"
                    paddingBottom="0.3rem"
                  >
                    Current Port : {activeShipDetails.currentPort}
                  </VuiTypography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <VuiTypography
                    fontSize="0.75rem"
                    color="white"
                    fontWeight="bold"
                    paddingBottom="0.3rem"
                  >
                   Call Sign : {activeShipDetails.callSign}
                  </VuiTypography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <VuiTypography
                    fontSize="0.75rem"
                    color="white"
                    fontWeight="bold"
                    paddingBottom="0.3rem"
                  >
                  Booking Status : {activeShipDetails.isBooked}
                  </VuiTypography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <VuiTypography
                    fontSize="0.75rem"
                    color="white"
                    fontWeight="bold"
                    paddingBottom="0.3rem"
                  >
                 Ship Flag : {activeShipDetails.shipFlag}
                  </VuiTypography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <VuiTypography
                    fontSize="0.75rem"
                    color="white"
                    fontWeight="bold"
                    paddingBottom="0.3rem"
                  >
                 Ship Price : {activeShipDetails.shipPrice}
                  </VuiTypography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <VuiTypography
                    fontSize="0.75rem"
                    color="white"
                    fontWeight="bold"
                    paddingBottom="0.3rem"
                  >
                 Dead Weight: : {activeShipDetails.deadWeight}
                  </VuiTypography>
                </Grid>
  
                <Grid item xs={12} sm={6}>
                  <VuiTypography
                    fontSize="0.75rem"
                    color="white"
                    fontWeight="bold"
                    paddingBottom="0.3rem"
                  >
                    Ship Status : {activeShipDetails.shipStatus}
                  </VuiTypography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <VuiTypography
                    fontSize="0.75rem"
                    color="white"
                    fontWeight="bold"
                    paddingBottom="0.3rem"
                  >
                    Capacity : {activeShipDetails.capacity}
                  </VuiTypography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <VuiTypography
                    fontSize="0.75rem"
                    color="white"
                    fontWeight="bold"
                    paddingBottom="0.3rem"
                  >
                    Category : {activeShipDetails.category}
                  </VuiTypography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <VuiTypography
                    fontSize="0.75rem"
                    color="white"
                    fontWeight="bold"
                    paddingBottom="0.3rem"
                  >
                    Ship Type : {activeShipDetails.shipType}
                  </VuiTypography>
                </Grid>

                <Grid item xs={12} sm={12}>
                  <VuiTypography
                    fontSize="0.75rem"
                    color="white"
                    fontWeight="bold"
                    paddingBottom="0.3rem"
                    align="right"
                  >
                    <VuiButton 
                        variant="gradient" 
                        color="info" 
                        size="medium" 
                        onClick={() =>  history.push({ pathname: "/merchant/active-ships"})}>
                            Go Back
                    </VuiButton>
                  </VuiTypography>
                </Grid>
                
              </Grid>
            </VuiBox>
          </Card>
        </VuiBox>
      </DashboardLayout>
    );
  }
  
  export default activeShipDetails;