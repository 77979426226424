const axios = require("axios");
import swal from "sweetalert";

import { baseUrl } from "./../helpers/baseUrl";

const TRACEABILITY_URL = `${baseUrl}/serviceProvider/traceability`;


const setupAxiosDefaultHeaders = () => {
	axios.defaults.headers.common["authorization"] = localStorage.getItem("jwtTokenBitDocs");
}

/*
const GET_METHOD  = "GET";
const POST_METHOD = "POST";
const quickAxiosRequest = (method, url, data) => {
	setupAxiosDefaultHeaders();

	try {
		if (method != GET_METHOD && method != POST_METHOD) {
			throw new Error(`axios quick request: unrecognized method (${method})`);
		}
		const response = method == GET_METHOD
	} catch (err) {

	}
}
*/


export async function getTraceableProducts() {
	setupAxiosDefaultHeaders();
  try {
    const response = await axios.get(`${TRACEABILITY_URL}/getTraceableProducts`);
    return response.data;
  } catch (err) {
    throw err;
  }
}


export async function getTraceableSuppliers() {
	setupAxiosDefaultHeaders();
  try {
    const response = await axios.get(`${TRACEABILITY_URL}/getTraceableSuppliers`);
    return response.data;
  } catch (err) {
    throw err;
  }
}

export async function addTraceableProduct(data) {
  setupAxiosDefaultHeaders();
  
  try {
    const response = await axios.post(
      `${TRACEABILITY_URL}/addTraceableProduct`,
      data
    );
    console.log("addTraceableProduct", response);
    return response;
  } catch (err) {
    console.log("addTraceableProduct", err.response);
    throw err;
  }

}

export async function getTraceableProductById(productId) {
  setupAxiosDefaultHeaders();
  const response = await axios.get(
    `${TRACEABILITY_URL}/getTraceableProductById?id=${productId}`
  );
  
  if (response) {
    return response.data;
  } else {
    swal("", response.message, "getTraceableProductByIdError");
  }

  return
}

export async function deleteTraceableProduct(productId) {
  setupAxiosDefaultHeaders();
  try {
    const response = await axios.get(`${TRACEABILITY_URL}/deleteTraceableProductById?id=${productId}`);
    return response.data;
  } catch (err) {
    throw err;
  }
}

export async function getSupplierDetailsById(supplierId) {
	setupAxiosDefaultHeaders();
  try {
    const response = await axios.get(`${TRACEABILITY_URL}/getTraceableSupplierById?id=${supplierId}`);
    return response.data;
  } catch (err) {
    throw err;
  }
}

export async function updateSupplierDetailsById(supplierId, data) {
	setupAxiosDefaultHeaders();
  try {
    const response = await axios.post(`${TRACEABILITY_URL}/updateSupplierDetailsById?id=${supplierId}`, data);
    return response.data;
  } catch (err) {
    throw err;
  }
}


export async function getTraceableProductViewableDataById(productId) {
  //setupAxiosDefaultHeaders();
  try {
    const response = await axios.get(`${TRACEABILITY_URL}/getTraceableProductViewableDataById?id=${productId}`);
    return response.data;
  } catch (err) {
    throw err;
  }
}


export async function uploadToBlockChainById(productId) {
  try {
    const response = await axios.post(`${TRACEABILITY_URL}/uploadToBlockChainById?id=${productId}`);
    return response.data;
  } catch (err) {
    throw err;
  }
}