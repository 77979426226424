import React from "react";
import ReactDOM from "react-dom";
// import { HashRouter } from "react-router-dom";
import { BrowserRouter as Router } from "react-router-dom";
import App from "App";
import { Provider } from "react-redux";

import store from "./store";

// Vision UI Context Provider
import { VisionUIControllerProvider } from "context";

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <VisionUIControllerProvider>
        <App />
      </VisionUIControllerProvider>
    </Router>
  </Provider>,
  document.getElementById("root")
);
