// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import { useState, useEffect } from "react";

// @mui material components
import Icon from "@mui/material/Icon";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";
import linearGradient from "assets/theme/functions/linearGradient";
import colors from "assets/theme/base/colors";
import { getActivePlan } from "services/pricingService";

function Bill({ name, company, email, vat, noGutter }) {
  const { gradients } = colors;
  const { bill } = gradients;

  const [availableDoc, setAvailableDoc] = useState("0");
  const [planName, setPlanName] = useState("");
  const [smartDoc, setSmartDoc] = useState("");
  const [smartDocNotOnChain, setSmartDocNotOnChain] = useState("");
  const [btntPrice, setBtntPrice] = useState("");
  const [amountUsd, setAmountUsd] = useState("");

  useEffect(() => {
    fetchActivePlan();
  }, []);

  const fetchActivePlan = async () => {
    const resp = await getActivePlan();
    console.log("resp", resp);

    if (resp.success === false) {
      setAvailableDoc("0");
    } else {
      setAvailableDoc(resp.totalDocumentCountAvailable - resp.totalDocumentCountUsed);
      setPlanName(resp.name);
      setSmartDoc(resp.smartDocumentsCount);
      setSmartDocNotOnChain(resp.smartDocumentsNotOnChainCount);
      setBtntPrice(resp.btntPrice);
      setAmountUsd(resp.amountUsd);
    }
  };

  return (
    <VuiBox
      component="li"
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
      sx={{ background: "#F2F0FE" }}
      borderRadius="lg"
      p="24px"
      mb={noGutter ? "0px" : "24px"}
      mt="20px"
    >
      <VuiBox width="100%" display="flex" flexDirection="column">
        <VuiBox
          display="flex"
          justifyContent="space-between"
          alignItems={{ xs: "flex-start", sm: "center" }}
          flexDirection={{ xs: "column", sm: "row" }}
          mb="5px"
        >
          <VuiTypography
            variant="button"
            color="text"
            fontWeight="medium"
            textTransform="capitalize"
          >
            {/* {planName} */}
          </VuiTypography>

          <VuiBox
            display="flex"
            alignItems="center"
            mt={{ xs: 2, sm: 0 }}
            ml={{ xs: -1.5, sm: 0 }}
            sx={({ breakpoints }) => ({
              [breakpoints.only("sm")]: {
                flexDirection: "column",
              },
            })}
          >
            {/* <VuiBox mr={1}>
              <VuiButton variant="text" color="error">
                <Icon sx={{ mr: "4px" }}>delete</Icon>&nbsp;DELETE
              </VuiButton>
            </VuiBox> */}
            {/* <VuiButton variant="text" color="text">
              <Icon sx={{ mr: "4px" }}>edit</Icon>&nbsp;EDIT
            </VuiButton> */}
          </VuiBox>
        </VuiBox>
        <VuiBox mb={1} lineHeight={0}>
          <VuiTypography variant="caption" color="text">
            TotalDocumentCountAvailable:&nbsp;&nbsp;&nbsp;
            <VuiTypography
              variant="caption"
              color="text"
              fontWeight="regular"
              textTransform="capitalize"
            >
              {availableDoc}
            </VuiTypography>
          </VuiTypography>
        </VuiBox>
        <VuiBox mb={1} lineHeight={0}>
          <VuiTypography variant="caption" color="text">
            SmartDocumentsCount:&nbsp;&nbsp;&nbsp;
            <VuiTypography variant="caption" fontWeight="regular" color="text">
              {smartDoc}
            </VuiTypography>
          </VuiTypography>
        </VuiBox>
        <VuiBox mb={1} lineHeight={0}>
          <VuiTypography variant="caption" color="text">
            SmartDocumentsNotOnChainCount:&nbsp;&nbsp;&nbsp;
            <VuiTypography variant="caption" fontWeight="regular" color="text">
              {smartDocNotOnChain}
            </VuiTypography>
          </VuiTypography>
        </VuiBox>
        <VuiBox mb={1} lineHeight={0}>
          <VuiTypography variant="caption" color="text">
            BtntPrice:&nbsp;&nbsp;&nbsp;
            <VuiTypography variant="caption" fontWeight="regular" color="text">
              {btntPrice}
            </VuiTypography>
          </VuiTypography>
        </VuiBox>
        <VuiTypography variant="caption" color="text">
          AmountUsd:&nbsp;&nbsp;&nbsp;
          <VuiTypography variant="caption" fontWeight="regular" color="text">
            {amountUsd}
          </VuiTypography>
        </VuiTypography>
      </VuiBox>
    </VuiBox>
  );
}

// Setting default values for the props of Bill
Bill.defaultProps = {
  noGutter: false,
};

// Typechecking props for the Bill
Bill.propTypes = {
  name: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  vat: PropTypes.string.isRequired,
  noGutter: PropTypes.bool,
};

export default Bill;
