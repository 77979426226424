import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";

// Vision UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import dataTableData from "layouts/applications/data-tables/data/dataTableData";


// import { getSmartDocuments } from "services/documentService";
import { isMobile, isBrowser } from "react-device-detect";
import { PDFViewer } from "@react-pdf/renderer";
import { PDFDownloadLink } from "@react-pdf/renderer";
// import PDFDocument from "../add-bill-of-lading/pdfDocument";
import QRCode from "qrcode";
import Swal from "sweetalert2";

import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { MdDelete } from "react-icons/md";
import { MdModeEdit } from "react-icons/md";
import { FaEdit } from "react-icons/fa";

import { getTraceableSuppliers } from "services/traceabilityService";
import Modal from "@mui/material/Modal";
import CircularProgress from "@mui/material/CircularProgress";


import EditIcon from "@mui/icons-material/Edit";

const TRACEABLE_HEADER_COLUMNS =  [
	{
		Header: "Product",
		accessor: "product_name",
		align: "left"
	},
	{
		Header: "Supplier",
		accessor: "supplier_name",
		align: "left"
	},
	{
		Header: "Actions",
		accessor: "actions",
		align: "center",
		width: "30px"
	},
];

function ListSuppliers() {
	let history = useHistory();

	const [loading, setLoading] = useState(true);
	const [traceableSuppliersData, setTraceableSuppliersData] = useState([]);

	const handleEdit = (productName, supplierId) => {
		if (loading) return;
		setLoading(true);
		history.push(`/btnt-docs/update-supplier?id=${encodeURIComponent(supplierId)}&productname=${encodeURIComponent(productName)}`);

		console.log(productName, supplierId);
		// api edit traceable product 
		// if api then delete manually traceablePorductsData through index
	}

	useEffect(() => {
		setLoading(true);
		getTraceableSuppliers()
			.then((response) => {
				setLoading(false);
				setTraceableSuppliersData(response);
			})
			.catch((err) => {
				Swal.fire({
					icon: "error",
					text: "Something went wrong!",
					footer: err,
					showConfirmButton: true
				}).then(() => {
					history.push("/btnt-docs/");
				});
			});
	}, []);

	const onSeeTraceableProductsListClicked = () => {
		if (loading) return;
		history.push({
			pathname: "/btnt-docs/list-traceability",
		});
	}

	return (
		<DashboardLayout>
			<Modal open={loading} >
				<CircularProgress disableShrink sx={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}/>
			</Modal>
			<ButtonGroup size="small" style={{ marginBottom: "10px" }}>
				<Button style={{ backgroundColor: "#5081d9", color: "#fff" }} onClick={() => {history.push('/btnt-docs/add-traceable-product')}}>Add Product</Button>
				<Button style={{ backgroundColor: "#5081d9", color: "#fff" }} onClick={() => {history.push('/btnt-docs/list-traceability')}}>See Products</Button>
				<Button style={{ backgroundColor: "#5081d9", color: "#fff" }} disabled>Suppliers</Button>
			</ButtonGroup>
			<Card>
				{/*
				<VuiBox sx={{ display: "flex", justifyContent: "flex-end" }}>
					<VuiButton
						variant="gradient"
						component="span"
						color="info"
						onClick={onSeeTraceableProductsListClicked}
					>
						See Traceable Products List
					</VuiButton>
				</VuiBox>
				*/}

				<VuiBox p={3} pl={0} lineHeight={1}>
					<VuiTypography variant="h5" fontWeight="medium" color="text">
						Products that need your description.
					</VuiTypography>
					<VuiTypography variant="button" fontWeight="regular" color="text">
						List of supplier information requested
					</VuiTypography>
				</VuiBox>
				<DataTable canSearch table={{
					columns: TRACEABLE_HEADER_COLUMNS, 
					rows: traceableSuppliersData.map( (el, index) => {
						return {
							product_name: el.product_name,
							supplier_name: el.name,
							actions: (
								<ButtonGroup>
									<VuiButton variant="gradient" color="info" onClick={() => handleEdit(el.product_name, el.id)}>
										<EditIcon />
									</VuiButton>
								</ButtonGroup>
							)
						}
					})
				}} />
			</Card>
			<Footer />
		</DashboardLayout>
	);
}

export default ListSuppliers;