// prop-types is a library for typechecking of props
import React from "react";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
// @mui material components
import Grid from "@mui/material/Grid";
// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiButton from "components/VuiButton";

// Vision UI Dashboard PRO React example components
import DefaultPricingCard from "examples/Cards/PricingCards/DefaultPricingCard";
import PropTypes from "prop-types";
import { getPlansList } from "services/pricingService";
import VuiTypography from "components/VuiTypography";
import { createCheckoutSession } from "services/pricingService";
import { loadStripe } from "@stripe/stripe-js";
import { createBtntCheckoutSession } from "services/pricingService";

function PricingCards() {
  // { prices }
  let history = useHistory();
  const [plansList, setPlansList] = useState([]);
  const [plansListBoolean, setPlansListBoolean] = useState(false);
  const [amountUsd, setAmountUsd] = useState("");
  const [btntPrice, setBtntPrice] = useState("");
  const [name, setName] = useState("");
  const [smartDocCount, setSmartDocCount] = useState("");
  const [smartDocNotOnChain, setSmartDocNotOnChain] = useState("");
  const stripe = loadStripe(
    "pk_live_51LlAb8ABaeYGwH3nBqGSDvVKI2zB8LlmoWZNe14j2u63S3hfw13D15PCsPWREI7rHbeBFUnf4nLGvdudnlZF9Ire00LoJ7TOIF"
    // "pk_test_51LlAb8ABaeYGwH3nmCY14tdV2HF7z3V0x8Gu3zFLNp2YFKis4gKGDk7oanwmYcDxnKQYXMrsSu9lCJG00LCt8WfK00hRIDhrYa"
  );

  // const [basic, starter, business] = prices;

  useEffect(() => {
    fetchPlansList();
  }, []);

  const fetchPlansList = async () => {
    const response = await getPlansList();
    console.log("plansList", response);
    setPlansList(response);
    setPlansListBoolean(true);
  };

  const handleBuyBtnt = async (priceId, quantity, planId) => {
    console.log("values");

    const productData = {
      priceId: priceId,
      quantity: quantity,
      planId: planId,
    };

    const createBtntCheckoutSessionRes = await createBtntCheckoutSession(productData);

    console.log("SessionRes", createBtntCheckoutSessionRes);

    if (createBtntCheckoutSessionRes) {
      history.push(
        `/btnt-docs/payment-details/?token=${createBtntCheckoutSessionRes.orderData.paymentToken}&price=${createBtntCheckoutSessionRes.orderData.btntPrice}`
      );
    }
    // console.log("SessionRes", createBtntCheckoutSessionRes.orderData.paymentToken);

    // (await stripe).redirectToCheckout({
    //   sessionId: createCheckoutSessionRes.orderData.paymentToken,
    // });
  };

  const handleBuyStripe = async (priceId, quantity, planId) => {
    console.log("values");

    const productData = {
      priceId: priceId,
      quantity: quantity,
      planId: planId,
    };

    const createCheckoutSessionRes = await createCheckoutSession(productData);

    console.log("SessionRes", createCheckoutSessionRes);
    console.log("SessionRes", createCheckoutSessionRes.orderData.paymentToken);

    (await stripe).redirectToCheckout({
      sessionId: createCheckoutSessionRes.orderData.paymentToken,
    });
  };

  return (
    <VuiBox
      position="relative"
      // zIndex={10}
      // mt={{ xs: -17, xl: -19, xxl: -19 }}
      // px={{ xs: 1, sm: 0 }}
    >
      <Grid
        container
        spacing={3}
        sx={{ display: "flex", justifyContent: "center", flexDirection: "row" }}
      >
        {plansListBoolean
          ? plansList.map((val, idx) => {
              return (
                <Grid item xs={12} lg={3}>
                  <DefaultPricingCard
                    bestValue={val.name}
                    badge={{ color: "dark", label: val.name }}
                    price={{ currency: "$", value: val.amountUsd }}
                    specifications={[
                      {
                        label: `Create and upload on Blockchain (${val.smartDocumentsCount} BTNT ${
                          val.smartDocumentsCount > 1 ? "documents" : "document"
                        })`,
                        includes: true,
                      },
                      { label: "Create and save PDF", includes: true },
                      { label: "Proforma Invoice", includes: true },
                      { label: "Commercial Invoice", includes: true },
                      { label: "Quotation", includes: true },
                      { label: "Bill of Lading", includes: true },
                      { label: "Purchase Order", includes: true },
                    ]}
                    action={{
                      type: "internal",
                      // route: `/bit-docs/payment-details/${val.id}`,
                      color: "info",
                      label: "Pay with Crypto",
                      fire: () => handleBuyBtnt(val.priceApiId, "1", val.id),
                    }}
                    action2={{
                      type: "internal",
                      // route: `https://buy.stripe.com/test_6oE9CQd9Xfoc7MQaEE`,
                      color: "info",
                      label: "Pay with Card",
                      fire: () => handleBuyStripe(val.priceApiId, "1", val.id),
                    }}
                  />
                </Grid>
              );
            })
          : null}

        {/* <Grid item xs={12} lg={3}>
          <DefaultPricingCard
            badge={{ color: "dark", label: "Starter" }}
            price={{ currency: "$", value: starter }}
            specifications={[
              { label: "Create and upload on Blockchain (Upto 100 documents)", includes: true },
              { label: "Create and save PDF", includes: true },
              { label: "Proforma Invoice", includes: true },
              { label: "Commercial Invoice", includes: true },
              { label: "Quotation", includes: true },
              { label: "Bill of Lading", includes: true },
              { label: "Purchase Order", includes: true },
            ]}
            action={{
              type: "internal",
              route: "/",
              color: "info",
              label: "try premium",
            }}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <DefaultPricingCard
            badge={{ color: "dark", label: "Business" }}
            price={{ currency: "$", value: business }}
            specifications={[
              { label: "Create and upload on Blockchain (Upto 500 documents)", includes: true },
              { label: "Create and save PDF", includes: true },
              { label: "Proforma Invoice", includes: true },
              { label: "Commercial Invoice", includes: true },
              { label: "Quotation", includes: true },
              { label: "Bill of Lading", includes: true },
              { label: "Purchase Order", includes: true },
            ]}
            action={{
              type: "internal",
              route: "/",
              color: "info",
              label: "join now",
            }}
          />
        </Grid> */}
      </Grid>
    </VuiBox>
  );
}

// Typechecking props for the PricingCards
PricingCards.propTypes = {
  prices: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default PricingCards;
