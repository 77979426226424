import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";


import { styled } from '@mui/material/styles';
// @mui material components
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import AddIcon from "@mui/icons-material/Add";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
// @mui material components
// import VuiTypography from "components/VuiTypography";
// Vision UI Dashboard PRO React components
// import VuiBox from "components/VuiBox";;
import VuiSelect from "components/VuiSelect";
import VuiDatePicker from "components/VuiDatePicker";
// import VuiButton from "components/VuiButton";
import VuiInput from "components/VuiInput";
import VuiSwitch from "components/VuiSwitch";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";

// Vision UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import dataTableData from "layouts/applications/data-tables/data/dataTableData";

import { getProformaInvoice } from "services/documentService";
import { PDFViewer } from "@react-pdf/renderer";
import QRCode from "qrcode";
import Picture from "./ship.png";
import { getCompanyById } from "services/documentService";
import { isMobile, isBrowser } from "react-device-detect";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { updateProformaOnChain } from "services/documentService";
import Swal from "sweetalert2";
import { getActivePlan } from "services/pricingService";

import { checkCreateDocs } from "services/pricingService";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { deleteProformaInvoiceById } from "services/documentService";
import { MdDelete } from "react-icons/md";
import { MdModeEdit } from "react-icons/md";
import { FaEdit } from "react-icons/fa";

import ShipImage from "./ship.png";
import AttributeDescriptionTable from "./AttributeDescriptionTable";

import PreviewSection from "./PreviewSection";
import ProductSection from "./ProductSection";

import Validations from "shared/validations";
import ValidationService from "shared/validationService";
import validator from "validator";


import { addTraceableProduct } from "services/traceabilityService";

import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

const ShipImageFile = new File(
	[Buffer.from(ShipImage.split(",")[1], 'base64')], 
	"ShipImage.png", 
);	

const PRODUCT_DATA = {
	companyLogoFile: ShipImageFile,
	productImageFile: ShipImageFile, 

	companyLogo: ShipImage,
	productImage: ShipImage,

	product: {
		name: "",
		description: "",
		attributes: [
			{attribute: "", description: ""}
		]
	},
	suppliers: [
		{
			name: "",
			email: "",
		}
	]
}


const isEmpty = validator.isEmpty;
function createValidationDetails(productData) {
	const validationDetails = {};
	let didNotMeetValidation = false;

	// TODO: Migrate to custom_validation used in other code, though need to change function heavily
	/*
	const validationSchema = [
		{
			name: "productName",
			value: productData.product.name,
			validations: Validations.traceability.productName
		}
	];

	ValidationService.validate(validationSchema, (errCount, errMessage) => {
		if (validationErr.length > 0) {
			didNotMeetValidation = true;
			validationDetails
		}
	})
	*/

	if (productData.product.name.trim().length < 1 || productData.product.name.trim().length > 200) {
		didNotMeetValidation = true;
		validationDetails.productName = "Product name needs to be within 1 to 200 characters long";
	}

	if (productData.product.description.trim().length < 1 || productData.product.description.trim().length > 400) {
		didNotMeetValidation = true;
		validationDetails.productDescription = "Product description needs to be within 1 to 400 characters long";
	}

	productData.product.attributes.forEach((el) => {
		if (el.attribute.trim().length < 1 || el.attribute.trim().length > 30) {
			didNotMeetValidation = true;
			validationDetails.productAttributes = "Attribute name needs to be within 1 to 30 characters long"
		}
		else if (el.description.trim().length < 1 || el.description.trim().length > 180) {
			didNotMeetValidation = true;
			validationDetails.productAttributes = "Attribute description needs to be within 1 to 180 characters long"
		}
	})

	productData.suppliers.forEach((el, index) => {
		if (el.name.trim().length < 1 || el.name.trim().length > 200) {
			didNotMeetValidation = true;
			validationDetails.suppliers = validationDetails.suppliers || [];
			validationDetails.suppliers[index] = validationDetails.suppliers[index] || {}
			validationDetails.suppliers[index].name = "Name needs to be within 1 to 200 characters long"
		}
		if (el.email.trim().length < 1 || el.email.trim().length > 50) {
			didNotMeetValidation = true;
			validationDetails.suppliers = validationDetails.suppliers || [];
			validationDetails.suppliers[index] = validationDetails.suppliers[index] || {}
			validationDetails.suppliers[index].email = "Email needs to be within 1 to 50 characters long"
		} 
		else if (!validator.isEmail(el.email)) {
			didNotMeetValidation = true;
			validationDetails.suppliers = validationDetails.suppliers || [];
			validationDetails.suppliers[index] = validationDetails.suppliers[index] || {}
			validationDetails.suppliers[index].email = "Invalid email format, should be (name@domain)!"
		}
	});
	
	return (didNotMeetValidation && validationDetails);
}

function AddTraceableProduct() {
	let history = useHistory();
	const [loading, setLoading] = useState(false);
	const [productData, setProductData] = useState(PRODUCT_DATA);
	const [validationDetails, setValidationDetails] = useState(null);

	const onSubmit = () => {
		if (loading) {
			return;
		}

		setLoading(true);

		const newValidationDetails = createValidationDetails(productData);

		if (newValidationDetails) {
			setValidationDetails(newValidationDetails);
			setLoading(false);
			return;
		}

		setValidationDetails(null);

		let formData = new FormData();

		formData.append("companyLogoFile", productData.companyLogoFile);
		formData.append("productImageFile", productData.productImageFile);
		formData.append("data", JSON.stringify({
			product: productData.product,
			suppliers: productData.suppliers
		}));

		console.log(formData);

		addTraceableProduct(formData)
			.then(response => {
				setLoading(false);
				Swal.fire({text: "Product created successfully!", icon: "success", showConfirmButton: false, timer: 1500}).then(() => {
					history.push("/btnt-docs/list-traceability");
				});
			})
			.catch(error => {
				setLoading(false);
				Swal.fire({
					icon: "error",
					text: "Something went wrong!",
					footer: error
				});
		})
	}

	return (
		<DashboardLayout>
			<Modal open={loading} >
				<CircularProgress disableShrink sx={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}/>
			</Modal>
			<ButtonGroup size="small" style={{marginBottom: "10px"}}>
				<Button style={{backgroundColor: "#5081d9", color: "#fff"}} disabled>Add Product</Button>
				<Button style={{backgroundColor: "#5081d9", color: "#fff"}} onClick={() => {history.push('/btnt-docs/list-traceability')}}>See Products</Button>
				<Button style={{backgroundColor: "#5081d9", color: "#fff"}} onClick={() => {history.push('/btnt-docs/list-suppliers') }}>Suppliers</Button>
			</ButtonGroup>
			<Card id="basic-info" sx={{ overflow: "visible" }}>
				{/* Page title */}
				<VuiBox p={3} pl={0} lineHeight={1}>
					<VuiTypography variant="h5" fontWeight="medium" color="text">
						Create Product
					</VuiTypography>
					<VuiTypography variant="button" fontWeight="regular" color="text">
						Write your product and brand story, and make it accessible through blockchain using QR Code.
					</VuiTypography>
				</VuiBox>

				<ProductSection productData={productData}
							    onSubmit={onSubmit} validationDetails={validationDetails}       
								onProductDataChanged={(newData) => {if (!loading) { setProductData({...newData}) }}} 
				/> 
			</Card>
		</DashboardLayout>
	)
}

export default AddTraceableProduct;