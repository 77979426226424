import React from "react";
import Card from "@mui/material/Card";
import { useHistory } from "react-router-dom";
import { getRequestForm } from "services/userService";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";

// Vision UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import { useEffect, useState } from "react";

const GetRequest = () => {
  let history = useHistory();
  const [requestData, setRequestData] = useState("");

  let getDate = (date) => {
    var d = new Date(date);
    var date = d.getUTCMonth() + 1 + " / " + d.getUTCDate() + " / " + d.getUTCFullYear();
    return date;
  };

  let getTime = (time) => {
    var d = new Date(time);
    var time = d.getUTCHours() + " : " + d.getUTCMinutes();
    return time;
  };

  const dateWithTime = (time) => {
    var d = new Date(time);
    var time =
      d.getUTCDate() +
      " / " +
      (d.getUTCMonth() + 1) +
      " / " +
      d.getUTCFullYear() +
      " " +
      d.getUTCHours() +
      ":" +
      d.getUTCMinutes();

    return time;
  };

  useEffect(async () => {
    await getRequestFormData();
  }, []);

  const getRequestFormData = () => {
    getRequestForm().then((response) => {
      console.log(response);
      setRequestData(response);
    });
  };

  const dataTableData = {
    columns: [
      { Header: "Name", accessor: "name", align: "center" },
      { Header: "Contact", accessor: "phone", align: "center" },
      { Header: "Email", accessor: "email", align: "center" },
      { Header: "Commodity Name", accessor: "commodityName", align: "center" },
      { Header: "Budget", accessor: "budget", align: "center" },
      { Header: "Description", accessor: "description", align: "center" },
    ],
    rows: requestData ? requestData : [],
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox pt={6} pb={3}>
        <Card>
          <VuiBox p={3} pl={0} lineHeight={1}>
            <VuiTypography variant="h5" fontWeight="medium" color="white">
              Request Form Data
            </VuiTypography>
            <VuiTypography variant="button" fontWeight="regular" color="text">
              List of all requested forms.
            </VuiTypography>
          </VuiBox>
          <DataTable table={dataTableData} canSearch />
        </Card>
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
};

export default GetRequest;
